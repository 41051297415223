import API from "./API";

export const getPropertyDocuments = (payload) => {
   return API.get(
      `/api/property/getPropertyDocument/${payload.pagination.page}/${payload.pagination.limit}`,
      { params: payload.data }
   );
};

export const getTenantDocuments = (payload) => {
   return API.get(
      `/api/tenant/getTenantDocument/${payload.pagination.page}/${payload.pagination.limit}`,
      { params: payload.data }
   );
};

export const getLandlordDocuments = (payload) => {
   return API.get(
      `/api/landlord/getLandlordDocument/${payload.pagination.page}/${payload.pagination.limit}`,
      { params: payload.data }
   );
};
