import produce from "immer";
import { toast } from "react-toastify";
const INITIAL_STATE = {
   productPayment: {
      loading: false,
      success: false,
      response: [],
      error: null
   },

   customerDetails: {
      loading: false,
      success: false,
      response: null,
      error: null
   },

   billingDetails: {
      loading: false,
      success: false,
      response: null,
      error: null
   },

   PaymentDetails: {
      loading: false,
      success: false,
      response: null,
      error: null
   },

   InvoiceDetails: {
      loading: false,
      success: false,
      response: null,
      hasmore: false,
      error: null
   },

   ReceiptDetails: {
      loading: false,
      success: false,
      response: null,
      hasmore: false,
      error: null
   },

   customerPortal: {
      loading: false,
      success: false,
      response: null,
      error: null
   },

   paymentPortal: {
      loading: false,
      success: false,
      response: null,
      error: null
   },

   paymentMethod: {
      loading: false,
      success: false,
      response: null,
      error: null
   },

   customerSubscription: {
      loading: false,
      success: false,
      response: null,
      error: null
   },

   subscriptionHistory: {
      loading: false,
      success: false,
      response: null,
      error: null
   },
   deletePaymentMethod: {
      success: false,
      error: null
   },
   cancelCustomerSubscription: {
      success: false,
      error: null,
      loading: false
   }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_ALL_PRODUCT_WITH_PRICE":
            draft.productPayment.loading = true;

            break;

         case "GET_ALL_PRODUCT_WITH_PRICE_SUCCESS":
            draft.productPayment.loading = false;
            draft.productPayment.success = true;
            draft.productPayment.response = action.response.data.data;

            break;

         case "GET_ALL_PRODUCT_WITH_PRICE_FAIL":
            draft.productPayment.loading = false;
            draft.productPayment.error = action.data;
            draft.productPayment.success = false;
            draft.productPayment.response = null;
            break;

         case "GET_CONSUMER_DETAILS":
            draft.customerDetails.loading = true;

            break;

         case "GET_CONSUMER_DETAILS_SUCCESS":
            draft.customerDetails.loading = false;
            draft.customerDetails.success = true;
            draft.customerDetails.response = action.response.data;

            break;

         case "GET_CONSUMER_DETAILS_FAIL":
            draft.customerDetails.loading = false;
            draft.customerDetails.error = action.data;
            draft.customerDetails.success = false;
            draft.customerDetails.response = null;
            break;

         case "OPEN_CUSTOMER_PORTAL":
            draft.customerPortal.loading = true;
            break;
         case "OPEN_CUSTOMER_PORTAL_SUCCESS":
            draft.customerPortal.loading = false;
            draft.customerPortal.success = true;
            draft.customerPortal.response = action.response.data;
            break;
         case "OPEN_CUSTOMER_PORTAL_FAIL":
            draft.customerPortal.loading = false;
            draft.customerPortal.error = action.data;
            draft.customerPortal.success = false;
            draft.customerPortal.response = null;
            break;

         case "OPEN_PAYMENT_PORTAL":
            draft.paymentPortal.loading = true;
            break;
         case "OPEN_PAYMENT_PORTAL_SUCCESS":
            draft.paymentPortal.loading = false;
            draft.paymentPortal.success = true;
            draft.paymentPortal.response = action.response.data;
            break;
         case "OPEN_PAYMENT_PORTAL_FAIL":
            draft.paymentPortal.loading = false;
            draft.paymentPortal.error = action.data;
            draft.paymentPortal.success = false;
            draft.paymentPortal.response = null;
            break;

         case "GET_PAYMENT_METHOD":
            draft.PaymentDetails.loading = true;
            break;
         case "GET_PAYMENT_METHOD_SUCCESS":
            draft.PaymentDetails.loading = false;
            draft.PaymentDetails.success = true;
            draft.PaymentDetails.response = action.payload.data;
            break;
         case "GET_PAYMENT_METHOD_ERROR":
            draft.PaymentDetails.loading = false;
            draft.PaymentDetails.error = action.data;
            draft.PaymentDetails.success = false;
            draft.PaymentDetails.response = null;
            break;

         case "GET_SUBSCRIPTION_HISTORY":
            draft.subscriptionHistory.loading = true;
            break;
         case "GET_SUBSCRIPTION_HISTORY_SUCCESS":
            draft.subscriptionHistory.loading = false;
            draft.subscriptionHistory.success = true;
            draft.subscriptionHistory.response = action.payload.data;
            break;
         case "GET_SUBSCRIPTION_HISTORY_ERROR":
            draft.subscriptionHistory.loading = false;
            draft.subscriptionHistory.error = action.data;
            draft.subscriptionHistory.success = false;
            draft.subscriptionHistory.response = null;
            break;

         case "GET_INVOICE":
            draft.InvoiceDetails.loading = true;
            break;
         case "GET_INVOICE_SUCCESS":
            draft.InvoiceDetails.loading = false;
            draft.InvoiceDetails.success = true;
            draft.InvoiceDetails.hasmore = action.payload.data.has_more;
            draft.InvoiceDetails.response = action.payload.data.data;
            break;
         case "GET_INVOICE_ERROR":
            draft.InvoiceDetails.loading = false;
            draft.InvoiceDetails.error = action.data;
            draft.InvoiceDetails.success = false;
            draft.InvoiceDetails.response = null;
            break;

         case "GET_RECEIPT":
            draft.ReceiptDetails.loading = true;
            break;
         case "GET_RECEIPT_SUCCESS":
            draft.ReceiptDetails.loading = false;
            draft.ReceiptDetails.success = true;
            draft.ReceiptDetails.hasmore = action.payload.data.has_more;
            draft.ReceiptDetails.response = action.payload.data.data;
            break;
         case "GET_RECEIPT_ERROR":
            draft.ReceiptDetails.loading = false;
            draft.ReceiptDetails.error = action.data;
            draft.ReceiptDetails.success = false;
            draft.ReceiptDetails.response = null;
            break;

         case "ADD_PAYMENT_METHOD":
            draft.paymentMethod.loading = true;
            break;
         case "ADD_PAYMENT_METHOD_SUCCESS":
            toast.dark("Payment Added SuccessFully", { closeButton: false });
            draft.paymentMethod.loading = false;
            draft.paymentMethod.success = true;
            draft.paymentMethod.response = action.payload;
            break;
         case "ADD_PAYMENT_METHOD_ERROR":
            draft.paymentMethod.loading = false;
            draft.paymentMethod.error = action.data;
            draft.paymentMethod.success = false;
            draft.paymentMethod.response = null;
            break;

         case "ADD_CUSTOMER_SUBSCRIPTION":
            draft.customerSubscription.loading = true;
            draft.customerSubscription.success = false;
            break;

         case "ADD_CUSTOMER_SUBSCRIPTION_SUCCESS":
            draft.customerSubscription.loading = false;
            draft.customerSubscription.success = true;
            draft.customerSubscription.response = action.response;
            toast.dark("Subscription plan Changed SuccessFully", {
               closeButton: false
            });

            break;

         case "ADD_CUSTOMER_SUBSCRIPTION_SET_SUCCESS_FALSE":
            draft.customerSubscription.success = false;

            break;
         case "ADD_CUSTOMER_SUBSCRIPTION_FAIL":
            draft.customerSubscription.loading = false;
            draft.customerSubscription.error = action.data;
            draft.customerSubscription.success = false;
            draft.customerSubscription.response = null;
            break;

         case "DELETE_PAYMENT_METHOD":
            draft.deletePaymentMethod.success = false;
            break;

         case "DELETE_PAYMENT_METHOD_SUCESS":
            draft.deletePaymentMethod.success = true;
            break;

         case "DELETE_PAYMENT_METHOD_FAIL":
            draft.deletePaymentMethod.success = false;
            draft.deletePaymentMethod.error = action.error;
            break;

         case "CANCEL_CUSTOMER_SUBSCRIPTION":
            draft.cancelCustomerSubscription.success = false;
            draft.cancelCustomerSubscription.loading = true;
            break;

         case "CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS":
            draft.cancelCustomerSubscription.loading = false;
            draft.cancelCustomerSubscription.success = true;
            toast.dark("Subscription Cancelled SuccessFully", {
               closeButton: false
            });
            break;

         case "CANCEL_CUSTOMER_SUBSCRIPTION_SET_SUCCESS_FALSE":
            draft.cancelCustomerSubscription.success = false;
            break;

         case "CANCEL_CUSTOMER_SUBSCRIPTION_ERROR":
            draft.cancelCustomerSubscription.success = false;
            draft.cancelCustomerSubscription.error = action.error;
            break;

         case "UPDATE_BILLING_ADDRESS":
            draft.billingDetails.loading = true;
            break;

         case "UPDATE_BILLING_ADDRESS_SUCCESS":
            draft.billingDetails.loading = false;
            draft.billingDetails.success = true;
            draft.billingDetails.response = action.payload.data;
            toast.dark("Billing Address Changed SuccessFully", {
               closeButton: false
            });

            break;

         case "UPDATE_BILLING_ADDRESS_FAIL":
            draft.billingDetails.loading = false;
            draft.billingDetails.error = action.data;
            draft.billingDetails.success = false;
            draft.billingDetails.response = null;
            break;
         default:
            break;
      }
   });
