const getCountryListRequest = () => ({
   type: "GET_COUNTRY_LIST_REQUEST"
});

const getCountryListSuccess = (response) => ({
   type: "GET_COUNTRY_LIST_SUCCESS",
   response
});

const getCountryListError = (error) => ({
   type: "GET_COUNTRY_LIST_ERROR",
   error
});

const getCountriesFromDB = () => ({
   type: "GET_COUNTRIES"
});

const getCountriesFromDBSuccess = (payload) => ({
   type: "GET_COUNTRIES_SUCCESS",
   payload
});

const getCountriesFromDBError = () => ({
   type: "GET_COUNTRIES_ERROR"
});

const getUserDetails = () => ({
   type: "GET_USER_DETAILS"
});

const getUserDetailsSuccess = (payload) => ({
   type: "GET_USER_DETAILS_SUCCESS",
   payload
});

const getUserDetailsError = () => ({
   type: "GET_USER_DETAILS_ERROR"
});

export const setToken = (token) => ({
   type: "SET_TOKEN",
   token
});

export const openCreateModal = (payload) => ({
   type: "OPEN_CREATE_MODAL",
   payload
});

export const closeCreateModal = () => ({
   type: "CLOSE_CREATE_MODAL"
});

export const addServiceProfessional = (payload) => ({
   type: "ADD_SERVICE_PROFESSIONAL",
   payload
});

export const setProgressLoading = (percentage) => ({
   type: "SET_PROGRESS_LOADING",
   percentage
});

export const getUserConstrains = () => ({
   type: "GET_USER_CONSTRAINS"
});

export const getUserConstrainsSuccess = (payload) => ({
   type: "GET_USER_CONSTRAINS_SUCCESS",
   payload
});

export const getUserConstrainsError = () => ({
   type: "GET_USER_CONSTRAINS_ERROR"
});

export const getUserExsistingCount = () => ({
   type: "GET_USER_EXSISTING_COUNT"
});

export const getUserExsistingCountSuccess = (payload) => ({
   type: "GET_USER_EXSISTING_COUNT_SUCCESS",
   payload
});

export const setUserExsistingCountSuccess = (payload) => ({
   type: "SET_USER_EXSISTING_COUNT_SUCCESS",
   payload
});

export const getUserExsistingCountError = () => ({
   type: "GET_USER_EXSISTING_COUNT_ERROR"
});

export const setCurrentDataUsage = (payload) => ({
   type: "SET_CURRENT_DATA_USAGE",
   payload
});

export const reduceCurrentDataUsage = (payload) => ({
   type: "REDUCE_CURRENT_DATA_USAGE",
   payload
});

export const setLogoutloading = () => ({
   type: "SET_LOGOUT_LOADING"
});

export const setLoggedOut = () => ({
   type: "SET_LOGGED_OUT"
});

export const setLoggedIn = () => ({
   type: "SET_LOGGED_IN"
});

export const setRequestInfoModalDetails = (payload) => ({
   type: "SET_REQUEST_INFO_MODAL_DETAILS",
   payload
});

export const updateServiceworker = () => ({
   type: "UPDATE_SERVICEWORKER"
});

export {
   getCountryListRequest,
   getCountryListSuccess,
   getCountryListError,
   getCountriesFromDB,
   getCountriesFromDBSuccess,
   getCountriesFromDBError,
   getUserDetails,
   getUserDetailsSuccess,
   getUserDetailsError
};
