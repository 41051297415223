const getStaffs = (payload) => ({
   type: "GET_STAFFS",
   payload
});

const getStaffsSuccess = (response) => ({
   type: "GET_STAFFS_SUCCESS",
   response
});

const getStaffsError = (error) => ({
   type: "GET_STAFFS_ERROR",
   error
});

export const resetStaffsFilter = () => ({
   type: "RESET_STAFFS_FILTER"
});

const getStaffbyid = (payload) => ({
   type: "GET_STAFFS_BYID",
   payload
});

const getStaffsbyidSuccess = (payload) => ({
   type: "GET_STAFFS__BYID_SUCCESS",
   payload
});

const getStaffsbyidError = (payload) => ({
   type: "GET_STAFFS_BYID_ERROR",
   payload
});

const addStaffs = (payload) => ({
   type: "ADD_STAFFS",
   payload
});

const addStaffsSuccess = (payload) => ({
   type: "ADD_STAFFS_SUCCESS",
   payload
});

const addStaffsError = (payload) => ({
   type: "ADD_STAFFS_ERROR",
   payload
});

const updateStaffs = (payload) => ({
   type: "UPDATE_STAFFS",
   payload
});

const updateStaffsSuccess = (payload) => ({
   type: "UPDATE_STAFFS_SUCCESS",
   payload
});

const updateStaffsError = (payload) => ({
   type: "UPDATE_STAFFS_ERROR",
   payload
});

const openaddstaffmodel = () => ({
   type: "OPEN_ADD_STAFFS_MODEL"
});
const closeaddstaffmodel = () => ({
   type: "CLOSE_ADD_STAFFS_MODEL"
});

const deleteStaffs = (payload) => ({
   type: "DELETE_STAFFS",
   payload
});

const deleteStaffsSuccess = (payload) => ({
   type: "DELETE_STAFFS_SUCCESS",
   payload
});

const deleteStaffsError = (payload) => ({
   type: "DELETE_STAFFS_ERROR",
   payload
});

const addStaffsRandomPassword = (payload) => ({
   type: "ADD_STAFFS_RANDOM_PASSWORD",
   payload
});

const addStaffsRandomPasswordSuccess = (payload) => ({
   type: "ADD_STAFFS_RANDOM_PASSWORD_SUCCESS",
   payload
});

const addStaffsRandomPasswordError = (payload) => ({
   type: "ADD_STAFFS_RANDOM_PASSWORD_ERROR",
   payload
});

const getStaffimage = (payload) => ({
   type: "GET_STAFFS_IMAGE",
   payload
});

const getStaffimageSuccess = (payload) => ({
   type: "GET_STAFFS_IMAGE_SUCCESS",
   payload
});
const getStaffimageError = (payload) => ({
   type: "GET_STAFFS_IMAGE_Error",
   payload
});

const getStaffHistorybyid = (payload) => ({
   type: "GET_STAFFS_HISTORY_BYID",
   payload
});

const getStaffsHistorybyidSuccess = (payload) => ({
   type: "GET_STAFFS_HISTORY_BYID_SUCCESS",
   payload
});

const getStaffsHistorybyidError = (payload) => ({
   type: "GET_STAFFS_HISTORY_BYID_ERROR",
   payload
});

const setstaffdetailcurrenttab = (payload) => ({
   type: "SET_STAFFS_DETAILS_CURRENTTAB",
   payload
});

const updateStaffsAccess = (payload) => ({
   type: "UPDATE_STAFFS_ACCESS",
   payload
});

const updateStaffsAccessSuccess = (payload) => ({
   type: "UPDATE_STAFFS_ACCESS_SUCCESS",
   payload
});

const updateStaffsAccessError = (payload) => ({
   type: "UPDATE_STAFFS_ACCESS_ERROR",
   payload
});

const getAllStaffRole = (payload) => ({
   type: "GET__ALL_STAFFS_ROLE",
   payload
});

const getAllStaffsRoleSuccess = (payload) => ({
   type: "GET_ALL_STAFFS__ROLE_SUCCESS",
   payload
});

const getAllStaffsRoleError = (payload) => ({
   type: "GET_ALL_STAFFS_ROLE_ERROR",
   payload
});

const staffGeneralSystemSetup = (payload) => ({
   type: "STAFF_GENERAL_SYSTEM_SETUP",
   payload
});

const staffGeneralSystemSetupSuccess = (payload) => ({
   type: "STAFF_GENERAL_SYSTEM_SETUP_SUCCESS",
   payload
});

const staffGeneralSystemSetupError = (payload) => ({
   type: "STAFF_GENERAL_SYSTEM_SETUP_ERROR",
   payload
});

const getAllStaffs = (payload) => ({
   type: "GET_ALL_STAFFS",
   payload
});

const getAllStaffsSuccess = (payload) => ({
   type: "GET_ALL_STAFFS_SUCCESS",
   payload
});

const getAllStaffsError = (payload) => ({
   type: "GET_ALL_STAFFS_ERROR",
   payload
});

const getStaffsByDesignation = (payload) => ({
   type: "GET_STAFFS_BY_DESIGNATION",
   payload
});

const getStaffsByDesignationSetNull = (payload) => ({
   type: "GET_STAFFS_BY_DESIGNATION",
   payload
});

const getStaffsByDesignationSuccess = (response) => ({
   type: "GET_STAFFS_BY_DESIGNATION_SUCCESS",
   response
});

const getStaffsByDesignationError = (error) => ({
   type: "GET_STAFFS_BY_DESIGNATION_ERROR",
   error
});

export const getStaffsByDesignationInspection = (payload) => ({
   type: "GET_STAFFS_BY_DESIGNATION_INSPECTION",
   payload
});

export const getStaffsByDesignationInspectionSetNull = (payload) => ({
   type: "GET_STAFFS_BY_DESIGNATION_INSPECTION",
   payload
});

export const getStaffsByDesignationInspectionSuccess = (response) => ({
   type: "GET_STAFFS_BY_DESIGNATION_INSPECTION_SUCCESS",
   response
});

export const getStaffsByDesignationInspectionError = (error) => ({
   type: "GET_STAFFS_BY_DESIGNATION_INSPECTION_ERROR",
   error
});

export const getLoggedStaffDetailsRequest = () => ({
   type: "GET_LOGGED_STAFF_DETAILS_REQUEST"
});

export const getLoggedStaffDetailsSuccess = (payload) => ({
   type: "GET_LOGGED_STAFF_DETAILS_SUCCESS",
   payload
});

export const getLoggedStaffDetailsError = () => ({
   type: "GET_LOGGED_STAFF_DETAILS_ERROR"
});

export const setNullStaffHistory = () => ({
   type: "SET_NULL_STAFF_HISTORY"
});

export {
   getStaffs,
   getStaffsSuccess,
   getStaffsError,
   addStaffs,
   addStaffsSuccess,
   addStaffsError,
   openaddstaffmodel,
   closeaddstaffmodel,
   getStaffbyid,
   getStaffsbyidError,
   getStaffsbyidSuccess,
   updateStaffs,
   updateStaffsSuccess,
   updateStaffsError,
   deleteStaffs,
   deleteStaffsSuccess,
   deleteStaffsError,
   addStaffsRandomPassword,
   addStaffsRandomPasswordSuccess,
   addStaffsRandomPasswordError,
   getStaffimage,
   getStaffimageSuccess,
   getStaffimageError,
   getStaffHistorybyid,
   getStaffsHistorybyidSuccess,
   getStaffsHistorybyidError,
   setstaffdetailcurrenttab,
   updateStaffsAccess,
   updateStaffsAccessError,
   updateStaffsAccessSuccess,
   getAllStaffRole,
   getAllStaffsRoleError,
   getAllStaffsRoleSuccess,
   staffGeneralSystemSetup,
   staffGeneralSystemSetupSuccess,
   staffGeneralSystemSetupError,
   getAllStaffs,
   getAllStaffsSuccess,
   getAllStaffsError,
   getStaffsByDesignation,
   getStaffsByDesignationSuccess,
   getStaffsByDesignationError,
   getStaffsByDesignationSetNull
};
