import produce from "immer";

const INITIAL_STATE = {
   contracts: null,
   loading: false,
   contractType: null,
   cancelContract: {
      loading: false,
      success: false
   }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_ALL_CONTRACTS":
            draft.loading = true;
            break;
         case "GET_ALL_CONTRACTS_SUCCESS":
            draft.loading = false;
            draft.contracts = action.payload.response;
            break;
         case "GET_ALL_CONTRACTS_ERROR":
            draft.loading = false;
            break;
         case "GET_CONTRACT_TYPE":
            draft.loading = true;
            break;
         case "GET_CONTRACT_TYPE_SUCCESS":
            draft.contractType = action.payload.response;
            draft.loading = false;
            break;
         case "GET_CONTRACT_TYPE_ERROR":
            draft.loading = false;
            break;
         case "CANCEL_CONTRACT_REQUEST":
            draft.cancelContract.loading = true;
            draft.cancelContract.success = false;
            break;
         case "CANCEL_CONTRACT_SUCCESS":
            draft.cancelContract.loading = false;
            draft.cancelContract.success = true;
            break;
         case "CANCEL_CONTRACT_ERROR":
            draft.cancelContract.loading = false;
            draft.cancelContract.success = false;
            break;
         default:
            return draft;
      }
   });
