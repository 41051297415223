import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import propertySaga from "./propertySaga";
import landlordSaga from "./landlordSaga";
import miscSaga from "./miscSaga";
import staffSaga from "./staffsSaga";
import tenantSaga from "./tenantSaga";
import guarantorSaga from "./guarantorSaga";
import occupantSaga from "./occupantSaga";
import contractSaga from "./contractSaga";
import settingSaga from "./settingSaga";
import profileSetupSaga from "./profileSetupSaga";
import propertyDropdownSaga from "./propertyDropdownSaga";
import taskSaga from "./taskSaga";
import notificationSaga from "./notificationSaga";
import dashboardSaga from "./dashboardSaga";
import paymentSaga from "./paymentSaga";
import invoiceSaga from "./invoiceSaga";
import dataMigration from "./dataMigrationSaga";
import uploadSaga from "./uploadSaga";
import RequestInfoSaga from "./requestInfoSaga";
import permissionsSaga from "./permissionsSaga";
import documentSaga from "./documentSaga";

export default function* rootSaga() {
   yield all([
      authSaga(),
      propertySaga(),
      landlordSaga(),
      miscSaga(),
      staffSaga(),
      tenantSaga(),
      guarantorSaga(),
      occupantSaga(),
      contractSaga(),
      settingSaga(),
      profileSetupSaga(),
      taskSaga(),
      propertyDropdownSaga(),
      notificationSaga(),
      dashboardSaga(),
      paymentSaga(),
      invoiceSaga(),
      dataMigration(),
      uploadSaga(),
      RequestInfoSaga(),
      permissionsSaga(),
      documentSaga()
   ]);
}
