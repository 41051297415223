import { put, call, takeEvery, select } from "redux-saga/effects";
import jwt_decode from "jwt-decode";
import {
   getAllRentPayableInvoiceAgainstPropertyFail,
   getAllRentPayableInvoiceAgainstPropertySuccess,
   getInvoiceDetailsFail,
   getInvoiceDetailsSuccess,
   getItemAddTypesFail,
   getItemAddTypesSuccess,
   updateInvoiceDetailsFail,
   updateInvoiceDetailsSuccess,
   getAllRentPayableInvoiceAgainstTenantFail,
   getAllRentPayableInvoiceAgainstTenantSuccess,
   getAllAccountReceivableInvoiceFail,
   getAllAccountReceivableInvoiceSuccess,
   addNoteToInvoiceError,
   addNoteToInvoiceSuccess,
   getNoteByInvoiceError,
   getNoteByInvoiceSuccess,
   updateNoteToInvoiceError,
   updateNoteToInvoiceSuccess,
   deleteNoteByInvoiceError,
   deleteNoteByInvoiceSuccess,
   GetCommentByInvoiceIdSuccess,
   GetCommentByInvoiceIdError,
   saveCommentByInvoiceIdSuccess,
   saveCommentByInvoiceIdError,
   deleteCommentByInvoiceIdSuccess,
   deleteCommentByInvoiceIdError,
   saveReplyByInvoiceIdSuccess,
   saveReplyByInvoiceIdError,
   saveAsReadByInvoiceIdSuccess,
   saveAsReadByInvoiceIdError,
   deleteReplyByInvoiceIdSuccess,
   deleteReplyByInvoiceIdError,
   saveEmoijByInvoiceIdSuccess,
   saveEmojiByInvoiceIdError,
   getBase64ForCommentByInvoiceIdSuccess,
   getBase64ForCommentByInvoiceIdError,
   getAllRentPayableInvoiceAgainstLandlordFail,
   getAllRentPayableInvoiceAgainstLandlordSuccess,
   addReceiptDetailsFail,
   addReceiptDetailsSuccess,
   deleteReceiptDetailsFail,
   deleteReceiptDetailsSuccess,
   getAllServiceTypeFail,
   getAllServiceTypeSuccess,
   addServiceTypeFail,
   addServiceTypeSuccess,
   addNewInvoiceFail,
   addNewInvoiceSuccess,
   deleteInvoiceDetailsSuccess,
   deleteInvoiceDetailsFail,
   changeInvoiceStatusToCancelSuccess,
   changeInvoiceStatusToCancelFail,
   downloadInvoicePDFSuccess,
   downloadInvoicePDFError,
   downloadInvoicePDFRequest,
   sendInvoiceSuccess,
   sendInvoiceError,
   sendInvoiceRequest
} from "../actions/invoiceAction";
import {
   getAllRentPayableInvoiceAgainstTenantAPI,
   getAllRentPayableInvoiceAgainstPropertyAPI,
   getInvoiceDetailsAPI,
   getItemAddTypeAPI,
   updateInvoiceDetailsAPI,
   getAllAccountReceivableInvoiceAPI,
   addNoteToInvoice,
   updateNoteToInvoice,
   getNoteByInvoice,
   deleteNoteByInvoice,
   GetCommentByTaskId,
   SaveComment,
   deleteCommentByid,
   saveReply,
   saveAsReadStatus,
   deleteReply,
   saveEmoij,
   getAllRentPayableInvoiceAgainstLandlordAPI,
   addReceiptDetailsAPI,
   deleteReceiptById,
   GetAllServiceType,
   addInvoiceDetailsAPI,
   addServiceTypeAPI,
   deleteInvoiceById,
   changeInvoiceToCancelAPI,
   downloadInvoicePDF,
   sendInvoice
} from "../api/invoice";

function* handleGetAllInvoiceAgainstProperty(action) {
   try {
      const response = yield call(
         getAllRentPayableInvoiceAgainstPropertyAPI,
         action.payload
      );
      yield put(getAllRentPayableInvoiceAgainstPropertySuccess(response.data));
   } catch (error) {
      yield put(getAllRentPayableInvoiceAgainstPropertyFail(error.response));
   }
}

function* handleGetAllInvoiceAgainstTenant(action) {
   try {
      const response = yield call(
         getAllRentPayableInvoiceAgainstTenantAPI,
         action.payload
      );
      yield put(getAllRentPayableInvoiceAgainstTenantSuccess(response.data));
   } catch (error) {
      yield put(getAllRentPayableInvoiceAgainstTenantFail(error.response));
   }
}

function* handleGetAllInvoiceAgainstLandlord(action) {
   try {
      const response = yield call(
         getAllRentPayableInvoiceAgainstLandlordAPI,
         action.payload
      );
      yield put(getAllRentPayableInvoiceAgainstLandlordSuccess(response.data));
   } catch (error) {
      yield put(getAllRentPayableInvoiceAgainstLandlordFail(error.response));
   }
}

function* handleGetAllAccountReceivableInvoice(action) {
   try {
      const response = yield call(
         getAllAccountReceivableInvoiceAPI,
         action.payload
      );
      yield put(getAllAccountReceivableInvoiceSuccess(response.data));
   } catch (error) {
      yield put(getAllAccountReceivableInvoiceFail(error.response));
   }
}

function* handleGetInvoiceDetails(action) {
   try {
      const response = yield call(getInvoiceDetailsAPI, action.payload);
      yield put(getInvoiceDetailsSuccess(response.data));
   } catch (error) {
      yield put(getInvoiceDetailsFail(error.response));
   }
}

function* handlegetItemAddType(action) {
   try {
      const response = yield call(getItemAddTypeAPI, action.payload);
      yield put(getItemAddTypesSuccess(response.data));
   } catch (error) {
      yield put(getItemAddTypesFail(error.response));
   }
}
function* handleupdateInvoiceDetails(action) {
   try {
      const response = yield call(updateInvoiceDetailsAPI, action.payload);
      yield put(updateInvoiceDetailsSuccess(response.data));
      if (action.payload.download) {
         yield put(
            downloadInvoicePDFRequest({
               id: action.payload.invoiceId,
               invoiceNumber: action.payload.invoiceNumber
            })
         );
      } else if (action.payload.sendInvoice) {
         yield put(sendInvoiceRequest(action.payload.invoiceId));
      }
   } catch (error) {
      yield put(updateInvoiceDetailsFail(error.response));
   }
}

function* handleAddInvoiceDetails(action) {
   try {
      const response = yield call(addInvoiceDetailsAPI, action.payload);
      yield put(addNewInvoiceSuccess(response.data));
   } catch (error) {
      yield put(addNewInvoiceFail(error.response));
   }
}

function* handleAddReceiptDetails(action) {
   try {
      const response = yield call(addReceiptDetailsAPI, action.payload);
      yield put(addReceiptDetailsSuccess(response.data));
   } catch (error) {
      yield put(addReceiptDetailsFail(error.response));
   }
}

function* handleDeleteReceiptDetails(action) {
   try {
      const response = yield call(deleteReceiptById, action.payload);
      yield put(deleteReceiptDetailsSuccess(response.data));
   } catch (error) {
      yield put(deleteReceiptDetailsFail(error.response));
   }
}

function* handleDeleteInvoice(action) {
   try {
      const response = yield call(deleteInvoiceById, action.payload);
      yield put(deleteInvoiceDetailsSuccess(response.data));
   } catch (error) {
      yield put(deleteInvoiceDetailsFail(error.response));
   }
}

function* handlechangeInvoiceToCancel(action) {
   try {
      const response = yield call(changeInvoiceToCancelAPI, action.payload);
      yield put(changeInvoiceStatusToCancelSuccess(response.data));
   } catch (error) {
      yield put(changeInvoiceStatusToCancelFail(error.response));
   }
}
//notes

function* handleAddNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         createdBy: agencyId
      };
      const response = yield call(addNoteToInvoice, notePayload);
      yield put(addNoteToInvoiceSuccess(response.data));
   } catch (error) {
      yield put(addNoteToInvoiceError(error));
   }
}

function* handleUpdateNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         updatedBy: agencyId
      };
      const response = yield call(updateNoteToInvoice, notePayload);
      yield put(updateNoteToInvoiceSuccess(response.data));
   } catch (error) {
      yield put(updateNoteToInvoiceError(error));
   }
}

function* handleGetNoteByInvoice({ InvoiceId }) {
   try {
      const response = yield call(getNoteByInvoice, InvoiceId);
      yield put(getNoteByInvoiceSuccess(response.data));
   } catch (error) {
      yield put(getNoteByInvoiceError(error));
   }
}

function* handleDeleteNoteByInvoice({ payload }) {
   try {
      yield call(deleteNoteByInvoice, payload);
      yield put(deleteNoteByInvoiceSuccess(payload));
   } catch (error) {
      yield put(deleteNoteByInvoiceError(error));
   }
}

//comments

function* handleGetCommentByTaskId(action) {
   try {
      const resp = yield call(GetCommentByTaskId, action.id);
      yield put(GetCommentByInvoiceIdSuccess(resp.data));
   } catch {
      yield put(GetCommentByInvoiceIdError());
   }
}

function* handleSaveComment(action) {
   const { currentlyUploadedIds } = yield select((state) => state.upload);
   try {
      yield call(SaveComment, {
         ...action.payload,
         fileIds: currentlyUploadedIds.join()
      });
      yield put(saveCommentByInvoiceIdSuccess());
   } catch {
      yield put(saveCommentByInvoiceIdError());
   }
}

function* handleDeleteComment(action) {
   try {
      yield call(deleteCommentByid, action.id);
      yield put(deleteCommentByInvoiceIdSuccess());
   } catch {
      yield put(deleteCommentByInvoiceIdError());
   }
}

function* handleSaveReply(action) {
   try {
      yield call(saveReply, action.payload);
      yield put(saveReplyByInvoiceIdSuccess());
   } catch {
      yield put(saveReplyByInvoiceIdError());
   }
}

function* handleSaveAsRead(action) {
   try {
      yield call(saveAsReadStatus, action.payload);
      yield put(saveAsReadByInvoiceIdSuccess());
   } catch {
      yield put(saveAsReadByInvoiceIdError());
   }
}

function* handleDeleteReply(action) {
   try {
      yield call(deleteReply, action.id);
      yield put(deleteReplyByInvoiceIdSuccess());
   } catch (error) {
      yield put(deleteReplyByInvoiceIdError());
   }
}

function* handleSaveEmoji(action) {
   try {
      yield call(saveEmoij, action.payload);
      yield put(saveEmoijByInvoiceIdSuccess());
   } catch (error) {
      yield put(saveEmojiByInvoiceIdError());
   }
}

function* handleGetBaseForComment(action) {
   try {
      const resp = yield call(GetAllServiceType, action.url);
      yield put(getBase64ForCommentByInvoiceIdSuccess(resp.data));
   } catch {
      yield put(getBase64ForCommentByInvoiceIdError());
   }
}

function* handleGetServiceType(action) {
   try {
      const resp = yield call(GetAllServiceType, action.payload);
      yield put(getAllServiceTypeSuccess(resp.data));
   } catch {
      yield put(getAllServiceTypeFail());
   }
}

function* handleAddServiceType(action) {
   try {
      const response = yield call(addServiceTypeAPI, action.payload);
      yield put(addServiceTypeSuccess(response.data));
   } catch (error) {
      yield put(addServiceTypeFail(error.response));
   }
}

function* handleDownloadPDF(action) {
   try {
      const response = yield call(
         downloadInvoicePDF,
         action.payload.downloadParam
      );
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.id = "invoice-download";
      link.href = url;
      link.download = `invoice-${action.payload.number}.pdf`;
      document.body.appendChild(link);
      link.click();
      // remove a tag and revoke that temp url after clicked
      document.getElementById("invoice-download").remove();
      window.URL.revokeObjectURL(url);
      yield put(downloadInvoicePDFSuccess());
   } catch {
      yield put(downloadInvoicePDFError());
   }
}

function* handleSendInvoice(action) {
   try {
      yield call(sendInvoice, action.payload);
      yield put(sendInvoiceSuccess());
   } catch {
      yield put(sendInvoiceError());
   }
}

export default function* watchstaffs() {
   yield takeEvery(
      "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY",
      handleGetAllInvoiceAgainstProperty
   );

   yield takeEvery("GET_INVOICE_DETAILS", handleGetInvoiceDetails);
   yield takeEvery("GET_ITEM_ADD_TYPES", handlegetItemAddType);
   yield takeEvery("UPDATE_INVOICE_DETAILS", handleupdateInvoiceDetails);
   yield takeEvery(
      "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT",
      handleGetAllInvoiceAgainstTenant
   );
   yield takeEvery(
      "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE",
      handleGetAllAccountReceivableInvoice
   );

   yield takeEvery("ADD_NOTE_TO_INVOICE", handleAddNote);
   yield takeEvery("GET_NOTE_BY_INVOICE", handleGetNoteByInvoice);
   yield takeEvery("UPDATE_NOTE_TO_INVOICE", handleUpdateNote);
   yield takeEvery("DELETE_NOTE_BY_INVOICE", handleDeleteNoteByInvoice);
   yield takeEvery("GET_COMMENT_BY_INVOICE_ID", handleGetCommentByTaskId);
   yield takeEvery("SAVE_COMMENT_BY_INVOICE_ID", handleSaveComment);
   yield takeEvery("DELETE_COMMENT_BY_INVOICE_ID", handleDeleteComment);
   yield takeEvery("SAVE_REPLY_BY_INVOICE_ID", handleSaveReply);

   yield takeEvery("SAVE_AS_READ_BY_INVOICE_ID", handleSaveAsRead);
   yield takeEvery("DELETE_REPLY_BY_INVOICE_ID", handleDeleteReply);
   yield takeEvery("SAVE_EMOJI_BY_INVOICE_ID", handleSaveEmoji);
   yield takeEvery(
      "GET_BASE64_FOR_COMMENT_BY_INVOICE_ID",
      handleGetBaseForComment
   );
   yield takeEvery(
      "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD",
      handleGetAllInvoiceAgainstLandlord
   );

   yield takeEvery("DELETE_RECEIPT_DETAILS", handleDeleteReceiptDetails);
   yield takeEvery("ADD_RECEIPT_DETAILS", handleAddReceiptDetails);
   yield takeEvery("GET_ALL_SERVICE_TYPE", handleGetServiceType);

   yield takeEvery("ADD_SERVICE_TYPE", handleAddServiceType);
   yield takeEvery("ADD_NEW_INVOICE", handleAddInvoiceDetails);

   yield takeEvery("DELETE_INVOICE_DETAILS", handleDeleteInvoice);
   yield takeEvery(
      "CHANGE_INVOICE_STATUS_TO_CANCEL",
      handlechangeInvoiceToCancel
   );
   yield takeEvery("DOWNLOAD_INVOICE_PDF_REQUEST", handleDownloadPDF);
   yield takeEvery("SEND_INVOICE_REQUEST", handleSendInvoice);
}
