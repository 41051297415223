import API from "./API";

export const UploadFileToGetData = (api, file, cb) => {
   const formData = new FormData();
   formData.append("file", file);
   return API.post(`/api/data/import/${api}`, formData, {
      onUploadProgress: cb
   });
};

export const SaveTenantUploadedData = (data) => {
   return API.post("/api/data/add/tenants", data);
};

export const SavePropertyUploadedData = (data) => {
   return API.post("/api/data/add/properties", data);
};

export const SaveLandlordUploadedData = (data) => {
   return API.post("/api/data/add/landlords", data);
};

export const ExportData = (payload) => {
   return API.get(`/api/data/export/${payload}`);
};

export const GetFile = async (fileKey) => {
   return await API.get(`/api/data/download/${fileKey}`, {
      responseType: "blob",
      headers: {
         Accept: "*"
      }
   });
};
