const getAllPropertiesRequest = (payload) => ({
   type: "GET_PROPERTIES_REQUEST",
   payload
});

const getAllPropertiesSuccess = (response) => ({
   type: "GET_PROPERTIES_SUCCESS",
   response
});

const getAllPropertiesError = (error) => ({
   type: "GET_PROPERTIES_ERROR",
   error
});

export const getAllPropertiesRequestLazyLoading = (payload) => ({
   type: "GET_PROPERTIES_REQUEST_LAZY_LOADING",
   payload
});

export const getAllPropertiesRequestLazyLoadingSetNull = () => ({
   type: "GET_PROPERTIES_REQUEST_LAZY_LOADING_SET_NULL"
});

export const getAllPropertiesLazyLoadingSuccess = (response) => ({
   type: "GET_PROPERTIES_LAZY_LOADING_SUCCESS",
   response
});

export const getAllPropertiesLazyLoadingError = (error) => ({
   type: "GET_PROPERTIES_LAZY_LOADING_ERROR",
   error
});

const getLicenceTypesRequest = () => ({
   type: "GET_LICENCE_TYPES_REQUEST"
});

const getLicenceTypesSuccess = (response) => ({
   type: "GET_LICENCE_TYPES_SUCCESS",
   response
});

const getLicenceTypesError = (error) => ({
   type: "GET_LICENCE_TYPES_ERROR",
   error
});

const getPropertyTypesRequest = () => ({
   type: "GET_PROPERTY_TYPES_REQUEST"
});

const getPropertyTypesSuccess = (response) => ({
   type: "GET_PROPERTY_TYPES_SUCCESS",
   response
});

const getPropertyTypesError = (error) => ({
   type: "GET_PROPERTY_TYPES_ERROR",
   error
});

const getFurnishTypesRequest = () => ({
   type: "GET_FURNISH_TYPES_REQUEST"
});

const getFurnishTypesSuccess = (response) => ({
   type: "GET_FURNISH_TYPES_SUCCESS",
   response
});

const getFurnishTypesError = (error) => ({
   type: "GET_FURNISH_TYPES_ERROR",
   error
});

const getPropertyStatusRequest = () => ({
   type: "GET_PROPERTY_STATUS_REQUEST"
});

const getPropertyStatusSuccess = (response) => ({
   type: "GET_PROPERTY_STATUS_SUCCESS",
   response
});

const getPropertyStatusError = (error) => ({
   type: "GET_PROPERTY_STATUS_ERROR",
   error
});

const getUnitTypesRequest = () => ({
   type: "GET_UNIT_TYPES_REQUEST"
});

const getUnitTypesSuccess = (response) => ({
   type: "GET_UNIT_TYPES_SUCCESS",
   response
});

const getUnitTypesError = (error) => ({
   type: "GET_UNIT_TYPES_ERROR",
   error
});

const savePropertyRequest = (payload) => ({
   type: "SAVE_PROPERTY_REQUEST",
   payload
});

const savePropertySuccess = (response) => ({
   type: "SAVE_PROPERTY_SUCCESS",
   response
});

const savePropertyError = (error) => ({
   type: "SAVE_PROPERTY_ERROR",
   error
});

const resetPropertySaveData = () => ({
   type: "RESET_PROPERTY_SAVE"
});

const resetPropertyFilter = () => ({
   type: "RESET_PROPERTY_FILTER"
});

const getPropertyDetailsRequest = (payload) => ({
   type: "GET_PROPERTY_DETAILS_REQUEST",
   payload
});

const getPropertyDetailsSuccess = (response) => ({
   type: "GET_PROPERTY_DETAILS_SUCCESS",
   response
});

const getPropertyDetailsError = (error) => ({
   type: "GET_PROPERTY_DETAILS_ERROR",
   error
});

const getPropertyBasicInfo = (payload) => ({
   type: "GET_PROPERTY_BASIC_INFO",
   payload
});

const getPropertyBasicInfoSetNull = (payload) => ({
   type: "GET_PROPERTY_BASIC_INFO_SET_NULL",
   payload
});

const getPropertyBasicInfoSuccess = (response) => ({
   type: "GET_PROPERTY_BASIC_INFO_SUCCESS",
   response
});

const getPropertyBasicInfoError = (error) => ({
   type: "GET_PROPERTY_BASIC_INFO_ERROR",
   error
});

const updatePropertyMultiUnitRequest = (payload) => ({
   type: "UPDATE_PROPERTY_MULTIUNIT_REQUEST",
   payload
});

const updatePropertyMultiUnitSuccess = (response) => ({
   type: "UPDATE_PROPERTY_MULTIUNIT_SUCCESS",
   response
});

const updatePropertyMultiUnitError = (error) => ({
   type: "UPDATE_PROPERTY_MULTIUNIT_ERROR",
   error
});

const updateUnitMultiUnitRequest = (payload) => ({
   type: "UPDATE_UNIT_MULTIUNIT_REQUEST",
   payload
});

const updateUnitMultiUnitSuccess = (response) => ({
   type: "UPDATE_UNIT_MULTIUNIT_SUCCESS",
   response
});

const updateUnitMultiUnitSuccessFalse = () => ({
   type: "UPDATE_UNIT_MULTIUNIT_SUCCESS_FALSE"
});

const updateUnitMultiUnitError = (error) => ({
   type: "UPDATE_UNIT_MULTIUNIT_ERROR",
   error
});

const setLoadingOn = () => ({
   type: "SET_LOADING_ON"
});

const setLoadingOff = () => ({
   type: "SET_LOADING_OFF"
});

const deletePortfolioById = (payload) => ({
   type: "DELETE_PORTFOLIO_BY_ID",
   payload
});

const deletePortfolioByIdSucess = (response) => ({
   type: "DELETE_PORTFOLIO_BY_ID_SUCESS",
   response
});

const deletePortfolioByIdFail = (error) => ({
   type: "DELETE_PORTFOLIO_BY_ID_FAIL",
   error
});

const deleteImageById = (payload) => ({
   type: "DELETE_IMAGE_BY_ID",
   payload
});

const deleteImageByIdSucess = (response) => ({
   type: "DELETE_IMAGE_BY_ID_SUCESS",
   response
});

const deleteImageByIdFail = (error) => ({
   type: "DELETE_IMAGE_BY_ID_FAIL",
   error
});

const updateTenantRentRequest = (payload) => ({
   type: "UPDATE_TENANT_RENT_REQUEST",
   payload
});

const updateTenantRentSuccess = (response) => ({
   type: "UPDATE_TENANT_RENT_SUCCESS",
   response
});

const updateTenantRentFail = (error) => ({
   type: "UPDATE_TENANT_RENT_FAIL",
   error
});

const mapTenantToPropertyRequest = (payload) => ({
   type: "MAP_TENANT_TO_PROPERTY",
   payload
});

const mapTenantToPropertyRequestSuccess = (response) => ({
   type: "MAP_TENANT_TO_PROPERTY_SUCCESS",
   response
});

const mapTenantToPropertyRequestFail = (error) => ({
   type: "MAP_TENANT_TO_PROPERTY_FAIL",
   error
});

const addNoteToProperty = (payload) => ({
   type: "ADD_NOTE_TO_PROPERTY",
   payload
});

const addNoteToPropertySuccess = (payload) => ({
   type: "ADD_NOTE_TO_PROPERTY_SUCCESS",
   payload
});

const addNoteToPropertyError = (error) => ({
   type: "ADD_NOTE_TO_PROPERTY_ERROR",
   error
});

const updateNoteToProperty = (payload) => ({
   type: "UPDATE_NOTE_TO_PROPERTY",
   payload
});

const updateNoteToPropertyError = (error) => ({
   type: "UPDATE_NOTE_TO_PROPERTY_ERROR",
   error
});

const updateNoteToPropertySuccess = (payload) => ({
   type: "UPDATE_NOTE_TO_PROPERTY_SUCCESS",
   payload
});

const getNoteByProperty = (propertyId) => ({
   type: "GET_NOTE_BY_PROPERTY",
   propertyId
});

const getNoteByPropertySuccess = (payload) => ({
   type: "GET_NOTE_BY_PROPERTY_SUCCESS",
   payload
});

const getNoteByPropertyError = (error) => ({
   type: "GET_NOTE_BY_PROPERTY_ERROR",
   error
});

const deleteNoteByProperty = (payload) => ({
   type: "DELETE_NOTE_BY_PROPERTY",
   payload
});

const deleteNoteByPropertySuccess = (payload) => ({
   type: "DELETE_NOTE_BY_PROPERTY_SUCCESS",
   payload
});

const deleteNoteByPropertyError = (error) => ({
   type: "DELETE_NOTE_BY_PROPERTY_ERROR",
   error
});

const updatePropertyStatus = (payload) => ({
   type: "UPDATE_PROPERTY_STATUS",
   payload
});

const updatePropertyStatusError = (error) => ({
   type: "UPDATE_PROPERTY_STATUS_ERROR",
   error
});

const updatePropertyStatusSuccess = (payload) => ({
   type: "UPDATE_PROPERTY_STATUS_SUCCESS",
   payload
});

const saveColumnSelectionRequest = (payload) => ({
   type: "SAVE_COLUMN_SELECTION_REQUEST",
   payload
});

const saveColumnSelectionSuccess = (response) => ({
   type: "SAVE_COLUMN_SELECTION_SUCCESS",
   response
});

const saveColumnSelectionError = (error) => ({
   type: "SAVE_COLUMN_SELECTION_ERROR",
   error
});

const getColumnSelectionRequest = (payload) => ({
   type: "GET_COLUMN_SELECTION_REQUEST",
   payload
});

const getColumnSelectionSuccess = (response) => ({
   type: "GET_COLUMN_SELECTION_SUCCESS",
   response
});

const getColumnSelectionError = (error) => ({
   type: "GET_COLUMN_SELECTION_ERROR",
   error
});

const updatePropertyToArchive = (payload) => ({
   type: "UPDATE_PROPERTY_TO_ARCHIVE",
   payload
});

const updatePropertyToArchiveSuccess = (response) => ({
   type: "UPDATE_PROPERTY_TO_ARCHIVE_SUCCESS",
   response
});

const updatePropertyToArchiveFail = (error) => ({
   type: "UPDATE_PROPERTY_TO_ARCHIVE_FAIL",
   error
});

const getAllProperty = () => ({
   type: "GET_ALL_PROPERTY"
});

const getAllPropertySuccess = (payload) => ({
   type: "GET_ALL_PROPERTY_SUCCESS",
   payload
});

const getAllPropertyError = (payload) => ({
   type: "GET_ALL_PROPERTY_ERROR",
   payload
});

const mapLandlordToPropertyRequest = (payload) => ({
   type: "MAP_LANDLORD_TO_PROPERTY_REQUEST",
   payload
});

const mapLandlordToPropertySuccess = (response) => ({
   type: "MAP_LANDLORD_TO_PROPERTY_SUCCESS",
   response
});

const mapLandlordToPropertyError = (error) => ({
   type: "MAP_LANDLORD_TO_PROPERTY_ERROR",
   error
});

const saveLandlordRentDetailsRequest = (payload) => ({
   type: "SAVE_LANDLORD_RENT_DETAILS_REQUEST",
   payload
});

const saveLandlordRentDetailsSuccess = (response) => ({
   type: "SAVE_LANDLORD_RENT_DETAILS_SUCCESS",
   response
});

const saveLandlordRentDetailsError = (error) => ({
   type: "SAVE_LANDLORD_RENT_DETAILS_ERROR",
   error
});

export const getUtility = (id) => ({
   type: "GET_UTILITY",
   id
});

export const getUtilitySuccess = (payload) => ({
   type: "GET_UTILITY_SUCCESS",
   payload
});

export const getUtilityError = () => ({
   type: "GET_UTILITY_ERROR"
});

export const saveUtility = (data) => ({
   type: "SAVE_UTILITY",
   data
});

export const saveUtilitySuccess = () => ({
   type: "SAVE_UTILITY_SUCCESS"
});

export const saveUtilityError = () => ({
   type: "SAVE_UTILITY_ERROR"
});

export const updateUtility = (data) => ({
   type: "UPDATE_UTILITY",
   data
});

export const updateUtilitySuccess = () => ({
   type: "UPDATE_UTILITY_SUCCESS"
});

export const updateUtilityError = () => ({
   type: "UPDATE_UTILITY_ERROR"
});

export const deleteUtility = (id) => ({
   type: "DELETE_UTILITY",
   id
});

export const deleteUtilitySuccess = () => ({
   type: "DELETE_UTILITY_SUCCESS"
});

export const deleteUtilityError = () => ({
   type: "DELETE_UTILITY_ERROR"
});

export const getPropertyDocuments = (data) => ({
   type: "GET_PROPERTY_DOCUMENTS",
   data
});

export const getPropertyDocumentSuccess = (payload) => ({
   type: "GET_PROPERTY_DOCUMENTS_SUCCESS",
   payload
});

export const getPropertyDocumentError = () => ({
   type: "GET_PROPERTY_DOCUMENT_ERROR"
});

export const addPropertyDocument = (data) => ({
   type: "ADD_PROPERTY_DOCUMENT",
   data
});

export const addPropertyDocumentSuccess = () => ({
   type: "ADD_PROPERTY_DOCUMENT_SUCCESS"
});

export const addPropertyDocumentError = () => ({
   type: "ADD_PROPERTY_DOCUMENT_ERROR"
});

export const updatePropertyDocument = (data) => ({
   type: "UPDATE_PROPERTY_DOCUMENT",
   data
});

export const updatePropertyDocumentSuccess = () => ({
   type: "UPDATE_PROPERTY_DOCUMENT_SUCCESS"
});

export const updatePropertyDocumentError = () => ({
   type: "UPDATE_PROPERTY_DOCUMENT_ERROR"
});

export const deletePropertyDocs = (data) => ({
   type: "DELETE_PROPERTY_DOCS",
   data
});

export const deletePropertyDocsSuccess = () => ({
   type: "DELETE_PROPERTY_DOCS_SUCCESS"
});

export const deletePropertyDocsError = () => ({
   type: "DELETE_PROPERTY_DOCS_ERROR"
});

export const getPropretyTypesById = (id) => ({
   type: "GET_PROPERTY_TYPE_BY_ID",
   id
});

export const getPropertyTypesByIdSuccess = (payload) => ({
   type: "GET_PROPERTY_TYPES_BY_ID_SUCCESS",
   payload
});

export const getPropertyTypesByIdError = () => ({
   type: "GET_PROPERTY_TYPES_BY_ID_ERROR"
});

export const deletePropertyById = (payload, history) => ({
   type: "DELETE_PROPERTY_BY_ID",
   payload,
   history
});

export const deletePropertyByIdSuccess = () => ({
   type: "DELETE_PROPERTY_BY_ID_SUCCESS"
});

export const deletePropertyByIdError = () => ({
   type: "DELETE_PROPERTY_BY_ID_ERROR"
});

export const unlinklandlordFromProperty = (data) => ({
   type: "UNLINK_LANDLORD_FROM_PROPERTY",
   data
});

export const unlinklandlordFromPropertySuccess = () => ({
   type: "UNLINK_LANDLORD_FROM_PROPERTY_SUCCESS"
});

export const unlinklandlordFromPropertyError = () => ({
   type: "UNLINK_LANDLORD_FROM_PROPERTY_ERROR"
});

export const unlinkTenantFromProperty = (data) => ({
   type: "UNLINK_TENANT_FROM_PROPERTY",
   data
});

export const unlinkTenantFromPropertySuccess = () => ({
   type: "UNLINK_TENANT_FROM_PROPERTY_SUCCESS"
});

export const unlinkTenantFromPropertyError = () => ({
   type: "UNLINK_TENANT_FROM_PROPERTY_ERROR"
});

export const getPropertyWarningRequest = (data) => ({
   type: "GET_PROPERTY_WARNING_REQUEST",
   data
});

export const getPropertyWarningRequestSuccess = (response) => ({
   type: "GET_PROPERTY_WARNING_SUCCESS",
   response
});

export const getPropertyWarningRequestError = (error) => ({
   type: "GET_PROPERTY_WARNING_ERROR",
   error
});

export const setNullPropertyDocuments = () => ({
   type: "SET_NULL_PROPERTY_DOCUMENTS"
});

export const changePropertyLoadedState = (payload) => ({
   type: "CHANGE_PROPERTY_LOADED_STATE",
   payload
});

export {
   getAllProperty,
   getAllPropertySuccess,
   getAllPropertyError,
   getAllPropertiesRequest,
   getAllPropertiesSuccess,
   getAllPropertiesError,
   getLicenceTypesRequest,
   getLicenceTypesSuccess,
   getLicenceTypesError,
   getPropertyTypesRequest,
   getPropertyTypesSuccess,
   getPropertyTypesError,
   getFurnishTypesRequest,
   getFurnishTypesSuccess,
   getFurnishTypesError,
   getPropertyStatusRequest,
   getPropertyStatusSuccess,
   getPropertyStatusError,
   getUnitTypesRequest,
   getUnitTypesSuccess,
   getUnitTypesError,
   savePropertyRequest,
   savePropertySuccess,
   savePropertyError,
   resetPropertySaveData,
   resetPropertyFilter,
   getPropertyDetailsRequest,
   getPropertyDetailsSuccess,
   getPropertyDetailsError,
   getPropertyBasicInfo,
   getPropertyBasicInfoSuccess,
   getPropertyBasicInfoError,
   updatePropertyMultiUnitRequest,
   updatePropertyMultiUnitSuccess,
   updatePropertyMultiUnitError,
   updateUnitMultiUnitSuccessFalse,
   updateUnitMultiUnitRequest,
   updateUnitMultiUnitSuccess,
   updateUnitMultiUnitError,
   setLoadingOn,
   setLoadingOff,
   deletePortfolioById,
   deletePortfolioByIdSucess,
   deletePortfolioByIdFail,
   deleteImageById,
   deleteImageByIdFail,
   deleteImageByIdSucess,
   updateTenantRentRequest,
   updateTenantRentSuccess,
   updateTenantRentFail,
   mapTenantToPropertyRequest,
   mapTenantToPropertyRequestSuccess,
   mapTenantToPropertyRequestFail,
   addNoteToProperty,
   addNoteToPropertySuccess,
   addNoteToPropertyError,
   deleteNoteByProperty,
   deleteNoteByPropertyError,
   deleteNoteByPropertySuccess,
   getNoteByProperty,
   getNoteByPropertyError,
   getNoteByPropertySuccess,
   updateNoteToProperty,
   updateNoteToPropertyError,
   updateNoteToPropertySuccess,
   updatePropertyStatus,
   updatePropertyStatusSuccess,
   updatePropertyStatusError,
   saveColumnSelectionRequest,
   saveColumnSelectionSuccess,
   saveColumnSelectionError,
   getColumnSelectionRequest,
   getColumnSelectionSuccess,
   getColumnSelectionError,
   updatePropertyToArchive,
   updatePropertyToArchiveFail,
   updatePropertyToArchiveSuccess,
   mapLandlordToPropertyRequest,
   mapLandlordToPropertyError,
   mapLandlordToPropertySuccess,
   saveLandlordRentDetailsRequest,
   saveLandlordRentDetailsSuccess,
   saveLandlordRentDetailsError,
   getPropertyBasicInfoSetNull
};
