import produce from "immer";

const INITIAL_STATE = {
   createRequestInfo: {
      loading: false
   },
   requestInfoStatus: {
      loading: false,
      data: {}
   },
   requestInfo: {}
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "CREATE_REQUEST_INFO_REQUEST":
            draft.createRequestInfo.loading = true;
            break;
         case "CREATE_REQUEST_INFO_SUCCESS":
            draft.createRequestInfo.loading = false;
            break;
         case "CREATE_REQUEST_INFO_ERROR":
            draft.createRequestInfo.loading = false;
            break;
         case "GET_REQUEST_INFO_STATUS_SUCCESS":
            draft.requestInfoStatus.data = action.payload.response;
            break;
         case "GET_REQUEST_INFO_SUCCESS":
            draft.requestInfo = action.payload;
            break;
      }
   });
