import { put, call, takeEvery, select } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import {
   getLandlordByEmailSuccess,
   getLandlordByEmailError,
   getLandlordByNameSuccess,
   getLandlordByNameError,
   saveLandlordSuccess,
   saveLandlordError,
   createLandlordSuccess,
   getLandlordsSuccess,
   getLandlordsError,
   getLandlordByIdSuccess,
   getLandlordByIdError,
   createLandlordError,
   fetchLandlordBankDetailsSuccess,
   fetchLandlordBankDetailsError,
   saveLandlordBankDetailsSuccess,
   saveLandlordBankDetailsError,
   updateLandlordSuccess,
   updateLandlordError,
   deleteLandlordByIdSuccess,
   deleteLandlordByIdError,
   getContractByIdSuccess,
   getContractByIdError,
   editBankSuccess,
   editBankError,
   getDocumentByIdSuccess,
   getDocumentByIdError,
   addContractError,
   addContractSuccess,
   addBankSuccess,
   addBankError,
   getPropertySuccess,
   getPropertyError,
   addDocumentSuccess,
   addDocumentError,
   editContractSuccess,
   editContractError,
   addNoteToLandlordSuccess,
   addNoteToLandlordError,
   getNoteByLandlordSuccess,
   getNoteByLandlordError,
   updateNoteToLandlordSuccess,
   updateNoteToLandlordError,
   deleteNoteByLandlordSuccess,
   deleteNoteByLandlordError,
   deleteContractDocByIdSuccess,
   deleteContractDocByidError,
   updateDocumentSuccess,
   updateDocumentError,
   getVersionHistoryBylandlordidSuccess,
   getVersionHistoryBylandlordidError,
   getVersionHistoryByIdSuccess,
   getVersionHistoryByIdError,
   getDocumentUrlByIdSuccess,
   getDocumentUrlByIdError,
   updateLandlordToArchiveFail,
   updateLandlordToArchiveSuccess,
   deleteDocumentByIdError,
   deleteDocumentByIdSuccess,
   getEmergencyDetailsForLandlordSuccess,
   getEmergencyDetailsForLandlordError,
   deleteDocumentBySingleIdSuccess,
   deleteDocumentBySingleIdError,
   getDropDownValueForAddNewDocumentError,
   getDropDownValueForAddNewDocumentSuccess,
   getLandlordsLazySuccess,
   getLandlordsLazyError,
   getLandlords
} from "../actions/landlordActions";

import {
   getLandlordByEmail,
   getLandlordByName,
   saveLandlord,
   fetchLandlordBankDetails,
   addBanks,
   getVersionHistoryBylandlordidDocType,
   getVersionHistoryById
} from "../api/landlords";

import { saveBankDetails, editBankDetails } from "../api/bank";

import {
   createLandlord,
   getLandlordsLazy,
   getLandlordById,
   updateLandlordById,
   deleteLandlordById,
   getContractsById,
   getDocumentById,
   addContract,
   getpropertiesBylandlord,
   addDocument,
   editContract,
   deleteNoteByLandlord,
   getNoteByLandlord,
   addNoteToLandlord,
   updateNoteToLandlord,
   deleteContractDocumentByid,
   getDocumentBasestrById,
   updateLandlordToArchiveAPI,
   editDocument,
   deleteDocumentById,
   getEmergencyDetailsForLanldord,
   deleteDocumentBySingleId,
   getDropDownValueForAddNewDocumentType,
   getLandlords as getLandlordAPI
} from "../api/landlord";

function* handleGetLandlordByEmail(payload) {
   try {
      const response = yield call(getLandlordByEmail, payload.payload);
      yield put(getLandlordByEmailSuccess(response));
   } catch (error) {
      yield put(getLandlordByEmailError(error.response));
   }
}

function* handleGetLandlordByName(action) {
   try {
      const response = yield call(getLandlordByName, action.payload);
      yield put(getLandlordByNameSuccess(response));
   } catch (error) {
      yield put(getLandlordByNameError(error.response));
   }
}

function* handleSaveLandlord(payload) {
   try {
      const response = yield call(saveLandlord, payload.payload);
      yield put(saveLandlordSuccess(response));
   } catch (error) {
      yield put(saveLandlordError(error));
   }
}

function* handleFetchLandlordBankDetails(payload) {
   try {
      const response = yield call(fetchLandlordBankDetails, payload.payload);
      yield put(fetchLandlordBankDetailsSuccess(response));
   } catch (error) {
      yield put(fetchLandlordBankDetailsError(error));
   }
}

function* handleCreateLandlord(action) {
   const { payload } = action;
   try {
      const response = yield call(createLandlord, payload);
      yield put(createLandlordSuccess(response));
   } catch (error) {
      yield put(createLandlordError(error.response));
   }
}

function* handleGetLandlords(action) {
   try {
      const response = yield call(getLandlordAPI, action.payload);
      yield put(getLandlordsSuccess(response.data));
   } catch {
      yield put(getLandlordsError());
   }
}

function* handleGetLandlordsLazy(action) {
   try {
      const response = yield call(getLandlordsLazy, action.payload);
      yield put(getLandlordsLazySuccess(response.data));
   } catch (error) {
      yield put(getLandlordsLazyError(error.response));
   }
}

function* handleGetLandlordById({ payload }) {
   const { landlordId } = payload;
   try {
      const response = yield call(getLandlordById, landlordId);
      yield put(getLandlordByIdSuccess(response.data));
   } catch (error) {
      yield put(getLandlordByIdError(error.response));
   }
}

function* handleSaveLandlordBankDetails(action) {
   try {
      const response = yield call(saveBankDetails, action.payload);
      yield put(saveLandlordBankDetailsSuccess(response));
   } catch (error) {
      yield error.response && put(saveLandlordBankDetailsError(error));
   }
}

function* handleUpdateLandlord(action) {
   const landlords = yield select((state) => state.landlords.landlords);
   try {
      yield call(updateLandlordById, action.payload);
      yield put(updateLandlordSuccess());
      yield put(
         getLandlords({
            page: landlords.currentPage,
            limit: landlords.entriesPerPage,
            landlordType: landlords.landlordType,
            landlordStatus: landlords.landlordStatus,
            orderBy: landlords.orderBy,
            searchBy: landlords.searchBy
         })
      );
      action.history.goBack();
   } catch {
      yield put(updateLandlordError());
   }
}

function* handleDeleteLandlord({ payload, history }) {
   try {
      yield call(deleteLandlordById, payload);
      yield put(deleteLandlordByIdSuccess());
      history.push("/agency/landlords");
      yield put(
         getLandlords({
            page: 1,
            limit: 10
         })
      );
   } catch {
      yield put(deleteLandlordByIdError());
   }
}

function* handleGetContractsById({ payload }) {
   try {
      const resp = yield call(getContractsById, payload);
      yield put(getContractByIdSuccess(resp.data));
   } catch {
      yield put(getContractByIdError());
   }
}

function* handleEditBankDetails({ payload }) {
   try {
      yield call(editBankDetails, payload);
      yield put(editBankSuccess());
   } catch {
      yield put(editBankError());
   }
}

function* handleGetDocumentById({ payload }) {
   const landlord = yield select((state) => state.landlords);
   try {
      let resp = yield call(getDocumentById, payload);
      resp.data.response.docSettings = [
         ...(landlord.documentsById?.documents?.docSettings || []),
         ...resp.data.response.docSettings
      ];
      yield put(getDocumentByIdSuccess(resp.data));
   } catch {
      yield put(getDocumentByIdError());
   }
}

function* handleAddContract({ payload }) {
   try {
      yield call(addContract, payload);
      yield put(addContractSuccess());
   } catch {
      yield put(addContractError());
   }
}

function* handleAddBank({ payload }) {
   try {
      yield call(addBanks, payload);
      yield put(addBankSuccess());
   } catch {
      yield put(addBankError());
   }
}

function* handleGetProperties({ payload }) {
   try {
      const resp = yield call(getpropertiesBylandlord, payload);
      yield put(getPropertySuccess(resp.data));
   } catch {
      yield put(getPropertyError());
   }
}

function* handleAddDocument({ payload }) {
   try {
      yield call(addDocument, payload);
      yield put(addDocumentSuccess());
   } catch {
      yield put(addDocumentError());
   }
}

function* handleEditContract({ payload }) {
   try {
      yield call(editContract, payload);
      yield put(editContractSuccess());
   } catch {
      yield put(editContractError());
   }
}
function* handleAddNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         createdBy: agencyId
      };
      const response = yield call(addNoteToLandlord, notePayload);
      yield put(addNoteToLandlordSuccess(response.data));
   } catch (error) {
      yield put(addNoteToLandlordError(error));
   }
}

function* handleUpdateNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         updatedBy: agencyId
      };
      const response = yield call(updateNoteToLandlord, notePayload);
      yield put(updateNoteToLandlordSuccess(response.data));
   } catch (error) {
      yield put(updateNoteToLandlordError(error));
   }
}

function* handleGetNoteByLandlord({ landlordId }) {
   try {
      const response = yield call(getNoteByLandlord, landlordId);
      yield put(getNoteByLandlordSuccess(response.data));
   } catch (error) {
      yield put(getNoteByLandlordError(error));
   }
}

function* handleDeleteNoteByLandlord({ payload }) {
   try {
      yield call(deleteNoteByLandlord, payload);
      yield put(deleteNoteByLandlordSuccess(payload));
   } catch (error) {
      yield put(deleteNoteByLandlordError(error));
   }
}

function* handleDeleteContractDocById({ id }) {
   try {
      yield call(deleteContractDocumentByid, id);
      yield put(deleteContractDocByIdSuccess());
   } catch {
      yield put(deleteContractDocByidError());
   }
}

function* handleUpdateDocument({ payload }) {
   try {
      yield call(editDocument, payload);
      yield put(updateDocumentSuccess());
   } catch {
      yield put(updateDocumentError());
   }
}

function* handleGetVersionHistoryByLandlord({ payload }) {
   try {
      const resp = yield call(getVersionHistoryBylandlordidDocType, payload);
      yield put(getVersionHistoryBylandlordidSuccess(resp.data));
   } catch {
      yield put(getVersionHistoryBylandlordidError());
   }
}

function* handleGetVersionHistoryById({ id }) {
   try {
      const resp = yield call(getVersionHistoryById, id);
      yield put(getVersionHistoryByIdSuccess(resp.data));
   } catch {
      yield put(getVersionHistoryByIdError());
   }
}

function* handleGetDocumentUrlById({ id }) {
   try {
      const resp = yield call(getDocumentBasestrById, id);
      yield put(getDocumentUrlByIdSuccess(resp.data));
   } catch {
      yield put(getDocumentUrlByIdError());
   }
}

function* handleUpdateLandlordToArchive(action) {
   try {
      const response = yield call(updateLandlordToArchiveAPI, action.payload);
      yield put(updateLandlordToArchiveSuccess(response));
   } catch (error) {
      yield put(updateLandlordToArchiveFail(error.response.data));
   }
}

function* handleDeleteDocumentById(payload) {
   try {
      const response = yield call(deleteDocumentById, payload.payload);
      yield put(deleteDocumentByIdSuccess(response));
   } catch (error) {
      yield put(deleteDocumentByIdError(error.response));
   }
}

function* handleDeleteDocumentBySingleId(payload) {
   try {
      const response = yield call(deleteDocumentBySingleId, payload.payload);
      yield put(deleteDocumentBySingleIdSuccess(response));
   } catch (error) {
      yield put(deleteDocumentBySingleIdError(error.response));
   }
}

function* handleGetEmergencyDetailsForLandlord(action) {
   try {
      const response = yield call(getEmergencyDetailsForLanldord, action.id);
      yield put(getEmergencyDetailsForLandlordSuccess(response.data));
   } catch {
      yield put(getEmergencyDetailsForLandlordError());
   }
}

function* handleGetDropDownValuesForAddNewLandLordDocument(action) {
   try {
      const response = yield call(
         getDropDownValueForAddNewDocumentType,
         action.payload
      );
      yield put(getDropDownValueForAddNewDocumentSuccess(response.data));
   } catch {
      yield put(getDropDownValueForAddNewDocumentError());
   }
}

export default function* watchLandlord() {
   yield takeEvery("CREATE_LANDLORD", handleCreateLandlord);

   yield takeEvery("GET_LANDLORDS_LAZY", handleGetLandlordsLazy);
   yield takeEvery("GET_LANDLORDS", handleGetLandlords);
   yield takeEvery("GET_LANDLORD_BY_ID", handleGetLandlordById);
   yield takeEvery("GET_LANDLORD_BY_EMAIL_REQUEST", handleGetLandlordByEmail);
   yield takeEvery("GET_LANDLORD_BY_NAME_REQUEST", handleGetLandlordByName);
   yield takeEvery("SAVE_LANDLORD_REQUEST", handleSaveLandlord);
   yield takeEvery(
      "FETCH_LANDLORD_BANK_DETAILS_REQUEST",
      handleFetchLandlordBankDetails
   );
   yield takeEvery(
      "SAVE_LANDLORD_BANK_DETAILS_REQUEST",
      handleSaveLandlordBankDetails
   );
   yield takeEvery("UPDATE_LANDLORD_REQUEST", handleUpdateLandlord);

   yield takeEvery("DELETE_LANDLORD_REQUEST", handleDeleteLandlord);
   yield takeEvery(
      "GET_CONTRACT_BY_ID_LANDLORD_REQUEST",
      handleGetContractsById
   );
   yield takeEvery("EDIT_BANK", handleEditBankDetails);
   yield takeEvery("GET_DOCUMENT_BY_ID", handleGetDocumentById);
   yield takeEvery("ADD_CONTRACT_REQUEST", handleAddContract);
   yield takeEvery("ADD_BANK_LANDLORD", handleAddBank);
   yield takeEvery("GET_PROPERTY_REQUEST", handleGetProperties);
   yield takeEvery("ADD_DOCUMENT_LANDLORD", handleAddDocument);
   yield takeEvery("EDIT_CONTRACT_REQUEST", handleEditContract);
   yield takeEvery("ADD_NOTE_TO_LANDLORD", handleAddNote);
   yield takeEvery("GET_NOTE_BY_LANDLORD", handleGetNoteByLandlord);
   yield takeEvery("UPDATE_NOTE_TO_LANDLORD", handleUpdateNote);
   yield takeEvery("DELETE_NOTE_BY_LANDLORD", handleDeleteNoteByLandlord);
   yield takeEvery("DELETE_CONTRACT_DOC_BY_ID", handleDeleteContractDocById);
   yield takeEvery("UPDATE_DOCUMENT", handleUpdateDocument);
   yield takeEvery(
      "GET_VERSION_HISTORY_LANDLORD",
      handleGetVersionHistoryByLandlord
   );
   yield takeEvery("GET_VERSION_HISTORY_ID", handleGetVersionHistoryById);
   yield takeEvery("GET_DOCUMENT_URL_BY_ID", handleGetDocumentUrlById);
   yield takeEvery("UPDATE_LANDLORD_TO_ARCHIVE", handleUpdateLandlordToArchive);
   yield takeEvery("DELETE_DOCUMENT_BY_ID", handleDeleteDocumentById);
   yield takeEvery(
      "DELETE_DOCUMENT_BY_SINGLE_ID",
      handleDeleteDocumentBySingleId
   );
   yield takeEvery(
      "GET_EMERGENCY_DETAILS_FOR_LANDLORD",
      handleGetEmergencyDetailsForLandlord
   );
   yield takeEvery(
      "GET_DROPDOWN_VALUES_FOR_ADDNEWDOCUMENT_REQUEST",
      handleGetDropDownValuesForAddNewLandLordDocument
   );
}
