import { put, call, takeEvery } from "redux-saga/effects";
import {
   addDocumentTypeError,
   addDocumentTypeSuccess,
   getDocumentTypeByCatagoryError,
   getDocumentTypeByCatagorySuccess,
   getInvoiceSettingsError,
   getInvoiceSettingsSuccess,
   saveInvoiceSettingsError,
   getUploadedDocTypesError,
   getUploadedDocTypesSuccess
} from "../actions/settingAction";
import {
   addDocumentType,
   getDocumentByCatagory,
   getInvoiceSettings,
   getUploadedDocTypes,
   saveInvoiceSettings
} from "../api/setting";

function* handleGetDocTypeBycat({ catagory }) {
   try {
      const resp = yield call(getDocumentByCatagory, catagory);
      yield put(getDocumentTypeByCatagorySuccess(resp.data));
   } catch {
      yield put(getDocumentTypeByCatagoryError());
   }
}

function* handleAddDocument({ payload }) {
   try {
      yield call(addDocumentType, payload);
      yield put(addDocumentTypeSuccess());
   } catch {
      yield put(addDocumentTypeError());
   }
}

function* handleGetUploadedDocTypes({ category }) {
   try {
      const resp = yield call(getUploadedDocTypes, category);
      yield put(getUploadedDocTypesSuccess(resp.data));
   } catch {
      yield put(getUploadedDocTypesError());
   }
}

function* handleGetInvoiceSettings() {
   try {
      const resp = yield call(getInvoiceSettings);
      yield put(getInvoiceSettingsSuccess(resp.data));
   } catch (error) {
      yield put(getInvoiceSettingsError(error));
   }
}

function* handleSaveInvoiceSettings(action) {
   try {
      const resp = yield call(saveInvoiceSettings, action.payload);
      yield put(getInvoiceSettingsSuccess(resp.data));
   } catch (error) {
      yield put(saveInvoiceSettingsError(error));
   }
}

export default function* watchSettingsSaga() {
   yield takeEvery("GET_DOCUMENT_TYPE_BY_CATAGORY", handleGetDocTypeBycat);
   yield takeEvery("ADD_DOCUMENT_TYPE", handleAddDocument);
   yield takeEvery("GET_UPLOADED_DOC_TYPES", handleGetUploadedDocTypes);
   yield takeEvery("GET_INVOICE_SETTINGS", handleGetInvoiceSettings);
   yield takeEvery("SAVE_INVOICE_SETTINGS", handleSaveInvoiceSettings);
}
