import produce from "immer";

const INITIAL_STATE = {
   tenant: {
      uploadedFileData: [],
      loading: false,
      uploaded: false
   },
   save: {
      loading: false
   },
   export: {
      loading: false,
      fileKey: null
   }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "UPLOAD_FILE_TO_GET_DATA_REQUEST":
            draft.tenant.loading = true;
            break;
         case "UPLOAD_FILE_TO_GET_DATA_SUCCESS":
            draft.tenant.uploadedFileData = action.payload;
            draft.tenant.uploaded = true;
            draft.tenant.loading = false;
            break;
         case "UPLOAD_FILE_TO_GET_DATA_ERROR":
            draft.tenant.loading = false;
            draft.tenant.uploadedFileData = [];
            break;
         case "SAVE_UPLOADED_FILE_DATA":
            draft.save.loading = true;
            break;
         case "SAVE_UPLOADED_FILE_DATA_SUCCESS":
            draft.save.loading = false;
            break;
         case "SAVE_UPLOADED_FILE_DATA_ERROR":
            draft.save.loading = false;
            break;
         case "EXPORT_DATA":
            draft.export.loading = true;
            break;
         case "EXPORT_DATA_SUCCESS":
            draft.export.loading = false;
            draft.export.fileKey = action.url;
            break;
         case "EXPORT_DATA_ERROR":
            draft.export.loading = false;
            break;
         case "GET_FILE":
            draft.export.fileKey = null;
            break;
      }
   });
