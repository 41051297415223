import API from "./API";

export const notificationData = async (payload) => {
   return await API.post("/api/notification/data", payload);
};

export const fetchnotifications = async ({ page, limit }) => {
   return await API.get(
      `/api/notification/getAllNotifications/${page}/${limit}`
   );
};

export const changeNotificationStatus = async (payload) => {
   return await API.get(
      `/api/notification/changeStatus?notificationId=${payload.id}`
   );
};

export const markAsOld = (id) => {
   return API.get(`/api/notification/markOld?notificationId=${id}`);
};

export const newCount = () => {
   return API.get("/api/notification/new/count");
};
