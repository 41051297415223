import produce from "immer";

const INITIAL_STATE = {
   allPrioritiesLoading: null,
   allPriorities: [],
   allRemaindersLoading: null,
   allRemainders: [],
   allStatusesloading: null,
   allStatuses: [],
   allTypesLoading: null,
   allTypes: [],
   filterLoaded: false,
   addUpdateTaskSuccess: false,
   isTaskUpdate: false,
   loading: false,
   pendingTasks: {
      pendingSimpleTaskList: {},
      loaded: false
   },
   markAsDoneSuccess: false,
   taskDetailsById: {},
   comment: {
      loading: false,
      data: [],
      loaded: false
   },
   saveComment: false,
   deleteComment: false,
   deleteTask: false,
   timeline: {
      beforeTimeLineTasks: [],
      afterTimeLineTasks: [],
      paginationDetails: {},
      completedTasks: 0,
      dueTasks: 0,
      totalTasks: 0,
      success: false
   },
   timelineLoading: false,
   calendarTasks: [],
   calendarLoading: false,
   base64: {
      loading: false,
      base: null
   },
   saveReply: false,
   saveAsRead: false,
   deleteReply: false,
   saveEmoji: false,
   isSaveASRead: "done",
   base64Comment: {
      loading: false,
      base: null
   },
   view: localStorage.getItem("view") || "timeline",
   allTask: { data: {}, loading: false, loaded: false }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_TASK_PRIORITIES":
            draft.allPrioritiesLoading = true;
            break;

         case "GET_TASK_PRIORITIES_SUCCESS":
            draft.allPrioritiesLoading = false;
            draft.allPriorities = action.payload.data.response;
            break;

         case "GET_TASK_PRIORITIES_ERROR":
            draft.allPrioritiesLoading = false;
            draft.allPriorities = [];
            break;

         case "GET_TASK_REMAINDERS":
            draft.allRemaindersLoading = true;
            break;

         case "GET_TASK_REMAINDERS_SUCCESS":
            draft.allRemaindersLoading = false;
            draft.allRemainders = action.payload.data.response;
            break;

         case "GET_TASK_REMAINDERS_ERROR":
            draft.allRemaindersLoading = false;
            draft.allRemainders = [];
            break;

         case "GET_TASK_STATUSES":
            draft.allStatusesloading = true;
            break;

         case "GET_TASK_STATUSES_SUCCESS":
            draft.allStatusesloading = false;
            draft.allStatuses = action.payload.data.response;
            break;

         case "GET_TASK_STATUSES_ERROR":
            draft.allStatusesloading = false;
            draft.allStatuses = [];
            break;

         case "GET_TASK_TYPES":
            draft.allTypesLoading = true;
            break;

         case "GET_TASK_TYPES_SUCCESS":
            draft.filterLoaded = true;
            draft.allTypesLoading = false;
            draft.allTypes = action.payload.data.response;
            break;

         case "GET_TASK_TYPES_ERROR":
            draft.allTypesLoading = false;
            draft.allTypes = [];
            break;

         case "ADD_UPDATE_TASK":
            draft.isTaskUpdate = action.payload.taskId && true;
            draft.addUpdateTaskSuccess = false;
            draft.loading = true;
            break;

         case "ADD_UPDATE_TASK_SUCCESS":
            if (!draft.isTaskUpdate) {
               draft.addUpdateTaskSuccess = true;
            }
            draft.loading = false;
            break;

         case "ADD_UPDATE_TASK_ERROR":
            draft.addUpdateTaskSuccess = false;
            draft.loading = false;
            break;

         case "GET_PENDING_TASK_LIST":
            break;

         case "GET_PENDING_TASK_LIST_SUCCESS":
            draft.pendingTasks.pendingSimpleTaskList =
               action.payload.data.response;
            draft.pendingTasks.loaded = true;
            break;

         case "GET_PENDING_TASK_LIST_ERROR":
            draft.pendingSimpleTaskList = {};
            break;

         case "MARK_TASK_AS_DONE":
            draft.markAsDoneSuccess = false;
            break;

         case "MARK_TASK_AS_DONE_SUCCESS":
            draft.markAsDoneSuccess = true;
            break;

         case "MARK_TASK_AS_DONE_ERROR":
            draft.markAsDoneSuccess = false;
            break;

         case "GET_TASK_DETAILS_BY_ID":
            draft.taskDetailsById = {};
            break;

         case "GET_TASK_DETAILS_BY_ID_SUCCESS":
            draft.taskDetailsById = action.payload.data.response;
            break;

         case "GET_TASK_DETAILS_BY_ID_ERROR":
            draft.taskDetailsById = {};
            break;

         case "SET_TASK_VIEW":
            draft.view = action.view;
            break;

         // comment
         case "GET_COMMENT_BY_TASK_ID":
            draft.comment.loading = true;
            break;

         case "GET_COMMENT_BY_TASK_ID_SUCCESS":
            draft.comment.loaded = true;
            draft.comment.data = action.payload.response;
            draft.comment.loading = false;
            if (draft.comment.data) draft.isSaveASRead = "done";
            break;

         case "GET_COMMENT_BY_TASK_ID_ERROR":
            draft.comment.data = [];
            draft.comment.loading = false;
            break;

         case "SAVE_COMMENT_BY_TASK_ID":
            draft.saveComment = true;
            break;

         case "SAVE_COMMENT_BY_TASK_ID_SUCCESS":
            draft.saveComment = false;
            break;

         case "SAVE_COMMENT_BY_TASK_ID_ERROR":
            draft.saveComment = false;
            break;

         case "DELETE_COMMENT_BY_ID":
            draft.deleteComment = true;
            break;

         case "DELETE_COMMENT_BY_ID_SUCCESS":
            draft.deleteComment = false;
            break;

         case "DELETE_COMMENT_BY_ID_ERROR":
            draft.deleteComment = false;
            break;

         case "DELETE_TASK_DELETE_BY_ID":
            draft.deleteTask = true;
            break;

         case "DELETE_TASK_DELETE_BY_ID_SUCCESS":
            draft.deleteTask = false;
            break;

         case "DELETE_TASK_DELETE_BY_ID_ERROR":
            draft.deleteTask = false;
            break;

         case "GET_TASK_TIMELINE":
            draft.timelineLoading = true;
            draft.timeline.success = false;
            break;

         case "GET_TASK_TIMELINE_SUCCESS":
            draft.timelineLoading = false;
            draft.timeline.success = true;
            draft.timeline.afterTimeLineTasks = [
               ...draft.timeline.afterTimeLineTasks,
               ...action.payload.response.afterTimeLineTasks
            ];
            draft.timeline.beforeTimeLineTasks = [
               ...draft.timeline.beforeTimeLineTasks,
               ...action.payload.response.beforeTimeLineTasks
            ];
            draft.timeline.paginationDetails =
               action.payload.response.paginationDetails;
            draft.timeline.completedTasks =
               action.payload.response.completedTasks;
            draft.timeline.dueTasks = action.payload.response.dueTasks;
            draft.timeline.totalTasks = action.payload.response.totalTasks;
            break;

         case "GET_TASK_TIMELINE_ERROR":
            draft.timelineLoading = false;
            draft.timeline.success = false;
            break;

         case "GET_CALENDAR_TASKS":
            draft.addUpdateTaskSuccess = false;
            draft.calendarLoading = true;
            break;

         case "GET_CALENDAR_TASKS_SUCCESS":
            draft.calendarTasks = action.payload.response;
            draft.calendarLoading = false;
            break;

         case "GET_CALENDAR_TASKS_ERROR":
            draft.calendarLoading = false;
            break;

         case "GET_BASE64_BY_URL":
            draft.base64.loading = true;
            break;

         case "GET_BASE64_BY_URL_SUCCESS":
            draft.base64.base = action.payload.response;
            draft.base64.loading = false;
            break;

         case "GET_BASE64_BY_URL_ERROR":
            draft.base64.loading = false;
            break;

         case "SET_NULL":
            draft.base64.base = null;
            break;

         case "SAVE_REPLY":
            draft.saveReply = true;
            break;

         case "SAVE_REPLY_SUCCESS":
            draft.saveReply = false;
            break;

         case "SAVE_REPLY_ERROR":
            draft.saveReply = false;
            break;

         case "SAVE_AS_READ":
            draft.saveAsRead = true;
            draft.isSaveASRead = "loading";
            if (action.payload.comment) {
               draft.comment.data.forEach((val) => {
                  if (val.id == action.payload.id) {
                     val.new = false;
                  }
               });
            } else {
               draft.comment.data.forEach((val) => {
                  if (val.id == action.payload.cid) {
                     val.replyDTOList.forEach((val) => {
                        if (val.id == action.payload.id) {
                           val.new = false;
                        }
                     });
                  }
               });
            }
            break;

         case "SAVE_AS_READ_SUCCESS":
            draft.saveAsRead = false;
            break;

         case "SAVE_AS_READ_ERROR":
            draft.saveAsRead = false;
            break;

         case "DELETE_REPLY":
            draft.deleteReply = true;
            break;

         case "DELETE_REPLY_SUCCESS":
            draft.deleteReply = false;
            break;

         case "DELETE_REPLY_ERROR":
            draft.deleteReply = false;
            break;

         case "SAVE_EMOJI":
            draft.saveEmoji = true;
            break;

         case "SAVE_EMOJI_SUCCESS":
            draft.saveEmoji = false;
            break;

         case "SAVE_EMOJI_ERROR":
            draft.saveEmoji = false;
            break;

         case "GET_BASE64_FOR_COMMENT":
            draft.base64Comment.loading = true;
            break;

         case "GET_BASE64_FOR_COMMENT_SUCCESS":
            draft.base64Comment.base = action.payload.response;
            draft.base64Comment.loading = false;
            break;

         case "GET_BASE64_FOR_COMMENT_ERROR":
            draft.base64Comment.loading = false;
            break;

         case "GET_ALL_TASK":
            draft.allTask.loading = true;
            break;

         case "GET_ALL_TASK_SUCCESS":
            draft.allTask.loading = false;
            draft.allTask.loaded = true;
            draft.allTask.data = action.payload;
            break;

         case "GET_ALL_TASK_ERROR":
            draft.allTask.loading = false;
            break;

         case "SET_NULL_TASKS":
            draft.timeline.afterTimeLineTasks = [];
            draft.timeline.beforeTimeLineTasks = [];
            break;

         default:
            return draft;
      }
   });
