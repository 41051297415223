import produce from "immer";

const INITIAL_STATE = {
   path: "home"
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "CHANGE_ROUTE":
            draft.path = action.payload;
            break;

         default:
            return draft;
      }
   });
