import { END, eventChannel } from "redux-saga";
import { put, call, takeEvery, fork, take } from "redux-saga/effects";
import {
   ExportDataError,
   ExportDataSuccess,
   SaveUploadedFileDataError,
   SaveUploadedFileDataSuccess,
   UploadFileToGetDataError,
   UploadFileToGetDataSuccess
} from "../actions/dataMigrationAction";
import { changeLandlordLoadedState } from "../actions/landlordActions";
import { setProgressLoading } from "../actions/miscActions";
import { openSuccessModal } from "../actions/modalActions";
import { changePropertyLoadedState } from "../actions/propertyActions";
import { changeTenantLoadedState } from "../actions/tenantActions";
// import { setProgressLoading } from "../actions/miscActions";
import {
   ExportData,
   GetFile,
   SaveLandlordUploadedData,
   SavePropertyUploadedData,
   SaveTenantUploadedData,
   UploadFileToGetData
} from "../api/dataMigration";

function createUploader(api, fileKey) {
   let emit;
   const chan = eventChannel((emitter) => {
      emit = emitter;
      return () => {};
   });

   const uploadPromise = UploadFileToGetData(api, fileKey, (event) => {
      if (Math.round((event.loaded * 100) / event.total) == 100) {
         emit(Math.round((event.loaded * 100) / event.total));
         emit(END);
      }

      emit(Math.round((event.loaded * 100) / event.total));
   });

   return [uploadPromise, chan];
}

function* watchOnProgress(chan) {
   while (true) {
      const data = yield take(chan);
      yield put(setProgressLoading(data));
   }
}

function* handleGetDataFromFile(action) {
   const [uploadPromise, chan] = createUploader(
      action.file.module,
      action.file.file
   );
   yield fork(watchOnProgress, chan);
   try {
      let resp = yield call(() => uploadPromise);
      yield put(UploadFileToGetDataSuccess(resp.data.response));
      action.history();
   } catch (err) {
      yield put(UploadFileToGetDataError(err));
   }
}

function* handleSaveUploadedData(action) {
   try {
      if (action.payload.module === "tenant") {
         yield call(SaveTenantUploadedData, action.payload.data);
         yield put(SaveUploadedFileDataSuccess());
         yield put(changeTenantLoadedState(false));
      } else if (action.payload.module === "property") {
         yield call(SavePropertyUploadedData, action.payload.data);
         yield put(SaveUploadedFileDataSuccess());
         yield put(changePropertyLoadedState(false));
      } else {
         yield call(SaveLandlordUploadedData, action.payload.data);
         yield put(SaveUploadedFileDataSuccess());
         yield put(changeLandlordLoadedState(false));
      }
      yield put(
         openSuccessModal({
            description: `${action.payload.module} imported successfully`,
            buttonText: `Back to ${action.payload.module}`,
            to:
               action.payload.module === "property"
                  ? "/agency/properties"
                  : action.payload.module === "tenant"
                  ? "/agency/tenants"
                  : "/agency/landlords"
         })
      );
   } catch {
      yield put(SaveUploadedFileDataError());
   }
}

function* handleExportData(action) {
   try {
      const resp = yield call(ExportData, action.payload);
      yield put(ExportDataSuccess(resp.data.response.fileKey));
   } catch {
      yield put(ExportDataError());
   }
}

function* handleGetFile(action) {
   try {
      const resp = yield call(GetFile, action.fileKey);
      // download zip file
      let url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.id = "export-download";
      link.href = url;
      link.download = "kadscloud.zip";
      document.body.appendChild(link);
      link.click();
      // remove a tag and revoke that temp url after clicked
      document.getElementById("export-download").remove();
      window.URL.revokeObjectURL(url);
   } catch (err) {
      console.error(err);
   }
}

export default function* watchDataMigrationActions() {
   yield takeEvery("UPLOAD_FILE_TO_GET_DATA_REQUEST", handleGetDataFromFile);
   yield takeEvery("SAVE_UPLOADED_FILE_DATA", handleSaveUploadedData);
   yield takeEvery("EXPORT_DATA", handleExportData);
   yield takeEvery("GET_FILE", handleGetFile);
}
