// property
export const getAllPropertyDocuments = (payload) => ({
   type: "GET_ALL_PROPERTY_DOCUMENTS",
   payload
});

export const getAllPropertyDocumentsSuccess = (payload) => ({
   type: "GET_ALL_PROPERTY_DOCUMENTS_SUCCESS",
   payload
});

export const getAllPropertyDocumentsError = () => ({
   type: "GET_ALL_PROPERTY_DOCUMENTS_ERROR"
});

// tenant
export const getAllTenantDocuments = (payload) => ({
   type: "GET_ALL_TENANT_DOCUMENTS",
   payload
});

export const getAllTenantDocumentsSuccess = (payload) => ({
   type: "GET_ALL_TENANT_DOCUMENTS_SUCCESS",
   payload
});

export const getAllTenantDocumentsError = () => ({
   type: "GET_ALL_TENANT_DOCUMENTS_ERROR"
});

// landlord
export const getAllLandlordDocuments = (payload) => ({
   type: "GET_ALL_LANDLORD_DOCUMENTS",
   payload
});

export const getAllLandlordDocumentsSuccess = (payload) => ({
   type: "GET_ALL_LANDLORD_DOCUMENTS_SUCCESS",
   payload
});

export const getAllLandlordDocumentsError = () => ({
   type: "GET_ALL_LANDLORD_DOCUMENTS_ERROR"
});

// common

export const setDocumentsNull = (payload) => ({
   type: "SET_DOCUMENTS_NULL",
   payload
});
