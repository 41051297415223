import API from "./API";
const getLandlordByEmail = async (payload) => {
   return await API.get(
      `api/landlord/getLandLordDetailByEmail?email=${payload.email}`
   );
};

const getLandlordByName = async (payload) => {
   return await API.get(
      `api/landlord/getLandlordsByLandlordName?name=${payload.name}`
   );
};

const saveLandlord = async (payload) => {
   const formData = new FormData();
   payload.contacts = payload.contacts ? payload.contacts : [];

   Object.keys(payload).map((key) => formData.append(key, payload[key]));
   formData.delete("contacts");
   formData.delete("bankDetails");

   for (let i = 0; i < payload.contacts.length; i++) {
      formData.append("contacts[" + i + "].label", payload.contacts[i].label);
      formData.append("contacts[" + i + "].number", payload.contacts[i].number);
   }

   payload.bankDetails.map(
      ({ bank, accountNumber, nameOnTheBank, sortCode }, index) => {
         formData.append(`bankDetails[${index}].bank`, bank);
         formData.append(`bankDetails[${index}].accountNumber`, accountNumber);
         formData.append(`bankDetails[${index}].nameOnTheBank`, nameOnTheBank);
         formData.append(`bankDetails[${index}].sortCode`, sortCode);
      }
   );

   return await API.post("/api/landlord/addLandLord", formData, {
      headers: {
         "content-type": "multipart/form-data"
      }
   });
};

const fetchLandlordBankDetails = async (payload) => {
   return await API.get(
      `api/landlord/getBankDetailsByLandlordId?landlordId=${payload.id}`
   );
};

export const addBanks = (data) => {
   return API.post("api/property/addBankingDetail", data);
};

export const updateDocument = (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.delete("documentUrl");
   data.documentUrl.map((fileObj) => formData.append("documentUrl", fileObj));
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   return API.post("api/landlord/updateLandlordDocument", formData);
};

export const getVersionHistoryBylandlordidDocType = (payload) => {
   return API.get("api/landlord/getVersionHistoryByType", {
      params: {
         landlordId: payload.landlordId,
         documentType: payload.documentType
      }
   });
};

export const getVersionHistoryById = (id) => {
   return API.get("api/landlord/getVersionHistory", {
      params: {
         id
      }
   });
};

export {
   fetchLandlordBankDetails,
   getLandlordByEmail,
   getLandlordByName,
   saveLandlord
};
