export const getTaskPriorities = () => ({
   type: "GET_TASK_PRIORITIES"
});

export const getTaskPrioritiesSuccess = (payload) => ({
   type: "GET_TASK_PRIORITIES_SUCCESS",
   payload
});

export const getTaskPrioritiesError = () => ({
   type: "GET_TASK_PRIORITIES_ERROR"
});

export const getTaskReminders = () => ({
   type: "GET_TASK_REMAINDERS"
});

export const getTaskRemindersSuccess = (payload) => ({
   type: "GET_TASK_REMAINDERS_SUCCESS",
   payload
});

export const getTaskRemindersError = () => ({
   type: "GET_TASK_REMAINDERS_ERROR"
});

export const getTaskStatuses = () => ({
   type: "GET_TASK_STATUSES"
});

export const getTaskStatusesSuccess = (payload) => ({
   type: "GET_TASK_STATUSES_SUCCESS",
   payload
});

export const getTaskStatusesError = () => ({
   type: "GET_TASK_STATUSES_ERROR"
});

export const getTaskTypes = () => ({
   type: "GET_TASK_TYPES"
});

export const getTaskTypesSuccess = (payload) => ({
   type: "GET_TASK_TYPES_SUCCESS",
   payload
});

export const getTaskTypesError = () => ({
   type: "GET_TASK_TYPES_ERROR"
});

export const addUpdateTask = (payload) => ({
   type: "ADD_UPDATE_TASK",
   payload
});

export const addUpdateTaskSuccess = (payload) => ({
   type: "ADD_UPDATE_TASK_SUCCESS",
   payload
});

export const addUpdateTaskError = (payload) => ({
   type: "ADD_UPDATE_TASK_ERROR",
   payload
});

export const getPendingTaskList = () => ({
   type: "GET_PENDING_TASK_LIST"
});

export const getPendingTaskListSuccess = (payload) => ({
   type: "GET_PENDING_TASK_LIST_SUCCESS",
   payload
});

export const getPendingTaskListError = () => ({
   type: "GET_PENDING_TASK_LIST_ERROR"
});

export const markTaskAsDone = (payload) => ({
   type: "MARK_TASK_AS_DONE",
   payload
});

export const markTaskAsDoneSuccess = (payload) => ({
   type: "MARK_TASK_AS_DONE_SUCCESS",
   payload
});

export const markTaskAsDoneError = (payload) => ({
   type: "MARK_TASK_AS_DONE_ERROR",
   payload
});

export const getTaskDetails = (payload) => ({
   type: "GET_TASK_DETAILS_BY_ID",
   payload
});

export const getTaskDetailsSuccess = (payload) => ({
   type: "GET_TASK_DETAILS_BY_ID_SUCCESS",
   payload
});

export const getTaskDetailsError = (payload) => ({
   type: "GET_TASK_DETAILS_BY_ID_ERROR",
   payload
});

export const DeleteTaskById = (payload) => ({
   type: "DELETE_TASK_DELETE_BY_ID",
   payload
});

export const DeleteTaskByIdSuccess = () => ({
   type: "DELETE_TASK_DELETE_BY_ID_SUCCESS"
});

export const DeleteTaskIdError = () => ({
   type: "DELETE_TASK_DELETE_BY_ID_ERROR"
});

export const GetTaskTimeline = (data) => ({
   type: "GET_TASK_TIMELINE",
   data
});

export const GetTaskTimelineSuccess = (payload) => ({
   type: "GET_TASK_TIMELINE_SUCCESS",
   payload
});

export const GetTaskTimelineError = () => ({
   type: "GET_TASK_TIMELINE_ERROR"
});

export const getBase64ByURL = (url) => ({
   type: "GET_BASE64_BY_URL",
   url
});

export const getBase64ByURLSuccess = (payload) => ({
   type: "GET_BASE64_BY_URL_SUCCESS",
   payload
});

export const getBase64ByURLError = () => ({
   type: "GET_BASE64_BY_URL_ERROR"
});

export const deleteTaskDocById = (id) => ({
   type: "DELETE_TASK_DOC_BY_ID",
   id
});

export const deleteTaskDocByIdSuccess = () => ({
   type: "DELETE_TASK_DOC_BY_ID_SUCCESS"
});

export const deleteTaskDocByIdError = () => ({
   type: "DELETE_TASK_DOC_BY_ID_ERROR"
});

export const setTaskView = (view) => ({
   type: "SET_TASK_VIEW",
   view
});

// set null

export const setNull = () => ({
   type: "SET_NULL"
});

// comment

export const GetCommentByTaskId = (id) => ({
   type: "GET_COMMENT_BY_TASK_ID",
   id
});

export const GetCommentByTaskIdSuccess = (payload) => ({
   type: "GET_COMMENT_BY_TASK_ID_SUCCESS",
   payload
});

export const GetCommentByTaskIdError = () => ({
   type: "GET_COMMENT_BY_TASK_ID_ERROR"
});

export const SaveComment = (payload) => ({
   type: "SAVE_COMMENT_BY_TASK_ID",
   payload
});

export const saveCommentSuccess = () => ({
   type: "SAVE_COMMENT_BY_TASK_ID_SUCCESS"
});

export const saveCommentError = () => ({
   type: "SAVE_COMMENT_BY_TASK_ID_ERROR"
});

export const deleteCommentById = (id) => ({
   type: "DELETE_COMMENT_BY_ID",
   id
});

export const deleteCommentByIdSuccess = () => ({
   type: "DELETE_COMMENT_BY_ID_SUCCESS"
});

export const deleteCommentByIdError = () => ({
   type: "DELETE_COMMENT_BY_ID_ERROR"
});

export const saveAsRead = (payload) => ({
   type: "SAVE_AS_READ",
   payload
});

export const saveAsReadSuccess = () => ({
   type: "SAVE_AS_READ_SUCCESS"
});

export const saveAsReadError = () => ({
   type: "SAVE_AS_READ_ERROR"
});

// reply

export const saveReply = (payload) => ({
   type: "SAVE_REPLY",
   payload
});

export const saveReplySuccess = () => ({
   type: "SAVE_REPLY_SUCCESS"
});

export const saveReplyError = () => ({
   type: "SAVE_REPLY_ERROR"
});

export const deleteReply = (id) => ({
   type: "DELETE_REPLY",
   id
});

export const deleteReplySuccess = () => ({
   type: "DELETE_REPLY_SUCCESS"
});

export const deleteReplyError = () => ({
   type: "DELETE_REPLY_ERROR"
});

export const saveEmoji = (payload) => ({
   type: "SAVE_EMOJI",
   payload
});

export const saveEmoijSuccess = () => ({
   type: "SAVE_EMOJI_SUCCESS"
});

export const saveEmojiError = () => ({
   type: "SAVE_EMOJI_ERROR"
});

export const getBase64ForComment = (url) => ({
   type: "GET_BASE64_FOR_COMMENT",
   url
});

export const getBase64ForCommentSuccess = (payload) => ({
   type: "GET_BASE64_FOR_COMMENT_SUCCESS",
   payload
});

export const getBase64ForCommentError = () => ({
   type: "GET_BASE64_FOR_COMMENT_ERROR"
});

// calendar

export const getCalendarTasks = (data) => ({
   type: "GET_CALENDAR_TASKS",
   data
});

export const getCalendarTasksSuccess = (payload) => ({
   type: "GET_CALENDAR_TASKS_SUCCESS",
   payload
});

export const getCalendarTasksError = () => ({
   type: "GET_CALENDAR_TASKS_ERROR"
});

export const getAllTask = (payload) => ({
   type: "GET_ALL_TASK",
   payload
});

export const getAllTaskSuccess = (payload) => ({
   type: "GET_ALL_TASK_SUCCESS",
   payload
});

export const getAllTaskError = () => ({
   type: "GET_ALL_TASK_ERROR"
});

export const setNullTasks = () => ({
   type: "SET_NULL_TASKS"
});
