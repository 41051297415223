const getLandlordByEmailRequest = (payload) => ({
   type: "GET_LANDLORD_BY_EMAIL_REQUEST",
   payload
});

const getLandlordByEmailSuccess = (response) => ({
   type: "GET_LANDLORD_BY_EMAIL_SUCCESS",
   response
});

const getLandlordByEmailError = (error) => ({
   type: "GET_LANDLORD_BY_EMAIL_ERROR",
   error
});

const getLandlordByNameRequest = (payload) => ({
   type: "GET_LANDLORD_BY_NAME_REQUEST",
   payload
});

const getLandlordByNameSuccess = (response) => ({
   type: "GET_LANDLORD_BY_NAME_SUCCESS",
   response
});

const getLandlordByNameError = (error) => ({
   type: "GET_LANDLORD_BY_NAME_ERROR",
   error
});

const saveLandlordRequest = (payload) => ({
   type: "SAVE_LANDLORD_REQUEST",
   payload
});

const saveLandlordSuccess = (response) => ({
   type: "SAVE_LANDLORD_SUCCESS",
   response
});

const saveLandlordError = (error) => ({
   type: "SAVE_LANDLORD_ERROR",
   error
});

const fetchLandlordBankDetailsRequest = (payload) => ({
   type: "FETCH_LANDLORD_BANK_DETAILS_REQUEST",
   payload
});

const fetchLandlordBankDetailsSuccess = (response) => ({
   type: "FETCH_LANDLORD_BANK_DETAILS_SUCCESS",
   response
});

const fetchLandlordBankDetailsError = (error) => ({
   type: "FETCH_LANDLORD_BANK_DETAILS_ERROR",
   error
});

const saveLandlordBankDetailsRequest = (payload) => ({
   type: "SAVE_LANDLORD_BANK_DETAILS_REQUEST",
   payload
});

const saveLandlordBankDetailsSuccess = (response) => ({
   type: "SAVE_LANDLORD_BANK_DETAILS_SUCCESS",
   response
});

const saveLandlordBankDetailsError = (error) => ({
   type: "SAVE_LANDLORD_BANK_DETAILS_ERROR",
   error
});

const resetLandlordData = () => ({
   type: "RESET_LANDLORD_DATA"
});

const updateLandlordToArchive = (payload) => ({
   type: "UPDATE_LANDLORD_TO_ARCHIVE",
   payload
});

const updateLandlordToArchiveSuccess = (response) => ({
   type: "UPDATE_LANDLORD_TO_ARCHIVE_SUCCESS",
   response
});

const updateLandlordToArchiveFail = (error) => ({
   type: "UPDATE_LANDLORD_TO_ARCHIVE_FAIL",
   error
});

export const resetLandlordFilter = () => ({
   type: "RESET_LANDLORD_FILTER"
});

export {
   getLandlordByEmailRequest,
   getLandlordByEmailSuccess,
   getLandlordByEmailError,
   getLandlordByNameRequest,
   getLandlordByNameSuccess,
   getLandlordByNameError,
   saveLandlordRequest,
   saveLandlordSuccess,
   saveLandlordError,
   fetchLandlordBankDetailsRequest,
   fetchLandlordBankDetailsSuccess,
   fetchLandlordBankDetailsError,
   saveLandlordBankDetailsRequest,
   saveLandlordBankDetailsSuccess,
   saveLandlordBankDetailsError,
   resetLandlordData,
   updateLandlordToArchive,
   updateLandlordToArchiveFail,
   updateLandlordToArchiveSuccess
};

const createLandlord = (payload) => ({
   type: "CREATE_LANDLORD",
   payload
});

const createLandlordSuccess = (payload) => ({
   type: "CREATE_LANDLORD_SUCCESS",
   payload
});

const createLandlordError = () => ({
   type: "CREATE_LANDLORD_ERROR"
});

const getLandlords = (payload) => ({
   type: "GET_LANDLORDS",
   payload
});

const getLandlordsSuccess = (response) => ({
   type: "GET_LANDLORDS_SUCCESS",
   response
});

const getLandlordsError = (payload) => ({
   type: "GET_LANDLORDS_ERROR",
   payload
});

export const getLandlordsLazy = (payload) => ({
   type: "GET_LANDLORDS_LAZY",
   payload
});

export const getLandlordsLazySetNull = () => ({
   type: "GET_LANDLORDS_LAZY_SET_NULL"
});

export const getLandlordsLazySuccess = (response) => ({
   type: "GET_LANDLORDS_LAZY_SUCCESS",
   response
});

export const getLandlordsLazyError = (payload) => ({
   type: "GET_LANDLORDS_LAZY_ERROR",
   payload
});

const getLandlordById = (payload) => ({
   type: "GET_LANDLORD_BY_ID",
   payload
});

const getLandlordByIdSuccess = (payload) => ({
   type: "GET_LANDLORD_BY_ID_SUCCESS",
   payload
});

const getLandlordByIdError = (payload) => ({
   type: "GET_LANDLORD_BY_ID_ERROR",
   payload
});

const setPage = (payload) => ({
   type: "SET_PAGE",
   payload
});

const updateLandlord = (payload, history) => ({
   type: "UPDATE_LANDLORD_REQUEST",
   payload,
   history
});

const updateLandlordSuccess = () => ({
   type: "UPDATE_LANDLORD_SUCCESS"
});

const updateLandlordError = () => ({
   type: "UPDATE_LANDLORD_ERROR"
});

const deleteLandlordById = (payload, history) => ({
   type: "DELETE_LANDLORD_REQUEST",
   payload,
   history
});

const deleteLandlordByIdSuccess = () => ({
   type: "DELETE_LANDLORD_SUCCESS"
});

const deleteLandlordByIdError = () => ({
   type: "DELETE_LANDLORD_ERROR"
});

const addBank = (payload) => ({
   type: "ADD_BANK_LANDLORD",
   payload
});

const addBankSuccess = () => ({
   type: "ADD_BANK_SUCCESS"
});

const addBankError = () => ({
   type: "ADD_BANK_ERROR"
});

const getProperty = (payload) => ({
   type: "GET_PROPERTY_REQUEST",
   payload
});

const getPropertySuccess = (payload) => ({
   type: "GET_PROPERTY_SUCCESS",
   payload
});

const getPropertyError = () => ({
   type: "GET_PROPERTY_ERROR"
});

export {
   createLandlord,
   createLandlordSuccess,
   createLandlordError,
   getLandlords,
   getLandlordsSuccess,
   getLandlordsError,
   getLandlordById,
   getLandlordByIdSuccess,
   getLandlordByIdError,
   updateLandlord,
   updateLandlordSuccess,
   updateLandlordError,
   deleteLandlordById,
   deleteLandlordByIdSuccess,
   deleteLandlordByIdError,
   setPage,
   addBank,
   addBankSuccess,
   addBankError,
   getProperty,
   getPropertySuccess,
   getPropertyError
};

const addContract = (payload) => ({
   type: "ADD_CONTRACT_REQUEST",
   payload
});

const addContractSuccess = () => ({
   type: "ADD_CONTRACT_SUCCESS"
});

const addContractError = () => ({
   type: "ADD_CONTRACT_ERROR"
});

const getContractById = (payload) => ({
   type: "GET_CONTRACT_BY_ID_LANDLORD_REQUEST",
   payload
});

const getContractByIdSuccess = (payload) => ({
   type: "GET_CONTRACT_BY_ID_LANDLORD_SUCCESS",
   payload
});

const getContractByIdError = () => ({
   type: "GET_CONTRACT_BY_ID_LANDLORD_ERROR"
});

const getDocumentById = (payload) => ({
   type: "GET_DOCUMENT_BY_ID",
   payload
});

const getDocumentByIdSuccess = (payload) => ({
   type: "GET_DOCUMENT_BY_ID_SUCCESS",
   payload
});

const getDocumentByIdError = () => ({
   type: "GET_DOCUMENT_BY_ID_ERROR"
});

const editBank = (payload) => ({
   type: "EDIT_BANK",
   payload
});

const editBankSuccess = () => ({
   type: "EDIT_BANK_SUCCESS"
});

const editBankError = () => ({
   type: "EDIT_BANK_ERROR"
});

const addDocument = (payload) => ({
   type: "ADD_DOCUMENT_LANDLORD",
   payload
});

const addDocumentSuccess = (payload) => ({
   type: "ADD_DOCUMENT_SUCCESS",
   payload
});

const addDocumentError = () => ({
   type: "ADD_DOCUMENT_ERROR"
});

const editContract = (payload) => ({
   type: "EDIT_CONTRACT_REQUEST",
   payload
});

const editContractSuccess = () => ({
   type: "EDIT_CONTRACT_SUCCESS"
});

const editContractError = () => ({
   type: "EDIT_CONTRACT_ERROR"
});

const deleteContractDocByid = (id) => ({
   type: "DELETE_CONTRACT_DOC_BY_ID",
   id
});

const deleteContractDocByIdSuccess = () => ({
   type: "DELETE_CONTRACT_DOC_BY_ID_SUCCESS"
});

const deleteContractDocByidError = () => ({
   type: "DELETE_CONTRACT_DOC_BY_ID_ERROR"
});

export {
   getContractById,
   getContractByIdSuccess,
   getContractByIdError,
   getDocumentById,
   getDocumentByIdSuccess,
   getDocumentByIdError,
   editBank,
   editBankSuccess,
   editBankError,
   addContract,
   addContractSuccess,
   addContractError,
   addDocument,
   addDocumentError,
   addDocumentSuccess,
   editContract,
   editContractError,
   editContractSuccess,
   deleteContractDocByid,
   deleteContractDocByidError,
   deleteContractDocByIdSuccess
};

const addNoteToLandlord = (payload) => ({
   type: "ADD_NOTE_TO_LANDLORD",
   payload
});

const addNoteToLandlordSuccess = (payload) => ({
   type: "ADD_NOTE_TO_LANDLORD_SUCCESS",
   payload
});

const addNoteToLandlordError = (error) => ({
   type: "ADD_NOTE_TO_LANDLORD_ERROR",
   error
});

const updateNoteToLandlord = (payload) => ({
   type: "UPDATE_NOTE_TO_LANDLORD",
   payload
});

const updateNoteToLandlordError = (error) => ({
   type: "UPDATE_NOTE_TO_LANDLORD_ERROR",
   error
});

const updateNoteToLandlordSuccess = (payload) => ({
   type: "UPDATE_NOTE_TO_LANDLORD_SUCCESS",
   payload
});

const getNoteByLandlord = (landlordId) => ({
   type: "GET_NOTE_BY_LANDLORD",
   landlordId
});

const getNoteByLandlordSuccess = (payload) => ({
   type: "GET_NOTE_BY_LANDLORD_SUCCESS",
   payload
});

const getNoteByLandlordError = (error) => ({
   type: "GET_NOTE_BY_LANDLORD_ERROR",
   error
});

const deleteNoteByLandlord = (payload) => ({
   type: "DELETE_NOTE_BY_LANDLORD",
   payload
});

const deleteNoteByLandlordSuccess = (payload) => ({
   type: "DELETE_NOTE_BY_LANDLORD_SUCCESS",
   payload
});

const deleteNoteByLandlordError = (error) => ({
   type: "DELETE_NOTE_BY_LANDLORD_ERROR",
   error
});

const openAddNewLandlordModalFunction = (payload) => ({
   type: "OPEN_ADD_NEW_LANDLORD_MODAL",
   payload
});

const editDocument = (payload) => ({
   type: "EDIT_DOCUMENT",
   payload
});

const editDocumentSuccess = () => ({
   type: "EDIT_DOCUMENT_SUCCESS"
});

const editDocumentError = () => ({
   type: "EDIT_DOCUMENT_ERROR"
});

const updateDocument = (payload) => ({
   type: "UPDATE_DOCUMENT",
   payload
});

const updateDocumentSuccess = () => ({
   type: "UPDATE_DOCUMENT_SUCCESS"
});

const updateDocumentError = () => ({
   type: "UPDATE_DOCUMENT_ERROR"
});

const getVersionHistoryBylandlordid = (payload) => ({
   type: "GET_VERSION_HISTORY_LANDLORD",
   payload
});

const getVersionHistoryBylandlordidSuccess = (payload) => ({
   type: "GET_VERSION_HISTORY_LANDLORD_SUCCESS",
   payload
});

const getVersionHistoryBylandlordidError = () => ({
   type: "GET_VERSION_HISTORY_LANDLORD_ERROR"
});

const getVersionHistoryByid = (id) => ({
   type: "GET_VERSION_HISTORY_ID",
   id
});

const getVersionHistoryByIdSuccess = (payload) => ({
   type: "GET_VERSION_HISTORY_ID_SUCCESS",
   payload
});

const getVersionHistoryByIdError = () => ({
   type: "GET_VERSION_HISTORY_ID_ERROR"
});

const getDocumentUrlById = (id) => ({
   type: "GET_DOCUMENT_URL_BY_ID",
   id
});

const getDocumentUrlByIdSuccess = (payload) => ({
   type: "GET_DOCUMENT_URL_BY_ID_SUCCESS",
   payload
});

const getDocumentUrlByIdError = () => ({
   type: "GET_DOCUMENT_URL_BY_ID_ERROR"
});

const deleteDocumentById = (payload) => ({
   type: "DELETE_DOCUMENT_BY_ID",
   payload
});

const deleteDocumentByIdSuccess = (payload) => ({
   type: "DELETE_DOCUMENT_BY_ID_SUCCESS",
   payload
});

const deleteDocumentByIdError = () => ({
   type: "DELETE_DOCUMENT_BY_ID_ERROR"
});

const deleteDocumentBySingleId = (payload) => ({
   type: "DELETE_DOCUMENT_BY_SINGLE_ID",
   payload
});

const deleteDocumentBySingleIdSuccess = (payload) => ({
   type: "DELETE_DOCUMENT_BY_SINGLE_ID_SUCCESS",
   payload
});

const deleteDocumentBySingleIdError = () => ({
   type: "DELETE_DOCUMENT_BY_SINGLE_ID_ERROR"
});

const getEmergencyDetailsForLandlord = (id) => ({
   type: "GET_EMERGENCY_DETAILS_FOR_LANDLORD",
   id
});

const getEmergencyDetailsForLandlordSuccess = (payload) => ({
   type: "GET_EMERGENCY_DETAILS_FOR_LANDLORD_SUCCESS",
   payload
});

const getEmergencyDetailsForLandlordError = () => ({
   type: "GET_EMERGENCY_DETAILS_FOR_LANDLORD_ERROR"
});

const setDocuemtentBaseUrlNull = () => ({
   type: "SET_DOCUMENT_BASE_NULL"
});

const getDropDownValueForAddNewDocument = (payload) => ({
   type: "GET_DROPDOWN_VALUES_FOR_ADDNEWDOCUMENT_REQUEST",
   payload
});

const getDropDownValueForAddNewDocumentSuccess = (response) => ({
   type: "GET_DROPDOWN_VALUES_FOR_ADDNEWDOCUMENT_SUCCESS",
   response
});

const getDropDownValueForAddNewDocumentError = (error) => ({
   type: "GET_DROPDOWN_VALUES_FOR_ADDNEWDOCUMENT_ERROR",
   error
});

const setLandlordDocumentsNull = () => ({
   type: "SET_LANDLORD_DOCUMENTS_NULL"
});

const setLandlordContractsNull = () => ({
   type: "SET_LANDLORD_CONTRACT_NULL"
});

export const changeLandlordLoadedState = (payload) => ({
   type: "CHANGE_LANDLORD_LOADED_STATE",
   payload
});

export {
   addNoteToLandlord,
   addNoteToLandlordSuccess,
   addNoteToLandlordError,
   deleteNoteByLandlord,
   deleteNoteByLandlordError,
   deleteNoteByLandlordSuccess,
   getNoteByLandlord,
   getNoteByLandlordError,
   getNoteByLandlordSuccess,
   updateNoteToLandlord,
   updateNoteToLandlordError,
   updateNoteToLandlordSuccess,
   openAddNewLandlordModalFunction,
   updateDocument,
   updateDocumentError,
   updateDocumentSuccess,
   getVersionHistoryByid,
   getVersionHistoryByIdSuccess,
   getVersionHistoryByIdError,
   getVersionHistoryBylandlordid,
   getVersionHistoryBylandlordidSuccess,
   getVersionHistoryBylandlordidError,
   getDocumentUrlById,
   getDocumentUrlByIdSuccess,
   getDocumentUrlByIdError,
   editDocument,
   editDocumentError,
   editDocumentSuccess,
   deleteDocumentById,
   deleteDocumentByIdSuccess,
   deleteDocumentByIdError,
   deleteDocumentBySingleId,
   deleteDocumentBySingleIdError,
   deleteDocumentBySingleIdSuccess,
   getEmergencyDetailsForLandlord,
   getEmergencyDetailsForLandlordSuccess,
   getEmergencyDetailsForLandlordError,
   setDocuemtentBaseUrlNull,
   getDropDownValueForAddNewDocument,
   getDropDownValueForAddNewDocumentSuccess,
   getDropDownValueForAddNewDocumentError,
   setLandlordDocumentsNull,
   setLandlordContractsNull
};
