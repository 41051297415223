/*eslint no-case-declarations: "off"*/
import produce from "immer";

const INITAL_STATE = {
   loading: false,
   updateTenant: {
      loading: false
   },
   emergencyDetails: {
      loading: false,
      data: []
   },
   tenants: {
      loading: false,
      list: [],
      loaded: false,
      benefitType: null,
      profession: null,
      status: null,

      searchBy: null,
      sortBy: null,
      orderBy: null,
      totalPages: 1,
      currentPage: 1,
      entriesPerPage: 10
   },
   deleteTenant: {
      loading: false
   },
   addTenant: {
      loading: false,
      tenant: null,
      update: false,
      success: false
   },
   tenantLazy: {
      data: [],
      success: false,
      error: null,
      pagination: null,
      loading: false,
      loaded: false
   },
   tenantByEmail: {
      loading: false,
      tenant: null
   },

   tenantById: {
      loading: false,
      tenant: null
   },

   updateBankDetils: {
      loading: false
   },

   deleteBankDetails: {
      loading: false,
      success: false
   },

   addEmergency: {
      loading: false
   },

   propertiesByTenantId: {
      loading: false,
      properties: null
   },

   contract: {
      loading: false,
      contract: [],
      loaded: false,
      service_loaded: false,
      pagination: null
   },

   documents: {
      loading: false,
      documents: []
   },

   addDocument: {
      loading: false
   },
   addContract: {
      loading: false
   },
   deleteContract: {
      loading: false,
      success: false
   },
   tenantToArchival: {
      success: false,
      response: null,
      error: null
   },
   TenantProperty: {
      loading: false,
      data: [],
      success: false,
      error: null
   },
   deleteDoc: {
      deleteSingleDocLoading: false,
      deleteDocLoading: false
   },

   dropdownValuesForTenant: {
      data: [],
      loading: false
   },

   additionalInfo: {
      loading: false,
      success: false
   },

   currentPage: 1,

   openAddNewTenantModal: false
};

export default (state = INITAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "CHANGE_TENANT_LOADED_STATE":
            draft.tenants.loaded = action.payload;
            break;
         case "GET_TENANTS":
            draft.tenants.loading = true;

            draft.tenants.benefitType = action.payload.benefitType;
            draft.tenants.profession = action.payload.profession;
            draft.tenants.status = action?.payload?.status;
            draft.tenants.sortBy = action.payload.sortBy;
            draft.tenants.orderBy = action.payload.orderBy;
            draft.tenants.searchBy = action.payload.searchBy;
            break;

         case "GET_TENANTS_SUCCESS":
            draft.tenants.loaded = true;
            draft.tenants.list = action.response.response.tenantResponseList;
            draft.tenants.totalPages =
               action.response.response.paginationDetails.totalPages;
            draft.tenants.currentPage =
               action.response.response.paginationDetails.currentPage + 1;
            draft.tenants.entriesPerPage =
               action.response.response.paginationDetails.pageSize;
            draft.tenants.loading = false;
            draft.addTenant.success = false;

            break;

         case "GET_TENANTS_ERROR":
            draft.loading = false;
            draft.tenants.list = [];
            draft.tenants.totalPages = 0;
            draft.tenants.currentPage = 1;
            draft.addTenant.success = false;
            draft.tenants.loading = false;

            break;

         case "GET_TENANTS_LAZY":
            draft.tenantLazy.loading = true;

            break;

         case "GET_TENANTS_LAZY_SET_NULL":
            draft.tenantLazy.data = [];
            draft.tenantLazy.success = false;
            draft.tenantLazy.error = null;
            draft.tenantLazy.pagination = null;
            draft.tenantLazy.loading = false;
            draft.tenantLazy.loaded = false;

            break;

         case "GET_TENANTS_LAZY_SUCCESS":
            draft.tenantLazy.data = action.response.response.tenantResponseList;
            draft.tenantLazy.pagination =
               action.response.response.paginationDetails;
            draft.tenantLazy.loading = false;
            draft.tenantLazy.loaded = true;
            break;

         case "GET_TENANTS_LAZY_ERROR":
            draft.tenantLazy.loading = false;
            draft.tenantLazy.data = [];
            draft.tenantLazy.error = action.response;
            break;

         case "GET_TENANT_BY_EMAIL_REQUEST":
            draft.tenantByEmail.loading = true;
            break;

         case "GET_TENANT_BY_EMAIL_SUCCESS":
            draft.tenantByEmail.tenant = action.payload.response;
            draft.tenantByEmail.loading = false;
            break;

         case "GET_TENANT_BY_EMAIL_ERROR":
            draft.tenantByEmail.loading = false;
            draft.tenantByEmail.tenant = null;
            break;

         case "ADD_TENANT":
            draft.addTenant.loading = true;
            break;

         case "ADD_TENANT_SUCCESS":
            draft.addTenant.tenant = action.payload.response;
            draft.addTenant.loading = false;
            draft.addTenant.success = true;
            break;

         case "ADD_TENANT_ERROR":
            draft.addTenant.loading = false;
            break;

         case "GET_TENANT_BY_ID":
            draft.tenantById.loading = true;
            break;

         case "GET_TENANT_BY_ID_SUCCESS":
            draft.tenantById.loading = false;
            draft.tenantById.tenant = action.payload.response[0];
            break;

         case "GET_TENANT_BY_ID_ERROR":
            draft.tenantById.loading = false;
            break;

         case "DELETE_TENANT_BY_ID":
            draft.deleteTenant.loading = true;
            break;

         case "DELETE_TENANT_BY_ID_SUCCESS":
            draft.deleteTenant.loading = false;
            break;

         case "DELETE_TENANT_BY_ID_ERROR":
            draft.deleteTenant.loading = false;
            break;

         case "UPDATE_TENANT_BY_ID":
            draft.updateTenant.loading = true;
            break;

         case "UPDATE_TENANT_BY_ID_SUCCESS":
            draft.updateTenant.loading = false;
            break;

         case "UPDATE_TENANT_BY_ID_ERROR":
            draft.updateTenant.loading = false;
            break;

         case "DELETE_TENANT_BANKDETAILS_REQUEST":
            draft.deleteBankDetails.loading = true;
            draft.deleteBankDetails.success = false;
            break;

         case "DELETE_TENANT_BANKDETAILS_SUCCESS":
            draft.deleteBankDetails.loading = false;
            draft.deleteBankDetails.success = true;
            break;

         case "DELETE_TENANT_BANKDETAILS_ERROR":
            draft.deleteBankDetails.loading = false;
            draft.deleteBankDetails.success = false;
            break;

         case "UPDATE_TENANT_BANKDETAILS":
            draft.updateBankDetils.loading = true;
            break;

         case "UPDATE_TENANT_BANKDETAILS_SUCCESS":
            draft.updateBankDetils.loading = false;
            break;

         case "UPDATE_TENANT_BANKDETAILS_ERROR":
            draft.updateBankDetils.loading = false;
            break;

         case "ADD_EMERGENCY_DETAILS":
            draft.addEmergency.loading = true;
            break;

         case "ADD_EMERGENCY_DETAILS_SUCCESS":
            draft.addEmergency.loading = false;
            break;

         case "ADD_EMERGENCY_DETAILS_ERROR":
            draft.addEmergency.loading = false;
            break;

         case "ADD_BANK":
            draft.addTenant.loading = true;
            break;

         case "ADD_BANK_SUCCESS":
            draft.addTenant.loading = false;
            draft.addTenant.update = true;
            break;

         case "ADD_BANK_ERROR":
            draft.addTenant.loading = false;
            draft.addTenant.update = true;
            break;

         case "RESET_TENANT_DETAILS":
            draft.tenantByEmail.tenant = null;
            break;

         case "GET_PROPERTIES_BY_TENANT_ID":
            draft.propertiesByTenantId.loading = true;
            break;

         case "GET_PROPERTIES_BY_TENANT_ID_SUCCESS":
            draft.propertiesByTenantId.loading = false;
            draft.propertiesByTenantId.properties = action.payload.response;
            break;

         case "GET_PROPERTIES_BY_TENANT_ID_ERROR":
            draft.propertiesByTenantId.loading = false;
            break;

         case "SET_PAGE":
            const { page } = action.payload;
            draft.currentPage = page;
            break;

         case "GET_CONTRACT_BY_ID":
            draft.contract.loading = true;
            break;

         case "GET_CONTRACT_BY_ID_SUCCESS":
            draft.contract.loading = false;
            draft.contract.contract = [
               ...draft.contract.contract,
               ...action.payload.contracts
            ];
            draft.contract.pagination = action.payload.pagination;
            break;

         case "GET_CONTRACT_BY_ID_ERROR":
            draft.contract.loading = false;
            draft.contract.contract = [];
            break;

         case "GET_DOCUMENTS_BY_ID":
            draft.documents.loading = true;
            break;

         case "GET_DOCUMENTS_BY_ID_SUCCESS":
            draft.documents.documents = action.payload.response;
            draft.documents.loading = false;
            break;

         case "GET_DOCUMENT_BY_ID_ERROR":
            draft.documents.loading = false;
            break;

         case "ADD_DOCUMENT":
            draft.addDocument.loading = true;
            break;

         case "ADD_DOCUMENT_SUCCESS":
            draft.addDocument.loading = false;
            break;

         case "ADD_DOCUMENT_ERROR":
            draft.addDocument.loading = false;
            break;

         case "ADD_CONTRACT":
            draft.addContract.loading = true;
            break;

         case "ADD_CONTRACT_SUCCESS":
            draft.addContract.loading = false;
            break;

         case "ADD_CONTRACT_ERROR":
            draft.addContract.loading = false;
            break;

         case "OPEN_ADD_NEW_TENANT_MODAL":
            draft.openAddNewTenantModal = action.payload;
            break;

         case "EDIT_CONTRACT":
            draft.addContract.loading = true;
            break;

         case "EDIT_CONTRACT_SUCCESS":
            draft.addContract.loading = false;
            break;

         case "ADD_NOTE_TO_TENANT_SUCCESS":
            if (state.tenantById.tenant.notes) {
               draft.tenantById.tenant = {
                  ...state.tenantById.tenant,
                  notes: [
                     ...state.tenantById.tenant.notes,
                     action.payload.response
                  ]
               };
            } else {
               draft.tenantById.tenant = {
                  ...state.tenantById.tenant,
                  notes: [action.payload.response]
               };
            }
            break;

         case "GET_NOTE_BY_TENANT_SUCCESS":
            draft.tenantById.tenant.notes = action.payload.response;
            break;

         case "UPDATE_NOTE_TO_TENANT_SUCCESS": {
            const { id } = action.payload.response;
            const notes = state.tenantById.tenant.notes.map((note) =>
               note.id === id ? { ...note, ...action.payload.response } : note
            );
            draft.tenantById.tenant = {
               ...state.tenantById.tenant,
               notes
            };
            break;
         }

         case "DELETE_NOTE_BY_TENANT_SUCCESS": {
            const { id } = action.payload;
            draft.tenantById.tenant.notes = state.tenantById.tenant.notes.filter(
               (note) => note.id !== id
            );
            break;
         }

         case "DELETE_CONTRACT_BY_ID":
            draft.deleteContract.loading = true;
            draft.deleteContract.success = false;
            break;

         case "DELETE_CONTRACT_BY_ID_SUCCESS":
            draft.deleteContract.loading = false;
            draft.deleteContract.success = true;
            break;

         case "DELETE_CONTRACT_BY_ID_ERROR":
            draft.deleteContract.loading = false;
            draft.deleteContract.success = false;
            break;

         case "RESET_TENANT_FILTERS":
            draft.tenants.benefitType = null;
            draft.tenants.profession = null;
            draft.tenants.status = null;
            break;

         case "UPDATE_TENANT_TO_ARCHIVE":
            draft.tenantToArchival.success = false;
            break;

         case "UPDATE_TENANT_TO_ARCHIVE_SUCCESS":
            draft.tenantToArchival.response = action.response;
            draft.tenantToArchival.success = true;
            draft.tenants.loaded = false;
            break;

         case "UPDATE_TENANT_TO_ARCHIVE_FAIL":
            draft.tenantToArchival.success = false;
            draft.tenantToArchival.error = action.error;
            break;

         case "GET_EMERGENCY_DETAILS_FOR_TENANT":
            draft.emergencyDetails.loading = true;
            break;

         case "GET_EMERGENCY_DETAILS_FOR_TENANT_SUCCESS":
            draft.emergencyDetails.data = action.payload.response.data;
            draft.emergencyDetails.loading = false;
            break;

         case "GET_EMERGENCY_DETAILS_FOR_TENANT_ERROR":
            draft.emergencyDetails.loading = false;
            break;

         case "GET_TENANTS_PROPERTY_BASIC_INFO":
            draft.TenantProperty.loading = true;
            break;

         case "GET_TENANTS__PROPERTY_BASIC_INFO_SUCCESS":
            draft.TenantProperty.data = action.response.response;
            draft.TenantProperty.loading = false;
            break;

         case "GET_TENANTS__PROPERTY_BASIC_INFO_ERROR":
            draft.TenantProperty.loading = false;
            draft.TenantProperty.data = [];
            break;

         case "DELETE_TENANT_SINGLE_ID":
            draft.deleteDoc.deleteSingleDocLoading = true;
            break;

         case "DELETE_TENANT_SINGLE_DOC_SUCCESS":
            draft.deleteDoc.deleteSingleDocLoading = false;
            break;

         case "DELETE_TENANT_SINGLE_DOC_ERROR":
            draft.deleteDoc.deleteSingleDocLoading = false;
            break;

         case "DELETE_TENANT_DOC":
            draft.deleteDoc.deleteDocLoading = true;
            break;

         case "DELETE_TENANT_DOC_SUCCESS":
            draft.deleteDoc.deleteDocLoading = false;
            break;

         case "DELETE_TENANT_DOC_ERROR":
            draft.deleteDoc.deleteDocLoading = false;
            break;

         case "GET_DROPDOWN_VALUE_FOR_TENANT":
            draft.dropdownValuesForTenant.loading = true;
            break;

         case "GET_DROPDOWN_VALUE_FOR_TENANT_SUCCESS":
            draft.dropdownValuesForTenant.data = action.payload.response;
            draft.dropdownValuesForTenant.loading = false;
            break;

         case "GET_DROPDOWN_VALUE_FOR_TENANT_ERROR":
            draft.dropdownValuesForTenant.loading = false;
            draft.dropdownValuesForTenant.data = [];
            break;

         case "EDIT_TENANT_DOCUMENT":
            draft.addDocument.loading = true;
            break;

         case "EDIT_TENANT_DOCUMENT_SUCCESS":
            draft.addDocument.loading = false;
            break;

         case "EDIT_TENANT_DOCUMENT_ERROR":
            draft.addDocument.loading = false;
            break;

         case "SET_TENANT_CONTRACT_NULL":
            draft.contract.contract = [];
            break;

         case "SET_TENANT_DOCUMENT_NULL":
            draft.documents.documents = [];
            break;

         case "ADD_TENANT_ADDITIONAL_INFO":
            draft.additionalInfo.loading = true;
            draft.additionalInfo.success = false;
            break;

         case "ADD_TENANT_ADDITIONAL_INFO_SUCCESS":
            draft.additionalInfo.loading = false;
            draft.additionalInfo.success = true;
            break;

         case "ADD_TENANT_ADDITIONAL_INFO_ERROR":
            draft.additionalInfo.loading = false;
            draft.additionalInfo.success = false;
            break;

         default:
            return draft;
      }
   });
