import { put, call, takeEvery } from "redux-saga/effects";
import { closeRequestInfoModal } from "../actions/modalActions";
import {
   approveRequestInfoError,
   approveRequestInfoSuccess,
   createRequestInfoError,
   createRequestInfoSuccess,
   getRequestInfoError,
   getRequestInfoStatusError,
   getRequestInfoStatusRequest,
   getRequestInfoStatusSuccess,
   getRequestInfoSuccess,
   rejectRequestInfoError,
   rejectRequestInfoSuccess
} from "../actions/requestInfoAction";
import {
   approveRequestInfo,
   createRequestInfo,
   getRequestInfo,
   getRequestInfoStatus,
   rejectRequestInfo
} from "../api/RequestInfo";

function* handleCreateRequestInfo(action) {
   try {
      yield call(createRequestInfo, action.payload);
      yield put(createRequestInfoSuccess());
      yield put(closeRequestInfoModal());
      yield put(
         getRequestInfoStatusRequest({
            module: action.payload.role,
            id: action.payload.ownerId
         })
      );
   } catch {
      yield put(createRequestInfoError());
   }
}

function* handleGetRequestInfoStatus(action) {
   try {
      let resp = yield call(getRequestInfoStatus, action.payload);
      yield put(getRequestInfoStatusSuccess(resp.data));
   } catch {
      yield put(getRequestInfoStatusError());
   }
}

function* handleGetRequestInfo(action) {
   try {
      let resp = yield call(getRequestInfo, action.payload);
      yield put(getRequestInfoSuccess(resp.data));
   } catch {
      yield put(getRequestInfoError());
   }
}

function* handleRejectRequestInfo(action) {
   try {
      yield call(rejectRequestInfo, action.payload);
      yield put(rejectRequestInfoSuccess());
      window.location.reload();
   } catch {
      yield put(rejectRequestInfoError());
   }
}

function* handleApproveRequestInfo(action) {
   try {
      yield call(approveRequestInfo, action.payload);
      yield put(approveRequestInfoSuccess());
      window.location.reload();
   } catch {
      yield put(approveRequestInfoError());
   }
}

export default function* watchRequestInfoSaga() {
   yield takeEvery("CREATE_REQUEST_INFO_REQUEST", handleCreateRequestInfo);
   yield takeEvery(
      "GET_REQUEST_INFO_STATUS_REQUEST",
      handleGetRequestInfoStatus
   );
   yield takeEvery("GET_REQUEST_INFO_REQUEST", handleGetRequestInfo);
   yield takeEvery("REJECT_REQUEST_INFO_REQUEST", handleRejectRequestInfo);
   yield takeEvery("APPROVE_REQUEST_INFO_REQUEST", handleApproveRequestInfo);
}
