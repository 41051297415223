export const getDocumentTypeByCatagory = (catagory) => ({
   type: "GET_DOCUMENT_TYPE_BY_CATAGORY",
   catagory
});

export const getDocumentTypeByCatagorySuccess = (payload) => ({
   type: "GET_DOCUMENT_TYPE_BY_CATAGORY_SUCCESS",
   payload
});

export const getDocumentTypeByCatagoryError = () => ({
   type: "GET_DOCUMENT_TYPE_BY_CATAGORY_ERROR"
});

export const addDocumentType = (payload) => ({
   type: "ADD_DOCUMENT_TYPE",
   payload
});

export const addDocumentTypeSuccess = () => ({
   type: "ADD_DOCUMENT_TYPE_SUCCESS"
});

export const addDocumentTypeError = () => ({
   type: "ADD_DOCUMENT_TYPE_ERROR"
});

export const getUploadedDocTypes = (category) => ({
   type: "GET_UPLOADED_DOC_TYPES",
   category
});

export const getUploadedDocTypesSuccess = (payload) => ({
   type: `GET_UPLOADED_DOC_TYPES_SUCCESS_${payload.responseBody.documentSettings.category.toUpperCase()}`,
   payload
});

export const getUploadedDocTypesError = () => ({
   type: "GET_UPLOADED_DOC_TYPES_ERROR"
});

export const getInvoiceSettings = () => ({
   type: "GET_INVOICE_SETTINGS"
});

export const getInvoiceSettingsSuccess = (payload) => ({
   type: "GET_INVOICE_SETTINGS_SUCCESS",
   payload
});

export const getInvoiceSettingsError = (error) => ({
   type: "GET_INVOICE_SETTINGS_ERROR",
   error
});

export const saveInvoiceSettings = (payload) => ({
   type: "SAVE_INVOICE_SETTINGS",
   payload
});

export const saveInvoiceSettingsError = () => ({
   type: "SAVE_INVOICE_SETTINGS_ERROR"
});

export const addDocumentPrefix = (payload) => ({
   type: "ADD_DOCUMENT_PREFIX",
   payload
});

export const addDocumentPrefixSuccess = () => ({
   type: "ADD_DOCUMENT_PREFIX_SUCCESS"
});

export const addDocumentPrefixError = () => ({
   type: "ADD_DOCUMENT_PREFIX_ERROR"
});

export const addContractPrefix = (payload) => ({
   type: "ADD_CONTRACT_PREFIX",
   payload
});

export const addContractPrefixSuccess = () => ({
   type: "ADD_CONTRACT_PREFIX_SUCCESS"
});

export const addContractPrefixError = () => ({
   type: "ADD_CONTRACT_PREFIX_ERROR"
});

export const addContractTypes = (payload) => ({
   type: "ADD_CONTRACT_TYPES",
   payload
});

export const addContractTypeSuccess = () => ({
   type: "ADD_CONTRACT_TYPES_SUCCESS"
});

export const addContractTypeError = () => ({
   type: "ADD_CONTRACT_TYPES_ERROR"
});
