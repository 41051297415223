import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { MainRoutes } from "./MainRoutes";

export const Routes = () => {
   return (
      <Router>
         <MainRoutes></MainRoutes>
      </Router>
   );
};

export default Routes;
