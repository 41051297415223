import { put, call, takeEvery } from "redux-saga/effects";

import {
   adminGeneralSystemSetupError,
   adminGeneralSystemSetupSuccess,
   getAgencyByIdError,
   getAgencyByIdSuccess,
   getAllCountryCallingCodeError,
   getAllCountryCallingCodeSuccess,
   getAllCurrencyError,
   getAllCurrencySuccess,
   getAllTimezoneError,
   getAllTimezoneSuccess,
   profileSetupRequiredError,
   profileSetupRequiredSuccess,
   sendAgencyOtpError,
   sendAgencyOtpSuccess,
   sendStaffOtpError,
   sendStaffOtpSuccess,
   staffProfileSetupRequiredError,
   staffProfileSetupRequiredSuccess,
   updateAgencyMobileNoError,
   updateAgencyMobileNoSuccess,
   updateAgencyProfileError,
   updateAgencyProfileSuccess,
   updateAgencyTwoFactorAuthError,
   updateAgencyTwoFactorAuthSuccess,
   updateStaffMobileNoError,
   updateStaffMobileNoSuccess,
   updateStaffTwoFactorAuthError,
   updateStaffTwoFactorAuthSuccess,
   validateAgencyOtpError,
   validateAgencyOtpSuccess,
   validateStaffOtpError,
   validateStaffOtpSuccess
} from "../actions/profileSetupActions";

import {
   UpdateAgencyProfile,
   GetAllCountryCallingCode,
   GetAllCurrency,
   GetAllTimezone,
   AdminGeneralSystemSetup,
   IsProfileSetupRequired,
   SendAgencyOtp,
   ValidateAgencyOtp,
   UpdateAgencyTwoFactorAuth,
   UpdateAgencyMobileNo,
   StaffProfileSetupRequired,
   SendStaffOtp,
   UpdateStaffMobileNo,
   UpdateStaffTwoFactorAuth,
   ValidateStaffOtp,
   GetAgencyById
} from "../api/profileSetup";
import { LocalStorageService } from "../services/localStorageService";
const localStorageService = LocalStorageService.getService();

function* handleUpdateAgencyProfile(action) {
   try {
      const response = yield call(UpdateAgencyProfile, action.payload);
      yield put(updateAgencyProfileSuccess(response));
   } catch (error) {
      yield put(updateAgencyProfileError(error.response));
   }
}

function* handleAdminGeneralSystemSetup(action) {
   try {
      const resp = yield call(AdminGeneralSystemSetup, action.payload);

      localStorageService.setToken(
         resp?.data?.response?.jwtAuthenticationResponse?.accessToken
      );
      yield put(adminGeneralSystemSetupSuccess(resp));
      window.location.reload();
   } catch (error) {
      yield put(adminGeneralSystemSetupError());
   }
}

function* handleGetAllCountryCallingCode() {
   try {
      const resp = yield call(GetAllCountryCallingCode);
      yield put(getAllCountryCallingCodeSuccess(resp));
   } catch {
      yield put(getAllCountryCallingCodeError());
   }
}

function* handleGetAllCurrency() {
   try {
      const resp = yield call(GetAllCurrency);
      yield put(getAllCurrencySuccess(resp));
   } catch {
      yield put(getAllCurrencyError());
   }
}

function* handleGetAllTimezone() {
   try {
      const resp = yield call(GetAllTimezone);
      yield put(getAllTimezoneSuccess(resp));
   } catch {
      yield put(getAllTimezoneError());
   }
}

function* handleIsProfileSetupRequired(action) {
   try {
      const resp = yield call(IsProfileSetupRequired);
      yield put(profileSetupRequiredSuccess(resp));
      if (resp.data.response === true) {
         action.history.replace("/agency/ProfileSetup");
      } else if (resp.data.response === false) {
         action.history.replace("/agency/overview");
      }
   } catch (error) {
      yield put(profileSetupRequiredError());
   }
}

function* handleSendAgencyOtp(action) {
   try {
      const resp = yield call(SendAgencyOtp, action.payload);
      yield put(sendAgencyOtpSuccess(resp));
   } catch (error) {
      yield put(sendAgencyOtpError());
   }
}

function* handleUpdateAgencyMobileNo(action) {
   try {
      const resp = yield call(UpdateAgencyMobileNo, action.payload);
      yield put(updateAgencyMobileNoSuccess(resp));
   } catch (error) {
      yield put(updateAgencyMobileNoError());
   }
}

function* handleUpdateAgencyTwoFactAuth(action) {
   try {
      const resp = yield call(UpdateAgencyTwoFactorAuth, action.payload);
      yield put(updateAgencyTwoFactorAuthSuccess(resp));
   } catch (error) {
      yield put(updateAgencyTwoFactorAuthError());
   }
}

function* handlevalidateAgencyOtp(action) {
   try {
      const resp = yield call(ValidateAgencyOtp, action.payload);
      yield put(validateAgencyOtpSuccess(resp));
   } catch (error) {
      yield put(validateAgencyOtpError());
   }
}

function* handleStaffProfileSetupRequired(action) {
   try {
      const resp = yield call(StaffProfileSetupRequired);
      yield put(staffProfileSetupRequiredSuccess(resp));
      if (resp.data.response === true) {
         action.history.replace("/agency/StaffProfileSetup");
      } else if (resp.data.response === false) {
         action.history.replace("/agency/overview");
      }
   } catch (error) {
      yield put(staffProfileSetupRequiredError());
   }
}

function* handleSendStaffOtp(action) {
   try {
      const resp = yield call(SendStaffOtp, action.payload);
      yield put(sendStaffOtpSuccess(resp));
   } catch (error) {
      yield put(sendStaffOtpError());
   }
}

function* handleUpdateStaffMobileNo(action) {
   try {
      const resp = yield call(UpdateStaffMobileNo, action.payload);
      yield put(updateStaffMobileNoSuccess(resp));
   } catch (error) {
      yield put(updateStaffMobileNoError());
   }
}

function* handleUpdateStaffTwoFactAuth(action) {
   try {
      const resp = yield call(UpdateStaffTwoFactorAuth, action.payload);
      yield put(updateStaffTwoFactorAuthSuccess(resp));
   } catch (error) {
      yield put(updateStaffTwoFactorAuthError());
   }
}

function* handlevalidateStaffyOtp(action) {
   try {
      const resp = yield call(ValidateStaffOtp, action.payload);
      yield put(validateStaffOtpSuccess(resp));
   } catch (error) {
      yield put(validateStaffOtpError());
   }
}

function* handleGetAgencyById() {
   try {
      const resp = yield call(GetAgencyById);
      yield put(getAgencyByIdSuccess(resp));
   } catch {
      yield put(getAgencyByIdError());
   }
}

export default function* watchProfileSetup() {
   yield takeEvery("UPDATE_AGENCY_PROFILE", handleUpdateAgencyProfile);
   yield takeEvery(
      "GET_ALL_COUNTRY_CALLING_CODE",
      handleGetAllCountryCallingCode
   );
   yield takeEvery("GET_ALL_CURRENCY", handleGetAllCurrency);
   yield takeEvery("GET_ALL_TIME_ZONE", handleGetAllTimezone);
   yield takeEvery("ADMIN_GENERAL_SYSTEM_SETUP", handleAdminGeneralSystemSetup);
   yield takeEvery("PROFILE_SETUP_REQUIRED", handleIsProfileSetupRequired);
   yield takeEvery("SEND_AGENCY_OTP", handleSendAgencyOtp);
   yield takeEvery("UPDATE_AGENCY_MOBILE_NO", handleUpdateAgencyMobileNo);
   yield takeEvery(
      "UPDATE_AGENCY_TWO_FACT_AUTH",
      handleUpdateAgencyTwoFactAuth
   );
   yield takeEvery("VALIDATE_AGENCY_OTP", handlevalidateAgencyOtp);
   yield takeEvery(
      "STAFF_PROFILE_SETUP_REQUIRED",
      handleStaffProfileSetupRequired
   );
   yield takeEvery("SEND_STAFF_OTP", handleSendStaffOtp);
   yield takeEvery("UPDATE_STAFF_MOBILE_NO", handleUpdateStaffMobileNo);
   yield takeEvery("UPDATE_STAFF_TWO_FACT_AUTH", handleUpdateStaffTwoFactAuth);
   yield takeEvery("VALIDATE_STAFF_OTP", handlevalidateStaffyOtp);
   yield takeEvery("GET_AGENCY_BY_ID", handleGetAgencyById);
}
