export const sendData = (payload) => ({
   type: "SEND_NOTIFICATION",
   payload
});

export const fetchNotificationsRequest = (payload) => ({
   type: "FETCH_NOTIFICATIONS_REQUEST",
   payload
});

export const fetchNotificationsSuccess = (response) => ({
   type: "FETCH_NOTIFICATIONS_SUCCESS",
   response
});

export const fetchNotificationsError = (error) => ({
   type: "FETCH_NOTIFICATIONS_ERROR",
   error
});

export const addNewNotification = (notification) => ({
   type: "ADD_NEW_NOTIFICATION",
   notification
});

export const dismissNotificationRequest = (payload) => ({
   type: "DISMISS_NOTIFICATION_REQUEST",
   payload
});

export const dismissNotificationSuccess = (response) => ({
   type: "DISMISS_NOTIFICATION_SUCCESS",
   response
});

export const dismissNotificationError = (error) => ({
   type: "DISMISS_NOTIFICATION_ERROR",
   error
});

export const setFilter = (payload) => ({
   type: "SET_FILTER",
   payload
});

export const getNewCount = () => ({
   type: "GET_NEW_COUNT"
});

export const getNewCountSuccess = (count) => ({
   type: "GET_NEW_COUNT_SUCCESS",
   count
});

export const getNewCountError = () => ({
   type: "GET_NEW_COUNT_ERROR"
});

export const markAsOld = (id) => ({
   type: "MARK_AS_OLD",
   id
});

export const markAsOldSuccess = () => ({
   type: "MARK_AS_OLD_SUCCESS"
});

export const markAsOldError = () => ({
   type: "MARK_AS_OLD_ERROR"
});
