import produce from "immer";

const INITIAL_STATE = {
   rentfrequencydropdown: [],
   schemetypedropdown: [],
   PaymentMethoddropdown: []
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_DROPDOWN_RENTFREQUENCY_SUCCESS":
            draft.rentfrequencydropdown = action.payload.data.response;
            break;

         case "GET_DROPDOWN_REGISTRATIONTYPE_SUCCESS":
            draft.schemetypedropdown = action.payload.data.response;
            break;

         case "GET_DROPDOWN_PAYMENTMETHOD_SUCCESS":
            draft.PaymentMethoddropdown = action.payload.data.response;
            break;

         default:
            return draft;
      }
   });
