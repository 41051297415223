import produce from "immer";

const INITIAL_STATE = {
   permissions: [],
   editPermissionLoading: false
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_PERMISSION_SUCCESS":
            draft.permissions = action.payload;
            break;
         case "EDIT_PERMISSION_REQUEST":
            draft.editPermissionLoading = true;
            break;
         case "EDIT_PERMISSION_SUCCESS":
            draft.editPermissionLoading = false;
            break;
         case "EDIT_PERMISSION_ERROR":
            draft.editPermissionLoading = false;
            break;
      }
   });
