import { put, call, takeEvery, select } from "redux-saga/effects";
import {
   getAllLandlordDocumentsError,
   getAllLandlordDocumentsSuccess,
   getAllPropertyDocumentsError,
   getAllPropertyDocumentsSuccess,
   getAllTenantDocumentsError,
   getAllTenantDocumentsSuccess
} from "../actions/documentActions";
import {
   getLandlordDocuments,
   getPropertyDocuments,
   getTenantDocuments
} from "../api/document";

function* handleGetPropertyDocuments(action) {
   const { property } = yield select((state) => state.documents);
   try {
      let resp = yield call(getPropertyDocuments, action.payload);

      resp.data.response.docSettings = [
         ...(property?.data?.docSettings || []),
         ...(resp?.data?.response?.docSettings || [])
      ];
      yield put(getAllPropertyDocumentsSuccess(resp.data.response));
   } catch {
      yield put(getAllPropertyDocumentsError());
   }
}

function* handleGetTenantDocuments(action) {
   const { tenant } = yield select((state) => state.documents);
   try {
      let resp = yield call(getTenantDocuments, action.payload);
      resp.data.response.docSettings = [
         ...(tenant?.data?.docSettings || []),
         ...(resp?.data?.response?.docSettings || [])
      ];
      yield put(getAllTenantDocumentsSuccess(resp.data.response));
   } catch {
      yield put(getAllTenantDocumentsError());
   }
}

function* handleGetLandlordDocuments(action) {
   const { landlord } = yield select((state) => state.documents);
   try {
      let resp = yield call(getLandlordDocuments, action.payload);
      resp.data.response.docSettings = [
         ...(landlord?.data?.docSettings || []),
         ...(resp?.data?.response?.docSettings || [])
      ];
      yield put(getAllLandlordDocumentsSuccess(resp.data.response));
   } catch {
      yield put(getAllLandlordDocumentsError());
   }
}

export default function* watchDocumentActions() {
   yield takeEvery("GET_ALL_PROPERTY_DOCUMENTS", handleGetPropertyDocuments);
   yield takeEvery("GET_ALL_TENANT_DOCUMENTS", handleGetTenantDocuments);
   yield takeEvery("GET_ALL_LANDLORD_DOCUMENTS", handleGetLandlordDocuments);
}
