import { put, call, takeEvery } from "redux-saga/effects";

import {
   getAllProductWithPrice,
   openCustomerPortalAPI,
   addPaymentMethodAPI,
   addCustomerSubscriptionAPI,
   getConsumerDetailsAPI,
   getPaymentMethodAPI,
   getInvoiceAPI,
   cancelCustomerSubscriptionAPI,
   updateBillingAddressAPI,
   getSubscriptionHistoryAPI,
   openPaymentPortalAPI,
   getReceiptAPI
} from "../api/payment";
import {
   getAllProductWithPriceSuccess,
   getAllProductWithPriceFail,
   openCustomerPortalFail,
   openCustomerPortalSuccess,
   addPaymentMethodError,
   addPaymentMethodSuccess,
   addCustomerSubscriptionError,
   addCustomerSubscriptionSuccess,
   getConsumerDetailsFail,
   getConsumerDetailsSuccess,
   getPaymentMethodError,
   getPaymentMethodSuccess,
   getInvoiceSuccess,
   getInvoiceError,
   cancelCustomerSubscriptionSuccess,
   cancelCustomerSubscriptionError,
   updateBillingAddressError,
   updateBillingAddressSuccess,
   getSubscriptionHistorySuccess,
   getSubscriptionHistoryError,
   openPaymentPortalSuccess,
   openPaymentPortalFail,
   getReceiptSuccess,
   getReceiptError
} from "../actions/paymentAction";
import { LocalStorageService } from "../services/localStorageService";
const localStorageService = LocalStorageService.getService();
function* handleGetCustomerDetails(action) {
   try {
      const response = yield call(getConsumerDetailsAPI, action.payload);
      yield put(getConsumerDetailsSuccess(response));
   } catch (error) {
      yield put(getConsumerDetailsFail(error.response.data));
   }
}

function* handleGetAllProductPayment(action) {
   try {
      const response = yield call(getAllProductWithPrice, action.payload);
      yield put(getAllProductWithPriceSuccess(response));
   } catch (error) {
      yield put(getAllProductWithPriceFail(error.response.data));
   }
}

function* handleGetPaymentMethod(action) {
   try {
      const response = yield call(getPaymentMethodAPI, action.payload);
      yield put(getPaymentMethodSuccess(response));
   } catch (error) {
      yield put(getPaymentMethodError(error.response));
   }
}

function* handleGetInvoice(action) {
   try {
      const response = yield call(getInvoiceAPI, action.payload);
      yield put(getInvoiceSuccess(response));
   } catch (error) {
      yield put(getInvoiceError(error.response));
   }
}

function* handleGetReceipt(action) {
   try {
      const response = yield call(getReceiptAPI, action.payload);
      yield put(getReceiptSuccess(response));
   } catch (error) {
      yield put(getReceiptError(error.response));
   }
}

function* handleOpenCustomerPortal(action) {
   try {
      const response = yield call(openCustomerPortalAPI, action.payload);
      yield put(openCustomerPortalSuccess(response));
   } catch (error) {
      yield put(openCustomerPortalFail(error.response.data));
   }
}

function* handleOpenPaymentPortal(action) {
   try {
      const response = yield call(openPaymentPortalAPI, action.payload);
      yield put(openPaymentPortalSuccess(response));
   } catch (error) {
      yield put(openPaymentPortalFail(error.response.data));
   }
}

function* handleAddPaymentMethod(action) {
   try {
      const response = yield call(addPaymentMethodAPI, action.payload);
      yield put(addPaymentMethodSuccess(response));
   } catch (error) {
      yield put(addPaymentMethodError(error.response.data));
   }
}

function* handleAddCustomerSubscription(action) {
   try {
      const response = yield call(addCustomerSubscriptionAPI, action.payload);

      localStorageService.setToken(response?.data?.jwt?.accessToken);
      yield put(addCustomerSubscriptionSuccess(response));
   } catch (error) {
      yield put(addCustomerSubscriptionError(error.response.data));
   }
}

function* handlecancelCustomerSubscription(action) {
   try {
      const response = yield call(
         cancelCustomerSubscriptionAPI,
         action.payload
      );
      localStorageService.setToken(response?.data?.jwt?.accessToken);
      yield put(cancelCustomerSubscriptionSuccess(response));
   } catch (error) {
      yield put(cancelCustomerSubscriptionError(error.response.data));
   }
}

function* handleDeletePaymentMethod(action) {
   try {
      const response = yield call(addPaymentMethodAPI, action.payload);
      yield put(addPaymentMethodSuccess(response));
   } catch (error) {
      yield put(addPaymentMethodError(error.response.data));
   }
}

function* handleUpdateBillingAddress(action) {
   try {
      const response = yield call(updateBillingAddressAPI, action.payload);
      yield put(updateBillingAddressSuccess(response));
   } catch (error) {
      yield put(updateBillingAddressError(error.response.data));
   }
}

function* handleGetSubscriptionHistory(action) {
   try {
      const response = yield call(getSubscriptionHistoryAPI, action.payload);
      yield put(getSubscriptionHistorySuccess(response));
   } catch (error) {
      yield put(getSubscriptionHistoryError(error.response));
   }
}

export default function* watchPaymentActions() {
   yield takeEvery("GET_ALL_PRODUCT_WITH_PRICE", handleGetAllProductPayment);
   yield takeEvery("OPEN_CUSTOMER_PORTAL", handleOpenCustomerPortal);
   yield takeEvery("ADD_PAYMENT_METHOD", handleAddPaymentMethod);
   yield takeEvery("ADD_CUSTOMER_SUBSCRIPTION", handleAddCustomerSubscription);
   yield takeEvery("DELETE_PAYMENT_METHOD", handleDeletePaymentMethod);
   yield takeEvery("GET_CONSUMER_DETAILS", handleGetCustomerDetails);
   yield takeEvery("GET_PAYMENT_METHOD", handleGetPaymentMethod);
   yield takeEvery("GET_INVOICE", handleGetInvoice);

   yield takeEvery(
      "CANCEL_CUSTOMER_SUBSCRIPTION",
      handlecancelCustomerSubscription
   );
   yield takeEvery("UPDATE_BILLING_ADDRESS", handleUpdateBillingAddress);

   yield takeEvery("GET_SUBSCRIPTION_HISTORY", handleGetSubscriptionHistory);
   yield takeEvery("OPEN_PAYMENT_PORTAL", handleOpenPaymentPortal);
   yield takeEvery("GET_RECEIPT", handleGetReceipt);
}
