import API from "./API";

export const createLandlord = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.delete("contacts");
   formData.delete("bankDetails");
   if (data.image == null) {
      formData.delete("image");
   }
   formData.set("dateOfBirth", data.dateOfBirth?.format("YYYY-MM-DD"));
   data.contacts.map(({ label, contactNumber }, index) => {
      formData.append(`contacts[${index}].label`, label);
      formData.append(`contacts[${index}].number`, contactNumber);
   });
   data.bankDetails.map(
      ({ bank, accountNumber, nameOnTheBank, sortCode }, index) => {
         formData.append(`bankDetails[${index}].bank`, bank);
         formData.append(`bankDetails[${index}].accountNumber`, accountNumber);
         formData.append(`bankDetails[${index}].nameOnTheBank`, nameOnTheBank);
         formData.append(`bankDetails[${index}].sortCode`, sortCode);
      }
   );
   return await API.post("/api/landlord/addLandLord", formData);
};

export const getLandlords = async (payload) => {
   let body = {};
   payload.landlordType && (body.landLordType = payload.landlordType);
   payload.landlordStatus && (body.landlordStatus = payload.landlordStatus);
   payload.sortBy && (body.sortBy = payload.sortBy);
   payload.orderBy && (body.orderBy = payload.orderBy);
   payload.searchBy && (body.searchBy = payload.searchBy);
   body.isArchive = payload.isArchive || false;
   return API.get(
      `/api/landlord/getLandlordByAgencyIdV1/${payload.page - 1}/${
         payload.limit
      }`,
      { params: body }
   );
};

export const getLandlordsLazy = async (payload) => {
   let body = {};
   payload.landlordType && (body.landLordType = payload.landlordType);
   payload.landlordStatus && (body.landlordStatus = payload.landlordStatus);
   payload.sortBy && (body.sortBy = payload.sortBy);
   payload.orderBy && (body.orderBy = payload.orderBy);
   payload.searchBy && (body.searchBy = payload.searchBy);
   payload.isArchive && (body.isArchive = payload.isArchive);
   return API.get(
      `/api/landlord/getLandlordByAgencyIdV1/${payload.page - 1}/${
         payload.limit
      }`,
      { params: body }
   );
};

export const updateLandlordById = (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   Object.keys(data).map((key) =>
      data[key] == null ? formData.delete(key, data[key]) : null
   );
   formData.delete("contacts");
   formData.delete("emails");
   if (data.dateOfBirth == undefined) {
      formData.delete("dateOfBirth");
   } else {
      formData.set("dateOfBirth", data.dateOfBirth?.format("YYYY-MM-DD"));
   }
   data.contacts.map(({ label, contactNumber, id }, index) => {
      formData.append(
         `contacts[${index}].label`,
         label == null || label == "" ? "Home" : label
      );
      formData.append(`contacts[${index}].number`, contactNumber);
      formData.append(`contacts[${index}].id`, id);
   });
   data.emails.map(({ email, label, id }, i) => {
      formData.append(`emails[${i}].email`, email);
      formData.append(`emails[${i}].id`, id);
      formData.append(
         `emails[${i}].label`,
         label == null || label == "" ? "Home" : label
      );
   });
   return API.post("/api/landlord/editLandord", formData);
};

export const getLandlordById = async (landlordId) => {
   return API.get("/api/landlord/getLandlordById", {
      params: {
         landlordId
      }
   });
};

export const deleteLandlordById = async (landlordId) => {
   return API.delete(`/api/bin/landlord/${landlordId}`);
};

export const getContractsById = async (payload) => {
   const { landlordId, propertyId, limit, page, ...rest } = payload;
   return API.get(`/api/landlord/getContractByLandlordIdv3/${page}/${limit}`, {
      params: {
         landlordId: landlordId,
         propertyId: propertyId,
         ...rest
      }
   });
};

export const getDocumentById = (payload) => {
   const { id: landlordId, docSettingsId, landlordDocId, ...rest } = payload;
   return API.get(
      `/api/landlord/getDocumentByLandlordIdv3/${payload?.page}/${payload?.limit}`,
      {
         params: {
            landlordId,
            docSettingsId,
            landlordDocId,
            ...rest
         }
      }
   );
};

export const getDropDownValueForAddNewDocumentType = (payload) => {
   const landlordId = payload?.landlordId;
   return API.get("/api/landlord/getLandlordTypesById", {
      params: {
         landlordId
      }
   });
};

export const deleteDocumentById = (payload) => {
   const landlordId = payload?.landlordId;
   const docId = payload?.docId;
   const docSettingsId = payload?.docSettingsId;

   return API.delete("/api/landlord/delete-landlord-docs", {
      params: {
         docId,
         docSettingsId,
         landlordId
      }
   });
};

export const deleteDocumentBySingleId = (payload) => {
   const { landlordDocUrlId, docSettingsId, docId } = payload;
   return API.delete("/api/landlord/delete-landlord-doc", {
      params: {
         landlordDocUrlId,
         docSettingsId,
         docId
      }
   });
};
export const addDocument = (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   Object.keys(data).map((key) => {
      if (data[key] == undefined || data[key] == null) {
         formData.delete(key);
      }
   });
   return API.post("/api/landlord/addLandlordDocument", formData);
};

export const editDocument = (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   Object.keys(data).map((key) => {
      if (data[key] == undefined || data[key] == null) {
         formData.delete(key);
      }
   });
   return API.post("/api/landlord/editLandlordDocument", formData);
};

export const addContract = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.set("contractTo", data.contractTo.format("YYYY-MM-DD"));
   formData.set("contractFrom", data.contractFrom.format("YYYY-MM-DD"));
   return API.post("/api/landlord/addLandlordContract", formData);
};

export const getpropertiesBylandlord = async (data) => {
   return API.get("/api/landlord/getPropertyByLandLordId", {
      params: {
         landLordId: data
      }
   });
};

export const editContract = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.set("contractTo", data.contractTo.format("YYYY-MM-DD"));
   formData.set("contractFrom", data.contractFrom.format("YYYY-MM-DD"));
   return API.post("/api/landlord/editLandlordContract", formData);
};
export const addNoteToLandlord = async (data) => {
   const { title, description, landlordId, createdBy } = data;
   return await API.post("/api/notes/saveNote", {
      title,
      description,
      landlordId,
      createdBy,
      ownerType: "L"
   });
};

export const updateNoteToLandlord = async (data) => {
   const { id, title, description, landlordId, updatedBy } = data;
   return await API.post("/api/notes/saveNote", {
      id,
      title,
      description,
      landlordId,
      updatedBy,
      ownerType: "L"
   });
};

export const getNoteByLandlord = async (landlordId) => {
   return await API.get("/api/notes/getNoteByLandlordId", {
      params: {
         landlordId
      }
   });
};

export const deleteNoteByLandlord = async ({ id, landlordId }) => {
   return await API.delete("/api/notes/deleteNote?by=L", {
      data: {
         id,
         landlordId
      }
   });
};

export const deleteContractDocumentByid = (id) => {
   return API.delete("/api/landlord/deleteLandlordContractdDocumentById", {
      params: {
         id
      }
   });
};

export const getDocumentBasestrById = (id) => {
   return API.get("/api/landlord/getDocumentUrlById", {
      params: {
         id: id
      }
   });
};

export const getEmergencyDetailsForLanldord = (id) => {
   return API.get(
      `/api/landlord/getEmergencyContactDetails?landlordId=${id}&tenantId=`
   );
};

export const updateLandlordToArchiveAPI = async (payload) => {
   const landlordId = payload.landlordId;
   const status = payload.status;
   return await API.put("/api/landlord/archiveLandlord", null, {
      params: {
         landlordId,
         status
      }
   });
};
