import API from "./API";

export const getDashAssets = () => {
   return API.get("/api/dashboard/assets");
};

export const getDashOverview = () => {
   return API.get("/api/dashboard/overview/today");
};

export const getDashboardOverview = () => {
   return API.get("/api/dashboard/overview");
};

export const getDashboardAccount = async (data) => {
   return await API.get(`/api/dashboard/overview/account?date=${data + "-01"}`);
};
