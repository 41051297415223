import API from "./API";

export const getAllProductWithPrice = async () => {
   return await API.get("/v1/stripe/admin/product/prices", { data: {} });
};

export const getConsumerDetailsAPI = async (payload) => {
   return await API.get(`/v1/stripe/customer/get-consumer?email=${payload}`, {
      data: {}
   });
};

export const getPaymentMethodAPI = async (payload) => {
   return await API.get(
      `/v1/stripe/customer/get-payment-methods?email=${payload}`,
      {
         data: {}
      }
   );
};

export const getSubscriptionHistoryAPI = async (payload) => {
   return await API.get(
      `/v1/stripe/customer/get-user-history?email=${payload}`,
      {
         data: {}
      }
   );
};

export const getInvoiceAPI = async ({
   email,
   limit,
   created_start,
   created_end,
   dueDate_start,
   dueDate_end
}) => {
   return await API.get(
      `/v1/stripe/customer/get-all-invoices?email=${email}&limit=${
         limit ? limit : ""
      }&created_start=${created_start ? created_start : ""}&created_end=${
         created_end ? created_end : ""
      }&dueDate_start=${dueDate_start ? dueDate_start : ""}&dueDate_end=${
         dueDate_end ? dueDate_end : ""
      }`,
      {
         data: {}
      }
   );
};

export const getReceiptAPI = async ({
   email,
   limit,
   created_start,
   created_end,
   dueDate_start,
   dueDate_end
}) => {
   return await API.get(
      `/v1/stripe/customer/get-all-receipts?email=${email}&limit=${
         limit ? limit : ""
      }&created_start=${created_start ? created_start : ""}&created_end=${
         created_end ? created_end : ""
      }&dueDate_start=${dueDate_start ? dueDate_start : ""}&dueDate_end=${
         dueDate_end ? dueDate_end : ""
      }`,
      {
         data: {}
      }
   );
};

export const openCustomerPortalAPI = async (payload) => {
   return await API.post(
      `/v1/stripe/customer/create-customer-portal-session?email=${payload}`,
      { data: {} }
   );
};

export const openPaymentPortalAPI = async (payload) => {
   return await API.get(`/v1/stripe/customer/get-failed-txn?email=${payload}`, {
      data: {}
   });
};

export const addPaymentMethodAPI = async (payload) => {
   return await API.post(`/v1/stripe/customer/create-payment-method`, payload);
};

export const deletePaymentMethodAPI = async (payload) => {
   return await API.delete(`/v1/stripe/customer/delete-payment-method`, {
      payload
   });
};

export const addCustomerSubscriptionAPI = async ({
   customerEmail,
   planName,
   password
}) => {
   let formData = new FormData();
   formData.append("customerEmail", customerEmail);
   formData.append("planName", planName);
   formData.append("password", password);
   return await API.post(
      `/v1/stripe/customer/create-customer-subscription`,
      formData
   );
};

export const updateBillingAddressAPI = async (payload) => {
   return await API.put(`/v1/stripe/customer/update-customer`, payload);
};

export const cancelCustomerSubscriptionAPI = async ({
   customerEmail,
   planName,
   password
}) => {
   let formData = new FormData();
   formData.append("customerEmail", customerEmail);
   formData.append("planName", planName);
   formData.append("password", password);
   return await API.post(
      `/v1/stripe/customer/cancel-customer-subscription`,
      formData
   );
};
