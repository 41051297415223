import produce from "immer";

const INITIAL_STATE = {
   rentPayableProperty: {
      data: [],
      success: false,
      error: null,
      pagination: null,
      loading: false
   },

   rentPayableTenant: {
      data: [],
      success: false,
      error: null,
      pagination: null,
      loading: false
   },

   rentPayableLandlord: {
      data: [],
      success: false,
      error: null,
      pagination: null,
      loading: false
   },

   invoiceNotes: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   saveNotes: {
      success: false,
      error: null,
      loading: false
   },

   updateNotes: {
      success: false,
      error: null,
      loading: false
   },
   deleteNotes: {
      success: false,
      error: null,
      loading: false
   },
   accountReceivable: {
      data: [],
      invoiceSummary: null,
      success: false,
      error: null,
      pagination: null,
      loading: false,
      loaded: false
   },

   invoice: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   editInvoice: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   addInvoice: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   addReceipt: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   deleteReceipt: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   deleteinvoice: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   changeInvoiceToCancel: {
      success: false,
      error: null,
      loading: false
   },

   exportinvoiceData: {
      module: "",
      genType: "",
      section: "",
      invoiceType: "",
      id: "",
      displayName: ""
   },
   itemAddType: {
      data: [],
      success: false,
      error: null,
      loading: false
   },

   comment: {
      loading: false,
      data: []
   },
   saveComment: false,
   deleteComment: false,
   base64: {
      loading: false,
      base: null
   },
   saveReply: false,
   saveAsRead: false,
   deleteReply: false,
   saveEmoji: false,
   isSaveASRead: "done",
   base64Comment: {
      loading: false,
      base: null
   },
   serviceType: {
      data: [],
      success: false,
      error: null,
      loading: false
   },
   addserviceType: {
      data: [],
      success: false,
      error: null,
      pagination: null,
      loading: false,
      loaded: false
   }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY":
            draft.rentPayableProperty.loading = true;

            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_SUCCESS":
            var arrProperty = action.response.response.invoiceDTO;
            var newArrProperty = [];
            arrProperty.forEach((obj) => {
               newArrProperty.push(obj);
            });
            draft.rentPayableProperty.data = [
               ...draft.rentPayableProperty.data,
               ...newArrProperty
            ];
            draft.rentPayableProperty.pagination =
               action.response.response.paginationDetails;
            draft.rentPayableProperty.invoiceSummary =
               action.response.response.invoiceSummary;
            draft.rentPayableProperty.loading = false;
            draft.rentPayableProperty.loaded = true;

            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_FAIL":
            draft.rentPayableProperty.loading = false;
            draft.rentPayableProperty.error = action.response;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_lOAD_FALSE":
            draft.rentPayableProperty.loaded = false;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_DATA_NULL":
            draft.rentPayableProperty.data = [];
            draft.rentPayableProperty.invoiceSummary = null;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT":
            draft.rentPayableTenant.loading = true;

            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_lOAD_FALSE":
            draft.rentPayableTenant.loaded = false;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_DATA_NULL":
            draft.rentPayableTenant.data = [];
            draft.rentPayableTenant.invoiceSummary = null;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_SUCCESS":
            var arrTenant = action.response.response.invoiceDTO;
            var newArrTenant = [];
            arrTenant.forEach((obj) => {
               newArrTenant.push(obj);
            });
            draft.rentPayableTenant.data = [
               ...draft.rentPayableTenant.data,
               ...newArrTenant
            ];
            draft.rentPayableTenant.pagination =
               action.response.response.paginationDetails;
            draft.rentPayableTenant.invoiceSummary =
               action.response.response.invoiceSummary;
            draft.rentPayableTenant.loading = false;
            draft.rentPayableTenant.loaded = true;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_FAIL":
            draft.rentPayableTenant.loading = false;
            draft.rentPayableTenant.error = action.response;
            draft.rentPayableTenant.data = [];
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD":
            draft.rentPayableLandlord.loading = true;

            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_SUCCESS":
            var arrLandlord = action.response.response.invoiceDTO;
            var newArrLandlord = [];
            arrLandlord.forEach((obj) => {
               newArrLandlord.push(obj);
            });
            draft.rentPayableLandlord.data = [
               ...draft.rentPayableLandlord.data,
               ...newArrLandlord
            ];
            draft.rentPayableLandlord.pagination =
               action.response.response.paginationDetails;
            draft.rentPayableLandlord.invoiceSummary =
               action.response.response.invoiceSummary;
            draft.rentPayableLandlord.loading = false;
            draft.rentPayableLandlord.loaded = true;

            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_lOAD_FALSE":
            draft.rentPayableLandlord.loaded = false;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_DATA_NULL":
            draft.rentPayableLandlord.data = [];
            draft.rentPayableLandlord.invoiceSummary = null;
            break;

         case "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_FAIL":
            draft.rentPayableLandlord.loading = false;
            draft.rentPayableLandlord.error = action.response;
            break;

         case "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE":
            draft.accountReceivable.loading = true;
            draft.addInvoice.success = false;
            draft.deleteinvoice.success = false;
            draft.invoice.data = [];
            break;

         case "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_lOAD_FALSE":
            draft.accountReceivable.loaded = false;
            break;

         case "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_SUCCESS":
            var arr = action.response.response.invoiceDTO;
            var newArr = [];
            arr.forEach((obj) => {
               newArr.push(obj);
            });
            draft.accountReceivable.data = [
               ...draft.accountReceivable.data,
               ...newArr
            ];
            draft.accountReceivable.pagination =
               action.response.response.paginationDetails;
            draft.accountReceivable.invoiceSummary =
               action.response.response.invoiceSummary;
            draft.accountReceivable.loading = false;
            draft.accountReceivable.loaded = true;
            break;
         case "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_DATA_NULL":
            draft.accountReceivable.data = [];
            draft.accountReceivable.invoiceSummary = null;
            break;

         case "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_FAIL":
            draft.accountReceivable.loading = false;
            draft.accountReceivable.data = [];
            draft.accountReceivable.error = action.response;
            break;

         case "GET_INVOICE_DETAILS":
            draft.invoice.loading = true;
            draft.invoice.data = [];
            draft.changeInvoiceToCancel.success = false;
            break;

         case "GET_INVOICE_DETAILS_SUCCESS":
            draft.invoice.data = action.response.response;
            draft.addReceipt.success = false;
            draft.deleteReceipt.success = false;
            draft.invoice.loading = false;
            break;

         case "GET_INVOICE_DETAILS_FAIL":
            draft.invoice.loading = false;
            draft.invoice.error = action.response;
            break;

         case "GET_ITEM_ADD_TYPES":
            draft.itemAddType.loading = true;
            draft.itemAddType.data = [];
            break;

         case "GET_ITEM_ADD_TYPES_SUCCESS":
            draft.itemAddType.data = action.response.response;

            draft.itemAddType.loading = false;
            break;

         case "GET_ITEM_ADD_TYPES_FAIL":
            draft.itemAddType.loading = false;
            draft.itemAddType.error = action.response;
            break;

         case "UPDATE_INVOICE_DETAILS":
            draft.editInvoice.loading = true;
            draft.editInvoice.success = false;
            draft.editInvoice.data = [];
            break;

         case "UPDATE_INVOICE_DETAILS_SUCCESS":
            draft.editInvoice.data = action.response.response;
            draft.editInvoice.success = true;
            draft.editInvoice.loading = false;
            break;

         case "UPDATE_INVOICE_DETAILS_FAIL":
            draft.editInvoice.loading = false;
            draft.editInvoice.success = false;
            draft.editInvoice.error = action.response;
            break;

         case "ADD_RECEIPT_DETAILS":
            draft.addReceipt.loading = true;
            draft.addReceipt.success = false;
            draft.addReceipt.data = [];
            break;

         case "ADD_RECEIPT_DETAILS_SUCCESS":
            draft.addReceipt.data = action.response.response;
            draft.addReceipt.success = true;
            draft.addReceipt.loading = false;
            break;

         case "ADD_RECEIPT_DETAILS_FAIL":
            draft.addReceipt.loading = false;
            draft.addReceipt.success = false;
            draft.addReceipt.error = action.response;
            break;

         case "DELETE_RECEIPT_DETAILS":
            draft.deleteReceipt.loading = true;
            draft.deleteReceipt.success = false;
            draft.deleteReceipt.data = [];
            break;

         case "DELETE_RECEIPT_DETAILS_SUCCESS":
            draft.deleteReceipt.data = action.response.response;
            draft.deleteReceipt.success = true;
            draft.deleteReceipt.loading = false;
            break;

         case "DELETE_RECEIPT_DETAILS_FAIL":
            draft.deleteReceipt.loading = false;
            draft.deleteReceipt.success = false;
            draft.deleteReceipt.error = action.response;
            break;

         case "DELETE_INVOICE_DETAILS":
            draft.deleteinvoice.loading = true;
            draft.deleteinvoice.success = false;
            draft.deleteinvoice.data = [];
            break;

         case "DELETE_INVOICE_DETAILS_SUCCESS":
            draft.deleteinvoice.data = action.response.response;
            draft.deleteinvoice.success = true;
            draft.deleteinvoice.loading = false;
            break;

         case "DELETE_INVOICE_DETAILS_FAIL":
            draft.deleteinvoice.loading = false;
            draft.deleteinvoice.success = false;
            draft.deleteinvoice.error = action.response;
            break;

         case "CHANGE_INVOICE_STATUS_TO_CANCEL":
            draft.changeInvoiceToCancel.loading = true;
            draft.changeInvoiceToCancel.success = false;
            //draft.changeInvoiceToCancel.data = [];
            break;

         case "CHANGE_INVOICE_STATUS_TO_CANCEL_SUCCESS":
            // draft.changeInvoiceToCancel.data = action.response.response;
            draft.changeInvoiceToCancel.success = true;
            draft.changeInvoiceToCancel.loading = false;
            break;

         case "CHANGE_INVOICE_STATUS_TO_CANCEL_FAIL":
            draft.changeInvoiceToCancel.loading = false;
            draft.changeInvoiceToCancel.success = false;
            //  draft.changeInvoiceToCancel.error = action.response;
            break;

         case "EXPORT_INVOICE_DATA":
            draft.exportinvoiceData = action.payload;
            //draft.changeInvoiceToCancel.data = [];
            break;

         case "ADD_NEW_INVOICE":
            draft.addInvoice.loading = true;
            draft.addInvoice.success = false;
            draft.addInvoice.data = [];
            break;

         case "ADD_NEW_INVOICE_SUCCESS":
            draft.addInvoice.data = action.response.response;
            draft.addInvoice.success = true;
            draft.addInvoice.loading = false;
            break;

         case "ADD_NEW_INVOICE_FAIL":
            draft.addInvoice.loading = false;
            draft.addInvoice.success = false;
            draft.addInvoice.error = action.response;
            break;

         //notes

         case "GET_NOTE_BY_INVOICE":
            draft.invoiceNotes.loading = true;
            draft.invoiceNotes.data = [];
            break;

         case "GET_NOTE_BY_INVOICE_SUCCESS":
            draft.invoiceNotes.data = action.payload.response;

            draft.invoiceNotes.loading = false;
            break;

         case "GET_NOTE_BY_INVOICE_ERROR":
            draft.invoiceNotes.loading = false;
            draft.invoiceNotes.error = action.response;
            break;

         case "ADD_NOTE_TO_INVOICE":
            draft.saveNotes.loading = true;
            draft.saveNotes.success = false;
            break;

         case "ADD_NOTE_TO_INVOICE_SUCCESS":
            draft.saveNotes.success = true;
            draft.saveNotes.loading = false;
            break;

         case "ADD_NOTE_TO_INVOICE_ERROR":
            draft.saveNotes.success = false;
            draft.saveNotes.loading = false;
            draft.saveNotes.error = action.response;
            break;

         case "UPDATE_NOTE_TO_INVOICE":
            draft.updateNotes.loading = true;
            draft.updateNotes.success = false;
            break;

         case "UPDATE_NOTE_TO_INVOICE_SUCCESS":
            draft.updateNotes.success = true;
            draft.updateNotes.loading = false;
            break;

         case "UPDATE_NOTE_TO_INVOICE_ERROR":
            draft.updateNotes.success = false;
            draft.updateNotes.loading = false;
            draft.updateNotes.error = action.response;
            break;

         case "DELETE_NOTE_BY_INVOICE":
            draft.deleteNotes.loading = true;
            draft.deleteNotes.success = false;
            break;

         case "DELETE_NOTE_BY_INVOICE_SUCCESS":
            draft.deleteNotes.success = true;
            draft.deleteNotes.loading = false;
            break;

         case "DELETE_NOTE_BY_INVOICE_ERROR":
            draft.deleteNotes.success = false;
            draft.deleteNotes.loading = false;
            draft.deleteNotes.error = action.response;
            break;

         // comment
         case "GET_COMMENT_BY_INVOICE_ID":
            draft.comment.loading = true;
            break;

         case "GET_COMMENT_BY_INVOICE_ID_SUCCESS":
            draft.comment.data = action.payload.response;
            draft.comment.loading = false;
            if (draft.comment.data) draft.isSaveASRead = "done";
            break;

         case "GET_COMMENT_BY_INVOICE_ID_ERROR":
            draft.comment.data = [];
            draft.comment.loading = false;
            break;

         case "SAVE_COMMENT_BY_INVOICE_ID":
            draft.saveComment = true;
            break;

         case "SAVE_COMMENT_BY_INVOICE_ID_SUCCESS":
            draft.saveComment = false;
            break;

         case "SAVE_COMMENT_BY_INVOICE_ID_ERROR":
            draft.saveComment = false;
            break;

         case "DELETE_COMMENT_BY_INVOICE_ID":
            draft.deleteComment = true;
            break;

         case "DELETE_COMMENT_BY_INVOICE_ID_SUCCESS":
            draft.deleteComment = false;
            break;

         case "DELETE_COMMENT_BY_INVOICE_ID_ERROR":
            draft.deleteComment = false;
            break;

         case "GET_BASE64_BY_URL":
            draft.base64.loading = true;
            break;

         case "GET_BASE64_BY_URL_SUCCESS":
            draft.base64.base = action.payload.response;
            draft.base64.loading = false;
            break;

         case "GET_BASE64_BY_URL_ERROR":
            draft.base64.loading = false;
            break;

         case "SET_NULL":
            draft.base64.base = null;
            break;

         case "SAVE_REPLY_BY_INVOICE_ID":
            draft.saveReply = true;
            break;

         case "SAVE_REPLY_BY_INVOICE_ID_SUCCESS":
            draft.saveReply = false;
            break;

         case "SAVE_REPLY_BY_INVOICE_ID_ERROR":
            draft.saveReply = false;
            break;

         case "SAVE_AS_READ_BY_INVOICE_ID":
            draft.saveAsRead = true;
            draft.isSaveASRead = "loading";
            if (action.payload.comment) {
               draft.comment.data.forEach((val) => {
                  if (val.id == action.payload.id) {
                     val.new = false;
                  }
               });
            } else {
               draft.comment.data.forEach((val) => {
                  if (val.id == action.payload.cid) {
                     val.replyDTOList.forEach((val) => {
                        if (val.id == action.payload.id) {
                           val.new = false;
                        }
                     });
                  }
               });
            }
            break;

         case "SAVE_AS_READ_BY_INVOICE_ID_SUCCESS":
            draft.saveAsRead = false;
            break;

         case "SAVE_AS_READ_BY_INVOICE_ID_ERROR":
            draft.saveAsRead = false;
            break;

         case "DELETE_REPLY_BY_INVOICE_ID":
            draft.deleteReply = true;
            break;

         case "DELETE_REPLY_BY_INVOICE_ID_SUCCESS":
            draft.deleteReply = false;
            break;

         case "DELETE_REPLY_BY_INVOICE_ID_ERROR":
            draft.deleteReply = false;
            break;

         case "SAVE_EMOJI_BY_INVOICE_ID":
            draft.saveEmoji = true;
            break;

         case "SAVE_EMOJI_BY_INVOICE_ID_SUCCESS":
            draft.saveEmoji = false;
            break;

         case "SAVE_EMOJI_BY_INVOICE_ID_ERROR":
            draft.saveEmoji = false;
            break;

         case "GET_BASE64_FOR_COMMENT_BY_INVOICE_ID":
            draft.base64Comment.loading = true;
            break;

         case "GET_BASE64_FOR_COMMENT_BY_INVOICE_ID_SUCCESS":
            draft.base64Comment.base = action.payload.response;
            draft.base64Comment.loading = false;
            break;

         case "GET_BASE64_FOR_COMMENT_BY_INVOICE_ID_ERROR":
            draft.base64Comment.loading = false;
            break;

         //service
         case "GET_ALL_SERVICE_TYPE":
            draft.serviceType.loading = true;
            break;

         case "GET_ALL_SERVICE_TYPE_SET_NULL":
            draft.serviceType.data = [];
            draft.serviceType.success = false;
            draft.serviceType.error = null;
            draft.serviceType.loading = false;

            break;

         case "GET_ALL_SERVICE_TYPE_SUCCESS":
            var arrService = action.response.response.invoicesServiceTypeDTO;
            var newArrService = [];
            arrService.forEach((obj) => {
               newArrService.push(obj);
            });
            draft.serviceType.data = [
               ...draft.serviceType.data,
               ...newArrService
            ];
            draft.serviceType.pagination =
               action.response.response.paginationDetails;
            draft.serviceType.loading = false;
            draft.serviceType.loaded = true;

            break;

         case "GET_ALL_SERVICE_TYPE_FAIL":
            draft.serviceType.data = action.payload.response;
            draft.serviceType.loading = false;
            break;

         case "ADD_SERVICE_TYPE":
            draft.addserviceType.loading = true;
            draft.addserviceType.success = false;
            draft.addserviceType.data = [];
            break;

         case "ADD_SERVICE_TYPE_SUCCESS":
            draft.addserviceType.data = action.response.response;
            draft.addserviceType.success = true;
            draft.addserviceType.loading = false;
            break;

         case "ADD_SERVICE_TYPE_FAIL":
            draft.addserviceType.loading = false;
            draft.addserviceType.success = false;
            draft.addserviceType.error = action.response;
            break;

         default:
            return draft;
      }
   });
