export const ImageExt = ["jpg", "png", "jpeg", "gif", "jfif"];
export const VideoExt = ["mp4", "webm", "mkv", "mov", "mpeg", "mpg"];
export const SupportExt = [
   "pdf",
   "doc",
   "docx",
   "xls",
   "xlsx",
   "csv",
   "ppt",
   "jpg",
   "png",
   "jpeg",
   "gif",
   "jfif",
   "tiff",
   "heif",
   "ppt",
   "pptx",
   "txt",
   "mp4",
   "webm",
   "mkv",
   "mov",
   "mpeg",
   "mpg"
];
