import API from "./API";

export const getAllContracts = (payload) => {
   return API.get("/api/contract/getContractDetailsByContractId", {
      params: {
         role: payload.role,
         fromdate: payload.fromdate,
         todate: payload.todate
      }
   });
};

export const getContractType = () => {
   return API.get("/api/contract/getContractTypes");
};

export const cancelContract = (data) => {
   return API.put(
      `/api/contract/cancelContract?contractId=${data.contractId}&ownerId=${data.ownerId}&flag=${data.flag}`
   );
};
