import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const Logout = React.lazy(() => import("./logout"));

const LogoutRoute = () => {
   return (
      <Switch>
         <Route exact path="/logout" component={Logout} />
         <Redirect from="/" to="/logout" />
      </Switch>
   );
};

export default LogoutRoute;
