import { put, call, takeEvery, select } from "redux-saga/effects";
import {
   addUpdateTaskError,
   addUpdateTaskSuccess,
   getPendingTaskListError,
   getPendingTaskListSuccess,
   getTaskDetailsError,
   getTaskDetailsSuccess,
   getTaskPrioritiesError,
   getTaskPrioritiesSuccess,
   getTaskRemindersError,
   getTaskRemindersSuccess,
   getTaskStatusesError,
   getTaskStatusesSuccess,
   getTaskTypesError,
   getTaskTypesSuccess,
   markTaskAsDoneError,
   markTaskAsDoneSuccess,
   GetCommentByTaskIdSuccess,
   GetCommentByTaskIdError,
   saveCommentSuccess,
   saveCommentError,
   deleteCommentByIdSuccess,
   deleteCommentByIdError,
   DeleteTaskByIdSuccess,
   DeleteTaskIdError,
   GetTaskTimelineSuccess,
   GetTaskTimelineError,
   getCalendarTasksSuccess,
   getCalendarTasksError,
   getBase64ByURLSuccess,
   getBase64ByURLError,
   saveReplySuccess,
   saveReplyError,
   deleteTaskDocByIdSuccess,
   deleteTaskDocByIdError,
   saveAsReadSuccess,
   saveAsReadError,
   deleteReplySuccess,
   deleteReplyError,
   saveEmoijSuccess,
   saveEmojiError,
   getBase64ForCommentSuccess,
   getBase64ForCommentError,
   getAllTaskSuccess,
   getAllTaskError
} from "../actions/taskActions";
import {
   AddUpdatetask,
   GetPendingTaskList,
   GetTaskDetails,
   GetTaskPriorities,
   GetTaskReminders,
   GetTaskStatuses,
   GetTaskTypes,
   MarkTaskAsDone,
   GetCommentByTaskId,
   SaveComment,
   deleteCommentByid,
   DeleteTaskById,
   getTaskTimeline,
   getCalendarTasks,
   getBaseByURL,
   saveReply,
   DeleteTaskDocById,
   saveAsReadStatus,
   deleteReply,
   saveEmoij,
   getBase64ForComment,
   getTaskList
} from "../api/tasks";

function* handleGetTaskPriorities() {
   try {
      const resp = yield call(GetTaskPriorities);
      yield put(getTaskPrioritiesSuccess(resp));
   } catch {
      yield put(getTaskPrioritiesError());
   }
}

function* handleGetTaskReminders() {
   try {
      const resp = yield call(GetTaskReminders);
      yield put(getTaskRemindersSuccess(resp));
   } catch {
      yield put(getTaskRemindersError());
   }
}

function* handleGetTaskStatuses() {
   try {
      const resp = yield call(GetTaskStatuses);
      yield put(getTaskStatusesSuccess(resp));
   } catch {
      yield put(getTaskStatusesError());
   }
}

function* handleGetTaskTypes() {
   try {
      const resp = yield call(GetTaskTypes);
      yield put(getTaskTypesSuccess(resp));
   } catch {
      yield put(getTaskTypesError());
   }
}

function* handleAddUpdateTask(action) {
   try {
      const response = yield call(AddUpdatetask, action.payload);
      yield put(addUpdateTaskSuccess(response));
   } catch (error) {
      yield put(addUpdateTaskError(error.response));
   }
}

function* handleGetPendingTaskList() {
   try {
      const resp = yield call(GetPendingTaskList);
      yield put(getPendingTaskListSuccess(resp));
   } catch {
      yield put(getPendingTaskListError());
   }
}

function* handleMarkTaskAsDone(action) {
   try {
      const response = yield call(MarkTaskAsDone, action.payload);
      yield put(markTaskAsDoneSuccess(response));
   } catch (error) {
      yield put(markTaskAsDoneError(error.response));
   }
}

function* handleGetTaskDetails(action) {
   try {
      const response = yield call(GetTaskDetails, action.payload);
      yield put(getTaskDetailsSuccess(response));
   } catch (error) {
      yield put(getTaskDetailsError(error.response));
   }
}

function* handleGetCommentByTaskId(action) {
   try {
      const resp = yield call(GetCommentByTaskId, action.id);
      yield put(GetCommentByTaskIdSuccess(resp.data));
   } catch {
      yield put(GetCommentByTaskIdError());
   }
}

function* handleSaveComment(action) {
   const { uploadedFile } = yield select((state) => state.upload);
   try {
      yield call(SaveComment, {
         ...action.payload,
         fileIds: uploadedFile.map((file) => file.id).join()
      });
      yield put(saveCommentSuccess());
   } catch {
      yield put(saveCommentError());
   }
}

function* handleDeleteComment(action) {
   try {
      yield call(deleteCommentByid, action.id);
      yield put(deleteCommentByIdSuccess());
   } catch {
      yield put(deleteCommentByIdError());
   }
}

function* handleDeleteTaskById(action) {
   try {
      yield call(DeleteTaskById, action.payload.id);
      yield put(DeleteTaskByIdSuccess());
      action.payload.history.push("/agency/TaskList");
   } catch {
      yield put(DeleteTaskIdError());
   }
}

function* handleGetTimeline(action) {
   try {
      const resp = yield call(getTaskTimeline, action.data, {
         page: action.data.page,
         size: action.data.size
      });
      yield put(GetTaskTimelineSuccess(resp.data));
   } catch {
      yield put(GetTaskTimelineError());
   }
}

function* handleGetCalendarTasks(action) {
   try {
      const resp = yield call(getCalendarTasks, action.data);
      yield put(getCalendarTasksSuccess(resp.data));
   } catch {
      yield put(getCalendarTasksError());
   }
}

function* handleGetBase(action) {
   try {
      const resp = yield call(getBaseByURL, action.url);
      yield put(getBase64ByURLSuccess(resp.data));
   } catch {
      yield put(getBase64ByURLError());
   }
}

function* handleSaveReply(action) {
   try {
      yield call(saveReply, action.payload);
      yield put(saveReplySuccess());
   } catch {
      yield put(saveReplyError());
   }
}

function* handleDeleteTaskDoc(action) {
   try {
      yield call(DeleteTaskDocById, action.id);
      yield put(deleteTaskDocByIdSuccess());
   } catch {
      yield put(deleteTaskDocByIdError());
   }
}

function* handleSaveAsRead(action) {
   try {
      yield call(saveAsReadStatus, action.payload);
      yield put(saveAsReadSuccess());
   } catch {
      yield put(saveAsReadError());
   }
}

function* handleDeleteReply(action) {
   try {
      yield call(deleteReply, action.id);
      yield put(deleteReplySuccess());
   } catch (error) {
      yield put(deleteReplyError());
   }
}

function* handleSaveEmoji(action) {
   try {
      yield call(saveEmoij, action.payload);
      yield put(saveEmoijSuccess());
   } catch (error) {
      yield put(saveEmojiError());
   }
}

function* handleGetBaseForComment(action) {
   try {
      const resp = yield call(getBase64ForComment, action.url);
      yield put(getBase64ForCommentSuccess(resp.data));
   } catch {
      yield put(getBase64ForCommentError());
   }
}

function* handleGetAllTask(action) {
   try {
      const resp = yield call(getTaskList, action.payload);
      yield put(getAllTaskSuccess(resp.data.response));
   } catch {
      yield put(getAllTaskError());
   }
}

export default function* watchTask() {
   yield takeEvery("GET_TASK_PRIORITIES", handleGetTaskPriorities);
   yield takeEvery("GET_TASK_REMAINDERS", handleGetTaskReminders);
   yield takeEvery("GET_TASK_STATUSES", handleGetTaskStatuses);
   yield takeEvery("GET_TASK_TYPES", handleGetTaskTypes);
   yield takeEvery("ADD_UPDATE_TASK", handleAddUpdateTask);
   yield takeEvery("GET_PENDING_TASK_LIST", handleGetPendingTaskList);
   yield takeEvery("MARK_TASK_AS_DONE", handleMarkTaskAsDone);
   yield takeEvery("GET_TASK_DETAILS_BY_ID", handleGetTaskDetails);
   yield takeEvery("GET_COMMENT_BY_TASK_ID", handleGetCommentByTaskId);
   yield takeEvery("SAVE_COMMENT_BY_TASK_ID", handleSaveComment);
   yield takeEvery("DELETE_COMMENT_BY_ID", handleDeleteComment);
   yield takeEvery("DELETE_TASK_DELETE_BY_ID", handleDeleteTaskById);
   yield takeEvery("GET_TASK_TIMELINE", handleGetTimeline);
   yield takeEvery("GET_CALENDAR_TASKS", handleGetCalendarTasks);
   yield takeEvery("GET_BASE64_BY_URL", handleGetBase);
   yield takeEvery("SAVE_REPLY", handleSaveReply);
   yield takeEvery("DELETE_TASK_DOC_BY_ID", handleDeleteTaskDoc);
   yield takeEvery("SAVE_AS_READ", handleSaveAsRead);
   yield takeEvery("DELETE_REPLY", handleDeleteReply);
   yield takeEvery("SAVE_EMOJI", handleSaveEmoji);
   yield takeEvery("GET_BASE64_FOR_COMMENT", handleGetBaseForComment);
   yield takeEvery("GET_ALL_TASK", handleGetAllTask);
}
