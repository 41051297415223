export const getAllContracts = (payload) => ({
   type: "GET_ALL_CONTRACTS",
   payload
});

export const getAllContractsSuccess = (payload) => ({
   type: "GET_ALL_CONTRACTS_SUCCESS",
   payload
});

export const getAllContractsError = () => ({
   type: "GET_ALL_CONTRACTS_ERROR"
});

export const getContractType = () => ({
   type: "GET_CONTRACT_TYPE"
});

export const getContractTypeSuccess = (payload) => ({
   type: "GET_CONTRACT_TYPE_SUCCESS",
   payload
});

export const getContractTypeError = () => ({
   type: "GET_CONTRACT_TYPE_ERROR"
});

export const cancelContractRequest = (payload) => ({
   type: "CANCEL_CONTRACT_REQUEST",
   payload
});

export const cancelContractSuccess = () => ({
   type: "CANCEL_CONTRACT_SUCCESS"
});

export const cancelContractError = () => ({
   type: "CANCEL_CONTRACT_ERROR"
});
