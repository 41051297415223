const getOccupantsByTenantIdPropertyId = (payload) => ({
   type: "GET_OCCUPANTS_BY_TENANT_ID_PROPERTY_ID",
   payload
});

const getOccupantsByTenantIdPropertyIdSuccess = (payload) => ({
   type: "GET_OCCUPANTS_BY_TENANT_ID_PROPERTY_ID_SUCCESS",
   payload
});

const getOccupantsByTenantIdPropertyIdError = () => ({
   type: "GET_OCCUPANTS_BY_TENANT_ID_PROPERTY_ID_ERROR"
});

const addOccupant = (payload) => ({
   type: "ADD_OCCUPANT",
   payload
});

const addOccupantSuccess = (payload) => ({
   type: "ADD_OCCUPANT_SUCCESS",
   payload
});

const addOccupantError = () => ({
   type: "ADD_OCCUPANT_ERROR"
});

const deleteOccupantById = (payload) => ({
   type: "DELETE_OCCUPANT_BY_ID",
   payload
});

const deleteOccupantByIdSuccess = (payload) => ({
   type: "DELETE_OCCUPANT_BY_ID_SUCCESS",
   payload
});

const deleteOccupantByIdError = () => ({
   type: "DELETE_OCCUPANT_BY_ID_ERROR"
});

const updateOccupant = (payload) => ({
   type: "UPDATE_OCCUPANT",
   payload
});

const updateOccupantSuccess = (payload) => ({
   type: "UPDATE_OCCUPANT_SUCCESS",
   payload
});

const updateOccupantError = () => ({
   type: "UPDATE_OCCUPANT_ERROR"
});

const getUnitDetailsByTenantIdPropertyId = (payload) => ({
   type: "GET_UNIT_DETAILS_BY_TENANT_ID_PROPERTY_ID",
   payload
});

const getUnitDetailsByTenantIdPropertyIdSuccess = (payload) => ({
   type: "GET_UNIT_DETAILS_BY_TENANT_ID_PROPERTY_ID_SUCCESS",
   payload
});

const getUnitDetailsByTenantIdPropertyIdError = () => ({
   type: "GET_UNIT_DETAILS_BY_TENANT_ID_PROPERTY_ID_ERROR"
});

export {
   getOccupantsByTenantIdPropertyId,
   getOccupantsByTenantIdPropertyIdSuccess,
   getOccupantsByTenantIdPropertyIdError,
   addOccupant,
   addOccupantSuccess,
   addOccupantError,
   deleteOccupantById,
   deleteOccupantByIdSuccess,
   deleteOccupantByIdError,
   updateOccupant,
   updateOccupantSuccess,
   updateOccupantError,
   getUnitDetailsByTenantIdPropertyId,
   getUnitDetailsByTenantIdPropertyIdSuccess,
   getUnitDetailsByTenantIdPropertyIdError
};
