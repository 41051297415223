import { put, call, takeEvery, all, select } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import {
   getAllPropertiesSuccess,
   getAllPropertiesError,
   getLicenceTypesSuccess,
   getLicenceTypesError,
   getPropertyTypesSuccess,
   getPropertyTypesError,
   getPropertyStatusSuccess,
   getPropertyStatusError,
   getFurnishTypesSuccess,
   getFurnishTypesError,
   getUnitTypesSuccess,
   getUnitTypesError,
   savePropertySuccess,
   savePropertyError,
   getPropertyDetailsSuccess,
   getPropertyDetailsError,
   getPropertyBasicInfoSuccess,
   getPropertyBasicInfoError,
   updatePropertyMultiUnitError,
   updatePropertyMultiUnitSuccess,
   updateUnitMultiUnitSuccess,
   updateUnitMultiUnitError,
   deletePortfolioByIdFail,
   deletePortfolioByIdSucess,
   deleteImageByIdSucess,
   deleteImageByIdFail,
   mapTenantToPropertyRequestFail,
   mapTenantToPropertyRequestSuccess,
   addNoteToPropertySuccess,
   addNoteToPropertyError,
   updateNoteToPropertySuccess,
   updateNoteToPropertyError,
   getNoteByPropertySuccess,
   getNoteByPropertyError,
   deleteNoteByPropertySuccess,
   deleteNoteByPropertyError,
   updatePropertyStatusError,
   updatePropertyStatusSuccess,
   getColumnSelectionSuccess,
   getColumnSelectionError,
   saveColumnSelectionSuccess,
   saveColumnSelectionError,
   updatePropertyToArchiveFail,
   updatePropertyToArchiveSuccess,
   getAllPropertySuccess,
   mapLandlordToPropertyError,
   mapLandlordToPropertySuccess,
   saveLandlordRentDetailsSuccess,
   saveLandlordRentDetailsError,
   getUtilitySuccess,
   getUtilityError,
   saveUtilitySuccess,
   saveUtilityError,
   updateUtilitySuccess,
   updateUtilityError,
   deleteUtilitySuccess,
   deleteUtilityError,
   getPropertyDocumentSuccess,
   getPropertyDocumentError,
   addPropertyDocumentSuccess,
   addPropertyDocumentError,
   updatePropertyDocumentSuccess,
   updatePropertyDocumentError,
   deletePropertyDocsSuccess,
   deletePropertyDocsError,
   getPropertyTypesByIdSuccess,
   getPropertyTypesByIdError,
   deletePropertyByIdSuccess,
   deletePropertyByIdError,
   getAllPropertiesLazyLoadingError,
   getAllPropertiesLazyLoadingSuccess,
   getAllPropertiesRequest,
   unlinkTenantFromPropertyError,
   unlinkTenantFromPropertySuccess,
   unlinklandlordFromPropertyError,
   unlinklandlordFromPropertySuccess,
   getPropertyWarningRequestError,
   getPropertyWarningRequestSuccess,
   setNullPropertyDocuments,
   getPropertyDocuments,
   getPropretyTypesById
} from "../actions/propertyActions";
import {
   getProperties,
   getLicenceTypes,
   getPropertyTypes,
   getPropertyStatus,
   getFurnishTypes,
   getUnitTypes,
   saveProperty,
   getPropertyDetails,
   getPropertyBasicInfo,
   updateMultiUnitProperty,
   updateMultiUnitUnitGallery,
   updateMultiUnit_Unitdetails,
   deletePortfolioByIdAPI,
   deleteImageByIdAPI,
   mapTenantToPropertyAPI,
   addNoteToProperty,
   updateNoteToProperty,
   deleteNoteByProperty,
   getNoteByProperty,
   updatePopertyStatusApi,
   getColumnSelection,
   saveColumnSelection,
   updatePropertyToArchiveAPI,
   GetAllProperty,
   mapLandlordToPropertyAPI,
   saveLandlordRentAPI,
   getAllUtility,
   saveUtility,
   updateUtility,
   deleteUtility,
   getDocumentByPropertyId,
   addDocumentByPropertyId,
   editDocumentByPropertyId,
   deletePropertyDocument,
   getPropertyTypesById,
   deletePropertyById,
   getPropertiesLazy,
   unlinkLandlordFromPropertyById,
   unlinkTenantFromPropertyById,
   getPropertyWarning
} from "../api/properties";
import { clearUploadedFiles } from "../actions/uploadAction";

function* handleGetProperties(action) {
   try {
      const response = yield call(getProperties, action.payload);
      yield put(getAllPropertiesSuccess(response));
   } catch (error) {
      yield put(getAllPropertiesError(error));
   }
}

function* handleGetPropertiesLazy(action) {
   try {
      const response = yield call(getPropertiesLazy, action.payload);
      yield put(getAllPropertiesLazyLoadingSuccess(response));
   } catch {
      yield put(getAllPropertiesLazyLoadingError());
   }
}

function* handleGetLicenceTypes() {
   try {
      const response = yield call(getLicenceTypes);
      yield put(getLicenceTypesSuccess(response));
   } catch (error) {
      yield put(getLicenceTypesError(error.response.data));
   }
}

function* handleGetPropertyTypes() {
   try {
      const response = yield call(getPropertyTypes);
      yield put(getPropertyTypesSuccess(response));
   } catch (error) {
      yield put(getPropertyTypesError(error.response.data));
   }
}

function* handleGetPropertyStatus() {
   try {
      const response = yield call(getPropertyStatus);
      yield put(getPropertyStatusSuccess(response));
   } catch (error) {
      yield put(getPropertyStatusError(error.response.data));
   }
}

function* handleGetFurnishTypes() {
   try {
      const response = yield call(getFurnishTypes);
      yield put(getFurnishTypesSuccess(response));
   } catch (error) {
      yield put(getFurnishTypesError(error.response.data));
   }
}

function* handleGetUnitTypes() {
   try {
      const response = yield call(getUnitTypes);
      yield put(getUnitTypesSuccess(response));
   } catch (error) {
      yield put(getUnitTypesError(error.response.data));
   }
}

function* handleSaveProperty(action) {
   try {
      const response = yield call(saveProperty, action.payload);
      yield put(savePropertySuccess(response));
   } catch (error) {
      yield put(savePropertyError(error.response.data));
   }
}

function* handleGetPropertyDetails(action) {
   try {
      const response = yield call(getPropertyDetails, action.payload);
      yield put(getPropertyDetailsSuccess(response));
   } catch (error) {
      yield put(getPropertyDetailsError(error));
   }
}

function* handleGetPropertyBasicInfo(action) {
   try {
      const response = yield call(getPropertyBasicInfo, action.payload);
      yield put(getPropertyBasicInfoSuccess(response));
   } catch (error) {
      console.error(error);
      yield put(getPropertyBasicInfoError(error));
   }
}

function* handleUpdateMultiUnitProperty(action) {
   try {
      const response = yield call(updateMultiUnitProperty, action.payload);
      yield put(updatePropertyMultiUnitSuccess(response));
      yield put(clearUploadedFiles());
   } catch (error) {
      yield put(updatePropertyMultiUnitError(error));
   }
}

function* handleUpdateMultiUnit_Units(action) {
   try {
      let response;

      if (action.payload.unitDetailsRequest.unitDetailsID != null) {
         response = yield all([
            call(updateMultiUnit_Unitdetails, action.payload)
         ]);
      } else {
         response = yield call(updateMultiUnit_Unitdetails, action.payload);

         let temp = action.payload;
         temp.unitDetailsRequest.unitDetailsID =
            response.data.response.unitDetails.id;
         yield call(updateMultiUnitUnitGallery, temp);
      }

      yield put(updateUnitMultiUnitSuccess(response));
   } catch (error) {
      yield put(updateUnitMultiUnitError(error.response));
   }
}

function* handleDeletePortfolioById(action) {
   try {
      const response = yield call(deletePortfolioByIdAPI, action.payload);
      yield put(deletePortfolioByIdSucess(response));
   } catch (error) {
      yield put(deletePortfolioByIdFail(error.response.data));
   }
}

function* handleDeleteImageById(action) {
   try {
      const response = yield call(deleteImageByIdAPI, action.payload);
      yield put(deleteImageByIdSucess(response));
   } catch (error) {
      yield put(deleteImageByIdFail(error.response.data));
   }
}

// function* handleUpdateTenantRentDetails(action) {
//    try {
//       const response = yield call(updateTenantRentDetailsAPI, action.payload);
//       yield put(updateTenantRentSuccess(response));
//    } catch (error) {
//       yield put(updateTenantRentFail(error.response.data));
//    }
// }

function* handleMapTenantToProperty(action) {
   try {
      const response = yield call(mapTenantToPropertyAPI, action.payload);
      yield put(mapTenantToPropertyRequestSuccess(response));
   } catch (error) {
      yield put(mapTenantToPropertyRequestFail(error.response.data));
   }
}

function* handleAddNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         createdBy: agencyId
      };
      const response = yield call(addNoteToProperty, notePayload);
      yield put(addNoteToPropertySuccess(response.data));
   } catch (error) {
      yield put(addNoteToPropertyError(error));
   }
}

function* handleUpdateNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         updatedBy: agencyId
      };
      const response = yield call(updateNoteToProperty, notePayload);
      yield put(updateNoteToPropertySuccess(response.data));
   } catch (error) {
      yield put(updateNoteToPropertyError(error));
   }
}

function* handleGetNoteByProperty({ propertyId }) {
   try {
      const response = yield call(getNoteByProperty, propertyId);
      yield put(getNoteByPropertySuccess(response.data));
   } catch (error) {
      yield put(getNoteByPropertyError(error));
   }
}

function* handleDeleteNoteByProperty({ payload }) {
   try {
      yield call(deleteNoteByProperty, payload);
      yield put(deleteNoteByPropertySuccess(payload));
   } catch (error) {
      yield put(deleteNoteByPropertyError(error));
   }
}

function* handleUpdatePropertyStatus({ payload }) {
   try {
      yield call(updatePopertyStatusApi, payload);
      yield put(updatePropertyStatusSuccess(payload));
   } catch (error) {
      yield put(updatePropertyStatusError(error));
   }
}

function* handleGetColumnSelection() {
   try {
      const response = yield call(getColumnSelection);
      yield put(getColumnSelectionSuccess(response));
   } catch (e) {
      yield put(getColumnSelectionError(e));
   }
}

function* handleSaveColumnSelection(action) {
   try {
      const response = yield call(saveColumnSelection, action.payload);
      yield put(saveColumnSelectionSuccess(response));
   } catch (e) {
      yield put(saveColumnSelectionError(e));
   }
}

function* handleUpdatePropertyToArchive(action) {
   try {
      const response = yield call(updatePropertyToArchiveAPI, action.payload);
      yield put(updatePropertyToArchiveSuccess(response));
   } catch (error) {
      yield put(updatePropertyToArchiveFail(error.response.data));
   }
}

function* handleGetAllproperty() {
   try {
      const resp = yield call(GetAllProperty);
      yield put(getAllPropertySuccess(resp));
   } catch {
      yield put(getAllPropertiesError());
   }
}

function* handleMapLandlordToProperty(action) {
   try {
      const response = yield call(mapLandlordToPropertyAPI, action.payload);
      yield put(mapLandlordToPropertySuccess(response));
   } catch (error) {
      yield put(mapLandlordToPropertyError(error));
   }
}

function* handleSaveLandlordRentDetails(action) {
   try {
      const response = yield call(saveLandlordRentAPI, action.payload);
      yield put(saveLandlordRentDetailsSuccess(response));
   } catch (error) {
      yield put(saveLandlordRentDetailsError(error.response.data));
   }
}

function* handleGetUtilities(action) {
   try {
      const resp = yield call(getAllUtility, action.id);
      yield put(getUtilitySuccess(resp.data));
   } catch {
      yield put(getUtilityError());
   }
}

function* handleAddUtility(action) {
   try {
      yield call(saveUtility, action.data);
      yield put(saveUtilitySuccess());
   } catch {
      yield put(saveUtilityError());
   }
}

function* handleUpdateUtility(action) {
   try {
      yield call(updateUtility, action.data);
      yield put(updateUtilitySuccess());
   } catch {
      yield put(updateUtilityError());
   }
}

function* handleDeleteUtility(action) {
   try {
      yield call(deleteUtility, action.id);
      yield put(deleteUtilitySuccess());
   } catch {
      yield put(deleteUtilityError());
   }
}

function* handleGetPropertyDocument(action) {
   try {
      const resp = yield call(getDocumentByPropertyId, action.data);
      yield put(getPropertyDocumentSuccess(resp.data));
   } catch {
      yield put(getPropertyDocumentError());
   }
}

function* handleAddPropertyDocument(action) {
   try {
      yield call(addDocumentByPropertyId, action.data);
      yield put(addPropertyDocumentSuccess());
   } catch {
      yield put(addPropertyDocumentError());
   }
}

function* handleEditPropertyDocument(action) {
   try {
      yield call(editDocumentByPropertyId, action.data);
      yield put(updatePropertyDocumentSuccess());
   } catch {
      yield put(updatePropertyDocumentError());
   }
}

function* handleDeletePropertyDocument(action) {
   try {
      yield call(deletePropertyDocument, action.data);
      yield put(deletePropertyDocsSuccess());
      yield put(setNullPropertyDocuments());
      yield put(
         getPropertyDocuments({
            data: { propertyId: action.data.propertyId },
            pagination: { page: 0, size: 10 }
         })
      );
      yield put(getPropretyTypesById(action.data.propertyId));
   } catch {
      yield put(deletePropertyDocsError());
   }
}

function* handleGetPropertyTypesById(action) {
   try {
      const resp = yield call(getPropertyTypesById, action.id);
      yield put(getPropertyTypesByIdSuccess(resp.data));
   } catch {
      yield put(getPropertyTypesByIdError());
   }
}

function* handleDeletePropertyById(action) {
   try {
      const { properties } = yield select((state) => state.properties);
      yield call(deletePropertyById, action.payload);
      yield put(deletePropertyByIdSuccess());
      yield put(
         getAllPropertiesRequest({
            page: properties.currentPage,
            limit: properties.entriesPerPage
         })
      );
      action.history.push("/agency/properties");
   } catch (error) {
      yield put(deletePropertyByIdError());
   }
}

function* handleUnlinklandlordFromPropertyById(action) {
   try {
      const response = yield call(unlinkLandlordFromPropertyById, action.data);
      yield put(unlinklandlordFromPropertySuccess(response));
   } catch (error) {
      yield put(unlinklandlordFromPropertyError(error.response.data));
   }
}

function* handleUnlinkTenantFromPropertyById(action) {
   try {
      const response = yield call(unlinkTenantFromPropertyById, action.data);
      yield put(unlinkTenantFromPropertySuccess(response));
   } catch (error) {
      yield put(unlinkTenantFromPropertyError(error.response.data));
   }
}

function* handleGetPropertyWarning(action) {
   try {
      const resp = yield call(getPropertyWarning, action.data);
      yield put(getPropertyWarningRequestSuccess(resp));
   } catch {
      yield put(getPropertyWarningRequestError());
   }
}

export default function* watchPropertyActions() {
   yield takeEvery("GET_PROPERTIES_REQUEST", handleGetProperties);
   yield takeEvery(
      "GET_PROPERTIES_REQUEST_LAZY_LOADING",
      handleGetPropertiesLazy
   );

   yield takeEvery("GET_LICENCE_TYPES_REQUEST", handleGetLicenceTypes);
   yield takeEvery("GET_PROPERTY_TYPES_REQUEST", handleGetPropertyTypes);
   yield takeEvery("GET_FURNISH_TYPES_REQUEST", handleGetFurnishTypes);
   yield takeEvery("GET_UNIT_TYPES_REQUEST", handleGetUnitTypes);
   yield takeEvery("SAVE_PROPERTY_REQUEST", handleSaveProperty);
   yield takeEvery("GET_PROPERTY_STATUS_REQUEST", handleGetPropertyStatus);
   yield takeEvery("GET_PROPERTY_DETAILS_REQUEST", handleGetPropertyDetails);
   yield takeEvery("GET_PROPERTY_BASIC_INFO", handleGetPropertyBasicInfo);
   yield takeEvery(
      "UPDATE_PROPERTY_MULTIUNIT_REQUEST",
      handleUpdateMultiUnitProperty
   );
   yield takeEvery(
      "UPDATE_UNIT_MULTIUNIT_REQUEST",
      handleUpdateMultiUnit_Units
   );
   yield takeEvery("DELETE_PORTFOLIO_BY_ID", handleDeletePortfolioById);
   yield takeEvery("DELETE_IMAGE_BY_ID", handleDeleteImageById);
   // yield takeEvery("UPDATE_TENANT_RENT_REQUEST", handleUpdateTenantRentDetails);
   yield takeEvery("MAP_TENANT_TO_PROPERTY", handleMapTenantToProperty);
   yield takeEvery("ADD_NOTE_TO_PROPERTY", handleAddNote);
   yield takeEvery("GET_NOTE_BY_PROPERTY", handleGetNoteByProperty);
   yield takeEvery("UPDATE_NOTE_TO_PROPERTY", handleUpdateNote);
   yield takeEvery("DELETE_NOTE_BY_PROPERTY", handleDeleteNoteByProperty);
   yield takeEvery("UPDATE_PROPERTY_STATUS", handleUpdatePropertyStatus);
   yield takeEvery("GET_COLUMN_SELECTION_REQUEST", handleGetColumnSelection);
   yield takeEvery("SAVE_COLUMN_SELECTION_REQUEST", handleSaveColumnSelection);
   yield takeEvery("UPDATE_PROPERTY_TO_ARCHIVE", handleUpdatePropertyToArchive);
   yield takeEvery("GET_ALL_PROPERTY", handleGetAllproperty);

   yield takeEvery(
      "MAP_LANDLORD_TO_PROPERTY_REQUEST",
      handleMapLandlordToProperty
   );
   yield takeEvery(
      "SAVE_LANDLORD_RENT_DETAILS_REQUEST",
      handleSaveLandlordRentDetails
   );
   yield takeEvery("GET_UTILITY", handleGetUtilities);
   yield takeEvery("SAVE_UTILITY", handleAddUtility);
   yield takeEvery("UPDATE_UTILITY", handleUpdateUtility);
   yield takeEvery("DELETE_UTILITY", handleDeleteUtility);
   yield takeEvery("GET_PROPERTY_DOCUMENTS", handleGetPropertyDocument);
   yield takeEvery("ADD_PROPERTY_DOCUMENT", handleAddPropertyDocument);
   yield takeEvery("UPDATE_PROPERTY_DOCUMENT", handleEditPropertyDocument);
   yield takeEvery("DELETE_PROPERTY_DOCS", handleDeletePropertyDocument);
   yield takeEvery("GET_PROPERTY_TYPE_BY_ID", handleGetPropertyTypesById);
   yield takeEvery("DELETE_PROPERTY_BY_ID", handleDeletePropertyById);
   yield takeEvery(
      "UNLINK_LANDLORD_FROM_PROPERTY",
      handleUnlinklandlordFromPropertyById
   );
   yield takeEvery(
      "UNLINK_TENANT_FROM_PROPERTY",
      handleUnlinkTenantFromPropertyById
   );

   yield takeEvery("GET_PROPERTY_WARNING_REQUEST", handleGetPropertyWarning);
}
