import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store/store";
import Routes from "./routes/Routes";
import Loader from "./components/shared/Loader";
import "./App.css";

export default function App() {
   return (
      <Provider store={store}>
         <PersistGate loading={<Loader />} persistor={persistor}>
            <Routes />
         </PersistGate>
      </Provider>
   );
}
