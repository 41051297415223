export const getAllProductWithPrice = () => ({
   type: "GET_ALL_PRODUCT_WITH_PRICE"
});

export const getAllProductWithPriceSuccess = (response) => ({
   type: "GET_ALL_PRODUCT_WITH_PRICE_SUCCESS",
   response
});

export const getAllProductWithPriceFail = (response) => ({
   type: "GET_ALL_PRODUCT_WITH_PRICE_FAIL",
   response
});

export const getConsumerDetails = (payload) => ({
   type: "GET_CONSUMER_DETAILS",
   payload
});

export const getConsumerDetailsSuccess = (response) => ({
   type: "GET_CONSUMER_DETAILS_SUCCESS",
   response
});

export const getConsumerDetailsFail = (response) => ({
   type: "GET_CONSUMER_DETAILS_FAIL",
   response
});

export const openCustomerPortal = (payload) => ({
   type: "OPEN_CUSTOMER_PORTAL",
   payload
});

export const openCustomerPortalSuccess = (response) => ({
   type: "OPEN_CUSTOMER_PORTAL_SUCCESS",
   response
});

export const openCustomerPortalFail = (response) => ({
   type: "OPEN_CUSTOMER_PORTAL_FAIL",
   response
});

export const openPaymentPortal = (payload) => ({
   type: "OPEN_PAYMENT_PORTAL",
   payload
});

export const openPaymentPortalSuccess = (response) => ({
   type: "OPEN_PAYMENT_PORTAL_SUCCESS",
   response
});

export const openPaymentPortalFail = (response) => ({
   type: "OPEN_PAYMENT_PORTAL_FAIL",
   response
});

export const getPaymentMethod = (payload) => ({
   type: "GET_PAYMENT_METHOD",
   payload
});

export const getPaymentMethodSuccess = (payload) => ({
   type: "GET_PAYMENT_METHOD_SUCCESS",
   payload
});

export const getPaymentMethodError = (error) => ({
   type: "GET_PAYMENT_METHOD_ERROR",
   error
});

export const getInvoice = (payload) => ({
   type: "GET_INVOICE",
   payload
});

export const getInvoiceSuccess = (payload) => ({
   type: "GET_INVOICE_SUCCESS",
   payload
});

export const getInvoiceError = (error) => ({
   type: "GET_INVOICE_ERROR",
   error
});

export const getReceipt = (payload) => ({
   type: "GET_RECEIPT",
   payload
});

export const getReceiptSuccess = (payload) => ({
   type: "GET_RECEIPT_SUCCESS",
   payload
});

export const getReceiptError = (error) => ({
   type: "GET_RECEIPT_ERROR",
   error
});

export const addPaymentMethod = (payload) => ({
   type: "ADD_PAYMENT_METHOD",
   payload
});

export const addPaymentMethodSuccess = (payload) => ({
   type: "ADD_PAYMENT_METHOD_SUCCESS",
   payload
});

export const addPaymentMethodError = (error) => ({
   type: "ADD_PAYMENT_METHOD_ERROR",
   error
});

export const addCustomerSubscription = (payload) => ({
   type: "ADD_CUSTOMER_SUBSCRIPTION",
   payload
});

export const addCustomerSubscriptionSuccess = (payload) => ({
   type: "ADD_CUSTOMER_SUBSCRIPTION_SUCCESS",
   payload
});

export const addCustomerSubscriptionSetSuccessFalse = (payload) => ({
   type: "ADD_CUSTOMER_SUBSCRIPTION_SET_SUCCESS_FALSE",
   payload
});

export const addCustomerSubscriptionError = (error) => ({
   type: "ADD_CUSTOMER_SUBSCRIPTION_ERROR",
   error
});

export const deletePaymentMethod = (payload) => ({
   type: "DELETE_PAYMENT_METHOD",
   payload
});

export const deletePaymentMethodSuccess = (payload) => ({
   type: "DELETE_PAYMENT_METHOD_SUCCESS",
   payload
});

export const deletePaymentMethodError = (error) => ({
   type: "DELETE_PAYMENT_METHOD_ERROR",
   error
});

export const updateCustomerSubscription = (payload) => ({
   type: "UPDATE_CUSTOMER_SUBSCRIPTION",
   payload
});

export const updateCustomerSubscriptionSuccess = (payload) => ({
   type: "UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS",
   payload
});

export const updateCustomerSubscriptionError = (error) => ({
   type: "UPDATE_CUSTOMER_SUBSCRIPTION_ERROR",
   error
});

export const updateBillingAddress = (payload) => ({
   type: "UPDATE_BILLING_ADDRESS",
   payload
});

export const updateBillingAddressSuccess = (payload) => ({
   type: "UPDATE_BILLING_ADDRESS_SUCCESS",
   payload
});

export const updateBillingAddressError = (error) => ({
   type: "UPDATE_BILLING_ADDRESS_ERROR",
   error
});

export const cancelCustomerSubscription = (payload) => ({
   type: "CANCEL_CUSTOMER_SUBSCRIPTION",
   payload
});

export const cancelCustomerSubscriptionSuccess = (payload) => ({
   type: "CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS",
   payload
});

export const cancelCustomerSubscriptionSetSuccessFalse = (payload) => ({
   type: "CANCEL_CUSTOMER_SUBSCRIPTION_SET_SUCCESS_FALSE",
   payload
});

export const cancelCustomerSubscriptionError = (error) => ({
   type: "CANCEL_CUSTOMER_SUBSCRIPTION_ERROR",
   error
});

export const getSubscriptionHistory = (payload) => ({
   type: "GET_SUBSCRIPTION_HISTORY",
   payload
});

export const getSubscriptionHistorySuccess = (payload) => ({
   type: "GET_SUBSCRIPTION_HISTORY_SUCCESS",
   payload
});

export const getSubscriptionHistoryError = (error) => ({
   type: "GET_SUBSCRIPTION_HISTORY_ERROR",
   error
});
