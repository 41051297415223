import API from "./API";
import jwt_decode from "jwt-decode";

export const getDocumentByCatagory = (catagory) => {
   return API.get("/api/documents/getAllDocumentTypesByCategory/" + catagory);
};

export const addDocumentType = (data) => {
   const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
   data.agencyId = agencyId;
   return API.post("/api/settings/addDocumentType", data);
};

export const getUploadedDocTypes = (category) => {
   return API.get("/api/settings/getDocumentTypesByCategory/" + category);
};

export const getInvoiceSettings = async () => {
   return await API.get("/api/settings/getInvoiceSettingsByAgencyId");
};

export const saveInvoiceSettings = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.delete("taxDetails");
   formData.delete("invoiceTemplate");

   if (data.taxDetails) {
      data.taxDetails.map(({ name, number, taxPercentage }, index) => {
         formData.append(`taxDetails[${index}].name`, name);
         formData.append(`taxDetails[${index}].number`, number);
         formData.append(`taxDetails[${index}].taxPercentage`, taxPercentage);
      });
   }

   if (data.invoiceTemplate) {
      Object.keys(data.invoiceTemplate).map((key) => {
         formData.append(`invoiceTemplate.${key}`, data.invoiceTemplate[key]);
      });
   }

   return await API.post("/api/settings/saveInvoiceSettings", formData);
};
