import API from "./API";
import jwt_decode from "jwt-decode";

export const getStaffs = async (payload) => {
   let body = {};
   payload.status && (body.status = payload.status);
   payload.designation && (body.desig = payload.designation);
   payload.sortBy && (body.sortBy = payload.sortBy);
   payload.orderBy && (body.orderBy = payload.orderBy);
   payload.searchBy && (body.searchBy = payload.searchBy);
   return API.get(
      `/api/staff/getAllStaffV1/${payload.page - 1}/${payload.limit}`,
      {
         params: body
      }
   );
};

export const getStaffsImage = async (staffId) => {
   return API.get("/api/staff/getStaffImagesByStaffId", {
      params: {
         staffId
      }
   });
};

export const addStaffs = async (staff) => {
   const { agencyId } = jwt_decode(localStorage.getItem("access_token"));

   let formData = new FormData();
   Object.keys(staff).map((key) => formData.append(key, staff[key]));
   //data.map()
   formData.delete("dateOfBirth");
   formData.append("dateOfBirth", staff.dateOfBirth?.format("YYYY-MM-DD"));
   formData.delete("contacts");
   staff.contacts.slice().map((array, index) => {
      formData.append(`contacts[${index}].id`, index);
      formData.append(`contacts[${index}].number`, array.number);
      formData.append(`contacts[${index}].label`, array.label);
   });
   formData.delete("email");
   formData.append("email.email", staff.email && staff.email.email);
   formData.append("email.label", "Personal");
   formData.delete("agencyId");
   formData.append("agencyId", agencyId);

   return await API.post("/api/staff/addStaff", formData, {
      params: {
         agencyId
      }
   });
};

export const addStaffsRandompassword = async (staffId) => {
   return await API.post("/api/staff/addStaffRandomPassword", null, {
      params: {
         staffId
      }
   });
};

export const updateStaffs = async (data) => {
   const tid = data.id;

   let formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.delete("dateOfBirth");
   formData.delete("joinDate");
   formData.append("dateOfBirth", data.dateOfBirth?.format("YYYY-MM-DD"));
   formData.append("joinDate", data.joinDate?.format("YYYY-MM-DD"));
   formData.delete("contacts");
   formData.delete("email");
   formData.delete("leftOn");

   if (data.leftOn) {
      formData.append(
         "leftOn",
         data.leftOn ? data.leftOn.format("YYYY-MM-DD") : null
      );
   }

   data.contacts.slice().map((array, index) => {
      formData.append(`contacts[${index}].number`, array.contactNumber);
      formData.append(`contacts[${index}].label`, array.label);
   });
   data.email.slice().map((array, index) => {
      formData.append(`emails[${index}].email`, array.email);
      formData.append(`emails[${index}].label`, array.label);
   });

   if (data.fromProfileSetup) {
      formData.append("isProfileAvailableInKadsWebsite", "true");
      formData.delete("registeredEmail");
      formData.delete("designation");
      formData.delete("pemail");
      formData.delete("pcontact");
      formData.delete("oemail");
      formData.delete("ocontact");
      formData.delete("fromProfileSetup");
      formData.delete("ppic");
      formData.delete("joinDate");
   }

   return await API.put("/api/staff/updateStaff", formData, {
      params: {
         id: tid
      }
   });
};

export const getStaffbyid = async (id) => {
   return await API.get("/api/staff/getStaffById", {
      params: {
         id
      }
   });
};

export const deleteStaffs = async (staffId) => {
   return await API.delete("api/staff/deleteStaffById", {
      params: {
         staffId
      }
   });
};
export const getStaffsHistory = async ({ page, limit, ...restParams }) => {
   return await API.get(
      `/api/staff/getStaffHistoryByStaffId/${page}/${limit}`,
      {
         params: restParams
      }
   );
};

export const updateStaffAccess = async (staffId) => {
   return await API.put("/api/staff/allowDenyStaffAccess", null, {
      params: {
         id: staffId && staffId.id,
         status: staffId && staffId.status
      }
   });
};

export const getAllStaffsRoleAPI = async () => {
   return API.get("/api/staff/getAllStaffRoles", null);
};

export const StaffGeneralSystemSetup = async (data) => {
   return await API.put("api/staff/staffGeneralSystemSetup", data);
};

export const GetAllStaffs = async (payload) => {
   return API.get("/api/staff/getAllStaff", {
      params: payload
   });
};

export const getStaffbyDesignation = async (designationId) => {
   return await API.get("/api/staff/getStaffForProperties", {
      params: {
         designationId
      }
   });
};

export const getStaffbyDesignationForInspection = async () => {
   return await API.get("/api/staff/getStaffForProperties", {
      data: null
   });
};

export const getLoggedStaffDetails = () => {
   return API.get("/api/staff/getLoggedStaffDetails");
};
