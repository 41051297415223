import produce from "immer";

const INITIAL_STATE = {
   countries: [],
   allCountries: [],
   countriesFromDB: [],
   userDetails: null,
   firebaseToken: null,
   createModal: false,
   modalTitle: "",
   invoiceId: null,
   modalChoose: "",
   ModuleName: "",
   role: "",
   serviceProfessional: [],
   percentage: 0,
   userConstrains: {
      loading: false,
      success: false,
      response: {},
      error: null
   },
   exsistingCount: {
      loading: false,
      success: false,
      response: null,
      error: null
   },
   logout: {
      loading: false,
      loggedOut: false
   },
   requestInfoDetails: {},
   serviceWorkerUpdated: false
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_COUNTRY_LIST_REQUEST":
            break;
         case "GET_COUNTRY_LIST_SUCCESS":
            draft.countries = action.response.data.response;
            break;
         case "GET_COUNTRY_LIST_ERROR":
            draft.countries = [];
            break;
         case "GET_ALL_COUNTRY_REQUEST":
            break;
         case "GET_ALL_COUNTRY_SUCCESS":
            draft.allCountries = action.payload;
            break;
         case "GET_ALL_COUNTRY_ERROR":
            draft.allCountries = [];
            break;
         case "GET_COUNTRIES":
            break;
         case "GET_COUNTRIES_SUCCESS":
            draft.countriesFromDB = action.payload.data;
            break;
         case "GET_COUNTRIES_ERROR":
            draft.countriesFromDB = [];
            break;
         case "GET_USER_DETAILS_SUCCESS":
            draft.userDetails = action.payload.response;
            break;
         case "SET_TOKEN":
            draft.firebaseToken = action.token;
            break;
         case "OPEN_CREATE_MODAL":
            draft.createModal = true;
            document.body.className = "hidden-scroll";
            draft.modalTitle = action.payload.title;
            draft.invoiceId = action.payload.invoiceId;
            draft.modalChoose = action.payload.module;
            draft.ModuleName = action.payload.ModuleName;
            break;
         case "CLOSE_CREATE_MODAL":
            document.body.style = "";
            draft.createModal = false;
            document.body.className = "show-scroll";
            break;
         case "ADD_SERVICE_PROFESSIONAL":
            draft.serviceProfessional.push(action.payload.full_name);
            break;
         case "SET_PROGRESS_LOADING":
            draft.percentage = action.percentage;
            break;
         case "GET_USER_CONSTRAINS":
            draft.userConstrains.loading = true;
            break;

         case "GET_USER_CONSTRAINS_SUCCESS":
            draft.userConstrains.response = action.payload.response;
            draft.userConstrains.loading = false;
            draft.userConstrains.success = true;
            break;
         case "GET_USER_CONSTRAINS_ERROR":
            draft.userConstrains.loading = false;
            draft.userConstrains.error = action.payload;
            draft.userConstrains.success = false;
            break;

         case "GET_USER_EXSISTING_COUNT":
            draft.exsistingCount.loading = true;
            break;

         case "GET_USER_EXSISTING_COUNT_SUCCESS":
            draft.exsistingCount.response = action.payload.response;
            draft.exsistingCount.loading = false;
            draft.exsistingCount.success = true;
            break;

         case "SET_USER_EXSISTING_COUNT_SUCCESS":
            draft.exsistingCount.response.currentUnitCount =
               action.payload.response;

            break;
         case "GET_USER_EXSISTING_COUNT_ERROR":
            draft.exsistingCount.loading = false;
            draft.exsistingCount.error = action.payload;
            draft.exsistingCount.success = false;
            break;
         case "SET_CURRENT_DATA_USAGE":
            draft.exsistingCount.response["currentDataUsageInBytes"] +=
               action.payload;
            break;
         case "REDUCE_CURRENT_DATA_USAGE":
            draft.exsistingCount.response["currentDataUsageInBytes"] -=
               action.payload;
            break;
         case "SET_LOGOUT_LOADING":
            draft.logout.loading = true;
            break;
         case "SET_LOGGED_OUT":
            draft.logout.loggedOut = true;
            break;
         case "SET_LOGGED_IN":
            draft.logout.loggedOut = false;
            break;
         case "SET_REQUEST_INFO_MODAL_DETAILS":
            draft.requestInfoDetails = action.payload;
            break;
         case "UPDATE_SERVICEWORKER":
            draft.serviceWorkerUpdated = true;
            break;
         default:
            return draft;
      }
   });
