import API from "./API";

export const getAllRentPayableInvoiceAgainstPropertyAPI = async ({
   propertyId,
   pageNumber,
   pageSize,
   module,
   invoiceStatus,
   invoiceType,
   serviceType
}) => {
   return await API.get(
      `/api/invoice/getInvoicesAgainstProperty/${pageNumber}/${pageSize}?propertyId=${propertyId}&module=${module}&invoiceStatus=${invoiceStatus}&isIndividual=${invoiceType}&invoiceServiceType=${serviceType}`,
      { data: {} }
   );
};

export const getAllRentPayableInvoiceAgainstTenantAPI = async ({
   tenantId,
   pageNumber,
   pageSize,
   module,
   invoiceStatus,
   invoiceType,
   serviceType
}) => {
   return await API.get(
      `/api/invoice/getInvoicesAgainstTenant/${pageNumber}/${pageSize}?tenantId=${tenantId}&module=${module}&invoiceStatus=${invoiceStatus}&isIndividual=${invoiceType}&invoiceServiceType=${serviceType}`,
      { data: {} }
   );
};

export const getAllRentPayableInvoiceAgainstLandlordAPI = async ({
   landlordId,
   pageNumber,
   pageSize,
   module,
   invoiceStatus,
   invoiceType,
   serviceType
}) => {
   return await API.get(
      `/api/invoice/getInvoicesAgainstLandlord/${pageNumber}/${pageSize}?landlordId=${landlordId}&module=${module}&invoiceStatus=${invoiceStatus}&isIndividual=${invoiceType}&invoiceServiceType=${serviceType}`,
      { data: {} }
   );
};

export const getAllAccountReceivableInvoiceAPI = async ({
   invoiceType,
   module,
   searchBy,
   invoiceStatus,
   pageNumber,
   pageSize,
   sortBy,
   orderBy,
   isDraft,
   endDate,
   startDate,
   genType,
   invoiceCategory,
   serviceType,
   isExport
}) => {
   return await API.get(
      `/api/invoice/getInvoicesByAgency/${pageNumber}/${pageSize}?invoiceType=${invoiceType}&module=${module}&searchBy=${searchBy}&invoiceStatus=${invoiceStatus}&orderBy=${orderBy}&sortBy=${sortBy}&isDraft=${isDraft}&startDate=${startDate}&endDate=${endDate}&genType=${genType}&isIndividual=${invoiceCategory}&invoiceServiceType=${serviceType}&isExport=${isExport}`,
      { data: {} }
   );
};

export const getInvoiceDetailsAPI = async ({ invoiceId }) => {
   return await API.get(
      `/api/invoice/getInvoiceDetails?invoiceId=${invoiceId}`,
      { data: {} }
   );
};

export const getItemAddTypeAPI = async () => {
   return await API.get(`/api/invoice/getItemAddTypes`, { data: {} });
};

export const updateInvoiceDetailsAPI = async (payload) => {
   return await API.post(`/api/invoice/editInvoice`, payload);
};

export const addReceiptDetailsAPI = async (payload) => {
   let formData = new FormData();
   Object.keys(payload).map((key) => formData.append(key, payload[key]));
   Object.keys(payload).map((key) => {
      if (payload[key] == undefined || payload[key] == null) {
         formData.delete(key);
      }
   });
   return await API.post(`/api/invoice/addReceipt`, formData);
};

export const deleteReceiptById = async ({ invoiceReceiptId, invoiceId }) => {
   return await API.delete(
      `/api/invoice/deleteInvoiceReceipt?invoiceId=${invoiceId}&invoiceReceiptId=${invoiceReceiptId}`,
      {
         data: {}
      }
   );
};

export const deleteInvoiceById = async ({ invoiceId }) => {
   return await API.delete(
      `/api/invoice/deleteInvoice?invoiceId=${invoiceId}`,
      {
         data: {}
      }
   );
};

export const changeInvoiceToCancelAPI = async ({ invoiceId, flag }) => {
   return await API.post(
      `/api/invoice/markInvoiceCancel?invoiceId=${invoiceId}&flag=${flag}`,
      {
         data: {}
      }
   );
};

export const addInvoiceDetailsAPI = async (payload) => {
   return await API.post(`/api/invoice/addServiceInvoice`, payload);
};

//NOTES

export const addNoteToInvoice = async (data) => {
   const { title, description, invoiceId, createdBy } = data;
   return await API.post("/api/notes/saveNote", {
      title,
      description,
      invoiceId,
      createdBy,
      ownerType: "I"
   });
};

export const updateNoteToInvoice = async (data) => {
   const { id, title, description, invoiceId, updatedBy } = data;
   return await API.post("/api/notes/saveNote", {
      id,
      title,
      description,
      invoiceId,
      updatedBy,
      ownerType: "I"
   });
};

export const getNoteByInvoice = async ({ invoiceId }) => {
   return await API.get("/api/notes/getNoteByInvoiceId", {
      params: {
         invoiceId
      }
   });
};

export const deleteNoteByInvoice = async ({ id, invoiceId }) => {
   return await API.delete("/api/notes/deleteNote?by=I", {
      data: {
         id,
         invoiceId
      }
   });
};

//comments

// comment

export const GetCommentByTaskId = async (id) => {
   return API.get(`/api/comments/by-invoice/${id}`);
};

export const SaveComment = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   return API.post("/api/comments/invoice/save", formData);
};

export const deleteCommentByid = async (id) => {
   return API.delete(`/api/comments/delete/${id}`);
};

export const saveReply = (data) => {
   return API.post("/api/replies/save", data);
};

export const saveAsReadStatus = (data) => {
   const { id, comment } = data;
   return API.post(
      `/api/${comment ? "comments" : "replies"}/read-status/save/${id}`,
      {}
   );
};

export const deleteReply = (id) => {
   return API.delete(`/api/replies/delete/${id}`);
};

export const saveEmoij = (payload) => {
   const { data, reply } = payload;
   return API.post(`/api/reactions${reply ? "/reply/save" : "/save"}`, data);
};

export const getBase64ForComment = (url) => {
   return API.get("/api/comments/attachment/base64", {
      params: {
         url
      }
   });
};

//serviceType
export const GetAllServiceType = async ({ pageNumber, pageSize }) => {
   return API.get(
      `/api/invoice/getInvoiceServiceType/${pageNumber}/${pageSize}`
   );
};

export const addServiceTypeAPI = async (payload) => {
   return await API.post(`/api/invoice/addUpdateInvoiceServiceType`, payload);
};

// invoice PDF download
export const downloadInvoicePDF = (data) => {
   return API.get("/api/invoice/download", {
      params: data,
      responseType: "blob",
      headers: {
         Accept: "*"
      }
   });
};

export const sendInvoice = (invoiceId) => {
   return API.get("/api/invoice/sendTestInvoice", { params: { invoiceId } });
};
