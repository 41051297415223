import API from "./API";

export const getDropdownRentfrequency = async () => {
   return await API.get("api/tenant/getRentFrequency");
};

export const getDropdownRegistraionType = async () => {
   return await API.get("api/tenant/getRegistrationType");
};

export const getDropdownPaymentMethod = async () => {
   return await API.get("api/tenant/getPaymentMethod");
};
