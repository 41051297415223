const getTenants = (payload) => ({
   type: "GET_TENANTS",
   payload
});

const getTenantsSuccess = (response) => ({
   type: "GET_TENANTS_SUCCESS",
   response
});

const getTenantsError = (payload) => ({
   type: "GET_TENANTS_ERROR",
   payload
});

export const getTenantsLazy = (payload) => ({
   type: "GET_TENANTS_LAZY",
   payload
});

export const getTenantsLazySetNull = () => ({
   type: "GET_TENANTS_LAZY_SET_NULL"
});

export const getTenantsLazySuccess = (response) => ({
   type: "GET_TENANTS_LAZY_SUCCESS",
   response
});

export const getTenantsLazyError = (payload) => ({
   type: "GET_TENANTS_LAZY_ERROR",
   payload
});

const createTenant = (payload) => ({
   type: "ADD_TENANT",
   payload
});

const createTenantSuccess = (payload) => ({
   type: "ADD_TENANT_SUCCESS",
   payload
});

const createTenantError = () => ({
   type: "ADD_TENANT_ERROR"
});

const getTenantByEmail = (payload) => ({
   type: "GET_TENANT_BY_EMAIL_REQUEST",
   payload
});

const getTenantByEmailSuccess = (payload) => ({
   type: "GET_TENANT_BY_EMAIL_SUCCESS",
   payload
});

const getTenantByEmailError = (payload) => ({
   type: "GET_TENANT_BY_EMAIL_ERROR",
   payload
});

const getTenantDetailsById = (payload) => ({
   type: "GET_TENANT_BY_ID",
   payload
});

const getTenanatDetailsByIdSuccess = (payload) => ({
   type: "GET_TENANT_BY_ID_SUCCESS",
   payload
});

const getTenantDetailsByIdError = () => ({
   type: "GET_TENANT_BY_ID_ERROR"
});

const deleteTenantByTenantId = (payload, history) => ({
   type: "DELETE_TENANT_BY_ID",
   payload,
   history
});

const deleteTenantByTenantIdSuccess = (payload) => ({
   type: "DELETE_TENANT_BY_ID_SUCCESS",
   payload
});

const deleteTenantByTenantIdError = () => ({
   type: "DELETE_TENANT_BY_ID_ERROR"
});

const updateTenantById = (payload) => ({
   type: "UPDATE_TENANT_BY_ID",
   payload
});

const updateTenantByIdSuccess = () => ({
   type: "UPDATE_TENANT_BY_ID_SUCCESS"
});

const updateTenantByIdError = () => ({
   type: "UPDATE_TENANT_BY_ID_ERROR"
});

const deleteBankDetails = (payload) => ({
   type: "DELETE_TENANT_BANKDETAILS_REQUEST",
   payload
});

const deleteBankDetailsSuccess = () => ({
   type: "DELETE_TENANT_BANKDETAILS_SUCCESS"
});

const deleteBankDetailsError = () => ({
   type: "DELETE_TENANT_BANKDETAILS_ERROR"
});

const updateBankDetails = (payload) => ({
   type: "UPDATE_TENANT_BANKDETAILS",
   payload
});

const updateBankDetailsSuccess = () => ({
   type: "UPDATE_TENANT_BANKDETAILS_SUCCESS"
});

const updateBankDetailsError = () => ({
   type: "UPDATE_TENANT_BANKDETAILS_ERROR"
});

const addEmergencyDetails = (payload) => ({
   type: "ADD_EMERGENCY_DETAILS",
   payload
});

const addEmergencyDetailSuccess = () => ({
   type: "ADD_EMERGENCY_DETAILS_SUCCESS"
});

const addEmergencyDetailsError = () => ({
   type: "ADD_EMERGENCY_DETAILS_ERROR"
});

const addBank = (payload) => ({
   type: "ADD_BANK",
   payload
});

const addBankSuccess = () => ({
   type: "ADD_BANK_SUCCESS"
});

const addBankError = () => ({
   type: "ADD_BANK_ERROR"
});

const resetTenantByEmail = () => ({
   type: "RESET_TENANT_DETAILS"
});

const getPropertiesDetailsByTenantId = (payload) => ({
   type: "GET_PROPERTIES_BY_TENANT_ID",
   payload
});

const getPropertiesDetailsByTenantIdSuccess = (payload) => ({
   type: "GET_PROPERTIES_BY_TENANT_ID_SUCCESS",
   payload
});

const getPropertiesDetailsByTenantIdError = () => ({
   type: "GET_PROPERTIES_BY_TENANT_ID_ERROR"
});

const setPage = (payload) => ({
   type: "SET_PAGE",
   payload
});

const addContract = (payload) => ({
   type: "ADD_CONTRACT",
   payload
});

const addContractSuccess = () => ({
   type: "ADD_CONTRACT_SUCCESS"
});

const addContractError = () => ({
   type: "ADD_CONTRACT_ERROR"
});

const editContract = (payload) => ({
   type: "EDIT_CONTRACT",
   payload
});

const editContractSuccess = () => ({
   type: "EDIT_CONTRACT_SUCCESS"
});

const editContractError = () => ({
   type: "EDIT_CONTRACT_ERROR"
});

const getContractById = (payload) => ({
   type: "GET_CONTRACT_BY_ID",
   payload
});

const getContractByIdSuccess = (payload) => ({
   type: "GET_CONTRACT_BY_ID_SUCCESS",
   payload
});

const getContractByIdError = () => ({
   type: "GET_CONTRACT_BY_ID_ERROR"
});

const getDocumentsById = (tenantId) => ({
   type: "GET_DOCUMENTS_BY_ID",
   tenantId
});

const getDocumentsByIdSuccess = (payload) => ({
   type: "GET_DOCUMENTS_BY_ID_SUCCESS",
   payload
});

const getDocumentsByError = () => ({
   type: "GET_DOCUMENT_BY_ID_ERROR"
});

const deleteContract = (payload) => ({
   type: "DELETE_CONTRACT_BY_ID",
   payload
});

const deleteContractSuccess = () => ({
   type: "DELETE_CONTRACT_BY_ID_SUCCESS"
});

const deleteContractError = () => ({
   type: "DELETE_CONTRACT_BY_ID_ERROR"
});

const addNoteToTenant = (payload) => ({
   type: "ADD_NOTE_TO_TENANT",
   payload
});

const addNoteToTenantSuccess = (payload) => ({
   type: "ADD_NOTE_TO_TENANT_SUCCESS",
   payload
});

const addNoteToTenantError = (error) => ({
   type: "ADD_NOTE_TO_TENANT_ERROR",
   error
});

const updateNoteToTenant = (payload) => ({
   type: "UPDATE_NOTE_TO_TENANT",
   payload
});

const updateNoteToTenantError = (error) => ({
   type: "UPDATE_NOTE_TO_TENANT_ERROR",
   error
});

const updateNoteToTenantSuccess = (payload) => ({
   type: "UPDATE_NOTE_TO_TENANT_SUCCESS",
   payload
});

const getNoteByTenant = (tenantId) => ({
   type: "GET_NOTE_BY_TENANT",
   tenantId
});

const getNoteByTenantSuccess = (payload) => ({
   type: "GET_NOTE_BY_TENANT_SUCCESS",
   payload
});

const getNoteByTenantError = (error) => ({
   type: "GET_NOTE_BY_TENANT_ERROR",
   error
});

const deleteNoteByTenant = (payload) => ({
   type: "DELETE_NOTE_BY_TENANT",
   payload
});

const deleteNoteByTenantSuccess = (response, payload) => ({
   type: "DELETE_NOTE_BY_TENANT_SUCCESS",
   response,
   payload
});

const deleteNoteByTenantError = (error) => ({
   type: "DELETE_NOTE_BY_TENANT_ERROR",
   error
});
const addDocument = (payload) => ({
   type: "ADD_DOCUMENT",
   payload
});

const addDocumentSuccess = () => ({
   type: "ADD_DOCUMENT_SUCCESS"
});

const addDocumentError = () => ({
   type: "ADD_DOCUMENT_ERROR"
});

const openAddNewTenantModalFunction = (payload) => ({
   type: "OPEN_ADD_NEW_TENANT_MODAL",
   payload
});

const deleteImgbyid = (payload) => ({
   type: "DELETE_IMG_BY_ID",
   payload
});

const deleteImgbyIdSuccess = () => ({
   type: "DELETE_IMG_BY_ID_SUCCESS"
});

const deleteImgByIdError = () => ({
   type: "DELETE_IMG_BY_ID_ERROR"
});

const resetTenantFilter = () => ({
   type: "RESET_TENANT_FILTERS"
});

const updateTenantToArchive = (payload) => ({
   type: "UPDATE_TENANT_TO_ARCHIVE",
   payload
});

const updateTenantToArchiveSuccess = (response) => ({
   type: "UPDATE_TENANT_TO_ARCHIVE_SUCCESS",
   response
});

const updateTenantToArchiveFail = (error) => ({
   type: "UPDATE_TENANT_TO_ARCHIVE_FAIL",
   error
});

const getEmergencyDetailsForTenant = (id) => ({
   type: "GET_EMERGENCY_DETAILS_FOR_TENANT",
   id
});

const getEmergencyDetailsForTenantSuccess = (payload) => ({
   type: "GET_EMERGENCY_DETAILS_FOR_TENANT_SUCCESS",
   payload
});

const getEmergencyDetailsForTenantError = () => ({
   type: "GET_EMERGENCY_DETAILS_FOR_TENANT_ERROR"
});

const getTenantsPropertyBasicInfo = (payload) => ({
   type: "GET_TENANTS_PROPERTY_BASIC_INFO",
   payload
});

const getTenantsPropertyBasicInfoSuccess = (response) => ({
   type: "GET_TENANTS__PROPERTY_BASIC_INFO_SUCCESS",
   response
});

const getTenantsPropertyBasicInfoError = (payload) => ({
   type: "GET_TENANTS__PROPERTY_BASIC_INFO_ERROR",
   payload
});

// const updateTenantDocument = (payload) => ({
//    type: "UPDATE_TENANT_DOCUMENT",
//    payload
// });

// const updateTenantDocumentSuccess = () => ({
//    type: "UPDATE_TENANT_DOCUMENT_SUCCESS"
// });

// const updateTenantDocumentError = () => ({
//    type: "UPDATE_TENANT_DOCUMENT_ERROR"
// });

const deleteTenantSingleDoc = (payload) => ({
   type: "DELETE_TENANT_SINGLE_ID",
   payload
});

const deleteTenantSingleDocSuccess = () => ({
   type: "DELETE_TENANT_SINGLE_DOC_SUCCESS"
});

const deleteTenantSingleDocError = () => ({
   type: "DELETE_TENANT_SINGLE_DOC_ERROR"
});

const deleteTenantDoc = (payload) => ({
   type: "DELETE_TENANT_DOC",
   payload
});

const deleteTenantDocSuccess = () => ({
   type: "DELETE_TENANT_DOC_SUCCESS"
});

const deleteTenantDocError = () => ({
   type: "DELETE_TENANT_DOC_ERROR"
});

const getDropdownValueForTenant = (id) => ({
   type: "GET_DROPDOWN_VALUE_FOR_TENANT",
   id
});

const getDropdownValueForTenantSuccess = (payload) => ({
   type: "GET_DROPDOWN_VALUE_FOR_TENANT_SUCCESS",
   payload
});

const getDropdownValueForTenantError = () => ({
   type: "GET_DROPDOWN_VALUE_FOR_TENANT_ERROR"
});

const editTenantDocument = (payload) => ({
   type: "EDIT_TENANT_DOCUMENT",
   payload
});

const editTenantDocumentSuccess = () => ({
   type: "EDIT_TENANT_DOCUMENT_SUCCESS"
});

const editTenantDocumentError = () => ({
   type: "EDIT_TENANT_DOCUMENT_ERROR"
});

const setTenantContractNull = () => ({
   type: "SET_TENANT_CONTRACT_NULL"
});

const setTenantDocumentNull = () => ({
   type: "SET_TENANT_DOCUMENT_NULL"
});

export const changeTenantLoadedState = (payload) => ({
   type: "CHANGE_TENANT_LOADED_STATE",
   payload
});

export const addTenantAdditionalInfo = (payload, history) => ({
   type: "ADD_TENANT_ADDITIONAL_INFO",
   payload,
   history
});

export const addTenantAdditionalInfoSuccess = () => ({
   type: "ADD_TENANT_ADDITIONAL_INFO_SUCCESS"
});

export const addTenantAdditionalInfoError = (payload) => ({
   type: "ADD_TENANT_ADDITIONAL_INFO_ERROR",
   payload
});

export {
   getTenants,
   getTenantsSuccess,
   getTenantsError,
   createTenant,
   createTenantSuccess,
   createTenantError,
   getTenantByEmail,
   getTenantByEmailSuccess,
   getTenantByEmailError,
   getTenantDetailsById,
   getTenanatDetailsByIdSuccess,
   getTenantDetailsByIdError,
   deleteTenantByTenantId,
   deleteTenantByTenantIdSuccess,
   deleteTenantByTenantIdError,
   updateTenantById,
   updateTenantByIdError,
   updateTenantByIdSuccess,
   deleteBankDetails,
   deleteBankDetailsSuccess,
   deleteBankDetailsError,
   updateBankDetails,
   updateBankDetailsSuccess,
   updateBankDetailsError,
   addEmergencyDetailSuccess,
   addEmergencyDetails,
   addEmergencyDetailsError,
   addBank,
   addBankSuccess,
   addBankError,
   resetTenantByEmail,
   getPropertiesDetailsByTenantId,
   getPropertiesDetailsByTenantIdSuccess,
   getPropertiesDetailsByTenantIdError,
   setPage,
   addContract,
   addContractError,
   addContractSuccess,
   editContract,
   editContractError,
   editContractSuccess,
   getContractById,
   getContractByIdSuccess,
   getContractByIdError,
   getDocumentsById,
   getDocumentsByIdSuccess,
   getDocumentsByError,
   addNoteToTenant,
   addNoteToTenantSuccess,
   addNoteToTenantError,
   getNoteByTenant,
   getNoteByTenantSuccess,
   getNoteByTenantError,
   updateNoteToTenant,
   updateNoteToTenantSuccess,
   updateNoteToTenantError,
   deleteNoteByTenant,
   deleteNoteByTenantSuccess,
   deleteNoteByTenantError,
   addDocument,
   addDocumentSuccess,
   addDocumentError,
   openAddNewTenantModalFunction,
   deleteContract,
   deleteContractSuccess,
   deleteContractError,
   deleteImgByIdError,
   deleteImgbyIdSuccess,
   deleteImgbyid,
   resetTenantFilter,
   updateTenantToArchive,
   updateTenantToArchiveFail,
   updateTenantToArchiveSuccess,
   getEmergencyDetailsForTenant,
   getEmergencyDetailsForTenantSuccess,
   getEmergencyDetailsForTenantError,
   getTenantsPropertyBasicInfo,
   getTenantsPropertyBasicInfoSuccess,
   getTenantsPropertyBasicInfoError,
   deleteTenantSingleDoc,
   deleteTenantSingleDocSuccess,
   deleteTenantSingleDocError,
   deleteTenantDoc,
   deleteTenantDocSuccess,
   deleteTenantDocError,
   getDropdownValueForTenant,
   getDropdownValueForTenantError,
   getDropdownValueForTenantSuccess,
   editTenantDocument,
   editTenantDocumentSuccess,
   editTenantDocumentError,
   setTenantContractNull,
   setTenantDocumentNull
};
