const signinRequest = (payload) => ({
   type: "SIGNIN_REQUEST",
   payload
});

const signinSuccess = (response) => ({
   type: "SIGNIN_SUCCESS",
   response
});

const signinError = (error) => ({
   type: "SIGNIN_ERROR",
   error
});

const signupRequest = (payload) => ({
   type: "SIGNUP_REQUEST",
   payload
});

const signupSuccess = (response) => ({
   type: "SIGNUP_SUCCESS",
   response
});

const signupError = (error) => ({
   type: "SIGNUP_ERROR",
   error
});

const signoutRequest = () => ({
   type: "SIGNOUT_REQUEST"
});

const signoutSuccess = (response) => ({
   type: "SIGNOUT_SUCCESS",
   response
});

const signoutError = (error) => ({
   type: "SIGNOUT_ERROR",
   error
});

const verfiyEmailRequest = (payload) => ({
   type: "VERIFY_EMAIL_REQUEST",
   payload
});

const verfiyEmailSuccess = (response) => ({
   type: "VERIFY_EMAIL_SUCCESS",
   response
});

const verfiyEmailError = (error) => ({
   type: "VERIFY_EMAIL_ERROR",
   error
});

const forgotPasswordRequest = (payload) => ({
   type: "FORGOT_PASSWORD_REQUEST",
   payload
});

const forgotPasswordSuccess = (response) => ({
   type: "FORGOT_PASSWORD_SUCCESS",
   response
});

const forgotPasswordError = (error) => ({
   type: "FORGOT_PASSWORD_ERROR",
   error
});

const resetPasswordRequest = (payload) => ({
   type: "RESET_PASSWORD_REQUEST",
   payload
});

const resetPasswordSuccess = (response) => ({
   type: "RESET_PASSWORD_SUCCESS",
   response
});

const resetPasswordError = (error) => ({
   type: "RESET_PASSWORD_ERROR",
   error
});

const resetAuthStatus = () => ({
   type: "RESET_AUTH_STATUS"
});

const resetPasswordStaffRequest = (payload) => ({
   type: "RESET_PASSWORD_STAFF_REQUEST",
   payload
});

const resetPasswordStaffSuccess = (response) => ({
   type: "RESET_PASSWORD_STAFF_SUCCESS",
   response
});

const resetPasswordStaffError = (error) => ({
   type: "RESET_PASSWORD_STAFF_ERROR",
   error
});

const staffSigninRequest = (payload) => ({
   type: "STAFF_SIGNIN_REQUEST",
   payload
});

const staffSigninSuccess = (response) => ({
   type: "STAFF_SIGNIN_SUCCESS",
   response
});

const staffSigninError = (error) => ({
   type: "STAFF_SIGNIN_ERROR",
   error
});

const subscribeTokenToFirebase = (token) => ({
   type: "SUBSCRIBE_TOKEN_TO_FIREBASE",
   token
});

const subscribeTokenToFirebaseSuccess = () => ({
   type: "SUBSCRIBE_TOKEN_TO_FIREBASE_SUCCESS"
});

const subscribeTokenToFirebaseError = () => ({
   type: "SUBSCRIBE_TOKEN_TO_FIREBASE_ERROR"
});

const cleanToken = (token) => ({
   type: "CLEAN_TOKEN",
   token
});

const cleanTokenSuccess = () => ({
   type: "CLEAN_TOKEN_SUCCESS"
});

const cleanTokenError = () => ({
   type: "CLEAN_TOKEN_ERROR"
});

const clearAccessToken = () => ({
   type: "CLEAR_ACCESS_TOKEN"
});

const validateCurrentPasswordRequest = (payload) => ({
   type: "VALIDATE_CURRENT_PASSWORD_REQUEST",
   payload
});

const validateCurrentPasswordSuccess = (payload) => ({
   type: "VALIDATE_CURRENT_PASSWORD_SUCCESS",
   payload
});

const validateCurrentPasswordError = () => ({
   type: "VALIDATE_CURRENT_PASSWORD_ERROR"
});

export {
   signinRequest,
   signinSuccess,
   signinError,
   signupRequest,
   signupSuccess,
   signupError,
   signoutRequest,
   verfiyEmailRequest,
   verfiyEmailSuccess,
   verfiyEmailError,
   forgotPasswordRequest,
   forgotPasswordSuccess,
   forgotPasswordError,
   resetPasswordRequest,
   resetPasswordSuccess,
   resetPasswordError,
   resetAuthStatus,
   resetPasswordStaffRequest,
   resetPasswordStaffSuccess,
   resetPasswordStaffError,
   staffSigninRequest,
   staffSigninSuccess,
   staffSigninError,
   signoutSuccess,
   signoutError,
   subscribeTokenToFirebase,
   subscribeTokenToFirebaseError,
   subscribeTokenToFirebaseSuccess,
   cleanToken,
   cleanTokenSuccess,
   cleanTokenError,
   clearAccessToken,
   validateCurrentPasswordRequest,
   validateCurrentPasswordSuccess,
   validateCurrentPasswordError
};
