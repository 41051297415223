import { put, call, takeEvery } from "redux-saga/effects";

import {
   getDropdownRentfrequencySuccess,
   getDropdownRentfrequencyFail,
   getDropdownRegistrationTypeFail,
   getDropdownRegistrationTypeSuccess,
   getDropdownPaymentMethodFail,
   getDropdownPaymentMethodSuccess
} from "../actions/propertyDropdownAction";

import {
   getDropdownRentfrequency,
   getDropdownPaymentMethod,
   getDropdownRegistraionType
} from "../api/propertyDropdown";

function* handleGetDropdownRentFrequency(action) {
   try {
      const response = yield call(getDropdownRentfrequency, action.payload);
      yield put(getDropdownRentfrequencySuccess(response));
   } catch (error) {
      yield put(getDropdownRentfrequencyFail(error.response.data));
   }
}

function* handleGetDropdownRegistrationType(action) {
   try {
      const response = yield call(getDropdownRegistraionType, action.payload);
      yield put(getDropdownRegistrationTypeSuccess(response));
   } catch (error) {
      yield put(getDropdownRegistrationTypeFail(error.response.data));
   }
}

function* handleGetDropdownPaymentMethod(action) {
   try {
      const response = yield call(getDropdownPaymentMethod, action.payload);
      yield put(getDropdownPaymentMethodSuccess(response));
   } catch (error) {
      yield put(getDropdownPaymentMethodFail(error.response.data));
   }
}

export default function* watchPropertyDropDownActions() {
   yield takeEvery(
      "GET_DROPDOWN_RENTFREQUENCY",
      handleGetDropdownRentFrequency
   );
   yield takeEvery(
      "GET_DROPDOWN_REGISTRATIONTYPE",
      handleGetDropdownRegistrationType
   );
   yield takeEvery(
      "GET_DROPDOWN_PAYMENTMETHOD",
      handleGetDropdownPaymentMethod
   );
}
