import API from "./API";

const saveBankDetails = async (payload) => {
  return await API.post(`api/property/addBankingDetail`, payload);
};

export const editBankDetails = (data) => {
  return API.put("/api/landlord/editBankDetails", data);
};

export { saveBankDetails };
