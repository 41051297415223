import API from "./API";

const getCountryList = async () => {
   return await API.get(`api/landlord/getAllCountry`);
};

const getCountriesFromDB = async () => {
   return await API.get(`api/country`);
};

const getUserDetails = () => {
   return API.get("/api/master/data/user");
};

const getUserConstrains = () => {
   return API.get("/api/agency/getConstraintsByAgency");
};

const getUserExsistingCount = () => {
   return API.get("/api/agency/getExistingCountByAgency");
};

export {
   getCountryList,
   getCountriesFromDB,
   getUserDetails,
   getUserConstrains,
   getUserExsistingCount
};
