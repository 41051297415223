import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./authReducer";
import routeReducer from "./routeReducer";
import siderReducer from "./siderReducer";
import propertyReducer from "./propertyReducer";
import landlordReducer from "./landlordReducer";
import miscReducer from "./miscReducer";
import modalViewReducer from "./modalViewReducer";
import staffReducer from "./staffReducer";
import tenantReducer from "./tenantReducer";
import guarantorReducer from "./guarantorReducer";
import occupantReducer from "./occupantReducer";
import contractReducer from "./contractReducer";
import settingReducer from "./settingReducer";
import profileSetupReducer from "./profileSetupReducer";
import taskReducer from "./taskReducer";
import propertyDropdownReducer from "./propertyDropdownReducer";
import notificationReducer from "./notificationReducer";
import dashboardReducer from "./dashboardReducer";
import paymentReducer from "./paymentReducer";
import invoiceReducer from "./invoiceReducer";
import dataMigration from "./dataMigrationReducer";
import uploadReducer from "./uploadReducer";
import documentViewerReducer from "./documentViewerReducer";
import requestInfoReducer from "./requestInfoReducer";
import permissionsReducer from "./permissionsReducer";
import documentReducer from "./documentReducer";

const appReducer = combineReducers({
   auth: authReducer,
   routes: routeReducer,
   sider: siderReducer,
   properties: propertyReducer,
   landlords: landlordReducer,
   misc: miscReducer,
   modalView: modalViewReducer,
   staffs: staffReducer,
   tenants: tenantReducer,
   guarantor: guarantorReducer,
   occupant: occupantReducer,
   contracts: contractReducer,
   settings: settingReducer,
   profileSetupReducer: profileSetupReducer,
   taskReducer: taskReducer,
   propertyDropdownReducer: propertyDropdownReducer,
   notifications: notificationReducer,
   dashboard: dashboardReducer,
   payment: paymentReducer,
   invoice: invoiceReducer,
   dataMigration: dataMigration,
   upload: uploadReducer,
   documentViewer: documentViewerReducer,
   requestInfo: requestInfoReducer,
   permissions: permissionsReducer,
   documents: documentReducer
});

const rootReducer = (state, action) => {
   if (action.type === "SIGNOUT_SUCCESS" || action.type === "SIGNOUT_ERROR") {
      localStorage.removeItem("access_token");
      storage.removeItem("persist:root");
      return appReducer(undefined, action);
   }
   return appReducer(state, action);
};

export default rootReducer;
