import produce from "immer";
import {
   GET_DASHBOARD_OVERVIEW,
   GET_DASHBOARD_OVERVIEW_ERROR,
   GET_DASHBOARD_OVERVIEW_SUCCESS
} from "../actions/dashboardAction";

export const DASHBOARD_MAPPING = {
   addedToday: "Added Today",
   infoPending: "Info Pending",
   total: "Total",
   unattachedLandlords: "Unattached Landlords",
   unattachedTenants: "Unattached Tenants",
   vacant: "Vacant",
   currentWeekTasks: "Current Week's Tasks",
   completedTasks: "Tasks Completed Today",
   expiring: "Expiring Current Week"
};

const INITIAL_DATA = {
   tenantContractsOverviewMap: {
      [DASHBOARD_MAPPING.addedToday]: 0,
      [DASHBOARD_MAPPING.infoPending]: 0,
      [DASHBOARD_MAPPING.total]: 0,
      [DASHBOARD_MAPPING.expiring]: []
   },
   landlordsOverviewMap: {
      [DASHBOARD_MAPPING.addedToday]: 0,
      [DASHBOARD_MAPPING.infoPending]: 0,
      [DASHBOARD_MAPPING.total]: 0,
      [DASHBOARD_MAPPING.unattachedLandlords]: 0
   },
   propertiesOverviewMap: {
      [DASHBOARD_MAPPING.addedToday]: 0,
      [DASHBOARD_MAPPING.infoPending]: 0,
      [DASHBOARD_MAPPING.total]: 0,
      [DASHBOARD_MAPPING.vacant]: 0
   },
   tasksOverviewMap: {
      [DASHBOARD_MAPPING.addedToday]: 0,
      [DASHBOARD_MAPPING.currentWeekTasks]: 0,
      [DASHBOARD_MAPPING.completedTasks]: 0,
      [DASHBOARD_MAPPING.total]: 0,
      [DASHBOARD_MAPPING.expiring]: []
   },
   tenantsOverviewMap: {
      [DASHBOARD_MAPPING.addedToday]: 0,
      [DASHBOARD_MAPPING.infoPending]: 0,
      [DASHBOARD_MAPPING.total]: 0,
      [DASHBOARD_MAPPING.unattachedTenants]: 0
   },
   accountSummaryMap: {
      AccountPayable: null,
      AccountReceivable: null
   },
   rentSummaryMap: {
      AccountPayable: null,
      AccountReceivable: null
   }
};

const INITAL_STATE = {
   overview: null,
   assets: null,
   account: null,
   data: {
      ...INITIAL_DATA
   },
   loading: false
};

const reducer = (state = INITAL_STATE, { type, payload }) =>
   produce(state, (draft) => {
      switch (type) {
         case "GET_DASH_ASSETS_SUCCESS":
            draft.assets = payload.response;
            break;
         case "GET_DASH_OVERVIEW_SUCCESS":
            draft.overview = payload.response;
            break;

         case GET_DASHBOARD_OVERVIEW:
            draft.loading = true;
            break;
         case GET_DASHBOARD_OVERVIEW_SUCCESS:
            draft.data = payload;
            draft.loading = false;
            break;

         case GET_DASHBOARD_OVERVIEW_ERROR:
            draft.loading = false;
            break;

         case "GET_DASH_ACCOUNTCREDIT_SUCCESS":
            draft.account = payload?.data?.response;
            break;
         default:
            break;
      }
   });

export default reducer;
