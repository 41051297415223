import { put, call, takeEvery } from "redux-saga/effects";

import {
   getGuarantorListByPropertyIdSuccess,
   getGuarantorListByPropertyIdError,
   addGuarantorSuccess,
   addGuarantorError,
   deleteGuarantorByIdSuccess,
   deleteGuarantorByIdError,
   updateGuarantorSuccess,
   updateGuarantorError,
   getGuarantorListByPropertyId
} from "../actions/guarantorAction";

import {
   AddGuarantor,
   DeleteGuarantor,
   GuarantorListByPropertyId,
   UpdateGuarantor
} from "../api/guarantor";

function* handleGetGuarantorLisyByPropertyId({ payload }) {
   try {
      const resp = yield call(GuarantorListByPropertyId, payload);
      yield put(getGuarantorListByPropertyIdSuccess(resp.data));
   } catch (error) {
      yield put(getGuarantorListByPropertyIdError());
   }
}

function* handleAddGuarantor(action) {
   const { payload } = action;
   try {
      const resp = yield call(AddGuarantor, payload);
      yield put(addGuarantorSuccess(resp.data));
      yield put(
         getGuarantorListByPropertyId({
            propertyId: payload.propertyId
         })
      );
   } catch (error) {
      yield put(addGuarantorError());
   }
}

function* handleDeleteGuarantor({ payload }) {
   try {
      yield call(DeleteGuarantor, payload);
      yield put(deleteGuarantorByIdSuccess());
   } catch (error) {
      yield put(deleteGuarantorByIdError());
   }
}

function* handleUpdateGuarantor(action) {
   const { payload } = action;
   try {
      const resp = yield call(UpdateGuarantor, payload);
      yield put(updateGuarantorSuccess(resp.data));
      yield put(
         getGuarantorListByPropertyId({
            propertyId: payload.propertyId
         })
      );
   } catch (error) {
      yield put(updateGuarantorError());
   }
}

export default function* watchGuarantorAction() {
   yield takeEvery(
      "GET_GUARANTOR_LIST_BY_PROPERTY_ID",
      handleGetGuarantorLisyByPropertyId
   );
   yield takeEvery("ADD_GUARANTOR", handleAddGuarantor);
   yield takeEvery("DELETE_GUARANTOR_BY_ID", handleDeleteGuarantor);
   yield takeEvery("UPDATE_GUARANTOR", handleUpdateGuarantor);
}
