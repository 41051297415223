import produce from "immer";
import moment from "moment";

const INITIAL_STATE = {
   notifications: [],
   filters: [],
   pagination: null,
   loading: false,
   count: 0,
   page: 0,
   error: false
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (newState) => {
      switch (action.type) {
         case "FETCH_NOTIFICATIONS_REQUEST":
            newState.loading = true;
            action.payload.page = newState.page++;
            action.payload.error = newState.error;
            break;
         case "FETCH_NOTIFICATIONS_SUCCESS":
            newState.loading = false;
            var arr = action.response.response.notifications;

            newState.pagination = action.response.response.paginationDetails;
            newState.notifications = [...newState.notifications, ...arr];
            break;
         case "FETCH_NOTIFICATIONS_ERROR":
            newState.loading = false;
            newState.error = true;
            break;

         case "ADD_NEW_NOTIFICATION":
            var obj = action.notification.data;
            obj.read = false;
            obj.id = obj.notification_id;
            var temp = {
               date: moment(action.notification.data.created_timestamp).format(
                  "DD-MM-YYYY"
               ),
               pushNotificationResponse: [{ data: obj }]
            };
            newState.notifications.unshift(temp);
            newState.count++;
            break;

         case "DISMISS_NOTIFICATION_REQUEST":
            newState.notifications.forEach((obj) => {
               if (obj.notification_id === action.payload.id) {
                  obj.read = true;
               }
            });
            break;

         case "DISMISS_NOTIFICATION_SUCCESS":
            break;
         case "DISMISS_NOTIFICATION_ERROR":
            newState.notifications.forEach((obj) => {
               if (obj.notification_id === action.response.id) {
                  obj.read = false;
               }
            });
            break;

         case "SET_FILTER":
            newState.filters = action.payload;
            break;
         case "GET_NEW_COUNT_SUCCESS":
            newState.count = action.count;
            break;
         case "MARK_AS_OLD":
            newState.count > 0 && newState.count--;
            break;
         default:
            return newState;
      }
   });
