export const UploadFileProgress = (payload) => ({
   type: "UPLOAD_FILE_PROGRESS",
   payload
});

export const UploadSingleFileRequest = (payload) => ({
   type: "UPLOAD_SINGLE_FILE_REQUEST",
   payload
});

export const UploadSingleFileSuccess = (payload) => ({
   type: "UPLOAD_SINGLE_FILE_SUCCESS",
   payload
});

export const UploadSingleFileError = (file) => ({
   type: "UPLOAD_SINGLE_FILE_ERROR",
   file
});

export const GetSingleFileRequest = (id) => ({
   type: "GET_SINGLE_FILE_REQUEST",
   id
});

export const GetSingleFileSuccess = (fileObj) => ({
   type: "GET_SINGLE_FILE_SUCCESS",
   fileObj
});

export const GetSingleFileError = () => ({
   type: "GET_SINGLE_FILE_ERROR"
});

export const DeleteFileById = (payload) => ({
   type: "DELETE_FILE_BY_ID",
   payload
});

export const DeleteFileByIdSuccess = () => ({
   type: "DELETE_FILE_BY_ID_SUCCESS"
});

export const DeleteFileByIdError = () => ({
   type: "DELETE_FILE_BY_ID_ERROR"
});

export const CancelFiles = () => ({
   type: "CANCEL_FILES_REQUEST"
});

export const clearUploadedFiles = () => ({
   type: "CLEAR_UPLOADED_FILES"
});

export const setUploadedFiles = (files, catagory) => ({
   type: "SET_UPLOADED_FILES",
   files,
   catagory
});

export const UploadProfilePicRequest = (payload) => ({
   type: "UPLOAD_PROFILE_PIC",
   payload
});

export const UploadProfilePicSuccess = (payload) => ({
   type: "UPLOAD_PROFILE_PIC_SUCCESS",
   payload
});

export const uploadProfilePicError = () => ({
   type: "UPLOAD_PROFILE_PIC_ERROR"
});

export const setBlobUrlById = (id) => ({
   type: "SET_BLOB_URL_BY_ID",
   id
});

export const setTempBlobUrlId = (id) => ({
   type: "SET_TEMP_BLOB_URL_ID",
   id
});

export const setBlobUrlByIdSuccess = (payload) => ({
   type: "SET_BLOB_URL_BY_ID_SUCCESS",
   payload
});

export const clearProfilePic = (payload) => ({
   type: "CLEAR_PROFILE_PIC",
   payload
});
