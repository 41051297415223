import produce from "immer";

const INITIAL_STATE = {
   showSidebar: false,
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "TOGGLE_SIDER":
            draft.showSidebar = !draft.showSidebar;
            break;
         default:
            return draft;
      }
   });
