export const GET_DASHBOARD_OVERVIEW = "GET_DASHBOARD_OVERVIEW";
export const GET_DASHBOARD_OVERVIEW_SUCCESS = "GET_DASHBOARD_OVERVIEW_SUCCESS";
export const GET_DASHBOARD_OVERVIEW_ERROR = "GET_DASHBOARD_OVERVIEW_ERROR";

export const getDashAssets = () => ({
   type: "GET_DASH_ASSETS"
});

export const getDashAssetsSuccess = (payload) => ({
   type: "GET_DASH_ASSETS_SUCCESS",
   payload
});

export const getDashAssetsError = () => ({
   type: "GET_DASH_ASSETS_ERROR"
});

export const getDashOverview = () => ({
   type: "GET_DASH_OVERVIEW"
});

export const getDashOverviewSuccess = (payload) => ({
   type: "GET_DASH_OVERVIEW_SUCCESS",
   payload
});

export const getDashOverviewError = () => ({
   type: "GET_DASH_OVERVIEW_ERROR"
});

export const getDashboardOverview = () => ({
   type: GET_DASHBOARD_OVERVIEW,
   payload: {}
});

export const getDashboardOverviewSuccess = (payload) => ({
   type: GET_DASHBOARD_OVERVIEW_SUCCESS,
   payload
});

export const getDashboardOverviewError = () => ({
   type: GET_DASHBOARD_OVERVIEW_ERROR,
   payload: {}
});

export const getDashAccountCredit = (payload) => ({
   type: "GET_DASH_ACCOUNTCREDIT",
   payload
});

export const getDashAccountCreditSuccess = (payload) => ({
   type: "GET_DASH_ACCOUNTCREDIT_SUCCESS",
   payload
});

export const getDashAccountCreditError = () => ({
   type: "GET_DASH_ACCOUNTCREDIT_ERROR"
});
