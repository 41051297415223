import API from "./API";

export const OccupantsByTenantIdPropertyId = async (data) => {
   const { tenantId, propertyId } = data;
   return API.get("/api/occupant/getOccupantsByTenantIdPropertyId", {
      params: {
         tenantId,
         propertyId
      }
   });
};

export const AddOccupant = async (data) => {
   return await API.post("/api/occupant/addOccupant", data);
};

export const DeleteOccupant = async (data) => {
   const { id } = data;
   return await API.delete("/api/occupant/deleteOccupant", {
      params: {
         id
      }
   });
};

export const UpdateOccupant = async (data) => {
   return await API.post("/api/occupant/updateOccupant", data);
};

export const UnitDetailsByTenantIdPropertyId = async (data) => {
   const { tenantId, propertyId } = data;
   return API.get("/api/occupant/getUnitDetailsByTenantIdPropertyId", {
      params: {
         tenantId,
         propertyId
      }
   });
};
