import API from "./API";
const signin = async (payload) => {
   return await API.post("/api/auth/signin", payload);
};

const signout = async () => {
   return await API.post("/api/auth/logout");
};
const staffSignin = async (payload) => {
   return await API.post("/api/auth/staff/signin", payload);
};

const signup = async (payload) => {
   return await API.post("/api/auth/signup", payload);
};

const verifyEmail = async (payload) => {
   if (payload.userName) {
      return await API.get(
         `/api/auth/confirm-account?token=${payload.token}&userName=${payload.userName}`
      );
   } else {
      return await API.get(`/api/auth/confirm-account?token=${payload.token}`);
   }
};

const forgotPassword = async (payload) => {
   return await API.get(`/api/auth/resetPasswordLink?email=${payload.email}`);
};

const resetPassword = async (payload) => {
   return await API.post(`/api/auth/forgetPassword`, payload);
};

const resetStaffPassword = async (payload) => {
   return await API.post(`/api/auth/resetStaffPassword`, payload);
};

const saveToken = (token) => {
   return API.post("/api/notification/token/save", { token });
};

const cleanToken = (token) => {
   return API.post("/api/notification/token/clean", { token });
};

const validateCurrentPassword = (currentPassword) => {
   return API.get("api/auth/validatePassword", {
      params: { password: currentPassword }
   });
};

export {
   signin,
   signup,
   verifyEmail,
   forgotPassword,
   resetPassword,
   resetStaffPassword,
   staffSignin,
   signout,
   saveToken,
   cleanToken,
   validateCurrentPassword
};
