import nodejsAPI from "./nodejsAPI";
import API from "./API";

export const createRequestInfo = (data) => {
   return nodejsAPI.post("/createRequestInfo", data);
};

export const getRequestInfo = (data) => {
   return nodejsAPI.get("/getRequestInfo", { params: data });
};

export const getRequestInfoStatus = (data) => {
   return API.get("/api/requests/request-raised/getStatus", {
      params: data
   });
};

export const rejectRequestInfo = (data) => {
   return nodejsAPI.delete(`/reject/${data.id}/${data.role}`);
};

export const approveRequestInfo = (data) => {
   return nodejsAPI.post(`/approve/${data.id}/${data.role}`);
};
