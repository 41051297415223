import produce from "immer";

const INITIAL_STATE = {
   visible: false,
   data: null
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "SET_DOCUMENT_VIEWER_DATA":
            draft.data = action.payload;
            break;
      }
   });
