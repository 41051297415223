/*eslint no-case-declarations: "off"*/
import produce from "immer";

const INITIAL_STATE = {
   landlordByEmail: {
      loading: false,
      landlord: undefined
   },
   emergencyDetails: {
      loading: false,
      data: null
   },
   landlordByName: {
      loading: false,
      landlords: []
   },

   updateLandlord: {
      loading: false
   },

   deleteLandlord: {
      loading: false
   },

   deleteDocumentType: {
      success: false
   },
   deleteDocumentTypeSingle: {
      success: false
   },
   newLandlord: {
      loading: false,
      success: false,
      error: null,
      landlord: null
   },

   loading: false,
   // landlords: [],
   currentLandlord: {
      loading: false,
      landlord: null
   },

   landlords: {
      loaded: false,
      loading: false,
      list: [],
      landLordType: null,
      landlordStatus: null,
      occupancy: null,
      searchBy: null,
      orderBy: null,
      sortBy: null,
      totalPages: 1,
      currentPage: 1,
      entriesPerPage: 10
   },

   landlordsLazy: {
      data: [],
      success: false,
      error: null,
      pagination: null,
      loading: false,
      loaded: false
   },

   bankDetails: {
      banks: [],
      loading: false,
      success: false,
      error: null
   },

   contractsById: {
      contracts: [],
      pagination: null,
      loading: false
   },

   documentsById: {
      documents: [],
      loading: false
   },

   addContract: {
      loading: false
   },

   propertiesById: {
      properties: [],
      loading: false
   },

   addDocument: {
      loading: false,
      success: false,
      error: null
   },

   updateDocumentReducer: {
      loading: false,
      success: false,
      error: null
   },

   versionHistory: {
      loading: false,
      versionHistory: [],
      loadingById: false,
      versionHistoryId: null
   },

   documentUrl: {
      loading: false,
      base: null
   },

   DropdownValuesAddnewDocument: {
      loading: false,
      data: null
   },

   landlordToArchival: {
      success: false,
      response: null,
      error: null
   },

   totalPages: 10,
   currentPage: 1,
   entriesPerPage: 10,
   openAddNewLandLordModal: false
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "CHANGE_LANDLORD_LOADED_STATE":
            draft.landlords.loaded = action.payload;
            break;
         case "GET_LANDLORDS":
            draft.loading = true;
            draft.landlords.loading = true;
            draft.landlords.landlordType = action.payload.landlordType;
            draft.landlords.landlordStatus = action.payload.landlordStatus;
            draft.landlords.searchBy = action.payload.searchBy;
            draft.landlords.orderBy = action.payload.orderBy;
            draft.landlords.sortBy = action.payload.sortBy;
            break;

         case "GET_LANDLORDS_SUCCESS":
            draft.landlords.loaded = true;
            draft.landlords.list = action.response.response.landlords;
            draft.landlords.totalPages =
               action.response.response.paginationDetails.totalPages;
            draft.landlords.currentPage =
               action.response.response.paginationDetails.currentPage + 1;
            draft.landlords.entriesPerPage =
               action.response.response.paginationDetails.pageSize;
            draft.loading = false;
            draft.landlords.loading = false;
            break;

         case "GET_LANDLORDS_ERROR":
            draft.loading = false;
            draft.landlords.list = [];
            draft.landlords.totalPages = 0;
            draft.landlords.currentPage = 1;
            draft.landlords.loading = false;
            break;

         case "GET_LANDLORDS_LAZY":
            draft.landlordsLazy.loading = true;

            break;

         case "GET_LANDLORDS_LAZY_SET_NULL":
            draft.landlordsLazy.data = [];
            draft.landlordsLazy.success = false;
            draft.landlordsLazy.error = null;
            draft.landlordsLazy.pagination = null;
            draft.landlordsLazy.loading = false;
            draft.landlordsLazy.loaded = false;

            break;

         case "GET_LANDLORDS_LAZY_SUCCESS":
            draft.landlordsLazy.data = action.response.response.landlords;
            draft.landlordsLazy.pagination =
               action.response.response.paginationDetails;
            draft.landlordsLazy.loading = false;
            draft.landlordsLazy.loaded = true;
            break;

         case "GET_LANDLORDS_LAZY_ERROR":
            draft.landlordsLazy.loading = false;
            draft.landlordsLazy.data = [];
            draft.landlordsLazy.error = action.response;
            break;

         case "RESET_LANDLORD_FILTER":
            draft.landlords.landlordType = null;
            draft.landlords.landlordStatus = null;
            break;

         case "GET_LANDLORD_BY_EMAIL_REQUEST":
            draft.landlordByEmail.loading = true;
            draft.landlordByEmail.landlord = undefined;
            break;

         case "GET_LANDLORD_BY_EMAIL_SUCCESS":
            draft.landlordByEmail.loading = false;
            draft.landlordByEmail.landlord = action.response.data.response;
            break;

         case "GET_LANDLORD_BY_EMAIL_ERROR":
            draft.landlordByEmail.loading = false;
            draft.landlordByEmail.landlord = null;
            break;

         case "CLEAR_LANDLORD_BY_EMAIL_DATA":
            draft.landlordByEmail.landlord = undefined;
            break;

         case "GET_LANDLORD_BY_NAME_REQUEST":
            draft.landlordByName.loading = true;
            break;

         case "GET_LANDLORD_BY_NAME_SUCCESS":
            draft.landlordByName.loading = false;
            draft.landlordByName.landlords = action.response.data.response.map(
               (user) => ({
                  text: `${user.firstName} ${user.lastName}`,
                  value: user.lanlordID
               })
            );
            break;

         case "GET_LANDLORD_BY_NAME_ERROR":
            draft.landlordByName.loading = false;
            draft.landlordByName.landlords = [];

            break;

         case "SAVE_LANDLORD_REQUEST":
            draft.newLandlord.loading = true;
            break;

         case "SAVE_LANDLORD_SUCCESS":
            draft.newLandlord.loading = false;
            draft.newLandlord.success = true;
            draft.newLandlord.landlord = action.response.data.response;
            draft.landlordByEmail.landlord = action.response.data.response;
            break;

         case "SAVE_LANDLORD_ERROR":
            draft.newLandlord.loading = false;
            draft.newLandlord.success = false;
            break;

         case "FETCH_LANDLORD_BANK_DETAILS_REQUEST":
            draft.bankDetails.banks = [];
            break;

         case "FETCH_LANDLORD_BANK_DETAILS_SUCCESS":
            draft.bankDetails.banks = action.response.data.response;
            break;

         case "FETCH_LANDLORD_BANK_DETAILS_ERROR":
            break;

         case "SAVE_LANDLORD_BANK_DETAILS_REQUEST":
            draft.bankDetails.loading = true;
            draft.bankDetails.success = false;
            break;

         case "SAVE_LANDLORD_BANK_DETAILS_SUCCESS":
            draft.bankDetails.loading = false;
            draft.bankDetails.success = true;
            draft.bankDetails.banks.push(action.response.data.response);
            break;

         case "SAVE_LANDLORD_BANK_DETAILS_ERROR":
            draft.bankDetails.loading = false;
            draft.bankDetails.success = false;
            break;

         case "RESET_LANDLORD_DATA":
            draft.landlordByEmail.loading = false;
            draft.landlordByEmail.landlord = undefined;
            draft.newLandlord.loading = false;
            draft.newLandlord.success = false;
            draft.newLandlord.landlord = null;
            break;

         case "CREATE_LANDLORD":
            draft.newLandlord.loading = true;
            break;

         case "CREATE_LANDLORD_SUCCESS":
            draft.newLandlord.loading = false;
            draft.newLandlord.success = true;
            draft.newLandlord.landlord = action.payload.data.response;
            draft.landlords.push(action.payload.data.response);
            break;

         case "CREATE_LANDLORD_ERROR":
            draft.newLandlord.loading = false;
            break;

         case "GET_LANDLORD_BY_ID":
            draft.currentLandlord.loading = true;
            break;

         case "GET_LANDLORD_BY_ID_SUCCESS":
            draft.currentLandlord.landlord = action.payload.response[0];
            draft.currentLandlord.loading = false;
            break;

         case "GET_LANDLORD_BY_ID_ERROR":
            draft.currentLandlord.loading = false;
            break;

         case "UPDATE_LANDLORD_REQUEST":
            draft.updateLandlord.loading = true;
            break;

         case "UPDATE_LANDLORD_SUCCESS":
            draft.updateLandlord.loading = false;
            break;

         case "UPDATE_LANDLORD_ERROR":
            draft.updateLandlord.loading = false;
            break;

         case "DELETE_LANDLORD_REQUEST":
            draft.deleteLandlord.loading = true;
            break;

         case "DELETE_LANDLORD_SUCCESS":
            draft.deleteLandlord.loading = false;
            break;

         case "DELETE_LANDLORD_ERROR":
            draft.deleteLandlord.loading = false;
            break;

         case "GET_CONTRACT_BY_ID_LANDLORD_REQUEST":
            draft.contractsById.loading = true;
            break;

         case "GET_CONTRACT_BY_ID_LANDLORD_SUCCESS":
            var arrProperty =
               action?.payload?.response?.landlordContracts?.content;
            var newArrProperty = [];
            arrProperty.forEach((obj) => {
               newArrProperty.push(obj);
            });
            draft.contractsById.contracts = [
               ...draft.contractsById.contracts,
               ...newArrProperty
            ];
            draft.contractsById.pagination =
               action?.payload?.response?.paginationDetails;
            draft.contractsById.loading = false;
            draft.contractsById.loaded = true;
            break;

         case "GET_CONTRACT_BY_ID_LANDLORD_ERROR":
            draft.contractsById.loading = false;
            draft.contractsById.contracts = [];
            break;

         case "SET_PAGE":
            const { page } = action.payload;
            draft.currentPage = page;
            break;

         case "EDIT_BANK":
            draft.currentLandlord.loading = true;
            break;

         case "EDIT_BANK_SUCCESS":
            draft.currentLandlord.loading = false;
            break;

         case "EDIT_BANK_ERROR":
            draft.currentLandlord.loading = false;
            break;

         case "GET_DOCUMENT_BY_ID":
            draft.documentsById.loading = true;
            break;

         case "GET_DOCUMENT_BY_ID_SUCCESS":
            draft.documentsById.documents = action?.payload?.response;
            draft.documentsById.loading = false;
            break;

         case "GET_DOCUMENT_BY_ID_ERROR":
            draft.documentsById.loading = false;
            break;

         case "SET_LANDLORD_CONTRACT_NULL":
            draft.contractsById.contracts = [];
            break;

         case "SET_LANDLORD_DOCUMENTS_NULL":
            draft.documentsById.documents = [];
            break;

         case "ADD_CONTRACT_REQUEST":
            draft.addContract.loading = true;
            break;

         case "ADD_CONTRACT_SUCCESS":
            draft.addContract.loading = false;
            break;

         case "ADD_CONTRACT_ERROR":
            draft.addContract.loading = false;
            break;

         case "GET_PROPERTY_REQUEST":
            draft.propertiesById.loading = true;
            break;

         case "GET_PROPERTY_SUCCESS":
            draft.propertiesById.properties = action.payload.response;
            draft.propertiesById.loading = false;
            break;

         case "GET_PROPERTY_ERROR":
            draft.propertiesById.loading = false;
            draft.propertiesById.properties = [];
            break;

         case "ADD_DOCUMENT_LANDLORD":
            draft.addDocument.loading = true;
            draft.addDocument.success = false;
            break;

         case "ADD_DOCUMENT_SUCCESS":
            draft.addDocument.loading = false;
            draft.addDocument.success = true;
            break;

         case "ADD_DOCUMENT_ERROR":
            draft.addDocument.loading = false;
            draft.addDocument.success = false;
            draft.addDocument.error = action.response;
            break;

         case "ADD_NOTE_TO_LANDLORD_SUCCESS":
            if (state.currentLandlord.landlord.notes) {
               draft.currentLandlord.landlord = {
                  ...state.currentLandlord.landlord,
                  notes: [
                     ...state.currentLandlord.landlord.notes,
                     action.payload.response
                  ]
               };
            } else {
               draft.currentLandlord.landlord = {
                  ...state.currentLandlord.landlord,
                  notes: [action.payload.response]
               };
            }
            break;

         case "GET_NOTE_BY_LANDLORD_SUCCESS":
            draft.currentLandlord.landlord = {
               ...state.currentLandlord.landlord,
               notes: action.payload.response
            };
            break;

         case "UPDATE_NOTE_TO_LANDLORD_SUCCESS": {
            const { id } = action.payload.response;
            const notes = state.currentLandlord.landlord.notes.map((note) =>
               note.id === id ? { ...note, ...action.payload.response } : note
            );
            draft.currentLandlord.landlord = {
               ...state.currentLandlord.landlord,
               notes
            };
            break;
         }

         case "DELETE_NOTE_BY_LANDLORD_SUCCESS":
            const { id } = action.payload;
            draft.currentLandlord.landlord.notes = state.currentLandlord.landlord.notes.filter(
               (note) => note.id !== id
            );
            break;

         case "EDIT_CONTRACT_REQUEST":
            draft.addContract.loading = true;
            break;

         case "EDIT_CONTRACT_SUCCESS":
            draft.addContract.loading = false;
            break;

         case "EDIT_CONTRACT_ERROR":
            draft.addContract.loading = false;
            break;

         case "OPEN_ADD_NEW_LANDLORD_MODAL":
            draft.openAddNewLandLordModal = action.payload;
            break;

         case "UPDATE_DOCUMENT":
            draft.updateDocumentReducer.loading = true;
            draft.updateDocumentReducer.success = false;
            break;

         case "UPDATE_DOCUMENT_SUCCESS":
            draft.updateDocumentReducer.loading = false;
            draft.updateDocumentReducer.success = true;
            break;

         case "UPDATE_DOCUMENT_ERROR":
            draft.updateDocumentReducer.loading = false;
            draft.updateDocumentReducer.success = false;
            break;

         case "GET_VERSION_HISTORY_LANDLORD":
            draft.versionHistory.loading = true;
            break;

         case "GET_VERSION_HISTORY_LANDLORD_SUCCESS":
            draft.versionHistory.loading = false;
            draft.versionHistory.versionHistory = action.payload.response;
            break;

         case "GET_VERSION_HISTORY_LANDLORD_ERROR":
            draft.versionHistory.loading = false;
            draft.versionHistory.versionHistory = null;
            break;

         case "GET_VERSION_HISTORY_ID":
            draft.versionHistory.loadingById = true;
            break;

         case "GET_VERSION_HISTORY_ID_SUCCESS":
            draft.versionHistory.versionHistoryId = action.payload.response;
            draft.versionHistory.loadingById = false;
            break;

         case "GET_VERSION_HISTORY_ID_ERROR":
            draft.versionHistory.loadingById = false;
            break;

         case "GET_DOCUMENT_URL_BY_ID":
            draft.documentUrl.loading = true;
            break;

         case "GET_DOCUMENT_URL_BY_ID_SUCCESS":
            draft.documentUrl.base = action.payload.response;
            draft.documentUrl.loading = false;
            break;

         case "GET_DOCUMENT_URL_BY_ID_ERROR":
            draft.documentUrl.loading = false;
            break;

         case "SET_DOCUMENT_BASE_NULL":
            draft.documentUrl.base = null;
            break;

         case "UPDATE_LANDLORD_TO_ARCHIVE":
            draft.landlordToArchival.success = false;

            break;

         case "UPDATE_LANDLORD_TO_ARCHIVE_SUCCESS":
            draft.landlordToArchival.response = action.response;
            draft.landlordToArchival.success = true;

            break;

         case "UPDATE_LANDLORD_TO_ARCHIVE_FAIL":
            draft.landlordToArchival.success = false;
            draft.landlordToArchival.error = action.error;

            break;

         case "DELETE_DOCUMENT_BY_ID":
            draft.deleteDocumentType.success = false;
            break;

         case "DELETE_DOCUMENT_BY_ID_SUCCESS":
            draft.deleteDocumentType.success = true;
            break;

         case "DELETE_DOCUMENT_BY_ID_ERROR":
            draft.deleteDocumentType.success = false;
            break;

         case "DELETE_DOCUMENT_BY_SINGLE_ID":
            draft.deleteDocumentTypeSingle.success = false;
            break;

         case "DELETE_DOCUMENT_BY_SINGLE_ID_SUCCESS":
            draft.deleteDocumentTypeSingle.success = true;
            break;

         case "DELETE_DOCUMENT_BY_SINGLE_ID_ERROR":
            draft.deleteDocumentTypeSingle.success = false;
            break;

         case "GET_EMERGENCY_DETAILS_FOR_LANDLORD":
            draft.emergencyDetails.loading = true;
            break;

         case "GET_EMERGENCY_DETAILS_FOR_LANDLORD_SUCCESS":
            draft.emergencyDetails.data = action.payload.response.data;
            draft.emergencyDetails.loading = false;
            break;

         case "GET_EMERGENCY_DETAILS_FOR_LANDLORD_ERROR":
            draft.emergencyDetails.loading = false;
            break;

         case "GET_DROPDOWN_VALUES_FOR_ADDNEWDOCUMENT_REQUEST":
            draft.DropdownValuesAddnewDocument.loading = true;
            break;

         case "GET_DROPDOWN_VALUES_FOR_ADDNEWDOCUMENT_SUCCESS":
            draft.DropdownValuesAddnewDocument.data = action.response.response;
            draft.DropdownValuesAddnewDocument.loading = false;
            break;

         case "GET_DROPDOWN_VALUES_FOR_ADDNEWDOCUMENT_ERROR":
            draft.DropdownValuesAddnewDocument.loading = false;
            break;

         default:
            return draft;
      }
   });
