import moment from "moment";
import API from "./API";

export const GetTaskPriorities = async () => {
   return await API.get("api/task/getTaskPriorities");
};

export const GetTaskReminders = async () => {
   return await API.get("api/task/getTaskReminders");
};

export const GetTaskStatuses = async () => {
   return await API.get("api/task/getTaskStatuses");
};

export const GetTaskTypes = async () => {
   return await API.get("api/task/getTaskTypes");
};

export const AddUpdatetask = async (data) => {
   let formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));

   formData.delete("documents");
   if (data.documents && data.documents.length != 0) {
      data.documents.forEach((doc, idx) =>
         formData.append(`documents[${idx}]`, doc)
      );
   }

   formData.delete("assignTo");
   if (data.assignTo && data.assignTo.length > 0) {
      data.assignTo.forEach((staffid, idx) =>
         formData.append(`assignTo[${idx}]`, staffid)
      );
   }

   formData.delete("propertyId");
   if (data.propertyId) {
      formData.append("propertyId", data.propertyId);
   }

   formData.delete("dueDateAndTime");
   if (data.dueDateAndTime) {
      formData.append(
         "dueDateAndTime",
         moment(data?.dueDateAndTime).format("YYYY-MM-DD hh:mm A")
      );
   }

   formData.delete("statusId");
   if (data.statusId) {
      formData.append("statusId", data.statusId);
   }

   formData.delete("reminderId");
   if (data.reminderId) {
      formData.append("reminderId", data.reminderId);
   }

   return await API.post("/api/task/addUpdateTask", formData);
};

export const GetPendingTaskList = async () => {
   return await API.get("api/task/getPendingTaskList");
};

export const MarkTaskAsDone = async (data) => {
   return await API.put("api/task/updateTaskStatus", "", {
      params: {
         taskId: data.taskId,
         statusId: data.statusId,
         previousStatusId: data.previousStatusId
      }
   });
};

export const GetTaskDetails = async (data) => {
   return await API.get("api/task/getTaskDetails", {
      params: {
         taskId: data.taskId
      }
   });
};

export const getTaskTimeline = (data, pagination) => {
   let staff1 = data?.staff?.join();
   delete data?.staff;
   delete data.page;
   delete data.size;
   return API.get(
      `/api/task/getTaskTimeline/${pagination.page}/${pagination.size}`,
      {
         params: {
            ...data,
            staffId: staff1
         }
      }
   );
};

export const DeleteTaskById = async (id) => {
   return await API.delete("/api/task/deleteTask", {
      params: {
         taskId: id
      }
   });
};

export const DeleteTaskDocById = (id) => {
   return API.delete("/api/task/deleteTaskDocument", {
      params: {
         taskDocumentId: id
      }
   });
};

export const getBaseByURL = (url) => {
   return API.get(`/api/task/getBase64?id=${url}`);
};

// comment

export const GetCommentByTaskId = async (id) => {
   return API.get(`/api/comments/by-task/${id}`);
};

export const SaveComment = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   return API.post("/api/comments/save", formData);
};

export const deleteCommentByid = async (id) => {
   return API.delete(`/api/comments/delete/${id}`);
};

export const saveReply = (data) => {
   return API.post("/api/replies/save", data);
};

export const saveAsReadStatus = (data) => {
   const { id, comment } = data;
   return API.post(
      `/api/${comment ? "comments" : "replies"}/read-status/save/${id}`,
      {}
   );
};

export const deleteReply = (id) => {
   return API.delete(`/api/replies/delete/${id}`);
};

export const saveEmoij = (payload) => {
   const { data, reply } = payload;
   return API.post(`/api/reactions${reply ? "/reply/save" : "/save"}`, data);
};

export const getBase64ForComment = (url) => {
   return API.get("/api/comments/attachment/base64", {
      params: {
         url
      }
   });
};

// calendar

export const getCalendarTasks = (data) => {
   let staff1 = data?.staff?.join();
   delete data?.staff;
   return API.get("/api/task/getCalendarViewTasks", {
      params: {
         ...data,
         staffId: staff1
      }
   });
};

export const getTaskList = (data) => {
   return API.get(`/api/task/getTaskListv2/${data.pagination}/${data.size}`, {
      params: data.params
   });
};
