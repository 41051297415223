import produce from "immer";

const INITAL_STATE = {
   occupantListBypropertyId: {
      loading: false,
      occupantList: null
   },

   addOccupant: {
      loading: false,
      occupant: null
   },

   deleteOccupant: {
      loading: false
   },

   updateOccupant: {
      loading: false,
      occupant: null
   },

   unitListByTenantIdPropertyId: {
      loading: false,
      unitList: null
   }
};

export default (state = INITAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_OCCUPANTS_BY_TENANT_ID_PROPERTY_ID":
            draft.occupantListBypropertyId.loading = true;
            break;

         case "GET_OCCUPANTS_BY_TENANT_ID_PROPERTY_ID_SUCCESS":
            draft.occupantListBypropertyId.loading = false;
            draft.occupantListBypropertyId.occupantList =
               action.payload.response;
            break;

         case "GET_OCCUPANTS_BY_TENANT_ID_PROPERTY_ID_ERROR":
            draft.occupantListBypropertyId.loading = false;
            draft.occupantListBypropertyId.occupantList = [];
            break;

         case "ADD_OCCUPANT":
            draft.addOccupant.loading = true;
            break;

         case "ADD_OCCUPANT_SUCCESS":
            draft.addOccupant.occupant = action.payload.response;
            draft.addOccupant.loading = false;
            break;

         case "ADD_OCCUPANT_ERROR":
            draft.addOccupant.loading = false;
            break;

         case "DELETE_OCCUPANT_BY_ID":
            draft.deleteOccupant.loading = true;
            break;

         case "DELETE_OCCUPANT_BY_ID_SUCCESS":
            // draft.deleteOccupant.occupant = action.payload.response;
            draft.deleteOccupant.loading = false;
            break;

         case "DELETE_OCCUPANT_BY_ID_ERROR":
            draft.deleteOccupant.loading = false;
            break;

         case "UPDATE_OCCUPANT":
            draft.updateOccupant.loading = true;
            break;

         case "UPDATE_OCCUPANT_SUCCESS":
            draft.updateOccupant.occupant = action.payload.response;
            draft.updateOccupant.loading = false;
            break;

         case "UPDATE_OCCUPANT_ERROR":
            draft.updateOccupant.loading = false;
            break;

         case "GET_UNIT_DETAILS_BY_TENANT_ID_PROPERTY_ID":
            draft.unitListByTenantIdPropertyId.loading = true;
            break;

         case "GET_UNIT_DETAILS_BY_TENANT_ID_PROPERTY_ID_SUCCESS":
            draft.unitListByTenantIdPropertyId.loading = false;
            draft.unitListByTenantIdPropertyId.unitList =
               action.payload.response;
            break;

         case "GET_UNIT_DETAILS_BY_TENANT_ID_PROPERTY_ID_ERROR":
            draft.unitListByTenantIdPropertyId.loading = false;
            draft.unitListByTenantIdPropertyId.unitList = [];
            break;

         default:
            return draft;
      }
   });
