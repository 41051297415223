import { put, call, takeEvery, select } from "redux-saga/effects";
import {
   getStaffsSuccess,
   getStaffsError,
   addStaffsError,
   addStaffsSuccess,
   updateStaffsSuccess,
   updateStaffsError,
   getStaffsbyidSuccess,
   getStaffsbyidError,
   deleteStaffsSuccess,
   deleteStaffsError,
   addStaffsRandomPasswordError,
   addStaffsRandomPasswordSuccess,
   getStaffimageSuccess,
   getStaffimageError,
   getStaffsHistorybyidSuccess,
   getStaffsHistorybyidError,
   updateStaffsAccessError,
   updateStaffsAccessSuccess,
   getAllStaffsRoleSuccess,
   getAllStaffsRoleError,
   staffGeneralSystemSetupSuccess,
   staffGeneralSystemSetupError,
   getAllStaffsSuccess,
   getAllStaffsError,
   getStaffsByDesignationSuccess,
   getStaffsByDesignationError,
   getStaffsByDesignationInspectionSuccess,
   getStaffsByDesignationInspectionError,
   getLoggedStaffDetailsSuccess,
   getLoggedStaffDetailsError
} from "../actions/staffActions";

import {
   getStaffs,
   addStaffs,
   getStaffbyid,
   updateStaffs,
   deleteStaffs,
   addStaffsRandompassword,
   getStaffsImage,
   getStaffsHistory,
   updateStaffAccess,
   getAllStaffsRoleAPI,
   StaffGeneralSystemSetup,
   GetAllStaffs,
   getStaffbyDesignation,
   getStaffbyDesignationForInspection,
   getLoggedStaffDetails
} from "../api/staffs";

function* handleGetStaffs(action) {
   try {
      const response = yield call(getStaffs, action.payload);
      yield put(getStaffsSuccess(response.data));
   } catch (error) {
      yield put(getStaffsError(error.response));
   }
}

function* handleGetStaffsImage(action) {
   try {
      const response = yield call(getStaffsImage, action.payload);
      yield put(getStaffimageSuccess(response.data));
   } catch (error) {
      yield put(getStaffimageError(error.response));
   }
}

function* handleGetStaffsbyid(action) {
   try {
      const response = yield call(getStaffbyid, action.payload);
      yield put(getStaffsbyidSuccess(response.data));
   } catch (error) {
      yield put(getStaffsbyidError(error.response));
   }
}

function* handleAddStaffs(action) {
   try {
      const response = yield call(addStaffs, action.payload);
      yield put(addStaffsSuccess(response));
   } catch (error) {
      yield put(addStaffsError(error.response));
   }
}

function* handleUpdateStaffsByid(action) {
   try {
      const response = yield call(updateStaffs, action.payload);
      yield put(updateStaffsSuccess(response));
      window.location.reload();
   } catch (error) {
      yield put(updateStaffsError(error.response));
   }
}

function* handleDeleteStaffs(action) {
   try {
      const response = yield call(deleteStaffs, action.payload);
      yield put(deleteStaffsSuccess(response));
   } catch (error) {
      yield put(deleteStaffsError(error.response));
   }
}

function* handleAddStaffsRandomPassword(action) {
   try {
      const response = yield call(addStaffsRandompassword, action.payload);
      yield put(addStaffsRandomPasswordSuccess(response));
   } catch (error) {
      yield put(addStaffsRandomPasswordError(error.response));
   }
}
function* handleGetStaffsHistorybyid(action) {
   const { staffhistory } = yield select((state) => state.staffs);
   try {
      let response = yield call(getStaffsHistory, action.payload);
      response.data.response.staffHistoryLog = [
         ...staffhistory.data,
         ...response.data.response.staffHistoryLog
      ];
      yield put(getStaffsHistorybyidSuccess(response.data));
   } catch (error) {
      yield put(getStaffsHistorybyidError(error.response));
   }
}

function* handleupdateStaffsAccess(action) {
   try {
      const response = yield call(updateStaffAccess, action.payload);
      yield put(updateStaffsAccessSuccess(response.data));
   } catch (error) {
      yield put(updateStaffsAccessError(error.response));
   }
}

function* handleGetAllStaffsRole() {
   try {
      const response = yield call(getAllStaffsRoleAPI);
      yield put(getAllStaffsRoleSuccess(response.data));
   } catch (error) {
      yield put(getAllStaffsRoleError(error.response));
   }
}

function* handleStaffGeneralSystemSetup(action) {
   try {
      const resp = yield call(StaffGeneralSystemSetup, action.payload);
      yield put(staffGeneralSystemSetupSuccess(resp));
   } catch (error) {
      yield put(staffGeneralSystemSetupError());
   }
}

function* handleGetAllStaffs(action) {
   try {
      const resp = yield call(GetAllStaffs, action.payload);
      yield put(getAllStaffsSuccess(resp));
   } catch {
      yield put(getAllStaffsError());
   }
}

function* handleGetStaffsByDesignation(action) {
   try {
      const response = yield call(getStaffbyDesignation, action.payload);
      yield put(getStaffsByDesignationSuccess(response.data));
   } catch (error) {
      yield put(getStaffsByDesignationError(error.response));
   }
}

function* handleGetStaffsByDesignationInspection(action) {
   try {
      const response = yield call(
         getStaffbyDesignationForInspection,
         action.payload
      );
      yield put(getStaffsByDesignationInspectionSuccess(response.data));
   } catch (error) {
      yield put(getStaffsByDesignationInspectionError(error.response));
   }
}

function* handleGetLoggedStaffDetails() {
   try {
      const resp = yield call(getLoggedStaffDetails);
      yield put(getLoggedStaffDetailsSuccess(resp.data));
   } catch {
      yield put(getLoggedStaffDetailsError());
   }
}

export default function* watchstaffs() {
   yield takeEvery("GET_STAFFS", handleGetStaffs);
   yield takeEvery("GET_STAFFS_IMAGE", handleGetStaffsImage);
   yield takeEvery("ADD_STAFFS", handleAddStaffs);
   yield takeEvery("ADD_STAFFS_RANDOM_PASSWORD", handleAddStaffsRandomPassword);
   yield takeEvery("GET_STAFFS_BYID", handleGetStaffsbyid);
   yield takeEvery("UPDATE_STAFFS", handleUpdateStaffsByid);
   yield takeEvery("DELETE_STAFFS", handleDeleteStaffs);
   yield takeEvery("GET_STAFFS_HISTORY_BYID", handleGetStaffsHistorybyid);
   yield takeEvery("UPDATE_STAFFS_ACCESS", handleupdateStaffsAccess);
   yield takeEvery("GET__ALL_STAFFS_ROLE", handleGetAllStaffsRole);
   yield takeEvery("STAFF_GENERAL_SYSTEM_SETUP", handleStaffGeneralSystemSetup);
   yield takeEvery("GET_ALL_STAFFS", handleGetAllStaffs);
   yield takeEvery("GET_STAFFS_BY_DESIGNATION", handleGetStaffsByDesignation);
   yield takeEvery(
      "GET_STAFFS_BY_DESIGNATION_INSPECTION",
      handleGetStaffsByDesignationInspection
   );
   yield takeEvery(
      "GET_LOGGED_STAFF_DETAILS_REQUEST",
      handleGetLoggedStaffDetails
   );
}
