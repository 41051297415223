export const UploadFileToGetData = (file, history) => ({
   type: "UPLOAD_FILE_TO_GET_DATA_REQUEST",
   file,
   history
});

export const UploadFileToGetDataSuccess = (payload) => ({
   type: "UPLOAD_FILE_TO_GET_DATA_SUCCESS",
   payload
});

export const UploadFileToGetDataError = () => ({
   type: "UPLOAD_FILE_TO_GET_DATA_ERROR"
});

export const SaveUploadedFileData = (payload) => ({
   type: "SAVE_UPLOADED_FILE_DATA",
   payload
});

export const SaveUploadedFileDataSuccess = () => ({
   type: "SAVE_UPLOADED_FILE_DATA_SUCCESS"
});

export const SaveUploadedFileDataError = () => ({
   type: "SAVE_UPLOADED_FILE_DATA_ERROR"
});

export const ExportData = (payload) => ({
   type: "EXPORT_DATA",
   payload
});

export const ExportDataSuccess = (url) => ({
   type: "EXPORT_DATA_SUCCESS",
   url
});

export const ExportDataError = () => ({
   type: "EXPORT_DATA_ERROR"
});

export const ResetExportData = () => ({
   type: "RESET_EXPORT_DATA"
});

export const GetFile = (fileKey) => ({
   type: "GET_FILE",
   fileKey
});
