import React from "react";
import { Layout, Row, Col, Spin, Space } from "antd";

export default function Loader() {
   return (
      <Layout style={{ height: "100vh" }}>
         <Layout.Content>
            <Row
               justify="space-around"
               align="middle"
               style={{ height: "100%" }}
            >
               <Col>
                  <Space size="middle">
                     <Spin size="large" />
                  </Space>
               </Col>
            </Row>
         </Layout.Content>
      </Layout>
   );
}
