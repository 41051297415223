import produce from "immer";

const INITIAL_STATE = {
   loading: false,
   updateProfileSuccess: false,
   adminGeneralSystemSetupSuccess: false,
   allCurrency: [],
   allCountryCallingCode: [],
   allTimeZone: [],
   isProfileSetupRequired: null,
   getAgencySetupOtpSuccess: false,
   validateAgencyOtpSuccess: null,
   updateAgencyTwoFactAuthSuccess: false,
   updateAgencyMobileNoSuccess: false,
   disableAuth: false,
   mobileNumber: "",
   userID: "",
   isStaffProfileSetupRequired: null,
   getStaffSetupOtpSuccess: false,
   updateStaffMobileNoSuccess: false,
   updateStaffTwoFactAuthSuccess: false,
   validateStaffOtpSuccess: null,
   agencyByIdDetails: null
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "UPDATE_AGENCY_PROFILE":
            draft.updateProfileSuccess = false;
            draft.loading = true;
            break;

         case "UPDATE_AGENCY_PROFILE_ERROR":
            draft.updateProfileSuccess = false;
            draft.loading = false;
            break;

         case "UPDATE_AGENCY_PROFILE_SUCCESS":
            draft.updateProfileSuccess = true;
            draft.userID = action.payload.data.response.userDetails.id;
            draft.loading = false;
            break;

         case "ADMIN_GENERAL_SYSTEM_SETUP":
            draft.adminGeneralSystemSetupSuccess = false;
            draft.loading = true;
            break;

         case "ADMIN_GENERAL_SYSTEM_SETUP_ERROR":
            draft.adminGeneralSystemSetupSuccess = false;
            draft.loading = false;
            break;

         case "ADMIN_GENERAL_SYSTEM_SETUP_SUCCESS":
            draft.adminGeneralSystemSetupSuccess = true;
            draft.loading = false;
            break;

         case "GET_ALL_COUNTRY_CALLING_CODE":
            break;

         case "GET_ALL_COUNTRY_CALLING_CODE_SUCCESS":
            draft.allCountryCallingCode = action.payload.data.response;
            break;

         case "GET_ALL_COUNTRY_CALLING_CODE_ERROR":
            draft.allCountryCallingCode = [];
            break;

         case "GET_ALL_CURRENCY":
            break;

         case "GET_ALL_CURRENCY_SUCCESS":
            draft.allCurrency = action.payload.data.response;
            break;

         case "GET_ALL_CURRENCY_ERROR":
            draft.allCurrency = [];
            break;

         case "GET_ALL_TIME_ZONE":
            break;

         case "GET_ALL_TIME_ZONE_SUCCESS":
            draft.allTimeZone = action.payload.data.response;
            break;

         case "GET_ALL_TIME_ZONE_ERROR":
            draft.allTimeZone = [];
            break;

         case "PROFILE_SETUP_REQUIRED":
            draft.isProfileSetupRequired = null;
            draft.loading = true;
            break;

         case "PROFILE_SETUP_REQUIRED_SUCCESS":
            draft.isProfileSetupRequired = action.payload.data.response;
            draft.loading = false;
            break;

         case "PROFILE_SETUP_REQUIRED_ERROR":
            draft.isProfileSetupRequired = false;
            draft.loading = false;
            break;

         case "SEND_AGENCY_OTP":
            draft.mobileNumber = action.payload.mobileNo;
            draft.getAgencySetupOtpSuccess = false;
            draft.loading = true;
            break;

         case "SEND_AGENCY_OTP_SUCCESS":
            draft.getAgencySetupOtpSuccess = true;
            draft.loading = false;
            break;

         case "SEND_AGENCY_OTP_ERROR":
            draft.getAgencySetupOtpSuccess = false;
            draft.loading = false;
            break;

         case "UPDATE_AGENCY_MOBILE_NO":
            draft.mobileNumber = action.payload.mobileNo;
            draft.updateAgencyMobileNoSuccess = false;
            draft.loading = true;
            break;

         case "UPDATE_AGENCY_MOBILE_NO_SUCCESS":
            draft.updateAgencyMobileNoSuccess = true;
            draft.loading = false;
            break;

         case "UPDATE_AGENCY_MOBILE_NO_ERROR":
            draft.updateAgencyMobileNoSuccess = false;
            draft.loading = false;
            break;

         case "UPDATE_AGENCY_TWO_FACT_AUTH":
            if (action.payload.twoFactorAuth === false) {
               draft.disableAuth = true;
            }
            draft.updateAgencyTwoFactAuthSuccess = false;
            draft.loading = true;
            break;

         case "UPDATE_AGENCY_TWO_FACT_AUTH_SUCCESS":
            if (draft.disableAuth) {
               draft.validateAgencyOtpSuccess = false;
            }
            draft.disableAuth = false;
            draft.updateAgencyTwoFactAuthSuccess = true;
            draft.loading = false;
            break;

         case "UPDATE_AGENCY_TWO_FACT_AUTH_ERROR":
            draft.disableAuth = false;
            draft.updateAgencyTwoFactAuthSuccess = false;
            draft.loading = false;
            break;

         case "VALIDATE_AGENCY_OTP":
            draft.validateAgencyOtpSuccess = false;
            draft.loading = true;
            break;

         case "VALIDATE_AGENCY_OTP_SUCCESS":
            draft.validateAgencyOtpSuccess = true;
            draft.loading = false;
            break;

         case "VALIDATE_AGENCY_OTP_ERROR":
            draft.validateAgencyOtpSuccess = false;
            draft.loading = false;
            break;

         case "STAFF_PROFILE_SETUP_REQUIRED":
            draft.isStaffProfileSetupRequired = null;
            draft.loading = true;
            break;

         case "STAFF_PROFILE_SETUP_REQUIRED_SUCCESS":
            draft.isStaffProfileSetupRequired = action.payload.data.response;
            draft.loading = false;
            break;

         case "STAFF_PROFILE_SETUP_REQUIRED_ERROR":
            draft.isStaffProfileSetupRequired = false;
            draft.loading = false;
            break;

         case "SEND_STAFF_OTP":
            draft.mobileNumber = action.payload.mobileNo;
            draft.getStaffSetupOtpSuccess = false;
            draft.loading = true;
            break;

         case "SEND_STAFF_OTP_SUCCESS":
            draft.getStaffSetupOtpSuccess = true;
            draft.loading = false;
            break;

         case "SEND_STAFF_OTP_ERROR":
            draft.getStaffSetupOtpSuccess = false;
            draft.loading = false;
            break;

         case "UPDATE_STAFF_MOBILE_NO":
            draft.mobileNumber = action.payload.mobileNo;
            draft.updateStaffMobileNoSuccess = false;
            draft.loading = true;
            break;

         case "UPDATE_STAFF_MOBILE_NO_SUCCESS":
            draft.updateStaffMobileNoSuccess = true;
            draft.loading = false;
            break;

         case "UPDATE_STAFF_MOBILE_NO_ERROR":
            draft.updateStaffMobileNoSuccess = false;
            draft.loading = false;
            break;

         case "UPDATE_STAFF_TWO_FACT_AUTH":
            if (action.payload.twoFactorAuth === false) {
               draft.disableAuth = true;
            }
            draft.updateStaffTwoFactAuthSuccess = false;
            draft.loading = true;
            break;

         case "UPDATE_STAFF_TWO_FACT_AUTH_SUCCESS":
            if (draft.disableAuth) {
               draft.validateAgencyOtpSuccess = false;
            }
            draft.disableAuth = false;
            draft.updateStaffTwoFactAuthSuccess = true;
            draft.loading = false;
            break;

         case "UPDATE_STAFF_TWO_FACT_AUTH_ERROR":
            draft.disableAuth = false;
            draft.updateStaffTwoFactAuthSuccess = false;
            draft.loading = false;
            break;

         case "VALIDATE_STAFF_OTP":
            draft.validateStaffOtpSuccess = false;
            draft.loading = true;
            break;

         case "VALIDATE_STAFF_OTP_SUCCESS":
            draft.validateStaffOtpSuccess = true;
            draft.loading = false;
            break;

         case "VALIDATE_STAFF_OTP_ERROR":
            draft.validateStaffOtpSuccess = false;
            draft.loading = false;
            break;

         case "GET_AGENCY_BY_ID":
            draft.loading = true;
            break;

         case "GET_AGENCY_BY_ID_SUCCESS":
            draft.loading = false;
            draft.agencyByIdDetails = action.payload.data.response;
            break;

         case "GET_AGENCY_BY_ID_ERROR":
            draft.loading = false;
            draft.agencyByIdDetails = null;
            break;

         default:
            return draft;
      }
   });
