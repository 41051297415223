import { put, call, takeEvery, select } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import {
   getTenantsSuccess,
   getTenantsError,
   createTenantSuccess,
   createTenantError,
   getTenantByEmailSuccess,
   getTenantByEmailError,
   getTenanatDetailsByIdSuccess,
   getTenantDetailsByIdError,
   deleteTenantByTenantIdError,
   deleteTenantByTenantIdSuccess,
   updateTenantByIdSuccess,
   updateTenantByIdError,
   updateBankDetailsSuccess,
   updateBankDetailsError,
   deleteBankDetailsSuccess,
   deleteBankDetailsError,
   addEmergencyDetailSuccess,
   addEmergencyDetailsError,
   addBankSuccess,
   addBankError,
   getPropertiesDetailsByTenantIdSuccess,
   getPropertiesDetailsByTenantIdError,
   getContractByIdSuccess,
   getContractByIdError,
   editContractError,
   editContractSuccess,
   addContractSuccess,
   addContractError,
   getDocumentsByIdSuccess,
   getDocumentsByError,
   addDocumentSuccess,
   addDocumentError,
   addNoteToTenantSuccess,
   addNoteToTenantError,
   getNoteByTenantSuccess,
   getNoteByTenantError,
   updateNoteToTenantSuccess,
   updateNoteToTenantError,
   deleteNoteByTenantSuccess,
   deleteNoteByTenantError,
   deleteContractSuccess,
   deleteContractError,
   deleteImgbyIdSuccess,
   deleteImgByIdError,
   updateTenantToArchiveFail,
   updateTenantToArchiveSuccess,
   getEmergencyDetailsForTenantSuccess,
   getEmergencyDetailsForTenantError,
   getTenantsPropertyBasicInfoSuccess,
   getTenantsPropertyBasicInfoError,
   deleteTenantSingleDocSuccess,
   deleteTenantSingleDocError,
   deleteTenantDocSuccess,
   deleteTenantDocError,
   getDropdownValueForTenantSuccess,
   getDropdownValueForTenantError,
   editTenantDocumentSuccess,
   editTenantDocumentError,
   getTenantsLazyError,
   getTenantsLazySuccess,
   getTenants,
   getContractById as getTenantContractById,
   setTenantContractNull,
   addTenantAdditionalInfoSuccess,
   addTenantAdditionalInfoError
} from "../actions/tenantActions";

import {
   getAllTenants,
   getAllTenantsLazy,
   addNewTenant,
   TenantDetailsById,
   TenantDetailsByEmail,
   deleteTenant,
   updateTenantById,
   updateBankDetails,
   deleteBankDetails,
   addEmergencyDetails,
   addBanks,
   PropertiesDetailsById,
   addContract,
   editTenantContract,
   TenantContracts,
   getDocuments,
   addDocument,
   addNoteToTenant,
   getNoteByTenant,
   updateNoteToTenant,
   deleteNoteByTenant,
   deleteContract,
   deleteimg,
   updateTenantToArchiveAPI,
   getEmergencyDetailsForTenant,
   TenantPropertyBasicInfoByID,
   deleteTenantDoc,
   deleteTenantSingleDoc,
   getDropdownValueForTenant,
   editTenantDoc,
   addAdditionalInfo
} from "../api/tenants";
import {
   getContractById,
   setLandlordContractsNull
} from "../actions/landlordActions";

function* handleGetTenants(action) {
   try {
      const resp = yield call(getAllTenants, action.payload);
      yield put(getTenantsSuccess(resp.data));
   } catch {
      yield put(getTenantsError());
   }
}

function* handleGetTenantsLazy(action) {
   try {
      const resp = yield call(getAllTenantsLazy, action.payload);
      yield put(getTenantsLazySuccess(resp.data));
   } catch (error) {
      yield put(getTenantsLazyError(error.response));
   }
}

function* handleAddTenants(action) {
   const { payload } = action;
   try {
      const resp = yield call(addNewTenant, payload);
      yield put(createTenantSuccess(resp.data));
   } catch (error) {
      yield put(createTenantError());
   }
}

function* handleGetTenantByEmail({ payload }) {
   try {
      const resp = yield call(TenantDetailsByEmail, payload);
      yield put(getTenantByEmailSuccess(resp.data));
   } catch (error) {
      yield put(getTenantByEmailError());
   }
}

function* handleGetTenantById({ payload }) {
   try {
      const resp = yield call(TenantDetailsById, payload);
      yield put(getTenanatDetailsByIdSuccess(resp.data));
   } catch (error) {
      yield put(getTenantDetailsByIdError());
   }
}

function* handleDeleteTenantByTenantId({ payload, history }) {
   try {
      yield call(deleteTenant, payload);
      yield put(deleteTenantByTenantIdSuccess());
      history.push("/agency/tenants");
      yield put(getTenants({ page: 1, limit: 10 }));
   } catch (error) {
      yield put(deleteTenantByTenantIdError());
   }
}

function* handleUpdateTenantById({ payload }) {
   const {
      currentPage,
      entriesPerPage,
      benefitType,
      profession,
      status,
      orderBy,
      searchBy
   } = yield select((state) => state.tenants.tenants);
   try {
      yield call(updateTenantById, payload);
      yield put(updateTenantByIdSuccess());
      yield put(
         getTenants({
            page: currentPage,
            limit: entriesPerPage,
            status: status,
            benefitType: benefitType,
            profession: profession,
            orderBy: orderBy,
            searchBy: searchBy
         })
      );
   } catch {
      yield put(updateTenantByIdError());
   }
}

function* handleDeleteBankDetails({ payload }) {
   try {
      yield call(deleteBankDetails, payload);
      yield put(deleteBankDetailsSuccess());
   } catch {
      yield put(deleteBankDetailsError());
   }
}

function* handleUpdateBankDetails({ payload }) {
   try {
      yield call(updateBankDetails, payload);
      yield put(updateBankDetailsSuccess());
   } catch {
      yield put(updateBankDetailsError());
   }
}

function* handleAddEmergency({ payload }) {
   try {
      yield call(addEmergencyDetails, payload);
      yield put(addEmergencyDetailSuccess());
   } catch {
      yield put(addEmergencyDetailsError());
   }
}

function* handleAddBank({ payload }) {
   try {
      yield call(addBanks, payload);
      yield put(addBankSuccess());
   } catch {
      yield put(addBankError());
   }
}

function* handleGetPropertiesByTenantId({ payload }) {
   try {
      const resp = yield call(PropertiesDetailsById, payload);
      yield put(getPropertiesDetailsByTenantIdSuccess(resp.data));
   } catch (error) {
      yield put(getPropertiesDetailsByTenantIdError());
   }
}

function* handleGetContractById({ payload }) {
   try {
      const resp = yield call(TenantContracts, payload);
      yield put(
         getContractByIdSuccess({
            contracts: payload.service_contract
               ? resp.data.response.tenantContract.content
               : resp.data.response.filteredData,
            pagination: resp.data.response.paginationDetails,
            service: payload.service_contract
         })
      );
   } catch {
      yield put(getContractByIdError());
   }
}

function* handleEditContract({ payload }) {
   try {
      yield call(editTenantContract, payload.data);
      yield put(editContractSuccess());
      yield put(
         getContractById({
            tenantId: payload.tenantId,
            propertyId: payload.id,
            page: 0,
            limit: 10
         })
      );
   } catch {
      yield put(editContractError());
   }
}

function* handleAddContract({ payload }) {
   try {
      yield call(addContract, payload.data);
      yield put(addContractSuccess());
      yield put(setTenantContractNull());
      yield put(
         getTenantContractById({
            tenantId: payload.tenantId,
            propertyId: payload.id,
            page: 0,
            limit: 10
         })
      );
   } catch {
      yield put(addContractError());
   }
}

function* handleGetDocumentsById({ tenantId }) {
   const tenant = yield select((state) => state.tenants);
   try {
      const res = yield call(getDocuments, tenantId);
      res.data.response.docSettings = [
         ...(tenant.documents?.documents?.docSettings || []),
         ...res.data.response.docSettings
      ];
      yield put(getDocumentsByIdSuccess(res.data));
   } catch {
      yield put(getDocumentsByError());
   }
}

function* handleAddDocument({ payload }) {
   try {
      yield call(addDocument, payload);
      yield put(addDocumentSuccess());
   } catch {
      yield put(addDocumentError());
   }
}

function* handleAddNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         createdBy: agencyId
      };
      const response = yield call(addNoteToTenant, notePayload);
      yield put(addNoteToTenantSuccess(response.data));
   } catch (error) {
      yield put(addNoteToTenantError(error));
   }
}

function* handleUpdateNote({ payload }) {
   try {
      const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
      const notePayload = {
         ...payload,
         updatedBy: agencyId
      };
      const response = yield call(updateNoteToTenant, notePayload);
      yield put(updateNoteToTenantSuccess(response.data));
   } catch (error) {
      yield put(updateNoteToTenantError(error));
   }
}

function* handleGetNoteByTenant({ tenantId }) {
   try {
      const response = yield call(getNoteByTenant, tenantId);
      yield put(getNoteByTenantSuccess(response.data));
   } catch (error) {
      yield put(getNoteByTenantError(error));
   }
}

function* handleDeleteNoteByTenant({ payload }) {
   try {
      const response = yield call(deleteNoteByTenant, payload);
      yield put(deleteNoteByTenantSuccess(response.data, payload));
   } catch (error) {
      yield put(deleteNoteByTenantError(error));
   }
}

function* handleDeleteContract({ payload }) {
   try {
      yield call(deleteContract, payload);
      yield put(deleteContractSuccess());
      if (payload?.role === "landlord") {
         yield put(setLandlordContractsNull());
         yield put(
            getContractById({
               landlordId: payload?.landlordId,
               propertyId: payload?.propertyId,
               page: 0,
               limit: 10
            })
         );
      } else {
         yield put(setTenantContractNull());
         yield put(
            getTenantContractById({
               tenantId: payload.tenantId,
               propertyId: payload.propertyId,
               page: 0,
               limit: 10
            })
         );
      }
   } catch (err) {
      yield put(deleteContractError());
   }
}

function* handleDeleteImg({ payload }) {
   try {
      yield call(deleteimg, payload);
      yield put(deleteImgbyIdSuccess());
   } catch {
      yield put(deleteImgByIdError());
   }
}

function* handleUpdateLandlordToArchive(action) {
   try {
      const response = yield call(updateTenantToArchiveAPI, action.payload);
      yield put(updateTenantToArchiveSuccess(response));
   } catch (error) {
      yield put(updateTenantToArchiveFail(error.response.data));
   }
}

function* handleGetEmergencyForTenant(action) {
   try {
      const response = yield call(getEmergencyDetailsForTenant, action.id);
      yield put(getEmergencyDetailsForTenantSuccess(response.data));
   } catch {
      yield put(getEmergencyDetailsForTenantError());
   }
}

function* handleGetTenantsPropertyById(action) {
   try {
      const resp = yield call(TenantPropertyBasicInfoByID, action.payload);
      yield put(getTenantsPropertyBasicInfoSuccess(resp.data));
   } catch (error) {
      yield put(getTenantsPropertyBasicInfoError(error.response));
   }
}

function* handleDeleteSingleDoc(action) {
   try {
      yield call(deleteTenantSingleDoc, action.payload);
      yield put(deleteTenantSingleDocSuccess());
   } catch {
      yield put(deleteTenantSingleDocError());
   }
}

function* handleDeleteDoc(action) {
   try {
      yield call(deleteTenantDoc, action.payload);
      yield put(deleteTenantDocSuccess());
   } catch {
      yield put(deleteTenantDocError());
   }
}

function* handleGetDropdownValueForTenant(action) {
   try {
      const resp = yield call(getDropdownValueForTenant, action.id);
      yield put(getDropdownValueForTenantSuccess(resp.data));
   } catch {
      yield put(getDropdownValueForTenantError());
   }
}

function* handleEditTenantDocument(action) {
   try {
      yield call(editTenantDoc, action.payload);
      yield put(editTenantDocumentSuccess());
   } catch {
      yield put(editTenantDocumentError());
   }
}

function* handleAddAdditionalInfo(action) {
   try {
      yield call(addAdditionalInfo, action.payload);
      yield put(addTenantAdditionalInfoSuccess());
      action?.history();
   } catch {
      yield put(addTenantAdditionalInfoError());
   }
}

export default function* watchTenantAction() {
   yield takeEvery("GET_TENANTS", handleGetTenants);
   yield takeEvery("GET_TENANTS_LAZY", handleGetTenantsLazy);

   yield takeEvery("ADD_TENANT", handleAddTenants);
   yield takeEvery("GET_TENANT_BY_EMAIL_REQUEST", handleGetTenantByEmail);
   yield takeEvery("GET_TENANT_BY_ID", handleGetTenantById);
   yield takeEvery("DELETE_TENANT_BY_ID", handleDeleteTenantByTenantId);
   yield takeEvery("UPDATE_TENANT_BY_ID", handleUpdateTenantById);
   yield takeEvery(
      "DELETE_TENANT_BANKDETAILS_REQUEST",
      handleDeleteBankDetails
   );
   yield takeEvery("UPDATE_TENANT_BANKDETAILS", handleUpdateBankDetails);
   yield takeEvery("ADD_EMERGENCY_DETAILS", handleAddEmergency);
   yield takeEvery("ADD_BANK", handleAddBank);
   yield takeEvery(
      "GET_PROPERTIES_BY_TENANT_ID",
      handleGetPropertiesByTenantId
   );
   yield takeEvery("ADD_CONTRACT", handleAddContract);
   yield takeEvery("EDIT_CONTRACT", handleEditContract);
   yield takeEvery("GET_CONTRACT_BY_ID", handleGetContractById);
   yield takeEvery("GET_DOCUMENTS_BY_ID", handleGetDocumentsById);
   yield takeEvery("ADD_DOCUMENT", handleAddDocument);
   yield takeEvery("ADD_NOTE_TO_TENANT", handleAddNote);
   yield takeEvery("GET_NOTE_BY_TENANT", handleGetNoteByTenant);
   yield takeEvery("UPDATE_NOTE_TO_TENANT", handleUpdateNote);
   yield takeEvery("DELETE_NOTE_BY_TENANT", handleDeleteNoteByTenant);
   yield takeEvery("DELETE_CONTRACT_BY_ID", handleDeleteContract);
   yield takeEvery("DELETE_IMG_BY_ID", handleDeleteImg);
   yield takeEvery("UPDATE_TENANT_TO_ARCHIVE", handleUpdateLandlordToArchive);
   yield takeEvery(
      "GET_EMERGENCY_DETAILS_FOR_TENANT",
      handleGetEmergencyForTenant
   );
   yield takeEvery(
      "GET_TENANTS_PROPERTY_BASIC_INFO",
      handleGetTenantsPropertyById
   );
   yield takeEvery("DELETE_TENANT_SINGLE_ID", handleDeleteSingleDoc);
   yield takeEvery("DELETE_TENANT_DOC", handleDeleteDoc);
   yield takeEvery(
      "GET_DROPDOWN_VALUE_FOR_TENANT",
      handleGetDropdownValueForTenant
   );
   yield takeEvery("EDIT_TENANT_DOCUMENT", handleEditTenantDocument);
   yield takeEvery("ADD_TENANT_ADDITIONAL_INFO", handleAddAdditionalInfo);
}
