const getGuarantorListByPropertyId = (payload) => ({
    type: "GET_GUARANTOR_LIST_BY_PROPERTY_ID",
    payload
});

const getGuarantorListByPropertyIdSuccess = (payload) => ({
    type: "GET_GUARANTOR_LIST_BY_PROPERTY_ID_SUCCESS",
    payload
});

const getGuarantorListByPropertyIdError = () => ({
    type: "GET_GUARANTOR_LIST_BY_PROPERTY_ID_ERROR"
});

const addGuarantor = (payload) => ({
    type: "ADD_GUARANTOR",
    payload
});

const addGuarantorSuccess = (payload) => ({
    type: "ADD_GUARANTOR_SUCCESS",
    payload
});

const addGuarantorError = () => ({
    type: "ADD_GUARANTOR_ERROR"
});

const deleteGuarantorById = (payload) => ({
    type: "DELETE_GUARANTOR_BY_ID",
    payload
});

const deleteGuarantorByIdSuccess = (payload) => ({
    type: "DELETE_GUARANTOR_BY_ID_SUCCESS",
    payload
});

const deleteGuarantorByIdError = () => ({
    type: "DELETE_GUARANTOR_BY_ID_ERROR"
});

const updateGuarantor = (payload) => ({
    type: "UPDATE_GUARANTOR",
    payload
});

const updateGuarantorSuccess = (payload) => ({
    type: "UPDATE_GUARANTOR_SUCCESS",
    payload
});

const updateGuarantorError = () => ({
    type: "UPDATE_GUARANTOR_ERROR"
});

export {
    getGuarantorListByPropertyId,
    getGuarantorListByPropertyIdSuccess,
    getGuarantorListByPropertyIdError,
    addGuarantor,
    addGuarantorSuccess,
    addGuarantorError,
    deleteGuarantorById,
    deleteGuarantorByIdSuccess,
    deleteGuarantorByIdError,
    updateGuarantor,
    updateGuarantorSuccess,
    updateGuarantorError
};
