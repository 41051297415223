import React, { useEffect, useState } from "react";
import Loader from "../components/shared/Loader";
import { useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import LogoutRoute from "../components/auth/LogoutRoute";

const AuthLayout = React.lazy(() => import("../components/auth/AuthLayout"));
const AgencyLayout = React.lazy(() =>
   import("../components/agency/AgencyLayout")
);
const TrailLayout = React.lazy(() =>
   import("../components/agency/pages/Trail/TrailPeriod")
);

export const MainRoutes = () => {
   const [locationPath, setLocationPath] = useState();
   const location = useLocation();

   const isLoggedin = useSelector(
      (state) => state.auth.access_token,
      shallowEqual
   );

   const { logout } = useSelector((state) => state.misc);

   const subscriptionMethod = () => {
      const subscriptionName = jwt_decode(localStorage.getItem("access_token"))
         ?.subscriptionName;
      if (
         subscriptionName == "TrialEnded" ||
         subscriptionName == "Cancelled" ||
         subscriptionName == "Suspended" ||
         subscriptionName == "Expired"
      ) {
         return (
            <React.Suspense fallback={<Loader />}>
               <TrailLayout />
            </React.Suspense>
         );
      } else {
         return (
            <React.Suspense fallback={<Loader />}>
               <AgencyLayout />
            </React.Suspense>
         );
      }
   };

   useEffect(() => {
      setLocationPath(location.pathname);
   }, [location]);

   return logout.loading ? (
      <React.Suspense fallback={<Loader />}>
         <LogoutRoute />
      </React.Suspense>
   ) : locationPath &&
     !(
        locationPath.toString().includes("auth/verify") ||
        locationPath.toString().includes("auth/reset-password")
     ) ? (
      !isLoggedin ? (
         <React.Suspense fallback={<Loader />}>
            <AuthLayout />
         </React.Suspense>
      ) : (
         subscriptionMethod()
      )
   ) : (
      <React.Suspense fallback={<Loader />}>
         <AuthLayout />
      </React.Suspense>
   );
};
