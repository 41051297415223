export const getAllRentPayableInvoiceAgainstProperty = (payload) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY",
   payload
});

export const getAllRentPayableInvoiceAgainstPropertySuccess = (response) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_SUCCESS",
   response
});

export const getAllRentPayableInvoiceAgainstPropertySetDataNull = (
   response
) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_DATA_NULL",
   response
});

export const getAllRentPayableInvoiceAgainstPropertyLoadFalse = (payload) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_lOAD_FALSE",
   payload
});

export const getAllRentPayableInvoiceAgainstPropertyFail = (response) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_PROPERTY_FAIL",
   response
});

export const getAllRentPayableInvoiceAgainstTenant = (payload) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT",
   payload
});

export const getAllRentPayableInvoiceAgainstTenantSuccess = (response) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_SUCCESS",
   response
});

export const getAllRentPayableInvoiceAgainstTenantLoadFalse = (payload) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_lOAD_FALSE",
   payload
});

export const getAllRentPayableInvoiceAgainstTenantSetDataNull = (response) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_DATA_NULL",
   response
});

export const getAllRentPayableInvoiceAgainstTenantFail = (response) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_TENANT_FAIL",
   response
});

export const getAllRentPayableInvoiceAgainstLandlord = (payload) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD",
   payload
});

export const getAllRentPayableInvoiceAgainstLandlordSuccess = (response) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_SUCCESS",
   response
});

export const getAllRentPayableInvoiceAgainstlandlordLoadFalse = (payload) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_lOAD_FALSE",
   payload
});

export const getAllRentPayableInvoiceAgainstlandlordSetDataNull = (
   response
) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_DATA_NULL",
   response
});

export const getAllRentPayableInvoiceAgainstLandlordFail = (response) => ({
   type: "GET_ALL_RENT_PAYABLE_INVOICE_AGAINST_LANDLORD_FAIL",
   response
});

export const getAllAccountReceivableInvoice = (payload) => ({
   type: "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE",
   payload
});

export const getAllAccountReceivableInvoiceLoadFalse = (payload) => ({
   type: "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_lOAD_FALSE",
   payload
});

export const getAllAccountReceivableInvoiceSetDataNull = (response) => ({
   type: "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_DATA_NULL",
   response
});
export const getAllAccountReceivableInvoiceSuccess = (response) => ({
   type: "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_SUCCESS",
   response
});

export const getAllAccountReceivableInvoiceFail = (response) => ({
   type: "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_FAIL",
   response
});

export const getInvoiceDetails = (payload) => ({
   type: "GET_INVOICE_DETAILS",
   payload
});

export const getInvoiceDetailsSuccess = (response) => ({
   type: "GET_INVOICE_DETAILS_SUCCESS",
   response
});

export const getInvoiceDetailsFail = (response) => ({
   type: "GET_INVOICE_DETAILS_FAIL",
   response
});

export const getItemAddTypes = () => ({
   type: "GET_ITEM_ADD_TYPES"
});

export const getItemAddTypesSuccess = (response) => ({
   type: "GET_ITEM_ADD_TYPES_SUCCESS",
   response
});

export const getItemAddTypesFail = (response) => ({
   type: "GET_ITEM_ADD_TYPES_FAIL",
   response
});

export const updateInvoiceDetails = (payload) => ({
   type: "UPDATE_INVOICE_DETAILS",
   payload
});

export const updateInvoiceDetailsSuccess = (response) => ({
   type: "UPDATE_INVOICE_DETAILS_SUCCESS",
   response
});

export const updateInvoiceDetailsFail = (response) => ({
   type: "UPDATE_INVOICE_DETAILS_FAIL",
   response
});

export const addReceiptDetails = (payload) => ({
   type: "ADD_RECEIPT_DETAILS",
   payload
});

export const addReceiptDetailsSuccess = (response) => ({
   type: "ADD_RECEIPT_DETAILS_SUCCESS",
   response
});

export const addReceiptDetailsFail = (response) => ({
   type: "ADD_RECEIPT_DETAILS_FAIL",
   response
});

export const deleteReceiptDetails = (payload) => ({
   type: "DELETE_RECEIPT_DETAILS",
   payload
});

export const deleteReceiptDetailsSuccess = (response) => ({
   type: "DELETE_RECEIPT_DETAILS_SUCCESS",
   response
});

export const deleteReceiptDetailsFail = (response) => ({
   type: "DELETE_RECEIPT_DETAILS_FAIL",
   response
});

export const addNewInvoice = (payload) => ({
   type: "ADD_NEW_INVOICE",
   payload
});

export const addNewInvoiceSuccess = (response) => ({
   type: "ADD_NEW_INVOICE_SUCCESS",
   response
});

export const addNewInvoiceFail = (response) => ({
   type: "ADD_NEW_INVOICE_FAIL",
   response
});

export const deleteInvoiceDetails = (payload) => ({
   type: "DELETE_INVOICE_DETAILS",
   payload
});

export const deleteInvoiceDetailsSuccess = (response) => ({
   type: "DELETE_INVOICE_DETAILS_SUCCESS",
   response
});

export const deleteInvoiceDetailsFail = (response) => ({
   type: "DELETE_INVOICE_DETAILS_FAIL",
   response
});

export const changeInvoiceStatusToCancel = (payload) => ({
   type: "CHANGE_INVOICE_STATUS_TO_CANCEL",
   payload
});

export const changeInvoiceStatusToCancelSuccess = (response) => ({
   type: "CHANGE_INVOICE_STATUS_TO_CANCEL_SUCCESS",
   response
});

export const changeInvoiceStatusToCancelFail = (response) => ({
   type: "CHANGE_INVOICE_STATUS_TO_CANCEL_FAIL",
   response
});

export const exportInvoiceData = (payload) => ({
   type: "EXPORT_INVOICE_DATA",
   payload
});

//notes

export const addNoteToInvoice = (payload) => ({
   type: "ADD_NOTE_TO_INVOICE",
   payload
});

export const addNoteToInvoiceSuccess = (payload) => ({
   type: "ADD_NOTE_TO_INVOICE_SUCCESS",
   payload
});

export const addNoteToInvoiceError = (error) => ({
   type: "ADD_NOTE_TO_INVOICE_ERROR",
   error
});

export const updateNoteToInvoice = (payload) => ({
   type: "UPDATE_NOTE_TO_INVOICE",
   payload
});

export const updateNoteToInvoiceError = (error) => ({
   type: "UPDATE_NOTE_TO_INVOICE_ERROR",
   error
});

export const updateNoteToInvoiceSuccess = (payload) => ({
   type: "UPDATE_NOTE_TO_INVOICE_SUCCESS",
   payload
});

export const getNoteByInvoice = (InvoiceId) => ({
   type: "GET_NOTE_BY_INVOICE",
   InvoiceId
});

export const getNoteByInvoiceSuccess = (payload) => ({
   type: "GET_NOTE_BY_INVOICE_SUCCESS",
   payload
});

export const getNoteByInvoiceError = (error) => ({
   type: "GET_NOTE_BY_INVOICE_ERROR",
   error
});

export const deleteNoteByInvoice = (payload) => ({
   type: "DELETE_NOTE_BY_INVOICE",
   payload
});

export const deleteNoteByInvoiceSuccess = (payload) => ({
   type: "DELETE_NOTE_BY_INVOICE_SUCCESS",
   payload
});

export const deleteNoteByInvoiceError = (error) => ({
   type: "DELETE_NOTE_BY_INVOICE_ERROR",
   error
});

// comment

export const GetCommentByInvoiceId = (id) => ({
   type: "GET_COMMENT_BY_INVOICE_ID",
   id
});

export const GetCommentByInvoiceIdSuccess = (payload) => ({
   type: "GET_COMMENT_BY_INVOICE_ID_SUCCESS",
   payload
});

export const GetCommentByInvoiceIdError = () => ({
   type: "GET_COMMENT_BY_INVOICE_ID_ERROR"
});

export const SaveCommentByInvoiceId = (payload) => ({
   type: "SAVE_COMMENT_BY_INVOICE_ID",
   payload
});

export const saveCommentByInvoiceIdSuccess = () => ({
   type: "SAVE_COMMENT_BY_INVOICE_ID_SUCCESS"
});

export const saveCommentByInvoiceIdError = () => ({
   type: "SAVE_COMMENT_BY_INVOICE_ID_ERROR"
});

export const deleteCommentByInvoiceId = (id) => ({
   type: "DELETE_COMMENT_BY_INVOICE_ID",
   id
});

export const deleteCommentByInvoiceIdSuccess = () => ({
   type: "DELETE_COMMENT_BY_INVOICE_ID_SUCCESS"
});

export const deleteCommentByInvoiceIdError = () => ({
   type: "DELETE_COMMENT_BY_INVOICE_ID_ERROR"
});

export const saveAsReadByInvoiceId = (payload) => ({
   type: "SAVE_AS_READ_BY_INVOICE_ID",
   payload
});

export const saveAsReadByInvoiceIdSuccess = () => ({
   type: "SAVE_AS_READ_BY_INVOICE_ID_SUCCESS"
});

export const saveAsReadByInvoiceIdError = () => ({
   type: "SAVE_AS_READ_BY_INVOICE_ID_ERROR"
});

// reply

export const saveReplyByInvoiceId = (payload) => ({
   type: "SAVE_REPLY_BY_INVOICE_ID",
   payload
});

export const saveReplyByInvoiceIdSuccess = () => ({
   type: "SAVE_REPLY_BY_INVOICE_ID_SUCCESS"
});

export const saveReplyByInvoiceIdError = () => ({
   type: "SAVE_REPLY_BY_INVOICE_ID_ERROR"
});

export const deleteReplyByInvoiceId = (id) => ({
   type: "DELETE_REPLY_BY_INVOICE_ID",
   id
});

export const deleteReplyByInvoiceIdSuccess = () => ({
   type: "DELETE_REPLY_BY_INVOICE_ID_SUCCESS"
});

export const deleteReplyByInvoiceIdError = () => ({
   type: "DELETE_REPLY_BY_INVOICE_ID_ERROR"
});

export const saveEmojiByInvoiceId = (payload) => ({
   type: "SAVE_EMOJI_BY_INVOICE_ID",
   payload
});

export const saveEmoijByInvoiceIdSuccess = () => ({
   type: "SAVE_EMOJI_BY_INVOICE_ID_SUCCESS"
});

export const saveEmojiByInvoiceIdError = () => ({
   type: "SAVE_EMOJI_BY_INVOICE_ID_ERROR"
});

export const getBase64ForCommentByInvoiceId = (url) => ({
   type: "GET_BASE64_FOR_COMMENT_BY_INVOICE_ID",
   url
});

export const getBase64ForCommentByInvoiceIdSuccess = (payload) => ({
   type: "GET_BASE64_FOR_COMMENT_BY_INVOICE_ID_SUCCESS",
   payload
});

export const getBase64ForCommentByInvoiceIdError = () => ({
   type: "GET_BASE64_FOR_COMMENT_BY_INVOICE_ID_ERROR"
});

//serviceType

export const getAllServiceType = (payload) => ({
   type: "GET_ALL_SERVICE_TYPE",
   payload
});

export const getAllServiceTypeSetNull = () => ({
   type: "GET_ALL_SERVICE_TYPE_SET_NULL"
});

export const getAllServiceTypeSuccess = (response) => ({
   type: "GET_ALL_SERVICE_TYPE_SUCCESS",
   response
});

export const getAllServiceTypeFail = (response) => ({
   type: "GET_ALL_SERVICE_TYPE_FAIL",
   response
});

export const addServiceType = (payload) => ({
   type: "ADD_SERVICE_TYPE",
   payload
});

export const addServiceTypeSuccess = (response) => ({
   type: "ADD_SERVICE_TYPE_SUCCESS",
   response
});

export const addServiceTypeFail = (response) => ({
   type: "ADD_SERVICE_TYPE_FAIL",
   response
});

// download invoice pdf
export const downloadInvoicePDFRequest = (payload) => ({
   type: "DOWNLOAD_INVOICE_PDF_REQUEST",
   payload
});

export const downloadInvoicePDFSuccess = () => ({
   type: "DOWNLOAD_INVOICE_PDF_SUCCESS"
});

export const downloadInvoicePDFError = () => ({
   type: "DOWNLOAD_INVOICE_PDF_ERROR"
});

export const sendInvoiceRequest = (payload) => ({
   type: "SEND_INVOICE_REQUEST",
   payload
});

export const sendInvoiceSuccess = () => ({
   type: "SEND_INVOICE_SUCCESS"
});

export const sendInvoiceError = () => ({
   type: "SEND_INVOICE_ERROR"
});
