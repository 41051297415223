import produce from "immer";

const INITIAL_STATE = {
   property: {
      loading: false,
      data: {
         docSettings: [],
         pagination: {}
      }
   },
   tenant: {
      loading: false,
      data: {
         docSettings: [],
         pagination: {}
      }
   },
   landlord: {
      loading: false,
      data: {
         docSettings: [],
         pagination: {}
      }
   }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_ALL_PROPERTY_DOCUMENTS":
            draft.property.loading = true;
            break;
         case "GET_ALL_PROPERTY_DOCUMENTS_SUCCESS":
            draft.property.loading = false;
            draft.property.data.docSettings = action.payload.docSettings;
            draft.property.data.pagination = action.payload.paginationDetails;
            break;
         case "GET_ALL_PROPERTY_DOCUMENTS_ERROR":
            draft.property.loading = false;
            break;
         case "GET_ALL_TENANT_DOCUMENTS":
            draft.tenant.loading = true;
            break;
         case "GET_ALL_TENANT_DOCUMENTS_SUCCESS":
            draft.tenant.loading = false;
            draft.tenant.data.docSettings = action.payload.docSettings;
            draft.tenant.data.pagination = action.payload.paginationDetails;
            break;
         case "GET_ALL_TENANT_DOCUMENTS_ERROR":
            draft.tenant.loading = false;
            break;
         case "GET_ALL_LANDLORD_DOCUMENTS":
            draft.landlord.loading = true;
            break;
         case "GET_ALL_LANDLORD_DOCUMENTS_SUCCESS":
            draft.landlord.loading = false;
            draft.landlord.data.docSettings = action.payload.docSettings;
            draft.landlord.data.pagination = action.payload.paginationDetails;
            break;
         case "GET_ALL_LANDLORD_DOCUMENTS_ERROR":
            draft.landlord.loading = false;
            break;
         case "SET_DOCUMENTS_NULL":
            switch (action.payload) {
               case "PROPERTY":
                  draft.property.data.docSettings = [];
                  draft.property.data.pagination = {};
                  break;
               case "TENANT":
                  draft.tenant.data.docSettings = [];
                  draft.tenant.data.pagination = {};
                  break;
               case "LANDLORD":
                  draft.landlord.data.docSettings = [];
                  draft.landlord.data.pagination = [];
                  break;
            }
            break;
      }
   });
