import produce from "immer";
import jwt_decode from "jwt-decode";

const INITIAL_STATE = {
   loading: false,
   access_token: null,
   success: false,
   error: null,
   errorCount: 0,
   staffErrorCount: 0,
   message: null,
   isStaff: false,
   staffRole: null,
   notificationLoading: false,
   oldPassword: false
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "SIGNIN_REQUEST":
            draft.loading = true;
            draft.error = null;
            break;

         case "SIGNIN_SUCCESS":
            draft.access_token = action.response.data.accessToken;
            draft.loading = false;
            draft.error = null;
            draft.isStaff = false;
            break;

         case "SIGNIN_ERROR":
            draft.loading = false;
            draft.error = action.error.data;
            draft.errorCount++;
            break;

         case "STAFF_SIGNIN_REQUEST":
            draft.loading = true;
            draft.error = null;
            break;

         case "STAFF_SIGNIN_SUCCESS":
            draft.access_token = action.response.data.response.accessToken;
            draft.loading = false;
            draft.error = null;
            draft.isStaff = true;
            draft.staffRole =
               jwt_decode(localStorage.getItem("access_token")) &&
               jwt_decode(localStorage.getItem("access_token")).roleId;
            break;

         case "STAFF_SIGNIN_ERROR":
            draft.loading = false;
            draft.error = action.error;
            draft.staffErrorCount++;
            break;

         case "SIGNUP_REQUEST":
            draft.loading = true;
            draft.error = null;
            break;

         case "SIGNUP_SUCCESS":
            draft.loading = false;
            draft.success = true;
            draft.message = action.response.data.message;
            break;

         case "SIGNUP_ERROR":
            draft.error = action.error;
            draft.loading = false;
            draft.success = false;
            break;

         case "VERIFY_EMAIL_REQUEST":
            draft.loading = true;
            draft.success = false;
            draft.error = null;
            break;

         case "VERIFY_EMAIL_SUCCESS":
            draft.loading = false;
            draft.success = true;
            draft.error = null;
            break;

         case "VERIFY_EMAIL_ERROR":
            draft.loading = false;
            draft.success = true;
            draft.error = action.error.message;
            break;

         case "RESET_AUTH_STATUS":
            draft.error = null;
            draft.message = null;
            draft.loading = false;
            draft.success = false;
            break;

         case "FORGOT_PASSWORD_REQUEST":
            draft.loading = true;
            draft.error = null;
            draft.success = false;
            break;

         case "FORGOT_PASSWORD_SUCCESS":
            draft.loading = false;
            draft.success = action.response.data.message;
            break;

         case "FORGOT_PASSWORD_ERROR":
            draft.loading = false;
            draft.error = action.error;
            break;

         case "RESET_PASSWORD_REQUEST":
            draft.loading = true;
            draft.error = null;
            draft.success = false;
            break;

         case "RESET_PASSWORD_SUCCESS":
            draft.loading = false;
            draft.success = action.response.data.message;
            break;

         case "RESET_PASSWORD_ERROR":
            draft.loading = false;
            draft.error = action.error;
            break;

         case "RESET_PASSWORD_STAFF_REQUEST":
            draft.loading = true;
            draft.error = null;
            draft.success = false;
            break;

         case "RESET_PASSWORD_STAFF_SUCCESS":
            draft.loading = false;
            draft.success = action.response.data.message;
            break;

         case "RESET_PASSWORD_STAFF_ERROR":
            draft.loading = false;
            draft.error = action.error;
            break;

         case "CLEAR_ACCESS_TOKEN":
            draft.access_token = null;
            break;

         case "SUBSCRIBE_TOKEN_TO_FIREBASE":
            draft.notificationLoading = true;
            break;

         case "SUBSCRIBE_TOKEN_TO_FIREBASE_SUCCESS":
            draft.notificationLoading = false;
            break;

         case "VALIDATE_CURRENT_PASSWORD_SUCCESS":
            draft.oldPassword = action.payload.response;
            break;

         default:
            return draft;
      }
   });
