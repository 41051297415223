import produce from "immer";

const INITIAL_STATE = {
   documentType: [],
   loading: false,
   addDocument: {
      loading: false
   },
   uploadedDocTypes: {
      documents: [],
      landlordDoc: [],
      staffDoc: [],
      propertyDoc: [],
      type: null,
      loading: false
   },
   invoiceSettings: {}
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_DOCUMENT_TYPE_BY_CATAGORY":
            draft.loading = true;
            break;
         case "GET_DOCUMENT_TYPE_BY_CATAGORY_SUCCESS":
            draft.documentType = action.payload.responseBody.documentTypes;
            draft.loading = false;
            break;

         case "GET_DOCUMENT_TYPE_BY_CATAGORY_ERROR":
            draft.loading = false;
            break;

         case "ADD_DOCUMENT_TYPE":
            draft.addDocument.loading = true;
            break;

         case "ADD_DOCUMENT_TYPE_SUCCESS":
            draft.addDocument.loading = false;
            break;

         case "ADD_DOCUMENT_TYPE_ERROR":
            draft.addDocument.loading = false;
            break;

         case "GET_UPLOADED_DOC_TYPES":
            draft.uploadedDocTypes.loading = true;
            break;

         case "GET_UPLOADED_DOC_TYPES_SUCCESS_TENANT":
            draft.uploadedDocTypes.documents =
               action.payload.responseBody.documentSettings.documentTypes;
            draft.uploadedDocTypes.loading = false;
            break;

         case "GET_UPLOADED_DOC_TYPES_SUCCESS_LANDLORD":
            draft.uploadedDocTypes.landlordDoc =
               action.payload.responseBody.documentSettings.documentTypes;
            draft.uploadedDocTypes.loading = false;
            break;

         case "GET_UPLOADED_DOC_TYPES_SUCCESS_PROPERTY":
            draft.uploadedDocTypes.propertyDoc =
               action.payload.responseBody.documentSettings.documentTypes;
            draft.uploadedDocTypes.loading = false;
            break;

         case "GET_UPLOADED_DOC_TYPES_SUCCESS_STAFF":
            draft.uploadedDocTypes.staffDoc =
               action.payload.responseBody.documentSettings.documentTypes;
            draft.uploadedDocTypes.loading = false;
            break;

         case "GET_UPLOADED_DOC_TYPES_ERROR":
            draft.uploadedDocTypes.loading = false;
            break;

         case "GET_INVOICE_SETTINGS_SUCCESS":
            draft.invoiceSettings = action.payload.response;
            break;

         default:
            return draft;
      }
   });
