import produce from "immer";
import { ImageExt, SupportExt } from "../services/uploadService";

const INITIAL_STATE = {
   uploadedFile: [],
   uploadedFileCount: 0,
   currentlyUploadedIds: [],
   FailedFileObj: [],
   blobUrlReqId: [],
   singleFile: {
      response: null,
      loading: false
   },
   profilePic: {},
   profilePicLoading: false,
   supportExt: SupportExt,
   max: 25,
   blobUrls: {}
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "UPLOAD_SINGLE_FILE_REQUEST":
            draft.supportExt.includes(
               action.payload.file.name.split(".").pop()
            ) &&
               draft.max >= draft.uploadedFileCount &&
               draft.uploadedFileCount++;
            if (action.payload.ext) {
               draft.supportExt = action.payload.ext;
            } else {
               draft.supportExt = SupportExt;
            }
            if (action.payload.max) {
               draft.max = action.payload.max;
            } else {
               draft.max = 25;
            }
            break;
         case "UPLOAD_SINGLE_FILE_SUCCESS": {
            let fileArrIndex = draft.uploadedFile.findIndex(
               (obj) => obj.token == action.payload.token
            );
            draft.currentlyUploadedIds.push(action.payload.file.response.id);
            draft.uploadedFile[fileArrIndex] = {
               ...draft.uploadedFile[fileArrIndex],
               ...action.payload.file.response
            };
            break;
         }
         case "UPLOAD_SINGLE_FILE_ERROR":
            draft.FailedFileObj.push(action.file);
            break;
         case "UPLOAD_FILE_PROGRESS": {
            let fileArrIndex = draft.uploadedFile.findIndex(
               (obj) => obj.token == action.payload.token
            );
            if (fileArrIndex == -1) {
               draft.uploadedFile.push(action.payload);
            } else {
               draft.uploadedFile[fileArrIndex].percentage =
                  action.payload.percentage;
            }
            break;
         }
         case "GET_SINGLE_FILE_REQUEST":
            draft.singleFile.loading = true;
            break;
         case "GET_SINGLE_FILE_SUCCESS":
            draft.singleFile.response = action.fileObj.response;
            draft.singleFile.loading = false;
            break;
         case "GET_SINGLE_FILE_ERROR":
            draft.singleFile.loading = false;
            break;
         case "DELETE_FILE_BY_ID":
            if (action.payload.ids.length == 1) {
               draft.uploadedFileCount--;
               draft.uploadedFile.splice(
                  draft.uploadedFile.findIndex(
                     (obj) => obj.id == action.payload.ids[0]
                  ),
                  1
               );
               draft.currentlyUploadedIds = draft.currentlyUploadedIds.filter(
                  (id) => id != action.payload.ids[0]
               );
            } else {
               draft.uploadedFile = [];
            }
            break;
         case "CLEAR_UPLOADED_FILES":
            draft.uploadedFile = [];
            draft.currentlyUploadedIds = [];
            break;
         case "SET_UPLOADED_FILES": {
            if (action.files) {
               draft.uploadedFileCount = action.files.length;
               let files = action.files;
               draft.uploadedFile.unshift(
                  ...files?.map((val) => {
                     return { ...val, catagory: action.catagory };
                  })
               );
            } else {
               draft.uploadedFileCount = 0;
               draft.uploadedFile = [];
            }
            break;
         }
         case "UPLOAD_PROFILE_PIC":
            draft.profilePicLoading = true;
            draft.supportExt = ImageExt;
            break;
         case "UPLOAD_PROFILE_PIC_SUCCESS":
            draft.profilePicLoading = false;
            draft.profilePic[action.payload.name] = action.payload.data;
            break;
         case "UPLOAD_PROFILE_PIC_ERROR":
            draft.profilePicLoading = false;
            break;
         case "CLEAR_PROFILE_PIC":
            delete draft.profilePic[action.payload.name];
            break;
         case "SET_TEMP_BLOB_URL_ID":
            draft.blobUrlReqId.push(action.id);
            break;
         case "SET_BLOB_URL_BY_ID_SUCCESS":
            draft.blobUrls[action.payload.id] = action.payload.url;
            break;
      }
   });
