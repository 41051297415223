import { put, call, takeEvery } from "redux-saga/effects";

import {
   getOccupantsByTenantIdPropertyIdSuccess,
   getOccupantsByTenantIdPropertyIdError,
   addOccupantSuccess,
   addOccupantError,
   deleteOccupantByIdSuccess,
   deleteOccupantByIdError,
   updateOccupantSuccess,
   updateOccupantError,
   getUnitDetailsByTenantIdPropertyIdSuccess,
   getUnitDetailsByTenantIdPropertyIdError
} from "../actions/occupantAction";

import {
   AddOccupant,
   DeleteOccupant,
   OccupantsByTenantIdPropertyId,
   UnitDetailsByTenantIdPropertyId,
   UpdateOccupant
} from "../api/occupant";

function* handleGetOccupantLisyByPropertyId({ payload }) {
   try {
      const resp = yield call(OccupantsByTenantIdPropertyId, payload);
      yield put(getOccupantsByTenantIdPropertyIdSuccess(resp.data));
   } catch (error) {
      yield put(getOccupantsByTenantIdPropertyIdError());
   }
}

function* handleAddOccupant(action) {
   const { payload } = action;
   try {
      const resp = yield call(AddOccupant, payload);
      yield put(addOccupantSuccess(resp.data));
   } catch (error) {
      yield put(addOccupantError());
   }
}

function* handleDeleteOccupant({ payload }) {
   try {
      yield call(DeleteOccupant, payload);
      yield put(deleteOccupantByIdSuccess());
   } catch (error) {
      yield put(deleteOccupantByIdError());
   }
}

function* handleUpdateOccupant(action) {
   const { payload } = action;
   try {
      const resp = yield call(UpdateOccupant, payload);
      yield put(updateOccupantSuccess(resp.data));
   } catch (error) {
      yield put(updateOccupantError());
   }
}

function* handleGetUnitDetailsByTenantIdPropertyId({ payload }) {
   try {
      const resp = yield call(UnitDetailsByTenantIdPropertyId, payload);
      yield put(getUnitDetailsByTenantIdPropertyIdSuccess(resp.data));
   } catch (error) {
      yield put(getUnitDetailsByTenantIdPropertyIdError());
   }
}

export default function* watchOccupantAction() {
   yield takeEvery(
      "GET_OCCUPANTS_BY_TENANT_ID_PROPERTY_ID",
      handleGetOccupantLisyByPropertyId
   );
   yield takeEvery("ADD_OCCUPANT", handleAddOccupant);
   yield takeEvery("DELETE_OCCUPANT_BY_ID", handleDeleteOccupant);
   yield takeEvery("UPDATE_OCCUPANT", handleUpdateOccupant);
   yield takeEvery(
      "GET_UNIT_DETAILS_BY_TENANT_ID_PROPERTY_ID",
      handleGetUnitDetailsByTenantIdPropertyId
   );
}
