import produce from "immer";

const INITIAL_STATE = {
   view: null,
   open: false,
   payload: null
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "OPEN_MODAL":
            draft.view = action.modal;
            draft.payload = action.payload;
            break;

         case "CLOSE_MODAL":
            draft.view = null;
            draft.payload = null;
            break;

         case "OPEN_ADD_TASK":
            draft.open = true;
            break;

         case "CLOSE_ADD_TASK":
            draft.open = false;
            break;

         default:
            return draft;
      }
   });
