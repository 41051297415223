import API from "./API";

export const getAllTenants = async (payload) => {
   let body = {};
   payload.benefitType && (body.benefitType = payload.benefitType);
   payload.profession && (body.profession = payload.profession);
   payload?.status &&
      (body.status =
         payload?.status == "active"
            ? 1
            : payload?.status == "prospect"
            ? 2
            : 3);
   payload.sortBy && (body.sortBy = payload.sortBy);
   payload.orderBy && (body.orderBy = payload.orderBy);
   payload.searchBy && (body.searchBy = payload.searchBy);
   body.isArchive = payload.isArchive || false;
   return await API.get(
      `/api/tenant/getAllTenantV1/${payload.page - 1}/${payload.limit}`,
      { params: body }
   );
};

export const getAllTenantsLazy = async (payload) => {
   let body = {};
   payload.benefitType && (body.benefitType = payload.benefitType);
   payload.profession && (body.profession = payload.profession);
   payload.status && (body.status = payload.status);
   payload.sortBy && (body.sortBy = payload.sortBy);
   payload.orderBy && (body.orderBy = payload.orderBy);
   payload.searchBy && (body.searchBy = payload.searchBy);

   return await API.get(
      `/api/tenant/getAllTenantV1/${payload.page - 1}/${payload.limit}`,
      { params: body }
   );
};

export const addNewTenant = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) =>
      data[key] == null ? delete data[key] : null
   );
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.delete("contacts");
   formData.delete("bankDetails");
   if (data.dateOfBirth != undefined) {
      formData.set("dateOfBirth", data.dateOfBirth?.format("YYYY-MM-DD"));
   } else {
      formData.delete("dateOfBirth");
   }

   data.contacts.map(({ label, number }, index) => {
      formData.append(`contacts[${index}].label`, label);
      formData.append(`contacts[${index}].number`, number);
   });
   data.bankDetails.map(
      ({ bank, accountNumber, nameOnTheBank, sortCode }, index) => {
         formData.append(`bankDetails[${index}].bank`, bank);
         formData.append(`bankDetails[${index}].accountNumber`, accountNumber);
         formData.append(`bankDetails[${index}].nameOnTheBank`, nameOnTheBank);
         formData.append(`bankDetails[${index}].sortCode`, sortCode);
      }
   );
   return await API.post("/api/tenant/addTenant", formData);
};

export const TenantDetailsById = async (data) => {
   const { tenantId } = data;
   return API.get("/api/tenant/getTenantDetailByTenantId", {
      params: {
         tenantId
      }
   });
};

export const TenantDetailsByEmail = async ({ email }) => {
   return API.get("/api/tenant/getTenantDetailByEmail", {
      params: {
         email
      }
   });
};

export const deleteTenant = async ({ id }) => {
   return API.delete(`/api/bin/tenant/${id}`);
};

export const updateTenantById = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   if (formData.get("studentType") == null) {
      formData.set("studentType", "");
   }
   if (formData.get("employeeType") == null) {
      formData.set("employeeType", "");
   }
   Object.keys(data).map((key) =>
      data[key] == null ? formData.delete(key) : null
   );
   if (data.image == undefined) {
      formData.delete("image");
   }
   formData.delete("contacts");
   formData.delete("emails");

   // append contact and email data
   data.contacts.map(({ label, contactNumber, id }, i) => {
      let isLabel = label == undefined ? "Mobile" : label;
      formData.append(`contacts[${i}].label`, isLabel);
      formData.append(`contacts[${i}].number`, contactNumber);
      formData.append(`contacts[${i}].id`, id);
   });
   data.emails.map(({ label, email, emailTpye, id }, i) => {
      let isLabel =
         label == null ||
         label == "null" ||
         label == undefined ||
         label == "undefined";
      formData.append(`emails[${i}].emailTpye`, emailTpye);
      formData.append(`emails[${i}].label`, isLabel ? "Home" : label);
      formData.append(`emails[${i}].email`, email);
      formData.append(`emails[${i}].id`, id || "");
   });
   if (data.courseStartDate && data.courseEndDate) {
      formData.set(
         "courseStartDate",
         data.courseStartDate
            ? data.courseStartDate?.format("YYYY-MM-DD")
            : null
      );
      formData.set(
         "courseEndDate",
         data.courseEndDate ? data.courseEndDate?.format("YYYY-MM-DD") : ""
      );
   }
   if (data.visaExpiry) {
      formData.set("visaExpiry", data.visaExpiry.format("YYYY-MM-DD"));
   }
   return API.post("/api/tenant/updateTenant", formData);
};

export const updateBankDetails = (data) => {
   return API.put("/api/landlord/editBankDetails", data);
};

export const deleteBankDetails = (data) => {
   return API.delete(`/api/landlord/deleteBankDetailsById`, {
      params: data
   });
};

export const addEmergencyDetails = (data) => {
   return API.post("/api/landlord/addEmergencyContact", data);
};

export const getEmergencyDetailsForTenant = (id) => {
   return API.get(
      `/api/landlord/getEmergencyContactDetails?tenantId=${id}&landlordId=`
   );
};

export const addBanks = (data) => {
   return API.post("api/property/addBankingDetail", data);
};

export const PropertiesDetailsById = async (data) => {
   return API.get("/api/tenant/getPropertiesByTenantId", {
      params: {
         tenantId: data
      }
   });
};

export const TenantContracts = (params) => {
   const { tenantId, propertyId, limit, page, ...rest } = params;
   delete rest.service_contract;
   return API.get(`/api/tenant/getContractByTenantIdv3/${page}/${limit}`, {
      params: {
         tenantId: tenantId,
         propertyId: propertyId,
         ...rest
      }
   });
};

export const editTenantContract = async (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.set("contractTo", data.contractTo?.format("YYYY-MM-DD"));
   formData.set("contractFrom", data.contractFrom?.format("YYYY-MM-DD"));
   return API.post("/api/tenant/editTenantContract", formData);
};

export const addContract = (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.set("contractTo", data.contractTo.format("YYYY-MM-DD"));
   formData.set("contractFrom", data.contractFrom.format("YYYY-MM-DD"));
   return API.post("/api/tenant/addTenantContract", formData);
};

export const getDocuments = (data) => {
   const { tenantId, docSettingsId, tenantDocId, page, limit, ...rest } = data;
   return API.get(
      `/api/tenant/getTenantDocumentByTenantIdv3/${page}/${limit}`,
      {
         params: {
            tenantId: tenantId,
            docSettingsId: docSettingsId,
            tenantDocId: tenantDocId,
            ...rest
         }
      }
   );
};

export const addDocument = (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.delete("documentUrl");
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   data.documentUrl.map((val) => formData.append("documentUrl", val));
   Object.keys(data).map((key) => data[key] == null && formData.delete(key));

   return API.post("/api/tenant/addTenantDocument", formData);
};

export const updateDocument = (data) => {
   const formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));
   formData.delete("documentUrl");
   data.documentUrl.map((val) => formData.append("documentUrl", val));
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   return API.post("/api/tenant/updateTenantDocument", formData);
};

export const deleteTenantSingleDoc = (data) => {
   const { tenantDocUrlId, docSettingsId, docId } = data;
   return API.delete("/api/tenant/delete-tenant-doc", {
      params: {
         tenantDocUrlId,
         docSettingsId,
         docId
      }
   });
};

export const deleteTenantDoc = (data) => {
   const { tenantId, docSettingsId, docId } = data;
   return API.delete("/api/tenant/delete-tenant-docs", {
      params: {
         tenantId,
         docSettingsId,
         docId
      }
   });
};

export const editTenantDoc = (data) => {
   const formData = new FormData();
   Object.keys(data).map(
      (key) => data[key] !== null && formData.append(key, data[key])
   );
   formData.delete("documentUrl");
   data.documentUrl.map((val) => formData.append("documentUrl", val));
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   return API.post("/api/tenant/editTenantDocument", formData);
};

export const getDropdownValueForTenant = (id) => {
   return API.get("/api/tenant/getTenantTypesById", {
      params: {
         tenantId: id
      }
   });
};

export const addNoteToTenant = async (data) => {
   const { title, description, tenantId, createdBy } = data;
   return await API.post("/api/notes/saveNote", {
      title,
      description,
      tenantId,
      createdBy,
      ownerType: "T"
   });
};

export const updateNoteToTenant = async (data) => {
   const { id, title, description, tenantId, updatedBy } = data;
   return await API.post("/api/notes/saveNote", {
      id,
      title,
      description,
      tenantId,
      updatedBy,
      ownerType: "T"
   });
};

export const getNoteByTenant = async (tenantId) => {
   return await API.get("/api/notes/getNoteByTenantId", {
      params: {
         tenantId
      }
   });
};

export const deleteNoteByTenant = async ({ id, tenantId }) => {
   return await API.delete("/api/notes/deleteNote?by=T", {
      data: {
         id,
         tenantId
      }
   });
};

export const deleteContract = ({ id, role }) => {
   return API.delete("/api/contract/deleteContractByIdRole", {
      params: {
         role: role,
         id
      }
   });
};

export const deleteimg = ({ id }) => {
   return API.delete("/api/tenant/deleteTenantContractDocumentById", {
      params: {
         id
      }
   });
};

export const updateTenantToArchiveAPI = async (payload) => {
   const tenantId = payload.tenantId;
   const status = payload.status;
   return await API.put("/api/tenant/archiveTenant", null, {
      params: {
         tenantId,
         status
      }
   });
};

export const TenantPropertyBasicInfoByID = async (data) => {
   const tenantId = data.tenantId;
   return API.get("/api/tenant/getPropertyBasicInfo", {
      params: {
         tenantId
      }
   });
};

// document

export const getAddedTenantTypesById = (id) => {
   return API.get("/api/tenant/getTenantTypesById", {
      params: {
         tenantId: id
      }
   });
};

export const addAdditionalInfo = (payload) => {
   return API.post("/api/tenant/additionalInfo", payload);
};
