export const getDropdownRentfrequency = (payload) => ({
   type: "GET_DROPDOWN_RENTFREQUENCY",
   payload
});

export const getDropdownRentfrequencySuccess = (payload) => ({
   type: "GET_DROPDOWN_RENTFREQUENCY_SUCCESS",
   payload
});

export const getDropdownRentfrequencyFail = (payload) => ({
   type: "GET_DROPDOWN_RENTFREQUENCY_FAIL",
   payload
});

export const getDropdownRegistrationType = (payload) => ({
   type: "GET_DROPDOWN_REGISTRATIONTYPE",
   payload
});

export const getDropdownRegistrationTypeSuccess = (payload) => ({
   type: "GET_DROPDOWN_REGISTRATIONTYPE_SUCCESS",
   payload
});

export const getDropdownRegistrationTypeFail = (payload) => ({
   type: "GET_DROPDOWN_REGISTRATIONTYPE_FAIL",
   payload
});

export const getDropdownPaymentMethod = (payload) => ({
   type: "GET_DROPDOWN_PAYMENTMETHOD",
   payload
});

export const getDropdownPaymentMethodSuccess = (payload) => ({
   type: "GET_DROPDOWN_PAYMENTMETHOD_SUCCESS",
   payload
});

export const getDropdownPaymentMethodFail = (payload) => ({
   type: "GET_DROPDOWN_PAYMENTMETHOD_FAIL",
   payload
});
