import { put, call, takeEvery } from "redux-saga/effects";
import {
   getCountryListSuccess,
   getCountryListError,
   getCountriesFromDBSuccess,
   getCountriesFromDBError,
   getUserDetailsSuccess,
   getUserDetailsError,
   getUserConstrainsError,
   getUserConstrainsSuccess,
   getUserExsistingCountError,
   getUserExsistingCountSuccess
} from "../actions/miscActions";
import {
   getCountryList,
   getCountriesFromDB,
   getUserDetails,
   getUserConstrains,
   getUserExsistingCount
} from "../api/misc";

function* handleGetCountryList(payload) {
   try {
      const response = yield call(getCountryList, payload.payload);
      yield put(getCountryListSuccess(response));
   } catch (error) {
      yield put(getCountryListError(error.response.data));
   }
}

function* handleGetCountriesFromDB() {
   try {
      const resp = yield call(getCountriesFromDB);
      yield put(getCountriesFromDBSuccess(resp));
   } catch {
      yield put(getCountriesFromDBError());
   }
}

function* handleGetUserDetails() {
   try {
      const resp = yield call(getUserDetails);
      yield put(getUserDetailsSuccess(resp.data));
   } catch {
      yield put(getUserDetailsError());
   }
}

function* handleGetUserConstrains() {
   try {
      const resp = yield call(getUserConstrains);
      yield put(getUserConstrainsSuccess(resp.data));
   } catch {
      yield put(getUserConstrainsError());
   }
}

function* handleGetUserExsistingCount() {
   try {
      const resp = yield call(getUserExsistingCount);
      yield put(getUserExsistingCountSuccess(resp.data));
   } catch {
      yield put(getUserExsistingCountError());
   }
}

export default function* watchMiscActions() {
   yield takeEvery("GET_COUNTRY_LIST_REQUEST", handleGetCountryList);
   yield takeEvery("GET_COUNTRIES", handleGetCountriesFromDB);
   yield takeEvery("GET_USER_DETAILS", handleGetUserDetails);
   yield takeEvery("GET_USER_CONSTRAINS", handleGetUserConstrains);
   yield takeEvery("GET_USER_EXSISTING_COUNT", handleGetUserExsistingCount);
}
