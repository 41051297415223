import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "../reducers";
import rootSaga from "../sagas";
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
   key: "root",
   storage,
   whitelist: ["auth", "routes"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
   persistedReducer,
   compose(
      applyMiddleware(sagaMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
         ? window.__REDUX_DEVTOOLS_EXTENSION__()
         : (f) => f
   )
);

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export default store;
