import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./style/kads-antd.css";
import store from "./store/store";
import * as serviceWorker from "./serviceWorker";
import { updateServiceworker } from "./actions/miscActions";

const onUpdate = () => {
   store.dispatch(updateServiceworker());
};

ReactDOM.render(
   // <React.StrictMode>
   <App />,
   // </React.StrictMode>,
   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({ onUpdate });
