import { END, eventChannel } from "redux-saga";
import { put, call, takeEvery, fork, take, select } from "redux-saga/effects";
import {
   reduceCurrentDataUsage,
   setCurrentDataUsage
} from "../actions/miscActions";
import {
   openDataUsageModal,
   openMaxCountModal,
   openSizeModal,
   openSupportExtModal
} from "../actions/modalActions";
import {
   clearUploadedFiles,
   DeleteFileByIdError,
   DeleteFileByIdSuccess,
   GetSingleFileError,
   GetSingleFileSuccess,
   setBlobUrlById,
   setBlobUrlByIdSuccess,
   setTempBlobUrlId,
   UploadFileProgress,
   uploadProfilePicError,
   UploadProfilePicSuccess,
   UploadSingleFileError,
   UploadSingleFileSuccess
} from "../actions/uploadAction";
import {
   DeleteFiles,
   getBinaryFile,
   GetSingleFile,
   UploadSingleFile
} from "../api/upload";
import { ImageExt } from "../services/uploadService";

function createUploader(payload) {
   let emit;
   const chan = eventChannel((emitter) => {
      emit = emitter;
      return () => {};
   });

   const uploadPromise = UploadSingleFile(payload.file, (event, id) => {
      if (Math.round((event.loaded * 100) / event.total) == 100) {
         emit({
            percentage: Math.round((event.loaded * 100) / event.total),
            token: id,
            catagory: payload.catagory
         });
         emit(END);
      }

      emit({
         percentage: Math.round((event.loaded * 100) / event.total),
         catagory: payload.catagory,
         token: id
      });
   });

   return [uploadPromise, chan];
}

function* watchOnProgress(chan) {
   while (true) {
      const data = yield take(chan);
      yield put(UploadFileProgress(data));
   }
}

function* handleUpload(action) {
   const { supportExt, max, uploadedFileCount } = yield select(
      (state) => state.upload
   );
   const { exsistingCount, userConstrains } = yield select(
      (state) => state.misc
   );
   try {
      // current file extension
      let CurrentFileExt = action.payload.file.name
         ?.split(".")
         ?.[action.payload.file.name?.split(".").length - 1]?.toLowerCase();

      // here checking upload file count, file size and current plan size before upload the file
      if (
         uploadedFileCount <= max &&
         action.payload.file.size < 104857600 &&
         supportExt.map((val) => val.toLowerCase()).includes(CurrentFileExt) &&
         userConstrains.response.dataUsage * 1024 * 1024 * 1024 >=
            exsistingCount?.response?.currentDataUsageInBytes +
               action.payload.file.size
      ) {
         const [uploadPromise, chan] = createUploader({
            file: action.payload.file,
            catagory: action.payload.catagory
         });
         yield fork(watchOnProgress, chan);
         let resp = yield call(() => uploadPromise);
         yield put(setCurrentDataUsage(action.payload.file.size));
         if (
            ImageExt.includes(resp.response.data.response.ext?.toLowerCase())
         ) {
            yield put(setBlobUrlById(resp.response.data.response.id));
         }
         yield put(
            UploadSingleFileSuccess({
               token: resp.token,
               file: resp.response.data
            })
         );
      } else {
         // if file size more than 100MB,it will open size warning modal
         if (action.payload.file.size > 104857600) {
            yield put(openSizeModal());
         } else {
            // if current plan size reached, it will open data usage modal
            if (
               !(
                  userConstrains.response.dataUsage * 1024 * 1024 * 1024 >=
                  exsistingCount?.response?.currentDataUsageInBytes +
                     action.payload.file.size
               )
            ) {
               yield put(openDataUsageModal());
            } else {
               if (!supportExt.includes(CurrentFileExt)) {
                  // only support extensions should be allowed. otherwise it will open warning modal
                  yield put(openSupportExtModal());
               } else {
                  yield put(openMaxCountModal());
               }
            }
         }
      }
   } catch {
      yield put(UploadSingleFileError());
   }
}

function* handleGetSingleFile(action) {
   try {
      let resp = yield call(GetSingleFile, action.id);
      yield put(GetSingleFileSuccess(resp.data));
   } catch (err) {
      yield put(GetSingleFileError(err));
   }
}

function* handleDeleteFile(action) {
   try {
      yield call(DeleteFiles, action.payload.ids);
      if (action.payload.ids?.length == 1) {
         yield put(reduceCurrentDataUsage(action.payload.size));
      }
      yield put(DeleteFileByIdSuccess());
   } catch {
      yield put(DeleteFileByIdError());
   }
}

function* handleCancelFiles() {
   try {
      const upload = yield select((state) => state.upload);
      if (upload.currentlyUploadedIds.length > 0) {
         yield call(DeleteFiles, upload.currentlyUploadedIds.join());
         yield put(clearUploadedFiles());
         yield put(DeleteFileByIdSuccess());
      }
   } catch {
      yield put(DeleteFileByIdError());
   }
}

function* handleProfilePic(action) {
   try {
      let CurrentFileExt = action.payload.file.name
         ?.split(".")
         ?.[action.payload.file.name?.split(".").length - 1]?.toLowerCase();
      if (ImageExt.includes(CurrentFileExt)) {
         let resp = yield call(UploadSingleFile, action.payload.file, () => {});
         yield put(
            UploadProfilePicSuccess({
               data: resp.response.data.response,
               name: action.payload.name
            })
         );
         yield put(setBlobUrlById(resp.response.data.response.id));
      } else {
         yield put(openSupportExtModal());
         yield put(uploadProfilePicError());
      }
   } catch {
      yield put(uploadProfilePicError());
   }
}

function* handleGetBlobUrlById(action) {
   const { blobUrls, blobUrlReqId } = yield select((state) => state.upload);
   if (!blobUrls[action.id] && !blobUrlReqId.includes(action.id)) {
      try {
         yield put(setTempBlobUrlId(action.id));
         let resp = yield call(getBinaryFile, action.id);
         let bloburl = window.URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpg" })
         );
         yield put(
            setBlobUrlByIdSuccess({
               url: bloburl,
               id: action.id
            })
         );
      } catch (err) {
         return err;
      }
   }
}
export default function* watchUploadAction() {
   yield takeEvery("UPLOAD_SINGLE_FILE_REQUEST", handleUpload);
   yield takeEvery("GET_SINGLE_FILE_REQUEST", handleGetSingleFile);
   yield takeEvery("DELETE_FILE_BY_ID", handleDeleteFile);
   yield takeEvery("CANCEL_FILES_REQUEST", handleCancelFiles);
   yield takeEvery("UPLOAD_PROFILE_PIC", handleProfilePic);
   yield takeEvery("SET_BLOB_URL_BY_ID", handleGetBlobUrlById);
}
