import { put, call, takeEvery } from "redux-saga/effects";
import {
   editPermissionError,
   editPermissionSuccess,
   getPermissionsError,
   getPermissionsRequest,
   getPermissionsSuccess
} from "../actions/permissionsAction";
import { editPermissions, getPermissions } from "../api/permissions";

function* handleGetPermissions() {
   try {
      const resp = yield call(getPermissions);
      yield put(getPermissionsSuccess(resp.data.response));
   } catch {
      yield put(getPermissionsError());
   }
}

function* handleEditPermissions(action) {
   try {
      yield call(editPermissions, action.payload);
      yield put(getPermissionsRequest());
      yield put(editPermissionSuccess());
   } catch {
      yield put(editPermissionError());
   }
}

export default function* watchPermissionsAction() {
   yield takeEvery("GET_PERMISSION_REQUEST", handleGetPermissions);
   yield takeEvery("EDIT_PERMISSION_REQUEST", handleEditPermissions);
}
