import { put, call, takeEvery } from "redux-saga/effects";
import {
   getDashAssetsError,
   getDashAssetsSuccess,
   getDashOverviewError,
   getDashOverviewSuccess,
   getDashAccountCreditError,
   getDashAccountCreditSuccess,
   getDashboardOverviewSuccess as getDashboardOverviewSuccessAction,
   getDashboardOverviewError as getDashboardOverviewErrorAction,
   GET_DASHBOARD_OVERVIEW
} from "../actions/dashboardAction";
import {
   getDashAssets,
   getDashOverview,
   getDashboardAccount,
   getDashboardOverview as getDashboardOverviewApi
} from "../api/dashboard";

function* handleGetAssets() {
   try {
      const resp = yield call(getDashAssets);
      yield put(getDashAssetsSuccess(resp.data));
   } catch {
      yield put(getDashAssetsError());
   }
}

function* handleGetOverview() {
   try {
      const resp = yield call(getDashOverview);
      yield put(getDashOverviewSuccess(resp.data));
   } catch {
      yield put(getDashOverviewError());
   }
}

function* handleGetDashboardOverview() {
   try {
      const {
         data: { response }
      } = yield call(getDashboardOverviewApi);
      yield put(getDashboardOverviewSuccessAction(response));
   } catch {
      yield put(getDashboardOverviewErrorAction());
   }
}

function* handleGetAccount(action) {
   try {
      const resp = yield call(getDashboardAccount, action.payload);

      yield put(getDashAccountCreditSuccess(resp));
   } catch {
      yield put(getDashAccountCreditError());
   }
}

export default function* watchContractsAction() {
   yield takeEvery("GET_DASH_ASSETS", handleGetAssets);
   yield takeEvery("GET_DASH_OVERVIEW", handleGetOverview);
   yield takeEvery(GET_DASHBOARD_OVERVIEW, handleGetDashboardOverview);
   yield takeEvery("GET_DASH_ACCOUNTCREDIT", handleGetAccount);
}
