/*eslint no-case-declarations: "off"*/
import produce from "immer";

const INITIAL_STATE = {
   loading: false,
   licenceTypes: [],
   propertyTypes: [],
   furnishTypes: [],
   unitTypes: [],
   propertyStatus: [],
   propertybasicinfo: {
      success: false,
      error: null,
      loading: false,
      basicInfo: null,
      notes: [],
      isLinked: false
   },

   propertiesLazy: {
      data: [],
      success: false,
      error: null,
      pagination: null,
      loading: false,
      loaded: false
   },

   properties: {
      loading: false,
      list: [],
      loaded: false,
      filterData: false,
      propertyType: null,
      licenseType: null,
      businessType: null,
      unitType: null,
      propertyStatus: null,
      occupancy: null,
      inspectionStatus: null,
      landlordId: null,
      isMyprotfolioActive: false,
      searchBy: null,
      orderBy: null,
      totalPages: 1,
      currentPage: 1,
      entriesPerPage: 10
   },

   propertyDetails: null,

   propertyStatusDetailsPage: {
      success: false,
      response: null,
      error: null
   },

   propertyToArchival: {
      success: false,
      response: null,
      error: null
   },

   saveProperty: {
      loading: false,
      success: false,
      error: null,
      property: null
   },

   columnSelection: {
      property: true,
      landlord: true,
      staffs: true,
      propertyType: true,
      licenseType: true,
      businessType: true,
      unitType: true,
      occupancy: false,
      inspection: false,
      expiry: false,
      propertyStatus: false
   },

   updateProperty: {
      loading: false,
      success: false,
      error: null,
      image: null
   },

   updateUnit: {
      loading: false,
      success: false,
      error: null
   },

   updateTeantRent: {
      loading: false,
      success: false,
      error: null
   },

   mapTeantProperty: {
      loading: false,
      success: false,
      error: null
   },

   mapLandlordProperty: {
      loading: false,
      success: false,
      error: null
   },

   saveLandlordRent: {
      loading: false,
      success: false,
      error: null
   },

   editProperty: {
      loading: false
   },

   deletePortfolio: {
      success: false,
      error: null
   },
   deleteImage: {
      success: false,
      error: null
   },
   allProperty: [],
   utility: {
      loading: false,
      data: [],
      delete: false,
      add: false
   },
   document: {
      loading: false,
      data: [],
      add: false,
      delete: false,
      types: []
   },
   deleteProperty: {
      loading: false
   },
   unlinkLandlordFromProperty: {
      success: false,
      error: null
   },
   unlinkTenantFromProperty: {
      success: false,
      error: null
   },

   propertyWarning: {
      success: false,
      response: null,
      error: null
   }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "CHANGE_PROPERTY_LOADED_STATE":
            draft.properties.loaded = action.payload;
            break;
         case "GET_PROPERTIES_REQUEST":
            draft.loading = true;
            draft.properties.propertyStatus = action.payload.propertyStatus;
            draft.properties.propertyType = action.payload.propertyType;
            draft.properties.licenseType = action.payload.licenseType;
            draft.properties.businessType = action.payload.businessType;
            draft.properties.unitType = action.payload.unitType;
            draft.properties.inspectionStatus = action.payload.inspectionStatus;
            draft.properties.occupancy = action.payload.occupancy;
            draft.properties.landlordId = action.payload.landlordId;
            draft.properties.orderBy = action.payload.orderBy;
            draft.properties.searchBy = action.payload.searchBy;
            draft.properties.currentPage = action.payload.page;
            draft.properties.entriesPerPage = action.payload.limit;
            draft.saveProperty.success = false;
            draft.unlinkLandlordFromProperty.success = false;
            draft.unlinkTenantFromProperty.success = false;
            break;

         case "GET_PROPERTIES_SUCCESS":
            draft.properties.loaded = true;
            draft.properties.list =
               action.response.data.response.propertyResponse;
            draft.properties.totalPages =
               action.response.data.response.paginationDetails.totalPages;
            draft.properties.currentPage =
               action.response.data.response.paginationDetails.currentPage + 1;
            draft.properties.entriesPerPage =
               action.response.data.response.paginationDetails.pageSize;
            draft.loading = false;
            draft.properties.isMyprotfolioActive =
               action.response.data.response.paginationDetails.isMyPortfolioActive;
            break;

         case "GET_PROPERTIES_ERROR":
            draft.loading = false;
            draft.properties.list = [];
            draft.properties.totalPages = 0;
            break;

         case "GET_PROPERTIES_REQUEST_LAZY_LOADING":
            draft.propertiesLazy.loading = true;

            break;

         case "GET_PROPERTIES_REQUEST_LAZY_LOADING_SET_NULL":
            draft.propertiesLazy.data = [];
            draft.propertiesLazy.success = false;
            draft.propertiesLazy.error = null;
            draft.propertiesLazy.pagination = null;
            draft.propertiesLazy.loading = false;
            draft.propertiesLazy.loaded = false;

            break;

         case "GET_ALL_ACCOUNT_RECEIVABLE_INVOICE_lOAD_FALSE":
            draft.propertiesLazy.loaded = false;
            break;

         case "GET_PROPERTIES_LAZY_LOADING_SUCCESS":
            draft.propertiesLazy.data =
               action.response.data.response.propertyResponse;
            draft.propertiesLazy.pagination =
               action.response.data.response.paginationDetails;
            draft.propertiesLazy.loading = false;
            draft.propertiesLazy.loaded = true;
            break;

         case "GET_PROPERTIES_LAZY_LOADING_ERROR":
            draft.propertiesLazy.loading = false;
            draft.propertiesLazy.data = [];
            draft.propertiesLazy.error = action.response;
            break;

         case "GET_LICENCE_TYPES_REQUEST":
            break;

         case "GET_LICENCE_TYPES_SUCCESS":
            draft.licenceTypes = action.response.data.response;
            break;

         case "GET_LICENCE_TYPES_ERROR":
            break;

         case "GET_PROPERTY_TYPES_REQUEST":
            break;

         case "GET_PROPERTY_TYPES_SUCCESS":
            draft.propertyTypes = action.response.data.response;
            break;

         case "GET_PROPERTY_TYPES_ERROR":
            break;

         case "GET_FURNISH_TYPES_REQUEST":
            break;

         case "GET_FURNISH_TYPES_SUCCESS":
            draft.furnishTypes = action.response.data.response;
            break;

         case "GET_FURNISH_TYPES_ERROR":
            draft.furnishTypes = [];
            break;

         case "GET_PROPERTY_STATUS_REQUEST":
            break;

         case "GET_PROPERTY_STATUS_SUCCESS":
            draft.propertyStatus = action.response.data.response;
            draft.properties.filterData = true;
            break;

         case "GET_PROPERTY_STATUS_ERROR":
            draft.propertyStatus = [];
            break;

         case "GET_UNIT_TYPES_REQUEST":
            break;
         case "GET_UNIT_TYPES_SUCCESS":
            draft.unitTypes = action.response.data.response;
            break;

         case "GET_UNIT_TYPES_ERROR":
            draft.unitTypes = [];
            break;

         case "SAVE_PROPERTY_REQUEST":
            draft.saveProperty.loading = true;
            draft.saveProperty.success = false;

            break;

         case "SAVE_PROPERTY_SUCCESS":
            draft.saveProperty.loading = false;
            draft.saveProperty.success = true;
            draft.saveProperty.error = null;
            draft.saveProperty.property = action.response.data.response;
            break;

         case "SAVE_PROPERTY_ERROR":
            draft.saveProperty.loading = false;
            draft.saveProperty.error = action.error;
            break;

         case "RESET_PROPERTY_SAVE":
            draft.saveProperty.loading = false;
            draft.saveProperty.success = false;
            draft.saveProperty.error = null;
            draft.saveProperty.property = null;
            break;

         case "GET_PROPERTY_DETAILS_REQUEST":
            break;

         case "GET_PROPERTY_DETAILS_SUCCESS":
            draft.propertyDetails = action.response.data.response;
            break;

         case "GET_PROPERTY_DETAILS_ERROR":
            draft.propertyDetails = null;
            break;

         case "GET_PROPERTY_BASIC_INFO":
            draft.propertybasicinfo.loading = true;
            draft.propertybasicinfo.basicInfo = null;
            draft.propertybasicinfo.success = false;
            break;

         case "GET_PROPERTY_BASIC_INFO_SET_NULL":
            draft.propertybasicinfo.basicInfo = null;
            break;
         case "GET_PROPERTY_BASIC_INFO_SUCCESS":
            draft.propertybasicinfo.loading = false;
            draft.propertybasicinfo.basicInfo = action.response.data.response;
            let temp =
               (action?.response?.data?.response?.linkedLandlords
                  ? action?.response?.data?.response?.linkedLandlords
                       ?.length !== 0
                  : false) ||
               !action?.response?.data?.response?.unitDetails
                  ?.map((val) => val?.tenant || [])
                  ?.every((val) => val?.length === 0);
            draft.propertybasicinfo.isLinked = temp;
            break;

         case "GET_PROPERTY_BASIC_INFO_ERROR":
            draft.propertybasicinfo.error = action.error;
            break;

         case "UPDATE_PROPERTY_MULTIUNIT_REQUEST":
            draft.updateProperty.loading = true;
            draft.updateProperty.success = false;

            break;

         case "UPDATE_PROPERTY_MULTIUNIT_SUCCESS":
            draft.updateProperty.loading = false;
            draft.updateProperty.success = true;
            draft.updateProperty.error = null;
            draft.updateProperty.image = action.response;
            break;

         case "UPDATE_PROPERTY_MULTIUNIT_ERROR":
            draft.updateProperty.loading = false;
            draft.updateProperty.error = action.error;
            break;

         case "UPDATE_UNIT_MULTIUNIT_REQUEST":
            draft.updateUnit.loading = true;
            draft.updateUnit.success = false;
            break;

         case "UPDATE_UNIT_MULTIUNIT_SUCCESS":
            draft.updateUnit.loading = false;
            draft.updateUnit.success = true;
            break;

         case "UPDATE_UNIT_MULTIUNIT_ERROR":
            draft.updateUnit.loading = false;
            draft.updateUnit.error = action.error;
            break;

         case "SET_LOADING_ON":
            draft.editProperty.loading = true;
            break;
         case "SET_LOADING_OFF":
            draft.editProperty.loading = false;
            break;

         case "DELETE_PORTFOLIO_BY_ID":
            draft.deletePortfolio.success = false;
            break;

         case "DELETE_PORTFOLIO_BY_ID_SUCESS":
            draft.deletePortfolio.success = true;
            break;

         case "DELETE_PORTFOLIO_BY_ID_FAIL":
            draft.deletePortfolio.success = false;
            draft.deletePortfolio.error = action.error;
            break;

         case "DELETE_IMAGE_BY_ID":
            draft.deleteImage.success = false;
            break;

         case "DELETE_IMAGE_BY_ID_SUCESS":
            draft.deleteImage.success = true;
            break;

         case "DELETE_IMAGE_BY_ID_FAIL":
            draft.deleteImage.success = false;
            draft.deleteImage.error = action.error;
            break;

         case "UPDATE_UNIT_MULTIUNIT_SUCCESS_FALSE":
            draft.updateProperty.success = false;
            draft.updateUnit.success = false;
            draft.updateTeantRent.success = false;
            draft.mapTeantProperty.success = false;
            draft.mapLandlordProperty.success = false;
            break;

         case "RESET_PROPERTY_FILTER":
            draft.properties.propertyStatus = null;
            draft.properties.propertyType = null;
            draft.properties.licenseType = null;
            draft.properties.businessType = null;
            draft.properties.unitType = null;
            draft.properties.inspectionStatus = null;
            draft.properties.occupancy = null;
            break;

         case "UPDATE_TENANT_RENT_REQUEST":
            draft.updateTeantRent.loading = true;
            draft.updateTeantRent.success = false;
            break;

         case "UPDATE_TENANT_RENT_SUCCESS":
            draft.updateTeantRent.loading = false;
            draft.updateTeantRent.success = true;
            break;

         case "UPDATE_TENANT_RENT_FAIL":
            draft.updateTeantRent.loading = false;
            draft.updateTeantRent.error = action.error;
            break;

         case "MAP_TENANT_TO_PROPERTY":
            draft.mapTeantProperty.loading = true;
            draft.mapTeantProperty.success = false;
            break;

         case "MAP_TENANT_TO_PROPERTY_SUCCESS":
            draft.mapTeantProperty.loading = false;
            draft.mapTeantProperty.success = true;
            break;

         case "MAP_TENANT_TO_PROPERTY_FAIL":
            draft.mapTeantProperty.loading = false;
            draft.mapTeantProperty.error = action.error;
            break;

         case "ADD_NOTE_TO_PROPERTY_SUCCESS":
            draft.propertybasicinfo.notes.push(action.payload.response);
            break;

         case "GET_NOTE_BY_PROPERTY_SUCCESS":
            draft.propertybasicinfo.notes = action.payload.response;
            break;

         case "GET_NOTE_BY_PROPERTY_ERROR":
            draft.propertybasicinfo.notes = [];
            break;

         case "UPDATE_NOTE_TO_PROPERTY_SUCCESS":
            for (let i = 0; i < draft.propertybasicinfo.notes.length; i++) {
               if (
                  draft.propertybasicinfo.notes[i].id ===
                  action.payload.response.id
               ) {
                  draft.propertybasicinfo.notes[i] = action.payload.response;
               }
            }
            break;

         case "DELETE_NOTE_BY_PROPERTY_SUCCESS":
            draft.propertybasicinfo.notes = draft.propertybasicinfo.notes.filter(
               (note) => note.id !== action.payload.id
            );
            break;

         case "UPDATE_PROPERTY_STATUS":
            draft.propertyStatusDetailsPage.success = false;
            break;

         case "UPDATE_PROPERTY_STATUS_SUCCESS":
            draft.propertyStatusDetailsPage.response = action.response;
            draft.propertyStatusDetailsPage.success = true;
            break;

         case "UPDATE_PROPERTY_STATUS_ERROR":
            draft.propertyStatusDetailsPage.success = false;
            draft.propertyStatusDetailsPage.error = action.error;
            break;

         case "SAVE_COLUMN_SELECTION_REQUEST":
            // draft.columnSelection = action.payload;
            break;

         case "GET_COLUMN_SELECTION_SUCCESS":
            draft.columnSelection = JSON.parse(
               action.response.data.response.preferences
            );
            break;

         case "GET_COLUMN_SELECTION_ERROR":
            draft.columnSelection = {
               property: true,
               landlord: true,
               staffs: true,
               propertyType: true,
               licenseType: true,
               businessType: true,
               unitType: true,
               occupancy: false,
               inspection: false,
               expiry: false,
               propertyStatus: false
            };
            break;

         case "SAVE_COLUMN_SELECTION_SUCCESS":
            draft.columnSelection = JSON.parse(
               action.response.data.response.preferences
            );
            break;

         case "UPDATE_PROPERTY_TO_ARCHIVE":
            draft.propertyToArchival.success = false;
            break;

         case "UPDATE_PROPERTY_TO_ARCHIVE_SUCCESS":
            draft.propertyToArchival.response = action.response;
            draft.propertyToArchival.success = true;
            break;

         case "UPDATE_PROPERTY_TO_ARCHIVE_FAIL":
            draft.propertyToArchival.success = false;
            draft.propertyToArchival.error = action.error;
            break;

         case "GET_ALL_PROPERTY":
            draft.loading = true;
            break;

         case "GET_ALL_PROPERTY_SUCCESS":
            draft.loading = false;
            draft.allProperty = action.payload.data.response;
            break;

         case "GET_ALL_PROPERTY_ERROR":
            draft.loading = false;
            draft.allProperty = [];
            break;

         case "MAP_LANDLORD_TO_PROPERTY_REQUEST":
            draft.mapLandlordProperty.loading = true;
            draft.mapLandlordProperty.success = false;
            break;

         case "MAP_LANDLORD_TO_PROPERTY_SUCCESS":
            draft.mapLandlordProperty.loading = false;
            draft.mapLandlordProperty.success = true;
            break;

         case "MAP_LANDLORD_TO_PROPERTY_ERROR":
            draft.mapLandlordProperty.loading = false;
            draft.mapLandlordProperty.error = action.error;
            break;

         case "SAVE_LANDLORD_RENT_DETAILS_REQUEST":
            draft.saveLandlordRent.loading = true;
            draft.saveLandlordRent.success = false;
            break;

         case "SAVE_LANDLORD_RENT_DETAILS_SUCCESS":
            draft.saveLandlordRent.loading = false;
            draft.saveLandlordRent.success = true;
            break;

         case "SAVE_LANDLORD_RENT_DETAILS_ERROR":
            draft.saveLandlordRent.loading = false;
            draft.saveLandlordRent.success = false;
            draft.saveLandlordRent.error = action.error;
            break;

         case "GET_UTILITY":
            draft.utility.loading = true;
            break;

         case "GET_UTILITY_SUCCESS":
            draft.utility.data = action.payload.response;
            draft.utility.loading = false;
            break;

         case "GET_UTILITY_ERROR":
            draft.utility.loading = false;
            break;

         case "SAVE_UTILITY":
            draft.utility.add = true;
            break;

         case "SAVE_UTILITY_SUCCESS":
            draft.utility.add = false;
            break;

         case "SAVE_UTILITY_ERROR":
            draft.utility.add = false;
            break;

         case "UPDATE_UTILITY":
            draft.utility.add = true;
            break;

         case "UPDATE_UTILITY_SUCCESS":
            draft.utility.add = false;
            break;

         case "UPDATE_UTILITY_ERROR":
            draft.utility.add = false;
            break;

         case "DELETE_UTILITY":
            draft.utility.delete = true;
            break;

         case "DELETE_UTILITY_SUCCESS":
            draft.utility.delete = false;
            break;

         case "DELETE_UTILITY_ERROR":
            draft.utility.delete = false;
            break;

         case "GET_PROPERTY_DOCUMENTS":
            draft.document.loading = true;
            break;

         case "GET_PROPERTY_DOCUMENTS_SUCCESS": {
            let temp = action.payload.response;
            temp.docSettings = [
               ...(draft.document.data.docSettings || []),
               ...(temp.docSettings || [])
            ];
            draft.document.data = temp;
            draft.document.loading = false;
            break;
         }

         case "GET_PROPERTY_DOCUMENT_ERROR":
            draft.document.data = [];
            draft.document.loading = false;
            break;

         case "ADD_PROPERTY_DOCUMENT":
            draft.document.add = true;
            break;

         case "ADD_PROPERTY_DOCUMENT_SUCCESS":
            draft.document.add = false;
            break;

         case "ADD_PROPERTY_DOCUMENT_ERROR":
            draft.document.add = false;
            break;

         case "UPDATE_PROPERTY_DOCUMENT":
            draft.document.add = true;
            break;

         case "UPDATE_PROPERTY_DOCUMENT_SUCCESS":
            draft.document.add = false;
            break;

         case "UPDATE_PROPERTY_DOCUMENT_ERROR":
            draft.document.add = false;
            break;

         case "DELETE_PROPERTY_DOCS":
            draft.document.delete = true;
            break;

         case "DELETE_PROPERTY_DOCS_SUCCESS":
            draft.document.delete = false;
            break;

         case "DELETE_PROPERTY_DOCS_ERROR":
            draft.document.delete = false;
            break;

         case "GET_PROPERTY_TYPE_BY_ID":
            break;

         case "GET_PROPERTY_TYPES_BY_ID_SUCCESS":
            draft.document.types = action.payload.response;
            break;

         case "GET_PROPERTY_TYPES_BY_ID_ERROR":
            draft.document.types = [];
            break;

         case "DELETE_PROPERTY_BY_ID":
            draft.deleteProperty.loading = true;
            break;

         case "DELETE_PROPERTY_BY_ID_SUCCESS":
            draft.deleteProperty.loading = false;
            break;

         case "DELETE_PROPERTY_BY_ID_ERROR":
            draft.deleteProperty.loading = false;
            break;

         case "UNLINK_LANDLORD_FROM_PROPERTY":
            draft.unlinkLandlordFromProperty.loading = true;
            draft.unlinkLandlordFromProperty.success = false;
            break;

         case "UNLINK_LANDLORD_FROM_PROPERTY_SUCCESS":
            draft.unlinkLandlordFromProperty.loading = false;
            draft.unlinkLandlordFromProperty.success = true;
            break;

         case "UNLINK_LANDLORD_FROM_PROPERTY_ERROR":
            draft.unlinkLandlordFromProperty.loading = false;
            draft.unlinkLandlordFromProperty.success = false;
            break;
         case "UNLINK_TENANT_FROM_PROPERTY":
            draft.unlinkTenantFromProperty.loading = true;
            draft.unlinkTenantFromProperty.success = false;
            break;

         case "UNLINK_TENANT_FROM_PROPERTY_SUCCESS":
            draft.unlinkTenantFromProperty.loading = false;
            draft.unlinkTenantFromProperty.success = true;
            break;

         case "UNLINK_TENANT_FROM_PROPERTY_ERROR":
            draft.unlinkTenantFromProperty.loading = false;
            draft.unlinkTenantFromProperty.success = false;
            break;

         case "GET_PROPERTY_WARNING_REQUEST":
            draft.propertyWarning.loading = true;
            break;

         case "GET_PROPERTY_WARNING_SUCCESS":
            draft.propertyWarning.data = action.response.data.response;
            draft.propertyWarning.loading = false;
            break;

         case "GET_PROPERTY_WARNING_ERROR":
            draft.propertyWarning.data = [];
            draft.propertyWarning.loading = false;
            break;

         case "SET_NULL_PROPERTY_DOCUMENTS":
            draft.document.data = [];
            break;

         default:
            return draft;
      }
   });
