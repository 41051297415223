import API from "./API";

export const GuarantorListByPropertyId = async (data) => {
   const { propertyId } = data;
   return API.get("/api/guarantor/getGuarantorByPropertyId", {
      params: {
         propertyId
      }
   });
};

export const AddGuarantor = async (data) => {
   return await API.post("/api/guarantor/addGuarantor", data);
};

export const DeleteGuarantor = async (data) => {
   const { id } = data;
   return await API.delete("/api/guarantor/deleteGuarantor", {
      params: {
         id
      }
   });
};

export const UpdateGuarantor = async (data) => {
   return await API.post("/api/guarantor/updateGuarantor", data);
};
