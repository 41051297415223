import API from "./API";
import jwt_decode from "jwt-decode";
const getProperties = async (payload) => {
   let body = {};
   payload.propertyStatus && (body.propertyStatus = payload.propertyStatus);
   payload.occupancy && (body.occupancy = payload.occupancy);
   payload.propertyType && (body.propertyType = payload.propertyType);
   payload.licenseType && (body.licenseType = payload.licenseType);
   payload.businessType && (body.businessType = payload.businessType);
   payload.unitType && (body.unitType = payload.unitType);
   payload.inspectionStatus &&
      (body.inspectionStatus = payload.inspectionStatus);
   payload.sortBy && (body.sortBy = payload.sortBy);
   payload.orderBy && (body.orderBy = payload.orderBy);
   payload.searchBy && (body.searchBy = payload.searchBy);
   payload.isArchive
      ? (body.isArchive = payload.isArchive)
      : (body.isArchive = false);
   body.isPortfolioActive = payload.isPortfolioActive;
   return await API.get(
      `api/property/getAllPropertiesByAgencyIdV1/${payload.page - 1}/${
         payload.limit
      }`,
      { params: body }
   );
};

const getPropertiesLazy = async (payload) => {
   let body = {};
   payload.propertyStatus && (body.propertyStatus = payload.propertyStatus);
   payload.occupancy && (body.occupany = payload.occupancy);
   payload.propertyType && (body.propertyType = payload.propertyType);
   payload.licenseType && (body.licenseType = payload.licenseType);
   payload.businessType && (body.businessType = payload.businessType);
   payload.unitType && (body.unitType = payload.unitType);
   payload.inspectionStatus &&
      (body.inspectionStatus = payload.inspectionStatus);
   payload.sortBy && (body.sortBy = payload.sortBy);
   payload.orderBy && (body.orderBy = payload.orderBy);
   payload.searchBy && (body.searchBy = payload.searchBy);
   payload.isArchive
      ? (body.isArchive = payload.isArchive)
      : (body.isArchive = false);
   body.isPortfolioActive = payload.isPortfolioActive;
   return await API.get(
      `api/property/getAllPropertiesByAgencyIdV1/${payload.page - 1}/${
         payload.limit
      }`,
      { params: body }
   );
};

const getPropertyTypes = async () => {
   return await API.get("api/property/getPropertyType");
};

const getPropertyStatus = async () => {
   return await API.get("api/property/getAllPropertyStatus");
};

const getLicenceTypes = async () => {
   return await API.get("api/property/GetLicenseType");
};

const getFurnishTypes = async () => {
   return await API.get("api/property/GetFurnishType");
};

const getUnitTypes = async () => {
   return await API.get("api/property/GetUnitType");
};

const saveProperty = async (payload) => {
   return await API.post("api/property/addProperty", payload);
};

const getPropertyDetails = async (payload) => {
   return await API.get(
      `api/property/getPropertyDetailsById?propertyId=${payload.id}`
   );
};

const getPropertyBasicInfo = async (payload) => {
   return await API.get("api/property/getPropertyBasicInfoById", {
      params: {
         propertyId: payload
      }
   });
};

//MultiUnit
const updateMultiUnitProperty = async (payload) => {
   const temp = {
      updatePropertyRequest: {
         property_id: payload.id,
         property_type_id: payload.property_type_id,
         property_status_id: 5,
         license_type_id: payload.license_type_id,
         unit_type_id: payload.unit_type_id,
         business_type: payload.business_type,
         type_preference: payload.type_preference,
         pet_preference: payload.pet_preference,
         noOfBedrooms: payload.noOfBedrooms,
         noOfBathrooms: payload.noOfBathrooms,
         furnished_type: "1",
         address: payload.address,
         city: payload.city,
         state: payload.state,
         zip: payload.zip,
         country_id: payload.country_id,
         user_id: payload.userId,
         longitude: "",
         latitude: "",
         expired_on: ""
      },
      managementDetailsRequest: {
         mangType: payload.mangType,

         commisionType:
            payload.commissionType &&
            payload.commissionType.map((manage) => {
               const temp = {
                  id: manage
               };

               return temp;
            }),
         propertyId: payload.id,
         firstTimeType: payload.firstTimeType,
         firstTimeFees: payload.firstTimeFees,
         firstTimeInvoicedate: payload.firstTimeInvoicedate,
         onGoingPayoutTerm: payload.onGoingPayoutTerm,
         onGoingFeeType: payload.onGoingFeeType,
         onGoingManagementFees: payload.onGoingManagementFees,
         onGoingInvoicedate: payload.onGoingInvoicedate
      },
      portfolioRequest: payload.portfolioRequest,
      amenityRequest: payload.amenityRequest,
      linkLandlord: payload.linkLandlord,
      fileIds: payload.fileIds
   };
   return await API.post("api/property/updateAllPropertyDetails", temp);
};

const updateMultiUnitPropertyGallery = async (payload) => {
   let formData = new FormData();
   payload.imageResponse.map((image) =>
      formData.append("image", image.originFileObj)
   );
   formData.append("id", payload.id);
   formData.append("imageType", "property");
   return await API.post("api/property/changeImage", formData);
};

const updateMultiUnit_Unitdetails = async (payload) => {
   const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
   return await API.post("api/property/addAllUnitDetails", payload, {
      params: {
         agencyId
      }
   });
};

const updateMultiUnitUnitGallery = async (payload) => {
   let formData = new FormData();
   payload.imageResponse.map((image) =>
      formData.append("image", image.originFileObj)
   );
   formData.append(
      "id",
      payload.unitDetailsRequest && payload.unitDetailsRequest.unitDetailsID
   );
   formData.append("imageType", "unit");
   return await API.post("api/property/changeImage", formData);
};

const deletePortfolioByIdAPI = async (payload) => {
   return await API.delete("api/property/deletePortfolio", {
      params: {
         portfolioId: payload
      }
   });
};

const deleteImageByIdAPI = async (payload) => {
   const { agencyId } = jwt_decode(localStorage.getItem("access_token"));
   return await API.delete("api/property/deleteImage", {
      params: {
         imageId: payload,
         agencyId
      }
   });
};

const updateTenantRentDetailsAPI = async (payload) => {
   return await API.post("api/tenant/addTenantRentDetails", payload);
};

const mapTenantToPropertyAPI = async (payload) => {
   return await API.post("api/tenant/mapTenantRentDetails", payload);
};

const updatePopertyStatusApi = async (payload) => {
   const propertyId = payload.propertyId;
   const statusId = payload.statusId;
   return await API.put("/api/property/updatePropertyStatus", null, {
      params: {
         propertyId,
         statusId
      }
   });
};

export const addNoteToProperty = async (data) => {
   const { title, description, propertyId, createdBy } = data;
   return await API.post("/api/notes/saveNote", {
      title,
      description,
      propertyId,
      createdBy,
      ownerType: "P"
   });
};

export const updateNoteToProperty = async (data) => {
   const { id, title, description, propertyId, updatedBy } = data;
   return await API.post("/api/notes/saveNote", {
      id,
      title,
      description,
      propertyId,
      updatedBy,
      ownerType: "P"
   });
};

export const getNoteByProperty = async (propertyId) => {
   return await API.get("/api/notes/getNoteByPropertyId", {
      params: {
         propertyId
      }
   });
};

export const deleteNoteByProperty = async ({ id, propertyId }) => {
   return await API.delete("/api/notes/deleteNote?by=P", {
      data: {
         id,
         propertyId
      }
   });
};

const getColumnSelection = async () => {
   return await API.get("/api/columnpreferences/getAll?module=p");
};

const saveColumnSelection = async (payload) => {
   return await API.post("/api/columnpreferences/saveAll?module=p", payload);
};

export const updatePropertyToArchiveAPI = async (payload) => {
   const propertyId = payload.propertyId;
   const status = payload.status;
   return await API.put("/api/property/archiveProperty", null, {
      params: {
         propertyId,
         status
      }
   });
};

export const GetAllProperty = async () => {
   return await API.get("api/property/getAllProperty ");
};

const mapLandlordToPropertyAPI = async (payload) => {
   return await API.post("/api/landlord/linkLandlordsToProperty", payload);
};
const saveLandlordRentAPI = async (payload) => {
   return await API.post("api/tenant/mapTenantRentDetails", payload);
};

export const getAllUtility = (id) => {
   return API.get(`/api/utility/by/${id}`);
};

export const saveUtility = (data) => {
   return API.post("/api/utility", data);
};

export const updateUtility = (data) => {
   const { id } = data;
   delete data.id;
   return API.put(`/api/utility/${id}`, data);
};

export const deleteUtility = (id) => {
   return API.delete(`/api/utility/${id}`);
};

export const getDocumentByPropertyId = (payload) => {
   const { data, pagination } = payload;
   return API.get(
      `/api/property/getDocumentByPropertyIdv3/${pagination.page}/${pagination.size}`,
      {
         params: data
      }
   );
};

export const addDocumentByPropertyId = (data) => {
   const formData = new FormData();
   Object.keys(data).map(
      (key) => data[key] !== null && formData.append(key, data[key])
   );
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   return API.post("/api/property/addPropertyDocument", formData);
};

export const editDocumentByPropertyId = (data) => {
   const formData = new FormData();
   Object.keys(data).map(
      (key) => data[key] !== null && formData.append(key, data[key])
   );
   formData.set("validTill", data.validTill.format("YYYY-MM-DD"));
   return API.post("/api/property/editPropertyDocument", formData);
};

export const deletePropertyDocument = (data) => {
   return API.delete("/api/property/delete-property-docs", {
      params: data
   });
};

export const getPropertyTypesById = (id) => {
   return API.get("/api/property/getPropertyTypesById", {
      params: {
         propertyId: id
      }
   });
};

export const deletePropertyById = (id) => {
   return API.delete(`/api/bin/property/${id}`);
};

export const unlinkLandlordFromPropertyById = ({ landlordId, propertyId }) => {
   return API.delete(
      `/api/landlord/unlinkLandlord?landlordId=${landlordId}&propertyId=${propertyId}`
   );
};

export const unlinkTenantFromPropertyById = ({ tenantId, unitId }) => {
   return API.delete(
      `/api/tenant/unlinkTenant?tenantId=${tenantId}&unitId=${unitId}`
   );
};

export const getPropertyWarning = async (payload) => {
   return await API.get(`api/property/getWarnings?propertyId=${payload}`, {});
};

export {
   getProperties,
   getPropertyTypes,
   getPropertyStatus,
   getLicenceTypes,
   getFurnishTypes,
   getUnitTypes,
   saveProperty,
   getPropertyDetails,
   getPropertyBasicInfo,
   updateMultiUnitProperty,
   updateMultiUnitPropertyGallery,
   updateMultiUnit_Unitdetails,
   updateMultiUnitUnitGallery,
   deletePortfolioByIdAPI,
   deleteImageByIdAPI,
   updateTenantRentDetailsAPI,
   mapTenantToPropertyAPI,
   updatePopertyStatusApi,
   getColumnSelection,
   saveColumnSelection,
   mapLandlordToPropertyAPI,
   saveLandlordRentAPI,
   getPropertiesLazy
};
