const updateAgencyProfile = (payload) => ({
   type: "UPDATE_AGENCY_PROFILE",
   payload
});

const updateAgencyProfileSuccess = (payload) => ({
   type: "UPDATE_AGENCY_PROFILE_SUCCESS",
   payload
});

const updateAgencyProfileError = (payload) => ({
   type: "UPDATE_AGENCY_PROFILE_ERROR",
   payload
});

const adminGeneralSystemSetup = (payload) => ({
   type: "ADMIN_GENERAL_SYSTEM_SETUP",
   payload
});

const adminGeneralSystemSetupSuccess = (payload) => ({
   type: "ADMIN_GENERAL_SYSTEM_SETUP_SUCCESS",
   payload
});

const adminGeneralSystemSetupError = (payload) => ({
   type: "ADMIN_GENERAL_SYSTEM_SETUP_ERROR",
   payload
});

const getAllCountryCallingCode = () => ({
   type: "GET_ALL_COUNTRY_CALLING_CODE"
});

const getAllCountryCallingCodeSuccess = (payload) => ({
   type: "GET_ALL_COUNTRY_CALLING_CODE_SUCCESS",
   payload
});

const getAllCountryCallingCodeError = () => ({
   type: "GET_ALL_COUNTRY_CALLING_CODE_ERROR"
});

const getAllCurrency = () => ({
   type: "GET_ALL_CURRENCY"
});

const getAllCurrencySuccess = (payload) => ({
   type: "GET_ALL_CURRENCY_SUCCESS",
   payload
});

const getAllCurrencyError = () => ({
   type: "GET_ALL_CURRENCY_ERROR"
});

const getAllTimezone = () => ({
   type: "GET_ALL_TIME_ZONE"
});

const getAllTimezoneSuccess = (payload) => ({
   type: "GET_ALL_TIME_ZONE_SUCCESS",
   payload
});

const getAllTimezoneError = () => ({
   type: "GET_ALL_TIME_ZONE_ERROR"
});

const profileSetupRequired = (history) => ({
   type: "PROFILE_SETUP_REQUIRED",
   history
});

const profileSetupRequiredSuccess = (payload) => ({
   type: "PROFILE_SETUP_REQUIRED_SUCCESS",
   payload
});

const profileSetupRequiredError = () => ({
   type: "PROFILE_SETUP_REQUIRED_ERROR"
});

const sendAgencyOtp = (payload) => ({
   type: "SEND_AGENCY_OTP",
   payload
});

const sendAgencyOtpSuccess = (payload) => ({
   type: "SEND_AGENCY_OTP_SUCCESS",
   payload
});

const sendAgencyOtpError = (payload) => ({
   type: "SEND_AGENCY_OTP_ERROR",
   payload
});

const updateAgencyMobileNo = (payload) => ({
   type: "UPDATE_AGENCY_MOBILE_NO",
   payload
});

const updateAgencyMobileNoSuccess = (payload) => ({
   type: "UPDATE_AGENCY_MOBILE_NO_SUCCESS",
   payload
});

const updateAgencyMobileNoError = (payload) => ({
   type: "UPDATE_AGENCY_MOBILE_NO_ERROR",
   payload
});

const updateAgencyTwoFactorAuth = (payload) => ({
   type: "UPDATE_AGENCY_TWO_FACT_AUTH",
   payload
});

const updateAgencyTwoFactorAuthSuccess = (payload) => ({
   type: "UPDATE_AGENCY_TWO_FACT_AUTH_SUCCESS",
   payload
});

const updateAgencyTwoFactorAuthError = (payload) => ({
   type: "UPDATE_AGENCY_TWO_FACT_AUTH_ERROR",
   payload
});

const validateAgencyOtp = (payload) => ({
   type: "VALIDATE_AGENCY_OTP",
   payload
});

const validateAgencyOtpSuccess = (payload) => ({
   type: "VALIDATE_AGENCY_OTP_SUCCESS",
   payload
});

const validateAgencyOtpError = (payload) => ({
   type: "VALIDATE_AGENCY_OTP_ERROR",
   payload
});

const staffProfileSetupRequired = (history) => ({
   type: "STAFF_PROFILE_SETUP_REQUIRED",
   history
});

const staffProfileSetupRequiredSuccess = (payload) => ({
   type: "STAFF_PROFILE_SETUP_REQUIRED_SUCCESS",
   payload
});

const staffProfileSetupRequiredError = () => ({
   type: "STAFF_PROFILE_SETUP_REQUIRED_ERROR"
});

const sendStaffOtp = (payload) => ({
   type: "SEND_STAFF_OTP",
   payload
});

const sendStaffOtpSuccess = (payload) => ({
   type: "SEND_STAFF_OTP_SUCCESS",
   payload
});

const sendStaffOtpError = (payload) => ({
   type: "SEND_STAFF_OTP_ERROR",
   payload
});

const updateStaffMobileNo = (payload) => ({
   type: "UPDATE_STAFF_MOBILE_NO",
   payload
});

const updateStaffMobileNoSuccess = (payload) => ({
   type: "UPDATE_STAFF_MOBILE_NO_SUCCESS",
   payload
});

const updateStaffMobileNoError = (payload) => ({
   type: "UPDATE_STAFF_MOBILE_NO_ERROR",
   payload
});

const updateStaffTwoFactorAuth = (payload) => ({
   type: "UPDATE_STAFF_TWO_FACT_AUTH",
   payload
});

const updateStaffTwoFactorAuthSuccess = (payload) => ({
   type: "UPDATE_STAFF_TWO_FACT_AUTH_SUCCESS",
   payload
});

const updateStaffTwoFactorAuthError = (payload) => ({
   type: "UPDATE_STAFF_TWO_FACT_AUTH_ERROR",
   payload
});

const validateStaffOtp = (payload) => ({
   type: "VALIDATE_STAFF_OTP",
   payload
});

const validateStaffOtpSuccess = (payload) => ({
   type: "VALIDATE_STAFF_OTP_SUCCESS",
   payload
});

const validateStaffOtpError = (payload) => ({
   type: "VALIDATE_STAFF_OTP_ERROR",
   payload
});

const getAgencyById = () => ({
   type: "GET_AGENCY_BY_ID"
});

const getAgencyByIdSuccess = (payload) => ({
   type: "GET_AGENCY_BY_ID_SUCCESS",
   payload
});

const getAgencyByIdError = () => ({
   type: "GET_AGENCY_BY_ID_ERROR"
});

export {
   updateAgencyProfile,
   updateAgencyProfileSuccess,
   updateAgencyProfileError,
   adminGeneralSystemSetup,
   adminGeneralSystemSetupSuccess,
   adminGeneralSystemSetupError,
   getAllCountryCallingCode,
   getAllCountryCallingCodeSuccess,
   getAllCountryCallingCodeError,
   getAllCurrency,
   getAllCurrencySuccess,
   getAllCurrencyError,
   getAllTimezone,
   getAllTimezoneSuccess,
   getAllTimezoneError,
   profileSetupRequired,
   profileSetupRequiredSuccess,
   profileSetupRequiredError,
   sendAgencyOtp,
   sendAgencyOtpSuccess,
   sendAgencyOtpError,
   updateAgencyMobileNo,
   updateAgencyMobileNoSuccess,
   updateAgencyMobileNoError,
   updateAgencyTwoFactorAuth,
   updateAgencyTwoFactorAuthSuccess,
   updateAgencyTwoFactorAuthError,
   validateAgencyOtp,
   validateAgencyOtpSuccess,
   validateAgencyOtpError,
   staffProfileSetupRequired,
   staffProfileSetupRequiredSuccess,
   staffProfileSetupRequiredError,
   sendStaffOtp,
   sendStaffOtpSuccess,
   sendStaffOtpError,
   updateStaffMobileNo,
   updateStaffMobileNoSuccess,
   updateStaffMobileNoError,
   updateStaffTwoFactorAuth,
   updateStaffTwoFactorAuthSuccess,
   updateStaffTwoFactorAuthError,
   validateStaffOtp,
   validateStaffOtpSuccess,
   validateStaffOtpError,
   getAgencyById,
   getAgencyByIdSuccess,
   getAgencyByIdError
};
