const showAddProperty = () => ({
   type: "OPEN_MODAL",
   modal: "ADD_PROPERTY"
});

const closeAddProperty = () => ({
   type: "CLOSE_MODAL",
   modal: "ADD_PROPERTY"
});

const showAddLandlord = () => ({
   type: "OPEN_MODAL",
   modal: "ADD_LANDLORD"
});

const closeAddLandlord = () => ({
   type: "CLOSE_MODAL",
   modal: "ADD_LANDLORD"
});

const showAddTenant = () => ({
   type: "OPEN_MODAL",
   modal: "ADD_TENANT"
});

const closeAddTenant = () => ({
   type: "CLOSE_MODAL",
   modal: "ADD_TENANT"
});

const openAddTask = () => ({
   type: "OPEN_ADD_TASK"
});

const closeAddTask = () => ({
   type: "CLOSE_ADD_TASK"
});

const showAddServiceProfessional = () => ({
   type: "OPEN_MODAL",
   modal: "ADD_SERVICE_PROFESSIONAL"
});

const closeAddServiceProfessional = () => ({
   type: "CLOSE_MODAL",
   modal: "CLOSE_SERVICE_PROFESSIONAL"
});

const openStatementModal = () => ({
   type: "OPEN_MODAL",
   modal: "STATEMENT_MODAL"
});

const closeStatementModal = () => ({
   type: "CLOSE_MODAL",
   modal: "STATEMENT_MODAL"
});

const showServiceType = () => ({
   type: "OPEN_MODAL",
   modal: "ADD_PROPERTY"
});

const closeServiceType = () => ({
   type: "CLOSE_MODAL",
   modal: "ADD_PROPERTY"
});

export const openDocumentViewer = () => ({
   type: "OPEN_MODAL",
   modal: "DOCUMENT_VIEWER"
});

export const closeDocumentViewer = () => ({
   type: "CLOSE_MODAL",
   modal: "DOCUMENT_VIEWER"
});

export const openMaxCountModal = () => ({
   type: "OPEN_MODAL",
   modal: "MAX_COUNT_MODAL"
});

export const closeMaxCountModal = () => ({
   type: "CLOSE_MODAL",
   modal: "MAX_COUNT_MODAL"
});

export const openSupportExtModal = () => ({
   type: "OPEN_MODAL",
   modal: "SUPPORT_EXT_MODAL"
});

export const closeSupportExtModal = () => ({
   type: "CLOSE_MODAL",
   modal: "SUPPORT_EXT_MODAL"
});

export const openDataUsageModal = () => ({
   type: "OPEN_MODAL",
   modal: "DATA_USAGE_MODAL"
});

export const closeDataUsageModal = () => ({
   type: "CLOSE_MODAL",
   modal: "DATA_USAGE_MODAL"
});

export const openRequestInfoModal = () => ({
   type: "OPEN_MODAL",
   modal: "REQUEST_INFO"
});

export const closeRequestInfoModal = () => ({
   type: "CLOSE_MODAL",
   modal: "REQUEST_INFO"
});

export const openSizeModal = () => ({
   type: "OPEN_MODAL",
   modal: "SIZE_WARNING_MODAL"
});

export const closeSizeModal = () => ({
   type: "CLOSE_MODAL",
   modal: "SIZE_WARNING_MODAL"
});

export const openRestrictionModal = () => ({
   type: "OPEN_MODAL",
   modal: "RESTRICTION_MODAL"
});

export const closeRestrictionModal = () => ({
   type: "CLOSE_MODAL",
   modal: "RESTRICTION_MODAL"
});

export const openSuccessModal = (payload) => ({
   type: "OPEN_MODAL",
   modal: "SUCCESS_MODAL",
   payload
});

export const closeSuccessModal = () => ({
   type: "CLOSE_MODAL",
   modal: "SUCCESS_MODAL"
});

export {
   showAddProperty,
   closeAddProperty,
   showAddLandlord,
   closeAddLandlord,
   showAddTenant,
   closeAddTenant,
   openAddTask,
   closeAddTask,
   showAddServiceProfessional,
   closeAddServiceProfessional,
   showServiceType,
   closeServiceType,
   openStatementModal,
   closeStatementModal
};
