import axios from "axios";
import { LocalStorageService } from "../services/localStorageService";
import { toast } from "react-toastify";
import store from "../store/store";
import { signoutRequest } from "../actions/authActions";
const localStorageService = LocalStorageService.getService();

const nodejsAPI = axios.create({
   baseURL: process.env.REACT_APP_NODE_API_URL,
   headers: {
      "Content-Type": "application/json"
   }
});

nodejsAPI.interceptors.request.use(
   (config) => {
      const token = localStorageService.getAccessToken();
      if (token) {
         config.headers["Authorization"] = "Bearer " + token;
      }
      if (!config.headers["Content-Type"]) {
         config.headers["Content-Type"] =
            "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq";
      }
      return config;
   },
   (error) => {
      Promise.reject(error);
   }
);

nodejsAPI.interceptors.response.use(
   (response) => {
      if (
         (response.data.message &&
            window.location.pathname.split("/").indexOf("auth") === -1 &&
            response.config.method === "post") ||
         response.config.method === "put" ||
         response.config.method === "delete"
      ) {
         toast.dark(response.data.message, { closeButton: false });
      }
      return response;
   },
   function (error) {
      console.error(error.response);
      const originalRequest = error.config;
      if (error.response.status === 400) {
         if (
            window.location.pathname.split("/").indexOf("auth") === -1 &&
            originalRequest.method !== "get"
         ) {
            toast.error(error.response.data.message, {
               autoClose: false
            });
         }
         return Promise.reject(error);
      } else if (error.response.status === 401) {
         // localStorageService.clearToken();
         store.dispatch(signoutRequest());
         return Promise.reject(error);
      } else {
         if (
            window.location.pathname.split("/").indexOf("auth") === -1 &&
            originalRequest.method !== "get" &&
            error.response.data.message
         ) {
            toast.error(error.response.data.message, {
               position: toast.POSITION.BOTTOM_LEFT,
               closeButton: false,
               autoClose: 3000
            });
         }
      }
      return Promise.reject(error);
   }
);

export default nodejsAPI;
