import { put, call, takeEvery } from "redux-saga/effects";

import {
   fetchNotificationsSuccess,
   fetchNotificationsError,
   dismissNotificationSuccess,
   dismissNotificationError,
   getNewCountSuccess,
   getNewCountError,
   markAsOldError,
   markAsOldSuccess
} from "../actions/notificationActions";

import {
   notificationData,
   fetchnotifications,
   changeNotificationStatus,
   newCount,
   markAsOld
} from "../api/notifications";

function* handleSendNotification(action) {
   try {
      yield call(notificationData, action.payload);
   } catch (error) {
      console.error(error);
   }
}

function* handleFetchNotification(action) {
   if (!action.payload.error) {
      try {
         const response = yield call(fetchnotifications, action.payload);
         yield put(fetchNotificationsSuccess(response.data));
      } catch (error) {
         yield put(fetchNotificationsError(error));
      }
   }
}

function* handleChangeNotificationStatus(action) {
   try {
      yield call(changeNotificationStatus, action.payload);
      yield put(dismissNotificationSuccess(action.payload));
   } catch (error) {
      yield put(dismissNotificationError(action.payload));
   }
}

function* handleGetCount() {
   try {
      const resp = yield call(newCount);
      yield put(getNewCountSuccess(resp.data.response));
   } catch (err) {
      yield put(getNewCountError());
   }
}

function* handleMarkAsOld(action) {
   try {
      yield call(markAsOld, action.id);
      yield put(markAsOldSuccess());
   } catch (err) {
      yield put(markAsOldError());
   }
}

export default function* watchNotification() {
   yield takeEvery("SEND_NOTIFICATION", handleSendNotification);
   yield takeEvery("FETCH_NOTIFICATIONS_REQUEST", handleFetchNotification);
   yield takeEvery(
      "DISMISS_NOTIFICATION_REQUEST",
      handleChangeNotificationStatus
   );
   yield takeEvery("GET_NEW_COUNT", handleGetCount);
   yield takeEvery("MARK_AS_OLD", handleMarkAsOld);
}
