import produce from "immer";

const INITIAL_STATE = {
   loading: false,
   staffs: {
      loaded: false,
      loading: false,
      list: [],
      designation: null,
      status: null,
      searchBy: null,
      orderBy: null,
      sortBy: null,
      totalPages: 1,
      currentPage: 1,
      entriesPerPage: 10
   },
   Deletestaffs: {
      success: false,
      loading: false
   },
   newArr: [],
   staffbyid: [],
   addstaffmodel: false,
   addStaffLoading: false,
   staffimage: null,
   staffhistory: {
      data: [],
      loading: false,
      pagination: {}
   },
   addstaffsucess: false,
   updateStaffSucess: false,
   userID: "",
   staffdetailCurrenttab: 1,
   updatestaffAccess: {
      success: false,
      error: null
   },
   staffsByDesignation: [],
   staffsByDesignationLoading: [false],
   staffsByDesignationInspection: [],
   staffRoles: {
      staffRolesdata: [],
      loading: false
   },
   staffGeneralSystemSetupSuccess: false,
   allStaff: [],
   loggedStaffDetails: {
      data: {},
      loading: false
   }
};

export default (state = INITIAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_STAFFS":
            draft.addstaffsucess = false;
            draft.staffs.loading = true;
            draft.staffs.designation = action.payload.designation;
            draft.staffs.status = action.payload.status;
            draft.staffs.sortBy = action.payload.sortBy;
            draft.staffs.orderBy = action.payload.orderBy;
            draft.staffs.searchBy = action.payload.searchBy;

            draft.updateStaffSucess = false;
            draft.Deletestaffs.success = false;
            break;

         case "GET_STAFFS_SUCCESS":
            draft.staffs.loaded = true;
            draft.staffs.list = action.response.response.staffDTOList;
            draft.staffs.totalPages =
               action.response.response.paginationDetails.totalPages;
            draft.staffs.currentPage =
               action.response.response.paginationDetails.currentPage + 1;
            draft.staffs.entriesPerPage =
               action.response.response.paginationDetails.pageSize;
            draft.staffs.loading = false;
            draft.staffs.success = false;
            draft.updateStaffSucess = false;
            break;

         case "GET_STAFFS_ERROR":
            draft.loading = false;
            draft.staffs.loading = false;
            draft.staffs.list = [];
            draft.staffs.totalPages = 0;
            draft.staffs.currentPage = 1;
            break;

         case "RESET_STAFFS_FILTER":
            draft.staffs.status = null;
            draft.staffs.designation = null;
            break;

         case "GET_STAFFS_BYID":
            draft.loading = true;
            break;

         case "GET_STAFFS__BYID_SUCCESS":
            draft.staffbyid = action.payload.response;
            draft.loading = false;
            break;

         case "GET_STAFFS_BYID_ERROR":
            draft.loading = false;
            break;

         case "ADD_STAFFS":
            draft.addstaffsucess = false;
            draft.addStaffLoading = true;
            break;

         case "ADD_STAFFS_ERROR":
            draft.addStaffLoading = false;
            break;

         case "ADD_STAFFS_SUCCESS":
            draft.addstaffmodel = false;
            draft.addstaffsucess = true;
            draft.addStaffLoading = false;
            break;

         case "UPDATE_STAFFS":
            draft.addstaffsucess = false;
            draft.updateStaffSucess = false;
            draft.loading = true;
            break;

         case "UPDATE_STAFFS_ERROR":
            draft.loading = false;
            draft.updateStaffSucess = false;
            break;

         case "UPDATE_STAFFS_SUCCESS":
            draft.addstaffsucess = true;
            draft.updateStaffSucess = true;
            draft.loading = false;
            // draft.userID = action.payload.data.response.userDetails.id;
            break;

         case "OPEN_ADD_STAFFS_MODEL":
            draft.addstaffmodel = true;
            break;
         case "CLOSE_ADD_STAFFS_MODEL":
            draft.addstaffmodel = false;
            break;

         case "DELETE_STAFFS":
            draft.Deletestaffs.loading = true;
            draft.Deletestaffs.success = false;
            break;

         case "DELETE_STAFFS_ERROR":
            draft.Deletestaffs.loading = false;
            draft.Deletestaffs.success = false;
            break;

         case "DELETE_STAFFS_SUCCESS":
            draft.Deletestaffs.loading = false;
            draft.Deletestaffs.success = true;
            break;

         case "ADD_STAFFS_RANDOM_PASSWORD":
            draft.loading = true;
            break;

         case "ADD_STAFFS_RANDOM_PASSWORD_ERROR":
            draft.loading = false;
            break;

         case "ADD_STAFFS_RANDOM_PASSWORD_SUCCESS":
            draft.loading = false;
            break;

         case "GET_STAFFS_IMAGE":
            draft.loading = true;
            break;
         case "GET_STAFFS_IMAGE_SUCCESS":
            draft.staffimage = action.payload.response;
            draft.loading = false;
            break;
         case "GET_STAFFS_IMAGE_ERROR":
            draft.loading = false;
            break;

         case "GET_STAFFS_HISTORY_BYID":
            draft.staffhistory.loading = true;
            break;

         case "SET_NULL_STAFF_HISTORY":
            draft.staffhistory.data = [];
            break;

         case "GET_STAFFS_HISTORY_BYID_SUCCESS":
            draft.staffhistory.data = action.payload.response.staffHistoryLog;
            draft.staffhistory.pagination =
               action.payload.response.paginationDetails;
            draft.staffhistory.loading = false;
            break;

         case "GET_STAFFS_HISTORY_BYID_ERROR":
            draft.staffhistory.loading = false;
            break;

         case "SET_STAFFS_DETAILS_CURRENTTAB":
            draft.staffdetailCurrenttab = action.payload;
            break;

         case "UPDATE_STAFFS_ACCESS":
            draft.updatestaffAccess.success = false;
            break;

         case "UPDATE_STAFFS_ACCESS_ERROR":
            draft.updatestaffAccess.success = false;
            draft.updatestaffAccess.error = action.payload.response;
            break;

         case "UPDATE_STAFFS_ACCESS_SUCCESS":
            draft.updatestaffAccess.success = true;

            break;

         case "GET__ALL_STAFFS_ROLE":
            draft.staffRoles.loading = true;
            break;

         case "GET_ALL_STAFFS_ROLE_ERROR":
            break;

         case "GET_ALL_STAFFS__ROLE_SUCCESS":
            draft.staffRoles.staffRolesdata = action.payload.response;
            draft.staffRoles.loading = false;
            break;

         case "STAFF_GENERAL_SYSTEM_SETUP":
            draft.staffGeneralSystemSetupSuccess = false;
            draft.loading = true;
            break;

         case "STAFF_GENERAL_SYSTEM_SETUP_ERROR":
            draft.staffGeneralSystemSetupSuccess = false;
            draft.loading = false;
            break;

         case "STAFF_GENERAL_SYSTEM_SETUP_SUCCESS":
            draft.staffGeneralSystemSetupSuccess = true;
            draft.loading = false;
            break;

         case "GET_ALL_STAFFS":
            draft.loading = true;
            draft.updateStaffSucess = false;
            break;

         case "GET_ALL_STAFFS_SUCCESS":
            draft.loading = false;
            draft.allStaff = action.payload.data.response;
            break;

         case "GET_ALL_STAFFS_ERROR":
            draft.loading = false;
            draft.allStaff = [];
            break;

         case "GET_STAFFS_BY_DESIGNATION":
            draft.staffsByDesignationLoading = true;
            break;

         case "GET_STAFFS_BY_DESIGNATION_SET_NULL":
            draft.staffsByDesignation = [];
            draft.staffsByDesignationLoading = false;
            break;

         case "GET_STAFFS_BY_DESIGNATION_SUCCESS":
            draft.staffsByDesignation = action.response.response;
            draft.staffsByDesignationLoading = false;
            break;

         case "GET_STAFFS_BY_DESIGNATION_ERROR":
            draft.staffsByDesignation = [];
            draft.staffsByDesignationLoading = false;
            break;

         case "GET_STAFFS_BY_DESIGNATION_INSPECTION":
            break;

         case "GET_STAFFS_BY_DESIGNATION_INSPECTION_SET_NULL":
            draft.staffsByDesignationInspection = [];
            break;

         case "GET_STAFFS_BY_DESIGNATION_INSPECTION_SUCCESS":
            draft.staffsByDesignationInspection = action.response.response;
            break;

         case "GET_STAFFS_BY_DESIGNATION_INSPECTION_ERROR":
            draft.staffsByDesignationInspection = action.data;
            break;

         case "GET_LOGGED_STAFF_DETAILS_REQUEST":
            draft.loggedStaffDetails.loading = true;
            break;

         case "GET_LOGGED_STAFF_DETAILS_SUCCESS":
            draft.loggedStaffDetails.data = action.payload.response;
            draft.loggedStaffDetails.loading = false;
            break;

         case "GET_LOGGED_STAFF_DETAILS_ERROR":
            draft.loggedStaffDetails.loading = false;
            break;

         default:
            return draft;
      }
   });
