import { put, call, takeEvery } from "redux-saga/effects";

import {
   getAllContractsSuccess,
   getAllContractsError,
   getContractTypeSuccess,
   getContractTypeError,
   cancelContractError,
   cancelContractSuccess
} from "../actions/contractsAction";
import {
   cancelContract,
   getAllContracts,
   getContractType
} from "../api/contract";

function* handleGetAllContracts({ payload }) {
   try {
      const resp = yield call(getAllContracts, payload);
      yield put(getAllContractsSuccess(resp.data));
   } catch {
      yield put(getAllContractsError());
   }
}

function* handleGetContractType() {
   try {
      const resp = yield call(getContractType);
      yield put(getContractTypeSuccess(resp.data));
   } catch {
      yield put(getContractTypeError());
   }
}

function* handleCancelContract(action) {
   try {
      yield call(cancelContract, action.payload);
      yield put(cancelContractSuccess());
   } catch {
      yield put(cancelContractError());
   }
}

export default function* watchContractsAction() {
   yield takeEvery("GET_ALL_CONTRACTS", handleGetAllContracts);
   yield takeEvery("GET_CONTRACT_TYPE", handleGetContractType);
   yield takeEvery("CANCEL_CONTRACT_REQUEST", handleCancelContract);
}
