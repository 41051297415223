import { put, call, takeEvery, select } from "redux-saga/effects";
import {
   signinSuccess,
   signinError,
   signupSuccess,
   signupError,
   verfiyEmailSuccess,
   verfiyEmailError,
   forgotPasswordSuccess,
   forgotPasswordError,
   resetPasswordSuccess,
   resetPasswordError,
   resetPasswordStaffError,
   resetPasswordStaffSuccess,
   staffSigninError,
   staffSigninSuccess,
   signoutError,
   signoutSuccess,
   subscribeTokenToFirebaseSuccess,
   subscribeTokenToFirebaseError,
   cleanTokenSuccess,
   cleanTokenError,
   validateCurrentPasswordSuccess,
   validateCurrentPasswordError
} from "../actions/authActions";
import {
   setLoggedIn,
   setLoggedOut,
   setLogoutloading
} from "../actions/miscActions";
import {
   signin,
   signup,
   verifyEmail,
   forgotPassword,
   resetPassword,
   resetStaffPassword,
   staffSignin,
   signout,
   saveToken,
   cleanToken,
   validateCurrentPassword
} from "../api/auth";
import { LocalStorageService } from "../services/localStorageService";
const localStorageService = LocalStorageService.getService();

function* handleSignin(payload) {
   try {
      const response = yield call(signin, payload.payload);
      localStorageService.setToken(response.data.accessToken);
      yield put(signinSuccess(response));
      yield put(setLoggedIn());
   } catch (error) {
      yield put(signinError(error.response));
   }
}

function* handleStaffSignin(payload) {
   try {
      const response = yield call(staffSignin, payload.payload);
      localStorageService.setToken(response.data.response.accessToken);
      yield put(staffSigninSuccess(response));
      yield put(setLoggedIn());
   } catch (error) {
      yield put(staffSigninError(error.response.data));
   }
}

function* handleSignup(payload) {
   try {
      const response = yield call(signup, payload.payload);
      yield put(signupSuccess(response));
   } catch (error) {
      yield put(signupError(error.response.data));
   }
}

function* handleVerifyEmail(payload) {
   try {
      const response = yield call(verifyEmail, payload.payload);
      yield put(verfiyEmailSuccess(response));
   } catch (error) {
      yield put(verfiyEmailError(error.response.data));
   }
}

function* handleForgotPassword(action) {
   try {
      const response = yield call(forgotPassword, action.payload);
      yield put(forgotPasswordSuccess(response));
   } catch (error) {
      yield put(forgotPasswordError(error.response.data));
   }
}

function* handleResetPassword(action) {
   try {
      const response = yield call(resetPassword, action.payload);
      yield put(resetPasswordSuccess(response));
   } catch (error) {
      yield put(resetPasswordError(error.response.data));
   }
}

function* handleResetStaffPassword(action) {
   try {
      const response = yield call(resetStaffPassword, action.payload);
      yield put(resetPasswordStaffSuccess(response));
   } catch (error) {
      yield put(resetPasswordStaffError(error.response.data));
   }
}

function* handleSignOut(action) {
   const { logout } = yield select((state) => state.misc);
   try {
      if (!logout.loggedOut && !logout.loading) {
         yield put(setLogoutloading());
         const response = yield call(signout, action.payload);
         yield put(signoutSuccess(response));
         yield put(setLoggedOut());
      }
   } catch (error) {
      yield put(signoutError(error.response.data));
   }
}

function* handleSaveToken(action) {
   try {
      yield call(saveToken, action.token);
      yield put(subscribeTokenToFirebaseSuccess());
   } catch (err) {
      yield put(subscribeTokenToFirebaseError());
   }
}

function* handleCleanToken(action) {
   try {
      yield call(cleanToken, action.token);
      yield put(cleanTokenSuccess());
   } catch (error) {
      yield put(cleanTokenError());
   }
}

function* handleValidatePassword(action) {
   try {
      let resp = yield call(validateCurrentPassword, action.payload);
      yield put(validateCurrentPasswordSuccess(resp.data));
   } catch {
      yield put(validateCurrentPasswordError());
   }
}

export default function* watchLogin() {
   yield takeEvery("SIGNIN_REQUEST", handleSignin);
   yield takeEvery("SIGNUP_REQUEST", handleSignup);
   yield takeEvery("VERIFY_EMAIL_REQUEST", handleVerifyEmail);
   yield takeEvery("FORGOT_PASSWORD_REQUEST", handleForgotPassword);
   yield takeEvery("RESET_PASSWORD_REQUEST", handleResetPassword);
   yield takeEvery("RESET_PASSWORD_STAFF_REQUEST", handleResetStaffPassword);
   yield takeEvery("STAFF_SIGNIN_REQUEST", handleStaffSignin);
   yield takeEvery("SIGNOUT_REQUEST", handleSignOut);
   yield takeEvery("SUBSCRIBE_TOKEN_TO_FIREBASE", handleSaveToken);
   yield takeEvery("CLEAN_TOKEN", handleCleanToken);
   yield takeEvery("VALIDATE_CURRENT_PASSWORD_REQUEST", handleValidatePassword);
}
