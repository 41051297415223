export const getPermissionsRequest = () => ({
   type: "GET_PERMISSION_REQUEST"
});

export const getPermissionsError = () => ({
   type: "GET_PERMISSION_ERROR"
});

export const getPermissionsSuccess = (payload) => ({
   type: "GET_PERMISSION_SUCCESS",
   payload
});

export const editPermissionRequest = (payload) => ({
   type: "EDIT_PERMISSION_REQUEST",
   payload
});

export const editPermissionSuccess = () => ({
   type: "EDIT_PERMISSION_SUCCESS"
});

export const editPermissionError = () => ({
   type: "EDIT_PERMISSION_ERROR"
});
