import API from "./API";

export const UploadSingleFile = async (file, cb) => {
   const formData = new FormData();
   formData.append("file", file.originFileObj);
   return {
      response: await API.post("/api/upload", formData, {
         onUploadProgress: (e) => cb(e, file.uid)
      }),
      token: file.uid
   };
};

export const GetSingleFile = (fileId) => {
   return API.get(`/api/getfile/${fileId}`);
};

export const DeleteFiles = (ids) => {
   return API.delete(`/api/deletefile/${ids}`);
};

export const getBinaryFile = async (id) => {
   return await API.get(`/api/getbinaryfile/${id}`, {
      responseType: "blob",
      headers: {
         Accept: "*"
      }
   });
};
