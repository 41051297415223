import jwt_decode from "jwt-decode";

const LocalStorageService = (function () {
   var _service;
   function _getService() {
      if (!_service) {
         _service = this;
         return _service;
      }
      return _service;
   }

   function _setItem(item, value) {
      localStorage.setItem(item, value);
   }

   function _getItem(item) {
      return localStorage.getItem(item);
   }

   function _setToken(token) {
      localStorage.setItem("access_token", token);
      //    localStorage.setItem(‘refresh_token’, tokenObj.refresh_token);
   }

   function _getAccessToken() {
      return localStorage.getItem("access_token");
   }

   function _getRefreshToken() {
      //    return localStorage.getItem(‘refresh_token’);
   }

   function _clearToken() {
      localStorage.removeItem("access_token");
      //    localStorage.removeItem(‘refresh_token’);
   }

   function _getUserData() {
      try {
         return jwt_decode(localStorage.getItem("access_token")).user;
      } catch (e) {
         return "JWT could not be decoded";
      }
   }

   return {
      getService: _getService,
      setItem: _setItem,
      getItem: _getItem,
      setToken: _setToken,
      getAccessToken: _getAccessToken,
      getRefreshToken: _getRefreshToken,
      clearToken: _clearToken,
      getUserData: _getUserData
   };
})();
export { LocalStorageService };
