export const createRequestInfoRequest = (payload) => ({
   type: "CREATE_REQUEST_INFO_REQUEST",
   payload
});

export const createRequestInfoSuccess = () => ({
   type: "CREATE_REQUEST_INFO_SUCCESS"
});

export const createRequestInfoError = () => ({
   type: "CREATE_REQUEST_INFO_ERROR"
});

export const getRequestInfoStatusRequest = (payload) => ({
   type: "GET_REQUEST_INFO_STATUS_REQUEST",
   payload
});

export const getRequestInfoStatusSuccess = (payload) => ({
   type: "GET_REQUEST_INFO_STATUS_SUCCESS",
   payload
});

export const getRequestInfoStatusError = () => ({
   type: "GET_REQUEST_INFO_STATUS_ERROR"
});

export const getRequestInfoRequest = (payload) => ({
   type: "GET_REQUEST_INFO_REQUEST",
   payload
});

export const getRequestInfoSuccess = (payload) => ({
   type: "GET_REQUEST_INFO_SUCCESS",
   payload
});

export const getRequestInfoError = () => ({
   type: "GET_REQUEST_INFO_ERROR"
});

export const rejectRequestInfoRequest = (payload) => ({
   type: "REJECT_REQUEST_INFO_REQUEST",
   payload
});

export const rejectRequestInfoSuccess = () => ({
   type: "REJECT_REQUEST_INFO_SUCCESS"
});

export const rejectRequestInfoError = () => ({
   type: "REJECT_REQUEST_INFO_ERROR"
});

export const approveRequestInfoRequest = (payload) => ({
   type: "APPROVE_REQUEST_INFO_REQUEST",
   payload
});

export const approveRequestInfoSuccess = () => ({
   type: "APPROVE_REQUEST_INFO_SUCCESS"
});

export const approveRequestInfoError = () => ({
   type: "APPROVE_REQUEST_INFO_ERROR"
});
