import produce from "immer";

const INITAL_STATE = {
   guarantorListBypropertyId: {
      loading: false,
      guarantorList: null
   },

   addGuarantor: {
      loading: false,
      guarantor: null
   },

   deleteGuarantor: {
      loading: false
   },

   updateGuarantor: {
      loading: false,
      guarantor: null
   }
};

export default (state = INITAL_STATE, action) =>
   produce(state, (draft) => {
      switch (action.type) {
         case "GET_GUARANTOR_LIST_BY_PROPERTY_ID":
            draft.guarantorListBypropertyId.loading = true;
            break;

         case "GET_GUARANTOR_LIST_BY_PROPERTY_ID_SUCCESS":
            draft.guarantorListBypropertyId.loading = false;
            draft.guarantorListBypropertyId.guarantorList =
               action.payload.response;
            break;

         case "GET_GUARANTOR_LIST_BY_PROPERTY_ID_ERROR":
            draft.guarantorListBypropertyId.loading = false;
            draft.guarantorListBypropertyId.guarantorList = [];
            break;

         case "ADD_GUARANTOR":
            draft.addGuarantor.loading = true;
            break;

         case "ADD_GUARANTOR_SUCCESS":
            draft.addGuarantor.guarantor = action.payload.response;
            draft.addGuarantor.loading = false;
            break;

         case "ADD_GUARANTOR_ERROR":
            draft.addGuarantor.loading = false;
            break;

         case "DELETE_GUARANTOR_BY_ID":
            draft.deleteGuarantor.loading = true;
            break;

         case "DELETE_GUARANTOR_BY_ID_SUCCESS":
            // draft.deleteGuarantor.guarantor = action.payload.response;
            draft.deleteGuarantor.loading = false;
            break;

         case "DELETE_GUARANTOR_BY_ID_ERROR":
            draft.deleteGuarantor.loading = false;
            break;

         case "UPDATE_GUARANTOR":
            draft.updateGuarantor.loading = true;
            break;

         case "UPDATE_GUARANTOR_SUCCESS":
            draft.updateGuarantor.guarantor = action.payload.response;
            draft.updateGuarantor.loading = false;
            break;

         case "UPDATE_GUARANTOR_ERROR":
            draft.updateGuarantor.loading = false;
            break;

         default:
            return draft;
      }
   });
