import API from "./API";
import jwt_decode from "jwt-decode";

export const UpdateAgencyProfile = async (data) => {
   let formData = new FormData();
   Object.keys(data).map((key) => formData.append(key, data[key]));

   formData.delete("company_logo_pic");
   data.company_logo_pic &&
      formData.append("company_logo_pic", data.company_logo_pic);

   formData.delete("profilePicture");
   data.profilePicture &&
      formData.append("profilePicture", data.profilePicture);

   formData.delete("cmpEmail");
   formData.delete("cmpContact");
   formData.delete("usrPemail");
   formData.delete("usrPcontact");
   formData.delete("usrOemail");
   formData.delete("usrOcontact");

   formData.delete("companyEmails");
   data.companyEmails.map((email, idx) => {
      for (const property in email) {
         formData.append(
            `companyEmails[${idx}].${property}`,
            email[property] ? email[property] : ""
         );
      }
   });

   formData.delete("companyContacts");
   data.companyContacts.map((contact, idx) => {
      for (const property in contact) {
         formData.append(
            `companyContacts[${idx}].${property}`,
            contact[property]
         );
      }
   });

   formData.delete("userContacts");
   data.userContacts.map((contact, idx) => {
      for (const property in contact) {
         formData.append(`userContacts[${idx}].${property}`, contact[property]);
      }
   });

   formData.delete("userEmails");
   data.userEmails.map((email, idx) => {
      for (const property in email) {
         formData.append(
            `userEmails[${idx}].${property}`,
            email[property] ? email[property] : ""
         );
      }
   });

   return await API.post("/api/agency/updateAgencyProfile", formData);
};

export const AdminGeneralSystemSetup = async (data) => {
   const { agencyId } = jwt_decode(localStorage.getItem("access_token"));

   return await API.post("api/agency/adminGeneralSystemSetup", {
      ...data,
      agencyId
   });
};

export const GetAllCountryCallingCode = async () => {
   return await API.get("api/agency/getAllCountryCallingCode");
};

export const GetAllCurrency = async () => {
   return await API.get("api/agency/getAllCurrency");
};

export const GetAllTimezone = async () => {
   return await API.get("api/agency/getAllTimezone");
};

export const IsProfileSetupRequired = async () => {
   const { email } = jwt_decode(localStorage.getItem("access_token"));

   return await API.get("api/agency/isProfileSetupRequired", {
      params: {
         agencyRegisteredEmail: email
      }
   });
};

export const SendAgencyOtp = async (data) => {
   // const { agencyId } = jwt_decode(localStorage.getItem("access_token"));

   return await API.post("api/agency/sendAgencyOtp", data);
};

export const UpdateAgencyMobileNo = async (data) => {
   return await API.post("api/agency/updateAgencyMobileNo", data);
};

export const UpdateAgencyTwoFactorAuth = async (data) => {
   const { agencyId } = jwt_decode(localStorage.getItem("access_token"));

   return await API.post("api/agency/updateAgencyTwoFactorAuthentication", {
      ...data,
      agencyId: agencyId
   });
};

export const ValidateAgencyOtp = async (data) => {
   return await API.post("api/agency/validateAgencyOtp", data);
};

export const StaffProfileSetupRequired = async () => {
   const { email } = jwt_decode(localStorage.getItem("access_token"));

   return await API.get("api/staff/isProfileSetupRequired", {
      params: {
         staffRegisteredEmail: email
      }
   });
};

export const SendStaffOtp = async (data) => {
   return await API.post("api/staff/sendStaffOtp", data);
};

export const UpdateStaffMobileNo = async (data) => {
   return await API.post("api/staff/updateStaffMobileNo", data);
};

export const UpdateStaffTwoFactorAuth = async (data) => {
   const { staffId } = jwt_decode(localStorage.getItem("access_token"));

   return await API.post("api/staff/updateTwoFactorAuthentication", {
      ...data,
      staffID: staffId
   });
};

export const ValidateStaffOtp = async (data) => {
   return await API.post("api/staff/validateStaffOtp", data);
};

export const GetAgencyById = async () => {
   return await API.get("/api/agency/getAgencyById");
};
